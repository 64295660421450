export const ChildrenRows = [
  {
    name: 'Child 1.',
    key: 'child_1',
  },
  {
    name: 'Child 2.',
    key: 'child_2',
  },
  {
    name: 'Child 3.',
    key: 'child_3',
  },
  {
    name: 'Child 4.',
    key: 'child_4',
  },
  {
    name: 'Child 5.',
    key: 'child_5',
  },
  {
    name: 'Child 6.',
    key: 'child_6',
  },
];

export const ChildrenInfoCols = [
  {
    name: 'Date',
    key: 'date',
  },
  {
    name: 'Procedure',
    key: 'procedure',
    options: [{ label: 'Vaginal Delivery/顺产' }, { label: 'Caesarean Section/剖腹产' }, { label: 'Miscarriage/流产' }, { label: 'Abortion/人工流产' }],
  },
  {
    name: 'Birth Weight',
    key: 'birth_weight',
  },
  {
    name: 'Birth Week',
    key: 'birth_week',
  },
  {
    name: 'Complications/Notes',
    key: 'complications_notes',
  },
];
