import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import * as classNames from 'classnames';
import { tify } from 'chinese-conv';
import AutoComplete from 'react-autocomplete';
import Styles from '../../../../DonorApplication/components/forms/BasicInformation/BasicInformation.module.scss';
import JobEducationForm from './JobEducationForm';
import Input from '../../../../DonorApplication/components/forms/BasicInformation/components/Input';
import DropdownMenu from '../../../../DonorApplication/components/forms/BasicInformation/components/DropdownMenu';
import { WifeRows, HusbandRows, JobInfoCols } from './constants';
import { LANG_EN, LANG_ZH_SIMPLIFIED } from '../../../../../res/constants';
import TableStyles from './JobTable.module.scss';
import { shallHighlight } from '../../../../../Utils/Util';

class JobEducationInfo extends React.Component {
  constructor(props) {
    super(props);
    const { form = new JobEducationForm() } = props;
    this.state = {
      form,
    };
  }

  handleInputChange = (event) => {
    const { target } = event;
    const { name: propName, value } = target;
    const { form } = this.state;
    const { user_attributes: userAttrs, form_attributes: formAttrs } = form;
    if (Object.prototype.hasOwnProperty.call(userAttrs, propName)) {
      userAttrs[propName].value = value;
    } else if (Object.prototype.hasOwnProperty.call(formAttrs, propName)) {
      formAttrs[propName].value = value;
    }
    this.setState({ form });
  };

  handleJobInfoTableInputChange = (event) => {
    const {
      target, row, col, formAttrs,
    } = event;
    const { name: propName, value } = target;
    const { form } = this.state;
    const attr = formAttrs[row.key][col.key];
    if (attr.propName === propName) {
      attr.value = value;
    }
    this.setState({ form });
  };

  renderFormTable = ({
    RowsAttrs, ColsAttrs, formAttrs, inputHandler, lang = LANG_EN, readOnly = false,
  }) => {
    const rows = RowsAttrs.map((row, index) => {
      let backgroundColor = '#fefafc';
      if (index % 2 === 0) {
        backgroundColor = '#ffffff';
      }
      const borderBottom = '1.5px solid rgba(238, 163, 201, 0.5)';
      const cols = ColsAttrs.map(col => (
        <div key={col.key} className={TableStyles.column} style={{ height: '40px' }}>
          <p style={{ visibility: 'hidden', margin: 0 }}>
            {lang === LANG_ZH_SIMPLIFIED ? col.name : tify(col.name)}
          </p>
          <div style={{
            position: 'absolute', minWidth: '100%', height: '100%', top: 0,
          }}
          >
            {col.options ? (
              <AutoComplete
                getItemValue={item => item.label}
                items={col.options}
                menuStyle={{
                  zIndex: '6000',
                  position: 'relative',
                  left: 0,
                  top: 0,
                  backgroundColor: 'red',
                }}
                renderItem={(item, isHighlighted) => (
                  <div
                    style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                  >
                    {item.label}
                  </div>
                )}
                value={formAttrs[row.key][col.key].value}
                onChange={(event) => {
                  const { target } = event;
                  target.name = formAttrs[row.key][col.key].propName;
                  inputHandler({
                    ...event, row, col, formAttrs, propName: formAttrs[row.key][col.key].propName,
                  });
                }}
                name={formAttrs[row.key][col.key].propName}
                onSelect={(val) => {
                  const event = {
                    target: {
                      name: formAttrs[row.key][col.key].propName,
                      value: val,
                    },
                  };
                  inputHandler({
                    ...event, row, col, formAttrs, propName: formAttrs[row.key][col.key].propName,
                  });
                }}
              />
            ) : (
              <input
                onChange={event => inputHandler({
                  ...event, row, col, formAttrs,
                })}
                readOnly={readOnly}
                name={formAttrs[row.key][col.key].propName}
                value={formAttrs[row.key][col.key].value}
                type={formAttrs[row.key][col.key].inputType}
              />
            )}
          </div>
        </div>
      ));
      return (
        <div className={TableStyles.row} key={row.key} style={[{ backgroundColor, borderBottom }]}>
          <div className={TableStyles.attrCell}>
            {lang === LANG_ZH_SIMPLIFIED ? row.name : tify(row.name)}
          </div>
          {cols}
        </div>
      );
    });
    const cols = ColsAttrs.map(col => (
      <div
        key={col.key}
        className={TableStyles.column}
      >
        {lang === LANG_ZH_SIMPLIFIED ? col.name : tify(col.name)}
      </div>
    ));
    const firstRow = (
      <div className={Styles.row}>
        <div className={TableStyles.attrCell} />
        {cols}
      </div>
    );
    return (
      <div className={TableStyles.table}>
        {firstRow}
        {rows}
      </div>
    );
  };

  render() {
    const { form } = this.state;
    const { user_attributes: userAttrs, form_attributes: formAttrs } = form;
    const { highlightRequiredField } = this.props;
    return (
      <div className={Styles.container}>
        <h1>Employment & Educational Information</h1>
        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(userAttrs.edu_level),
              })}
            >
              {`${userAttrs.edu_level.required ? '*' : ''}${userAttrs.edu_level.title}`}
            </p>
            <DropdownMenu
              className={Styles.fieldContainer}
              prop={userAttrs.edu_level}
              value={userAttrs.edu_level.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && shallHighlight(userAttrs.edu_level)}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(userAttrs.school),
              })}
            >
              {`${userAttrs.school.required ? '*' : ''}${userAttrs.school.title}`}
            </p>
            <Input
              prop={userAttrs.school}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && shallHighlight(userAttrs.school)}
            />
          </div>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${userAttrs.major.required ? '*' : ''}${userAttrs.major.title}`}</p>
            <Input
              prop={userAttrs.major}
              onchange={this.handleInputChange}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.stay_home_mom),
              })}
            >
              {`${formAttrs.stay_home_mom.required ? '*' : ''}${formAttrs.stay_home_mom.title}`}
            </p>
            <DropdownMenu
              className={Styles.fieldContainer}
              prop={formAttrs.stay_home_mom}
              value={formAttrs.stay_home_mom.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && shallHighlight(formAttrs.stay_home_mom)}
            />
          </div>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.stay_home_mom_notes.required ? '*' : ''}${formAttrs.stay_home_mom_notes.title}`}</p>
            <Input
              prop={formAttrs.stay_home_mom_notes}
              onchange={this.handleInputChange}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start', marginBottom: 0 }}>
          <div className={Styles.cell}>
            <p className={Styles.fieldTitle}>
              Please list your current and previous place of employment.
            </p>
            {this.renderFormTable({
              formAttrs: form.job_info_attributes,
              RowsAttrs: WifeRows,
              ColsAttrs: JobInfoCols,
              inputHandler: this.handleJobInfoTableInputChange,
            })}
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start', margin: '20px 0 0 0' }}>
          <div className={Styles.cell}>
            <p className={Styles.fieldTitle}>
              Please list your husband’s/partner’s current employment.
            </p>
            {this.renderFormTable({
              formAttrs: form.husband_job_info_attributes,
              RowsAttrs: HusbandRows,
              ColsAttrs: JobInfoCols,
              inputHandler: this.handleJobInfoTableInputChange,
            })}
          </div>
        </div>

        <div className={Styles.row} style={{ marginTop: '20px' }}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.has_stable_income),
              })}
            >
              {`${formAttrs.has_stable_income.required ? '*' : ''}${formAttrs.has_stable_income.title}`}
            </p>
            <DropdownMenu
              className={Styles.fieldContainer}
              prop={formAttrs.has_stable_income}
              value={formAttrs.has_stable_income.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && shallHighlight(formAttrs.has_stable_income)}
            />
          </div>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.num_ppl_you_support),
              })}
            >
              {`${formAttrs.num_ppl_you_support.required ? '*' : ''}${formAttrs.num_ppl_you_support.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.num_ppl_you_support}
              highlight={highlightRequiredField
              && shallHighlight(formAttrs.num_ppl_you_support)}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.receiving_food_stamps),
              })}
            >
              {`${formAttrs.receiving_food_stamps.required ? '*' : ''}${formAttrs.receiving_food_stamps.title}`}
            </p>
            <DropdownMenu
              className={Styles.fieldContainer}
              prop={formAttrs.receiving_food_stamps}
              value={formAttrs.receiving_food_stamps.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && shallHighlight(formAttrs.receiving_food_stamps)}
            />
          </div>
        </div>

      </div>
    );
  }
}

JobEducationInfo.defaultProps = {
  highlightRequiredField: false,
};

JobEducationInfo.propTypes = {
  form: PropTypes.instanceOf(JobEducationForm).isRequired,
  highlightRequiredField: PropTypes.bool,
};

export default Radium(JobEducationInfo);
