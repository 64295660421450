
export const EnKeyMaps = new Map([
  ['title', 'Success Stories'],
  ['read_more_txt', 'READ MORE'],
  ['view_all_txt', 'VIEW ALL'],
]);

export const ZhKeyMaps = new Map([
  ['title', '成功案例'],
  ['read_more_txt', '阅读更多'],
  ['view_all_txt', '查看更多'],
]);
