export const EnKeyMaps = new Map([
  ['FutureParents', 'Future Parents'],
  ['EggDonation', 'Egg Donation'],
  ['EggFreezing', 'Egg Freezing'],
  ['IVF', 'IVF'],
  ['Surrogacy', 'Surrogacy'],
  ['SuccessStories', 'Success Stories'],
  ['EggDonor', 'For Egg Donor'],
  ['Surrogate', 'For Surrogate'],
  ['Clinics', 'Clinics'],
  ['SignUp', 'Sign Up'],
  ['Login', 'Log In'],
  ['Logout', 'Log out'],
  ['menu', 'Menu'],
]);

export const ZhKeyMaps = new Map([
  ['FutureParents', '给准父母'],
  ['EggDonation', '卵子供给'],
  ['EggFreezing', '冻卵'],
  ['IVF', '试管婴儿'],
  ['Surrogacy', '代理妊娠'],
  ['SuccessStories', '成功案例'],
  ['EggDonor', '给捐卵者'],
  ['Surrogate', '给代理孕母'],
  ['Clinics', '合作诊所'],
  ['SignUp', '注册'],
  ['Login', '登陆'],
  ['Logout', '退出'],
  ['menu', '菜单'],
]);
