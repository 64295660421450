import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { connect } from 'react-redux';
import * as HttpStatus from 'http-status-codes';
import Styles from './LogIn.module.scss';
import { hideModal, showModal } from '../../../redux/Modal/Modalactions';
import ModalTypes from '../ModalTypes';
import { logIn } from '../../../Api/Apis/Users';
import { setUser } from '../../../redux/User/UserActions';
import { getAxiosInstance } from '../../../Utils/WebRequest';
import icClose from '../../../assets/close.png';
import { LANG_EN } from '../../../res/constants';
import { LoginEnContents, LoginZhContents } from './res/constants';
import { translate } from '../../../Utils/Util';

const mapStateToProps = state => ({
  langState: state.langState,
});

class LogInCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: {
        visible: false,
        message: '',
      },
      button: {
        disabled: false,
        text: '',
      },
      title: '',
      user: {
        email: '',
        password: '',
      },
      signUpTxt: '',
      emailInputPlaceholder: '',
      passwordInputPlaceholder: '',
      dontHaveAccountTxt: '',
    };
  }

  componentDidMount() {
    const { langState } = this.props;
    this.setContents(langState.lang);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setContents(langState.lang);
    }
  }

  setContents = (lang) => {
    const contents = lang === LANG_EN ? LoginEnContents : LoginZhContents;
    this.setState({
      ...contents,
    }, () => this.setButtonText());
  };

  handleInputChange = (event) => {
    const { target } = event;
    const { name, value } = target;
    const { user } = this.state;
    user[name] = value;
    this.setState({ user });
  };

  showAlert = (message = '') => {
    this.setState({ alert: { visible: true, message } });
  };

  dismissAlert = () => {
    this.setState({ alert: { visible: false, message: '' } });
  };

  setButtonText = () => {
    const { button, btnLoginTxt, loggingInTxt } = this.state;
    if (button.disabled) {
      button.text = loggingInTxt;
    } else {
      button.text = btnLoginTxt;
    }
    this.setState({ button });
  };

  enableButton = (text = 'Log in') => {
    const { langState } = this.props;
    const { lang } = langState;
    this.setState({ button: { disabled: false, text: translate({ lang, value: text }) } });
  };

  disableButton = (text = 'Logging in') => {
    const { langState } = this.props;
    const { lang } = langState;
    this.setState({ button: { disabled: true, text: translate({ lang, value: text }) } });
  };

  validateForm = () => {
    const { user, errorMessages } = this.state;
    if (Object.values(user).some(value => value == null || value.trim().length === 0)) {
      return new Error(errorMessages.fillAllFields);
    }
    return null;
  };

  onKeyPress = (event) => {
    const { key } = event;
    if (key === 'Enter') {
      this.onLogin();
    }
  };

  onLogin = () => {
    const {
      user, button, btnLoginTxt, loggingInTxt, errorMessages,
    } = this.state;
    if (button.disabled) { return; }
    const validationErr = this.validateForm();
    if (validationErr != null) {
      this.showAlert(validationErr.message);
    } else {
      this.dismissAlert();
      this.disableButton(loggingInTxt);
      logIn(user)
        .then((res) => {
          const { data: userInfo } = res.data;
          getAxiosInstance().defaults.headers.common.Authorization = userInfo.authentication_token;
          const { dispatch } = this.props;
          dispatch(setUser(userInfo));
          dispatch(hideModal());
        })
        .catch((err) => {
          const { response } = err;
          switch (response.status) {
            case HttpStatus.UNAUTHORIZED:
              this.showAlert(errorMessages.invalidPass);
              break;
            default:
              this.showAlert(errorMessages.unknownErr);
          }
          this.enableButton(btnLoginTxt);
        });
    }
  };

  render() {
    const {
      title, user, alert, button, dontHaveAccountTxt, signUpTxt, emailInputPlaceholder,
      passwordInputPlaceholder,
    } = this.state;
    const { dispatch, langState } = this.props;
    const { lang } = langState;
    const alertBanner = alert.visible ? (
      <div className={Styles.alert}>
        <p>{translate({ lang, value: alert.message })}</p>
      </div>
    ) : null;
    return (
      <div className={Styles.card} onKeyPress={this.onKeyPress}>
        <img alt="" className={Styles.btnClose} src={icClose} onClick={() => dispatch(hideModal())} />
        <h1 className={Styles.title}>{translate({ lang, value: title })}</h1>
        <div className={Styles.field}>
          <input
            type="email"
            placeholder={translate({ lang, value: emailInputPlaceholder })}
            name="email"
            onChange={this.handleInputChange}
            value={user.email}
          />
        </div>
        <div className={Styles.field}>
          <input
            type="password"
            placeholder={translate({ lang, value: passwordInputPlaceholder })}
            name="password"
            onChange={this.handleInputChange}
            value={user.password}
          />
        </div>

        {alertBanner}

        <div
          role="button"
          tabIndex="0"
          className={Styles.btnLogin}
          style={button.disabled ? { backgroundColor: 'grey', backgroundImage: 'none' } : {}}
          onKeyPress={this.onKeyPress}
          onClick={this.onLogin}
        >
          <p className={Styles.logInTxt}>{translate({ lang, value: button.text })}</p>
        </div>

        <div className={Styles.divider} />

        <p
          className={Styles.dontHaveAccount}
        >
          {translate({ lang, value: dontHaveAccountTxt })}
          <span onClick={() => dispatch(showModal({
            modalProps: { isOpen: true },
            modalType: ModalTypes.SIGN_UP,
          }))}
          >
            {translate({ lang, value: signUpTxt })}
          </span>
        </p>
      </div>
    );
  }
}

LogInCard.defaultProps = {
  dispatch: null,
};

LogInCard.propTypes = {
  dispatch: PropTypes.func,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};


export default connect(mapStateToProps)(Radium(LogInCard));
