import keyMirror from 'keymirror';

export const UserActionTypes = keyMirror({
	SET_USER: null,
	DELETE_USER: null,
});

export function setUser(user) {
	return {
		type: UserActionTypes.SET_USER,
		user,
	}
}

export function deleteUser() {
	return {
		type: UserActionTypes.DELETE_USER,
		user: null,
	}
}
