import React from 'react';
import PropTypes from 'prop-types';
import { tify } from 'chinese-conv';
import { styles } from './PDF.css';
import PersonalInfo from './PersonalInfo/PersonalInfo';
import MedicalInfo from './MedicalInfo/MedicalInfo';
import Pictures from './Pictures/Pictures';
import { LANG_EN, LANG_ZH_TRADITIONAL } from '../../../../../res/constants';
import BirthRecords from './BirthRecords/BirthRecords';
import DiseasesHistory from './DiseasesHistory/DiseasesHistory';

class PDF extends React.Component {
  constructor(props) {
    super(props);
    const { surrogateMother } = props;
    this.state = { surrogateMother };
  }

  getSurrogateMothersPhotos = () => {
    const { surrogateMother } = this.state;
    let photos = [];
    if (surrogateMother != null && surrogateMother.surrogate_app != null) {
      const { form_attributes: formAttrs } = surrogateMother.surrogate_app.data;
      let {
        full_photos: fullPhotos,
        life_photos: lifePhotos, child_photos: childPhotos,
      } = formAttrs;
      fullPhotos = fullPhotos || [];
      lifePhotos = lifePhotos || [];
      childPhotos = childPhotos || [];
      photos = [...fullPhotos, ...lifePhotos, ...childPhotos];
    }
    return photos;
  };

  render() {
    const { surrogateMother } = this.state;
    const photos = this.getSurrogateMothersPhotos();
    const { lang } = this.props;
    const title = lang === LANG_EN ? 'Surrogate mother Profile' : '代孕天使档案';
    return (
      <div style={styles.container}>
        <h1 style={styles.title}>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
        <PersonalInfo surrogateMother={surrogateMother} lang={lang} />
        <BirthRecords surrogateMother={surrogateMother} lang={lang} />
        <DiseasesHistory surrogateMother={surrogateMother} lang={lang} />
        <MedicalInfo surrogateMother={surrogateMother} lang={lang} />
        {surrogateMother != null ? <Pictures photos={photos} /> : null}
        <div style={{ height: '76px' }} />
      </div>
    );
  }
}

PDF.propTypes = {
  surrogateMother: PropTypes.shape({
    authentication_token: PropTypes.string,
  }).isRequired,
  lang: PropTypes.string.isRequired,
};

export default PDF;
