import React from 'react';
import BasicInfoForm from './components/forms/BasicInformation/forms/BasicInfoForm';
import HealthHistoryForm from './components/forms/HealthHistory/HealthHistoryForm';
import FamilyHistoryForm from './components/forms/FamilyHistory/FamilyHistoryForm';
import ShowYourselfForm from './components/forms/ShowYourself/ShowYourselfForm';
import CompletionForm from './components/forms/Completion/CompletionForm';
import { fetchUserById, updateUserInfo } from '../../Api/Apis/Users';
import { createOrUpdateDonorApplication } from '../../Api/Apis/DonorApp';
import { setUser } from '../../redux/User/UserActions';
import BasicInformation from './components/forms/BasicInformation/BasicInformation';
import HealthHistory from './components/forms/HealthHistory/HealthHistory';
import FamilyHistory from './components/forms/FamilyHistory/FamilyHistory';
import ShowYourself from './components/forms/ShowYourself/ShowYourself';
import Completion from './components/forms/Completion/Completion';
import Success from './components/forms/Success/Success';
import { LANG_EN } from '../../res/constants';

class DonorApplicationForm {
  constructor(user = null, component = null, lang = LANG_EN) {
    this.user = user;
    this.component = component;
    this.lang = lang;
    this.basicInfoForm = new BasicInfoForm({ user, lang });
    this.healthHistoryForm = new HealthHistoryForm({ user, lang });
    this.familyHistoryForm = new FamilyHistoryForm({ user, lang });
    this.showYourselfForm = new ShowYourselfForm({ user, lang });
    this.completionForm = new CompletionForm({ user, lang });
    this.forms = [
      this.basicInfoForm, this.healthHistoryForm,
      this.familyHistoryForm, this.showYourselfForm, this.completionForm];
    this.syncData(user);
  }

  syncData = (user = null) => {
    if (user != null) {
      this.syncAllFormData(user);
      fetchUserById(user.id)
        .then((res) => {
          const { dispatch } = this.component.props;
          const { data: userInfo } = res.data;
          dispatch(setUser(userInfo));
          this.syncAllFormData(userInfo);
        })
        .catch((err) => {});
    }
  };

  onLangChanged = (lang = LANG_EN) => {
    this.basicInfoForm.setContents(lang);
    this.healthHistoryForm.setContents(lang);
    this.showYourselfForm.setContents(lang);
    this.familyHistoryForm.setContents(lang);
    this.completionForm.setContents(lang);
  };

  syncAllFormData = (user) => {
    if (user != null) {
      this.basicInfoForm.syncData(user);
      this.healthHistoryForm.syncData(user);
      this.familyHistoryForm.syncData(user);
      this.showYourselfForm.syncData(user);
    }
  };

  submit = () => {
    Promise.all([
      this.showYourselfForm.save(),
      this.completionForm.save(),
    ])
      .then(res => this.saveUserInfoAndFormAttrs('all', true))
      .catch(err => Promise.reject(err));
  };

  validateForm = (formIndex = 0) => {
    switch (formIndex) {
      case 0:
        return this.basicInfoForm.validateForm();
      case 1:
        return this.healthHistoryForm.validateForm();
      case 3:
        return this.showYourselfForm.validateForm();
      default:
        return null;
    }
  };

  saveUserInfoAndFormAttrs = (formIndex = 0, completed = false) => {
    const validationErr = this.validateForm(formIndex);
    if (validationErr) { return Promise.reject(validationErr); }
    const {
      basicInfoForm, healthHistoryForm, familyHistoryForm, showYourselfForm,
    } = this;
    const user = basicInfoForm.getUserInfo();
    const formAttrs = {
      ...basicInfoForm.getFormAttrs(),
      ...healthHistoryForm.getFormAttrs(),
      ...familyHistoryForm.getFormAttrs(),
      ...showYourselfForm.getFormAttrs(),
    };
    return Promise.all([updateUserInfo(user), createOrUpdateDonorApplication(formAttrs, completed)])
      .then((res) => {
        const { data: updatedUserInfo } = res[0].data;
        const { data: updatedDonorApp } = res[1];
        updatedUserInfo.donor_app = updatedDonorApp;
        const { dispatch } = this.component.props;
        dispatch(setUser(updatedUserInfo));
        return res;
      })
      .catch(err => err);
  };

  getForm = (formIndex) => {
    if (this.user == null) { return null; }
    if (this.isCompleted()) {
      return <Success />;
    }
    const { highlightRequiredField = false } = this.component.state;
    switch (formIndex) {
      case 0:
        return (
          <BasicInformation
            form={this.basicInfoForm}
            highlightRequiredField={highlightRequiredField}
          />
        );
      case 1:
        return (
          <HealthHistory
            form={this.healthHistoryForm}
            highlightRequiredField={highlightRequiredField}
          />
        );
      case 2:
        return <FamilyHistory form={this.familyHistoryForm} />;
      case 3:
        return <ShowYourself form={this.showYourselfForm} />;
      case 4:
        return (
          <Completion
            completionForm={this.completionForm}
            highlightRequiredField={highlightRequiredField}
          />
        );
      case 5:
        return <Success />;
      default:
        return null;
    }
  };

  isCompleted = (user = this.user) => user != null && user.donor_app != null
      && user.donor_app.data != null && user.donor_app.data.completed;
}

export default DonorApplicationForm;
