export const ChildrenRows = [
  {
    name: 'Child 1.',
    key: 'child_1',
  },
  {
    name: 'Child 2.',
    key: 'child_2',
  },
  {
    name: 'Child 3.',
    key: 'child_3',
  },
  {
    name: 'Child 4.',
    key: 'child_4',
  },
  {
    name: 'Child 5.',
    key: 'child_5',
  },
  {
    name: 'Child 6.',
    key: 'child_6',
  },
];

export const ChildrenRowsZh = [
  {
    name: '孩子 1.',
    key: 'child_1',
  },
  {
    name: '孩子 2.',
    key: 'child_2',
  },
  {
    name: '孩子 3.',
    key: 'child_3',
  },
  {
    name: '孩子 4.',
    key: 'child_4',
  },
  {
    name: '孩子 5.',
    key: 'child_5',
  },
  {
    name: '孩子 6.',
    key: 'child_6',
  },
];

export const ChildrenInfoCols = [
  {
    name: 'Date',
    key: 'date',
  },
  {
    name: 'Procedure',
    key: 'procedure',
  },
  {
    name: 'Birth Weight',
    key: 'birth_weight',
  },
  {
    name: 'Birth Week',
    key: 'birth_week',
  },
  {
    name: 'Complications/Notes',
    key: 'complications_notes',
  },
];

export const ChildrenInfoColsZh = [
  {
    name: '日期',
    key: 'date',
  },
  {
    name: '手术',
    key: 'procedure',
  },
  {
    name: '出生体重',
    key: 'birth_weight',
  },
  {
    name: '出生周数',
    key: 'birth_week',
  },
  {
    name: '并发症/备注',
    key: 'complications_notes',
  },
];
