import { FamilyInfoRows, FamilyInfoCols } from './constants';
import { Prop, retrieveKeyValuesFrom } from '../BasicInformation/forms/Utils';
import BaseForm from '../BasicInformation/forms/BaseForm';
import { parseEyeColor, parseHairColor, parseHealthCondition } from '../../../../../Utils/Util';
import { LANG_EN } from '../../../../../res/constants';

class FamilyHistoryForm extends BaseForm {
  constructor({ user = null, component = null, lang = null }) {
    super({ user, component, lang });
    this.generateTableAttributes();
    this.syncData(user);
  }

  generateTableAttributes = () => {
    FamilyInfoRows.forEach((row) => {
      const propName = row.key;
      this.form_attributes[propName] = {};
      FamilyInfoCols.forEach((col) => {
        const subPropName = col.key;
        this.form_attributes[propName][subPropName] = new Prop({ propName: subPropName, inputType: subPropName === 'age' ? 'number' : 'text' });
      });
    });
  };

  getFormAttrs = () => {
    const result = {};
    const props = Object.keys(this.form_attributes);
    props.forEach((prop) => {
      result[prop] = retrieveKeyValuesFrom(this.form_attributes[prop]);
      const subProps = Object.keys(result[prop]);
      subProps.forEach((subProp) => {
        if (subProp === 'hair_color') {
          const hairColor = result[prop][subProp];
          result[prop][subProp] = parseHairColor({ lang: LANG_EN, hairColor });
        }
        if (subProp === 'eye_color') {
          const eyeColor = result[prop][subProp];
          result[prop][subProp] = parseEyeColor({
            lang: LANG_EN,
            eyeColor,
          });
        }
        if (subProp === 'health') {
          const health = result[prop][subProp];
          result[prop][subProp] = parseHealthCondition({
            lang: LANG_EN,
            healthCondition: health,
          });
        }
      });
    });
    return result;
  };

  setContents = (lang = LANG_EN) => {
    this.lang = lang;
    this.syncData();
  };

  syncData = (user = this.user) => {
    if (user != null && user.donor_app != null) {
      const { form_attributes: formAttrs } = user.donor_app.data;
      if (formAttrs != null) {
        Object.keys(formAttrs).forEach((attr) => {
          if (Object.hasOwnProperty.call(this.form_attributes, attr)) {
            Object.keys(formAttrs[attr]).forEach((subAttr) => {
              if (Object.hasOwnProperty.call(this.form_attributes[attr], subAttr)) {
                this.form_attributes[attr][subAttr].value = formAttrs[attr][subAttr] != null ? formAttrs[attr][subAttr] : '';
                if (subAttr === 'hair_color') {
                  const hairColor = this.form_attributes[attr][subAttr].value;
                  this.form_attributes[attr][subAttr].value = parseHairColor({
                    lang: this.lang,
                    hairColor,
                  });
                }
                if (subAttr === 'eye_color') {
                  const eyeColor = this.form_attributes[attr][subAttr].value;
                  this.form_attributes[attr][subAttr].value = parseEyeColor({
                    lang: this.lang,
                    eyeColor,
                  });
                }
                if (subAttr === 'health') {
                  const health = this.form_attributes[attr][subAttr].value;
                  this.form_attributes[attr][subAttr].value = parseHealthCondition({
                    lang: this.lang,
                    healthCondition: health,
                  });
                }
              }
            });
          }
        });
      }
      if (this.component) {
        this.component.setState({ form: this });
      }
    }
  }
}

export default FamilyHistoryForm;
