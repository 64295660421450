import { PATH_DONOR_APPLICATION, PATH_DONORS_LIST, PATH_FOR_SURROGATE } from '../../../res/constants';

export const EnKeyMaps = new Map([
  ['title', 'About NewCare'],
  ['intro', 'NewCare is a leading global surrogacy and egg donation concierge service company. We are dedicated to connecting intended parents with egg donors and surrogate mothers, and to providing them with comprehensive support throughout their journey. Our mission is to bring hope to families through the greatest value and highest standards in the process of surrogacy and egg donation.'],
  ['btn_txt', 'Get a Free Consultation'],
  ['modal_title', 'Success'],
  ['modal_msg', 'Your request has been successfully submitted. Our stuff will contact you within 48 hrs.'],
  ['advantage_title', 'Our Advantages'],
  ['advantages', '• Collaborates with over 40 fertility centers throughout USA\n'
	+ '• Delivers fast and high quality matches\n'
	+ '• Highly experienced in managing surrogacy and egg donation cycles between different cultural and ethnicity backgrounds\n'
	+ '• On-site IVF physicians that provides pre-review of medical records which increases match success rate and provides arm’s length medical consultation\n'
	+ '• Have over 500 active Asian and Caucasian egg donors in our dataset ready for the right match\n'
	+ '• Pre-medically qualified surrogate mothers ready for match\n'],
  ['our_services_title', 'Our Services'],
  ['our_services', '• Consultation and translation\n'
	+ '• Complete concierge service\n'
	+ '• IVF-ET\n'
	+ '• Egg Donation Program\n'
	+ '• Egg Freezing\n'
	+ '• Gestational Surrogacy Program\n'],
  ['be_surrogate_mother_title', 'Want to become a Surrogate Mother?'],
  ['be_surrogate_mother', `
	<p style="white-space: pre-line;">
	Are you between the age of 21-37?
	  • Have you had at least one successful live birth with no complications?
	  • Are you a non-smoker, non-drug user?
	  • Do you have regular menstrual cycles?
	  • Is your BMI less than 33?  Please click here to calculate your <a target="_blank" rel="noopener noreferrer" href="https://www.cdc.gov/healthyweight/assessing/bmi/adult_bmi/english_bmi_calculator/bmi_calculator.html">BMI</a>
	  • Are you physically and mentally healthy?
	  • Are you a US citizen or permanent resident?
	  • Click <a href="${PATH_FOR_SURROGATE}">HERE</a> to view the full list of requirements.
	</p>
	`],
  ['surrogate_btn', 'BECOME A SURROGATE'],
  ['be_egg_donor_title', 'Want to become an Egg Donor?'],
  ['be_egg_donor', `
	<p style="white-space: pre-line;">
	Are you between the age of 18-29?
	  • Are you physically and mentally healthy?
	  • Do you have regular menstrual cycles?
	  • Are you a non-smoker, non-drug user?
	  • Is your BMI less than 30?  Please click here to calculate your <a target="_blank" rel="noopener noreferrer" href="https://www.cdc.gov/healthyweight/assessing/bmi/adult_bmi/english_bmi_calculator/bmi_calculator.html">BMI</a>
	  • Click <a href="${PATH_DONORS_LIST}">HERE</a> to view the full list of egg donors.
	</p>
	`],
  ['egg_donor_btn', 'BECOME AN EGG DONOR'],
  ['more_info_title', 'Want to know more information about surrogacy and egg donation services?'],
  ['more_info', `
	<p style="white-space: pre-line;">• If you are a future parent who is looking for egg donors and/or surrogate mothers, please click <a href="${PATH_DONOR_APPLICATION}">HERE</a> to register to view available options of our egg donors and surrogate mothers.
	• Our case coordinator will contact you soon to help you start your journey.
	</p>
	`],
]);

export const ZhKeyMaps = new Map([
  ['title', '关于NewCare'],
  ['intro', 'NewCare Fertility Consulting是领先的国际第三方生殖服务公司。我们为准父母、卵子捐赠者和代孕母亲提供服务。我们的总部位于加利福尼亚州的阿卡迪亚（Arcadia）。\n\n'
  + '在NewCare，我们确保我们的捐赠者、代孕者和准父母在整个旅程中获得全面的支持。\n\n'
  + '“有希望就有幸福”，我们的使命是通过最佳价值和最高标准的代孕、卵子捐赠和体外受精过程，为家庭带来希望。'],
  ['btn_txt', '免费咨询'],
  ['modal_title', '请求成功'],
  ['modal_msg', '您的请求已经成功发送，我们会在48小时之内尽快与您联系。'],
  ['advantage_title', '我们的优势'],
  ['advantages', '• 免费咨询\n'
	+ '• 与遍布美国的40多个生育中心合作\n'
	+ '• 定制和完整的翻译服务\n'
	+ '• 100％合法和专业的第三方生殖服务\n'
	+ '• 拥有300多名活跃的亚洲和高加索卵子捐赠者\n'
	+ '• 每月超过10名合格的新代孕母亲\n',
  +'• 已帮助数百个家庭实现了成功的代孕过程\n'],
  ['our_services_title', '我们的服务'],
  ['our_services', '• 咨询和翻译\n'
	+ '• 完善的礼宾服务\n'
	+ '• 体外受精-胚胎移植（IVF-ET）\n'
	+ '• 卵子捐赠计划\n'
	+ '• 卵子冷冻计划\n'
	+ '• 妊娠代孕计划\n'],
  ['be_surrogate_mother_title', '想成为代孕母亲吗？'],
  ['be_surrogate_mother', `
	<p style="white-space: pre-line;">
	您的年龄在21-37岁之间吗？
	  • 您是否至少有过一次成功活产？顺产？且没有并发症？
	  • 您是非吸烟者、非吸毒者吗？
	  • 您有规律的月经期吗？
	  • 您的BMI（体重指数）是否低于33？请点击此处计算您的 <a target="_blank" rel="noopener noreferrer" href="https://www.cdc.gov/healthyweight/assessing/bmi/adult_bmi/english_bmi_calculator/bmi_calculator.html">BMI</a>
	  • 您的心理和精神健康吗？
	  • 您是美国公民还是永久居民？
	  • 点击<a href="${PATH_FOR_SURROGATE}">“此处”</a>以查看代孕天使。
	</p>
	`],
  ['surrogate_btn', '成为代孕者'],
  ['be_egg_donor_title', '想成为卵子捐赠者吗？'],
  ['be_egg_donor', `
	<p style="white-space: pre-line;">
	您的年龄在18-29岁之间吗？
	  • 您的心理和精神健康吗？
	  • 您有规律的月经期吗？
	  • 您是非吸烟者、非吸毒者吗？
	  • 您的BMI（体重指数）是否低于30？请点击此处计算您的 <a target="_blank" rel="noopener noreferrer" href="https://www.cdc.gov/healthyweight/assessing/bmi/adult_bmi/english_bmi_calculator/bmi_calculator.html">BMI</a>
	  • 点击<a href="${PATH_DONORS_LIST}">“此处”</a>以查看捐卵天使。
	</p>
	`],
  ['egg_donor_btn', '成为卵子捐赠者'],
  ['more_info_title', '想了解更多关于代孕和卵子捐赠服务的信息吗？'],
  ['more_info', `
	<p style="white-space: pre-line;">
	• 如果您是正在寻找卵子捐赠者和/或代孕母亲的未来的父母，请点击<a href="${PATH_DONOR_APPLICATION}">“此处”</a>进行注册，以查看我们的卵子捐赠者和代孕母亲的可用选项。
	• 我们的个案协调员将尽快与您联系，在整个旅程中为您提供帮助。
	</p>
	`],
]);
