
export const EnKeyMaps = new Map([
  ['title', 'IVF-ET Process'],
  ['intro', '• IVF starts at $29,800.00 (all fees are directly paid to IVF clinic)\n'
  + '• Complete IVF process takes approximately 2 months\n'
  + '• Extra charges will apply for transportation, escorting, accommodation and translation services\n'],
]);

export const ZhKeyMaps = new Map([
  ['title', '试管婴儿流程'],
  ['intro', '• 赴美试管费用2.98万美金起 （费用直接交给医院，我们不收取任何费用）\n'
  + '• 赴美试管取卵 + 移植需要至少两个月\n'
  + '• 来美陪同，翻译，接送，住宿等费用另计\n'],
]);
