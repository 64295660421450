import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Styles } from './CheckBox.css';
import icCheck from './ic-check.png';

function CheckBox(props) {
  const {
    checked, text, selectable, containerStyle = {}, name, textVisible,
    onClick,
  } = props;
  let btnText = null;
  if (textVisible && text != null) {
    btnText = (
      <p style={Styles.btnTxt}>{text}</p>
    );
  }
  let checkboxStyle = Styles.checkbox;
  if (checked) {
    checkboxStyle = { ...checkboxStyle, ...Styles.checked };
  }
  const img = checked ? <img style={Styles.icCheck} src={icCheck} alt="" /> : null;
  return (
    <div
      style={{ ...Styles.container, ...containerStyle }}
      onClick={() => {
        if (selectable && onClick instanceof Function) {
          // return name, checked (current check box state) and text
          onClick({ name, checked, text });
        }
      }}
    >
      <div style={checkboxStyle}>
        {img}
      </div>
      {btnText}
    </div>
  );
}

CheckBox.defaultProps = {
  checked: false,
  selectable: true,
  text: null,
  containerStyle: {},
  onClick: null,
  name: '',
  textVisible: true,
};

CheckBox.propTypes = {
  checked: PropTypes.bool,
  selectable: PropTypes.bool,
  text: PropTypes.string,
  containerStyle: PropTypes.object,
  onClick: PropTypes.func,
  name: PropTypes.string,
  textVisible: PropTypes.bool,
};

export default Radium(CheckBox);
