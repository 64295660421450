import styled from 'styled-components';

const styles = {
  container: {
    width: '100%',
    position: 'relative',
  },
};

export const EggDonationPageSection = styled.div`
  width: 100%;
  height: 100vh;
`;


export default styles;
