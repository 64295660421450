import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import Styles from './FertilityCenter.module.scss';
import icControl from '../../../../assets/ic-carousel-left-control.png';

class Swiper extends React.Component {
  constructor(props) {
    super(props);
    this.state = { position: 0, numPhotoVisible: 3 };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, numPhotoVisible: window.innerWidth <= 576 ? 1 : 3 });
  };

  onPrevious = () => {
    const { position } = this.state;
    if (position < 0) {
      this.setState({ position: position + 1 });
    }
  };

  onNext = () => {
    const { position, numPhotoVisible } = this.state;
    const { hospitals } = this.props;
    if (position + hospitals.length > numPhotoVisible) {
      this.setState({ position: position - 1 });
    }
  };

  onHospitalClicked = (index) => {
    const { onHospitalSelected } = this.props;
    onHospitalSelected(index);
  };

  render() {
    const { position, width } = this.state;
    const { hospitals } = this.props;
    const imgWidth = width <= 576 ? 260 : 360;
    const carouselStyle = {
      transform: `translateX(${position * imgWidth}px)`,
    };
    const cards = hospitals.map((hospital, index) => (
      <div
        className={Styles.card}
        key={hospital.id}
        onClick={() => this.onHospitalClicked(index)}
      >
        <img alt="" src={hospital.cover_photo_url} />
      </div>
    ));
    return (
      <div className={Styles.swiperContainer}>
        <div className={Styles.swiperContent}>
          <img
            className={Styles.indicatorLeft}
            alt=""
            onClick={this.onPrevious}
            src={icControl}
          />

          <div className={Styles.carouselWrapper}>
            <div className={Styles.carousel} style={carouselStyle}>
              {cards}
            </div>
          </div>

          <img
            className={Styles.indicatorRight}
            alt=""
            onClick={this.onNext}
            src={icControl}
          />
        </div>
      </div>
    );
  }
}

Swiper.propTypes = {
  onHospitalSelected: PropTypes.func.isRequired,
  hospitals: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.string,
    cover_photo_url: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  })).isRequired,
};

export default Radium(Swiper);
