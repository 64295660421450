export const Styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    ':hover': {
      cursor: 'pointer',
    },
  },
  checkbox: {
    width: '18px',
    height: '18px',
    borderRadius: '4px',
    border: 'solid 1px #e6e9eb',
    backgroundColor: '#ffffff',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checked: {
    backgroundColor: '#c3c8cc',
  },
  btnTxt: {
    fontFamily: 'Avenir-Roman',
    fontSize: '15px',
    color: '#52585f',
    margin: '0 0 0 10px',
    paddingTop: '2px',
  },
  icCheck: {
    width: '13px',
    height: '8px',
  },
};
