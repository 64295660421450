import React, { Fragment } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { tify } from 'chinese-conv';
import Styles from '../DonorApplication/Application.module.scss';
import Header from '../../components/Header/Header';
import EventsManager from './managers/EventsManager';
import SurrogateApplicationForm from './SurrogateApplicationForm';
import ModalTypes from '../../components/Modals/ModalTypes';
import { showModal } from '../../redux/Modal/Modalactions';
import { LANG_ZH_TRADITIONAL, PATH_HOME_PAGE } from '../../res/constants';
import Button from '../DonorApplication/components/Button/Button';
import ProgressBar from '../DonorApplication/components/ProgressBar/ProgressBar';

const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});

class SurrogateApplication extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    const surrogateApplicationForm = new SurrogateApplicationForm(user, this);
    this.eventsManager = new EventsManager(this);
    this.state = {
      user,
      formIndex: 0,
      progress: surrogateApplicationForm.isCompleted() ? 100 : 0,
      formCounts: 10,
      buttonsDisabled: surrogateApplicationForm.isCompleted(),
      saveBtnTxt: 'SAVE',
      nextBtnTxt: 'NEXT>>',
      buttonsVisible: !surrogateApplicationForm.isCompleted(),
      alert: {
        message: 'Saved successfully!',
        visible: false,
        color: 'red',
      },
      surrogateApplicationForm,
      highlightRequiredField: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { user } = this.state;
    if (user == null) {
      const { dispatch } = this.props;
      const data = { modalProps: { isOpen: true }, modalType: ModalTypes.SIGN_UP };
      dispatch(showModal(data));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { user } = nextProps;
      this.setState({ user });
    }
  }

  getButtons = () => {
    const {
      formIndex, buttonsDisabled, saveBtnTxt, nextBtnTxt, buttonsVisible, surrogateApplicationForm,
    } = this.state;
    if (!buttonsVisible || surrogateApplicationForm.isCompleted()) {
      return null;
    }
    const {
      onPrevious, onSave, onNext,
    } = this.eventsManager;
    const ButtonPrevious = formIndex > 0 ? (
      <Button
        text="<<PREVIOUS"
        onClick={onPrevious}
        disabled={buttonsDisabled}
      />
    )
      : null;
    return (
      <div className={Styles.buttonsWrapper}>
        {ButtonPrevious}
        <Button text={saveBtnTxt} disabled={buttonsDisabled} onClick={onSave} />
        <Button text={nextBtnTxt} onClick={onNext} disabled={buttonsDisabled} />
      </div>
    );
  };

  render() {
    const { onDismissAlert } = this.eventsManager;
    const {
      progress, alert, user, surrogateApplicationForm, formIndex,
    } = this.state;
    const { langState } = this.props;
    const { lang } = langState;
    const buttons = this.getButtons();
    return (
      <div className={Styles.container}>
        {alert.visible ? (
          <div className=" alert-dismissible fade show my-alert" role="alert" style={{ backgroundColor: alert.color ? alert.color : 'red' }}>
            <button type="button" aria-label="Close" onClick={onDismissAlert}><span aria-hidden="true">×</span></button>
            {lang === LANG_ZH_TRADITIONAL ? tify(alert.message) : alert.message}
          </div>
        ) : null}
        <Header fixed={false} allowTransparent={false} redirectPathAfterLogout={PATH_HOME_PAGE} />
        <h1 className={Styles.title}>Surrogate Application Form</h1>
        {user == null ? null : (
          <Fragment>
            <ProgressBar progress={progress} />
            <div style={{ height: '20px' }} />
            {surrogateApplicationForm.getForm(formIndex)}
            <div style={{ height: '60px' }} />
            {buttons}
          </Fragment>
        )}
      </div>
    );
  }
}

SurrogateApplication.defaultProps = {
  user: null,
};

SurrogateApplication.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape({
    display_name: PropTypes.string,
  }),
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(SurrogateApplication));
