
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'flex-end'
  },
  menuItem: {
    boxSizing: 'border-box',
    textShadow: '0 2px 4px rgba(238, 163, 201, 0.5)',
    fontFamily: 'AvenirNext',
    fontSize: '1.125em',
    fontWeight: 600,
    fontStretch: 'condensed',
    color: '#ffffff',
    WebkitTransition: '0.3s',
    ':hover': {
      cursor: 'pointer',
      color: 'rgb(248, 248, 248)',
    },
  },
  signUp: {
    padding: '6px 10px',
    boxSizing: 'border-box',
    fontFamily: 'AvenirNext',
    fontSize: '1.125em',
    fontWeight: 'bold',
    fontStretch: 'condensed',
    textAlign: 'center',
    color: '#ffabb9',
    borderRadius: '4px',
    boxShadow: '0 2px 4px 0 rgba(255, 160, 163, 0.15)',
    backgroundColor: '#ffffff',
    WebkitTransition: '0.3s',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(248, 248, 248)',
    },
  },
  dropDownMenu: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      padding: '10px 0',
    },
    items: {
      position: 'absolute',
      left: 0,
      top: '100%',
    },
    menuItemContainer: {
      width: '150px',
      padding: '0 5px',
      boxSizing: 'border-box',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'AvenirNext',
      backgroundColor: '#F4C6D3',
      fontSize: '1.125em',
      fontWeight: '500',
      fontStretch: 'condensed',
      lineHeight: '2.1',
      marginTop: 0,
      marginBottom: 0,
      color: '#ffffff',
      WebkitTransition: '0.3s',
      ':hover': {
        opacity: 0.66,
        backgroundColor: '#ffffff',
        color: '#ff94b9',
        cursor: 'pointer',
      },
    },
  },
  profileMenu: {
    container: {
      marginLeft: '117px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      ':hover': {
        cursor: 'pointer',
      },
    },
    profile: {
      container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      avatar: {
        height: '32px',
        width: '32px',
        borderRadius: '16px',
        objectFit: 'cover',
      },
    },
  },
};

export default styles;
