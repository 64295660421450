import { loadState } from '../../Utils/LocalStorage';
import { UserActionTypes } from './UserActions';

const persistedState = loadState();
let initialState = null;
if (persistedState && persistedState.userState) {
  initialState = persistedState.userState;
}

export function userState(state = initialState, action) {
  switch (action.type) {
    case UserActionTypes.SET_USER:
      return Object.assign({}, state, action.user);
    case UserActionTypes.DELETE_USER:
      return null;
    default:
      return state;
  }
}
