import { sify } from 'chinese-conv';
import { ERROR_TYPE_MISSED_FIELD, LANG_EN } from '../../../res/constants';

class EventsManager {
  constructor(component) {
    this.component = component;
  }

  disabledButtons = () => {
    this.component.setState({ buttonsDisabled: true }, () => this.component.setButtonsTxt());
  };

  enableButtons =() => {
    this.component.setState({ buttonsDisabled: false }, () => this.component.setButtonsTxt());
  };

  showAlert = (message = '', color = 'red') => {
    const { alert } = this.component.state;
    if (alert.timeout) { clearTimeout(alert.timeout); }
    alert.message = message;
    alert.visible = true;
    alert.color = color;
    this.component.setState({ alert }, () => {
      alert.timeout = setTimeout(() => {
        this.onDismissAlert();
      }, 3000);
    });
  };

  onDismissAlert = () => {
    const { alert } = this.component.state;
    alert.visible = false;
    if (alert.timeout) {
      clearTimeout(alert.timeout);
    }
    this.component.setState({ alert });
  };

  onSubmit = () => {
    const { donorApplicationForm } = this.component.state;
    this.requestHandler(donorApplicationForm.completionForm.save())
      .then(() => donorApplicationForm.submit())
      .then(() => {
        this.toNextForm();
        this.showAlert('Submitted successfully!', 'rgb(153, 216, 241)');
      })
      .catch(() => {});
  };

  onPrevious = () => {
    window.scrollTo(0, 0);
    const { formIndex, formCounts } = this.component.state;
    const newIndex = formIndex - 1;
    const progress = (newIndex / (formCounts - 1)) * 100;
    this.component.setState({ formIndex: newIndex, progress },
      () => this.component.setButtonsTxt());
  };

  onNext = () => {
    const { nextBtnTxt } = this.component.state;
    if (nextBtnTxt === 'SUBMIT' || sify(nextBtnTxt) === '提交') {
      this.onSubmit();
    } else {
      this.onSave()
        .then(() => {
          this.toNextForm();
        })
        .catch(() => {});
    }
  };

  onSave = () => {
    const {
      formIndex, donorApplicationForm, user,
    } = this.component.state;
    const { showYourselfForm, completionForm } = donorApplicationForm;
    this.disabledButtons();
    switch (formIndex) {
      case 0:
      case 1:
      case 2:
        return this.requestHandler(donorApplicationForm.saveUserInfoAndFormAttrs(formIndex));
      case 3:
        return this.requestHandler(showYourselfForm.save(user.id)
          .then(() => donorApplicationForm.saveUserInfoAndFormAttrs(formIndex))
          .catch(err => Promise.reject(err)));
      case 4:
        return this.requestHandler(completionForm.save(user.id));
      default:
        this.showAlert('Nothing to do.');
        this.enableButtons();
        return Promise.reject(new Error('No such form'));
    }
  };

  toNextForm = () => {
    const { formIndex, formCounts } = this.component.state;
    window.scrollTo(0, 0);
    const newIndex = formIndex + 1;
    const progress = (newIndex / (formCounts - 1)) * 100;
    let buttonsVisible = true;
    if (newIndex === formCounts - 1) {
      buttonsVisible = false;
    }
    this.component.setState({
      formIndex: newIndex, progress, buttonsVisible,
    }, () => this.component.setButtonsTxt());
  };

  requestHandler = request => new Promise((resolve, reject) => {
    request
      .then((res) => {
        const { langState } = this.component.props;
        const { lang } = langState;
        const successMsg = lang === LANG_EN ? 'Saved successfully!' : '保存成功!';
        this.showAlert(successMsg, 'rgb(153, 216, 241)');
        this.enableButtons();
        this.component.setState({ highlightRequiredField: false });
        resolve();
      })
      .catch((err) => {
        const { langState } = this.component.props;
        const { lang } = langState;
        let errMsg = lang === LANG_EN ? 'Failed saving data, please retry later.' : '保存失败，请稍后再试。';
        if (err.message) {
          errMsg = err.message;
        }
        if (err.type) {
          switch (err.type) {
            case ERROR_TYPE_MISSED_FIELD:
              this.component.setState({ highlightRequiredField: true });
              break;
            default:
          }
        }
        this.showAlert(errMsg);
        this.enableButtons();
        reject();
      });
  });
}

export default EventsManager;
