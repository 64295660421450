import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Radium from 'radium';
import { tify } from 'chinese-conv';
import MaritalStatusChildForm
  from '../../../../SurrogateApplication/components/forms/MaritalChildStatus/MaritalStatusChildForm';
import {
  ChildrenRows, ChildrenInfoCols, ChildrenRowsZh, ChildrenInfoColsZh,
} from './constants';
import { LANG_EN, LANG_ZH_SIMPLIFIED, LANG_ZH_TRADITIONAL } from '../../../../../res/constants';
import Styles from './BirthRecords.module.scss';

const mapStateToProps = state => ({
  langState: state.langState,
});

class BirthRecords extends React.Component {
  constructor(props) {
    super(props);
    const { surrogateMother = null } = props;
    const { langState } = this.props;
    const { lang } = langState;
    this.state = {
      surrogateMother,
      form: new MaritalStatusChildForm({ user: surrogateMother, component: this, lang }),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { surrogateMother = null } = nextProps;
      const { form } = this.state;
      form.syncData(surrogateMother, 'surrogate_app');
      this.setState({ surrogateMother, form }, () => {
        const { langState } = this.props;
        const { lang } = langState;
        form.setContents(lang);
      });
    }
  }

  renderFormTable = ({
    RowsAttrs, ColsAttrs, formAttrs, inputHandler, lang = LANG_EN, readOnly = false,
  }) => {
    const rows = RowsAttrs.map((row, index) => {
      let backgroundColor = '#fefafc';
      if (index % 2 === 0) {
        backgroundColor = '#ffffff';
      }
      const cols = ColsAttrs.map(col => (
        <div key={col.key} className={Styles.column} style={{ height: '40px' }}>
          <p style={{ visibility: 'hidden', margin: 0 }}>
            {lang === LANG_ZH_SIMPLIFIED ? col.name : tify(col.name)}
          </p>
          <div style={{
            position: 'absolute', width: '100%', height: '100%', top: 0,
          }}
          >
            <textarea
              onChange={event => inputHandler({
                ...event, row, col, formAttrs,
              })}
              readOnly={readOnly}
              name={formAttrs[row.key][col.key].propName}
              value={formAttrs[row.key][col.key].value}
            />
          </div>
        </div>
      ));
      return (
        <div className={Styles.row} key={row.key} style={[{ backgroundColor }]}>
          <div className={Styles.attrCell}>
            {lang === LANG_ZH_SIMPLIFIED ? row.name : tify(row.name)}
          </div>
          {cols}
        </div>
      );
    });
    const cols = ColsAttrs.map(col => (
      <div
        key={col.key}
        className={Styles.column}
      >
        {lang === LANG_ZH_SIMPLIFIED ? col.name : tify(col.name)}
      </div>
    ));
    const firstRow = (
      <div className={Styles.row}>
        <div className={Styles.attrCell} />
        {cols}
      </div>
    );
    return (
      <Fragment>
        {firstRow}
        {rows}
      </Fragment>
    );
  };

  render() {
    const { form } = this.state;
    const { langState } = this.props;
    const { lang } = langState;
    const title = lang === LANG_EN ? 'Birth Records' : '生产记录';
    return (
      <div className={Styles.container}>
        <h1>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
        {this.renderFormTable({
          RowsAttrs: lang === LANG_EN ? ChildrenRows : ChildrenRowsZh,
          ColsAttrs: lang === LANG_EN ? ChildrenInfoCols : ChildrenInfoColsZh,
          formAttrs: form.child_info_attributes,
          readOnly: true,
        })}
        <div style={{ height: '50px' }} />
      </div>
    );
  }
}

BirthRecords.defaultProps = {
  surrogateMother: null,
};

BirthRecords.propTypes = {
  surrogateMother: PropTypes.shape({
    authentication_token: PropTypes.string,
  }),
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(BirthRecords));
