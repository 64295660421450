import { FIELD_TYPE_MULTIPLE_CHOICE, FIELD_TYPE_TEXTAREA, FIELD_TYPE_YES_OR_NO } from '../../../../../res/constants';
import {
  Prop,
} from '../../../../DonorApplication/components/forms/BasicInformation/forms/Utils';
import BaseForm from '../../../../DonorApplication/components/forms/BasicInformation/forms/BaseForm';

class GeneralQuestionsForm extends BaseForm {
  constructor(user = null, component = null) {
    super({ user, component });
    this.user_attributes = {
      personality: new Prop({
        propName: 'personality', title: 'What is your personality?', type: FIELD_TYPE_TEXTAREA,
      }),
      talents: new Prop({
        propName: 'talents', title: 'What are your hobbies and talents?', type: FIELD_TYPE_TEXTAREA,
      }),
    };
    this.form_attributes = {
      willing_work_with: new Prop({
        propName: 'willing_work_with',
        title: 'Willing to work with: ',
        type: FIELD_TYPE_MULTIPLE_CHOICE,
        options: ['Same sex couple', 'Single male', 'Single female', 'Couple using egg/sperm donor', 'Older couple', 'Couple already have children', 'Non-English speaking couple with translator'],
      }),
      applying_others_facility: new Prop({
        index: 1,
        propName: 'applying_others_facility',
        title: 'Have you applied or are you currently applying to be a gestational carrier at any other medical facility, law firm and/or agency?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      applied_others_facility: new Prop({
        index: 1,
        propName: 'applied_others_facility',
        title: 'Have you ever applied to be a gestational carrier at any other medical facility, law firm and/ or agency and been told that you do not meet the facilities’ criteria to be a gestational carrier?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      has_pet: new Prop({
        index: 1,
        propName: 'has_pet',
        title: 'Do you have any pets?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      life_goals: new Prop({ propName: 'life_goals', title: 'What are your life goals?', type: FIELD_TYPE_TEXTAREA }),
      why_surrogate_mother: new Prop({
        propName: 'why_surrogate_mother', title: 'Why do you want to become a surrogate mother?', type: FIELD_TYPE_TEXTAREA,
      }),
    };
    this.generateNotesAttrs();
  }
}

export default GeneralQuestionsForm;
