export const styles = {
  container: {
    width: '100%',
    borderRadius: '30px',
    margin: '0 0 60px 0',
    boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.04)',
    backgroundColor: '#ffffff',
    display: 'flex',
    WebkitFlexDirection: 'column',
    WebkitAlignItems: 'center',
    title: {
      textShadow: '0 0 3px rgba(238, 163, 201, 05)',
      fontFamily: 'Avenir',
      fontsize: '40px',
      fontWeight: 500,
      textAlign: 'center',
      color: '#000000',
      margin: '40px 0 18px 0',
    },
  },
  row: {
    width: '100%',
    padding: '15px 0 15px 0',
    display: '-webkit-box',
    WebkitFlexDirection: 'row',
    WebkitAlignItems: 'center',
  },
  attrCell: {
    width: '230px',
    padding: '0 40px 0 60px',
    boxSizing: 'border-box',
    fontFamily: 'Avenir',
    fontSize: '20px',
    fontWeight: 500,
    textAlign: 'justify',
    color: '#000000',
  },
  valueCell: {
    fontFamily: 'Avenir',
    fontSize: '20px',
    textAlign: 'justify',
    color: '#000000',
  },
  column: {
    // whiteSpace: 'pre-line',
    margin: '0 18px 0 0',
    padding: 0,
    minWidth: '140px',
    maxWidth: '158px',
    fontFamily: 'Avenir-Roman',
    fontSize: '20px',
    // textAlign: 'center',
    color: '#000000',
    position: 'relative',
  },
  valueWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    input: {
      // width: '80px',
      // height: '50px',
      borderRadius: '10px',
      border: 'none',
      backgroundColor: 'transparent',
      fontFamily: 'Avenir-Roman',
      fontSize: '18px',
      // textAlign: 'center',
      color: '#000000',
      outline: 'none',
      // padding: '0 5px 0 5px',
      boxSizing: 'border-box',
      margin: 0,
    },
  },

};
