import React from 'react';
import Radium from 'radium';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { tify } from 'chinese-conv';
import Header from '../../components/Header/Header';
import Styles from './ProfilesList.module.scss';
import DonorsListCard from './components/DonorsListCard/DonorsListCard';
import Footer from '../../components/Footer/Footer';
import Button from '../DonorApplication/components/Button/Button';
import { fetchDonors } from '../../Api/Apis/DonorApp';
import { requestViewMore, fetchSingleUser } from '../../Api/Apis/Users';
import { setUser } from '../../redux/User/UserActions';
import { showModal } from '../../redux/Modal/Modalactions';
import ModalTypes from '../../components/Modals/ModalTypes';
import { styles } from './ProfilesList.css';
import PaginationBar from './components/PaginationBar/PaginationBar';
import {
  LANG_EN, LANG_ZH_TRADITIONAL,
  PATH_DONORS_LIST,
  PATH_HOME_PAGE,
  PATH_SURROGATE_MOTHERS_LIST, PROFILE_TYPE_DONOR, PROFILE_TYPE_SURROGATE,
  USER_STATUS_APPROVED,
} from '../../res/constants';
import { EnKeyMaps, ZhKeyMaps } from './res/constants';
import { getValueWith } from '../../Utils/Util';
import { fetchSurrogateMothers } from '../../Api/Apis/SurrogateApp';
import FilterBar from './components/FilterBar/FilterBar';
import FilterBarSurrogate from './components/FilterBarSurrogate/FilterBarSurrogate';
import Spin from './components/Spin/index'
const MAX_NUM_OF_ITEMS_PER_PAGE = 10;
const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});
const LangPacks = { EnKeyMaps, ZhKeyMaps };

class ProfilesList extends React.Component {
  constructor(props) {
    super(props);
    this.currentPage = 0;
    const { user } = props;
    this.state = {
      type: 'donor',
      user,
      button: {
        disabled: false,
        text: 'Request to View More',
      },
      users: [],
      loading: false
    };
    this.filterOptions = this.props.location.pathname === PATH_DONORS_LIST ? {
      ethnicity: 'All Races',
      edu_level: 'All Education Levels',
      age: 'All Ages',
      height: 'All Heights',
      blood_type: 'All Blood Types',
      nationality: 'All Countries',
      donation_experience: 'All',
      avail_status: 'All'
    } : {
      ethnicity: 'All Races',
      edu_level: 'All Education Levels',
      age: 'All Ages',
      surrogacy_experience: 'All',
      avail_status: 'All'
    }
  }

  componentDidMount() {
    const { user } = this.state;
    const { langState } = this.props;
    this.setContents(langState.lang);
    if (user == null) {
      const { dispatch } = this.props;
      const data = { modalProps: { isOpen: true }, modalType: ModalTypes.SIGN_UP };
      dispatch(showModal(data));
    } else {
      this.fetchUsers({});
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { user, langState } = nextProps;
      this.setContents(langState.lang);
      this.setState({ user }, () => {
        if (user == null) {
          this.setState({ users: [] });
        } else {
          this.fetchUsers({});
        }
      });
    }
  }

  getTitle = () => {
    const { location, langState } = this.props;
    const { lang } = langState;
    const { pathname } = location;
    let title = '';
    switch (pathname) {
      case PATH_DONORS_LIST:
        title = lang === LANG_EN ? 'Donor Profiles' : '捐卵天使档案';
        break;
      case PATH_SURROGATE_MOTHERS_LIST:
        title = lang === LANG_EN ? 'Surrogate mothers Profiles' : '代孕天使档案';
        break;
      default:
        return '';
    }
    title = lang === LANG_ZH_TRADITIONAL ? tify(title) : title;
    return title;
  };

  setContents = (lang) => {
    const { location } = this.props;
    const { pathname } = location;
    this.setState({
      type: pathname === PATH_DONORS_LIST ? PROFILE_TYPE_DONOR : PROFILE_TYPE_SURROGATE,
      title: this.getTitle(),
      btnTxt: getValueWith({ ...LangPacks, key: 'btnTxt', lang }),
      btnWaitingTxt: getValueWith({ ...LangPacks, key: 'btnWaitingTxt', lang }),
      errMsg: lang === LANG_EN ? EnKeyMaps.get('errMsg') : ZhKeyMaps.get('errMsg'),
      successMsg: lang === LANG_EN ? EnKeyMaps.get('successMsg') : ZhKeyMaps.get('successMsg'),
    }, () => this.setButtonTxt());
  };

  fetchUsers = ({ page = this.currentPage, filterOptions = this.filterOptions }) => {
    this.currentPage = page;
    this.filterOptions = filterOptions;
    const { location } = this.props;
    const { pathname } = location;
    let request;
    this.setState({ loading: true })
    if (filterOptions.donor_app_id || filterOptions.surrogate_app_id) {
      request = fetchSingleUser({ page, filterOptions })
    } else {
      request = pathname === PATH_DONORS_LIST
      ? fetchDonors({ page, filterOptions }) : fetchSurrogateMothers({ page, filterOptions });
    }
    request
      .then((res) => {
        const { data: users } = res.data.data;
        const { count: totalNumOfDonors } = res.data;
        const totalPages = Math.ceil(totalNumOfDonors / MAX_NUM_OF_ITEMS_PER_PAGE);
        this.setState({ users, totalPages, loading: false });
      })
      .catch(() => {
        const { errMsg, btnTxt } = this.state;
        this.enableButton(btnTxt);
        const { dispatch } = this.props;
        const ModalData = {
          modalProps: {
            isOpen: true,
            props: errMsg,
          },
          modalType: ModalTypes.ALERT,
        };
        dispatch(showModal(ModalData));
      });
  };

  setButtonTxt = () => {
    const { button, btnTxt, btnWaitingTxt } = this.state;
    if (button.disabled) {
      button.text = btnWaitingTxt;
    } else {
      button.text = btnTxt;
    }
  };

  enableButton = (text = 'Request to View More') => {
    this.setState({ button: { text, disabled: false } });
  };

  disableButton = (text = 'Please wait...') => {
    this.setState({ button: { text, disabled: true } });
  };

  onRequestViewMore = () => {
    const { btnTxt, btnWaitingTxt, successMsg } = this.state;
    this.disableButton(btnWaitingTxt);
    const { dispatch } = this.props;
    requestViewMore()
      .then((res) => {
        const { data: user } = res.data;
        this.enableButton(btnTxt);
        dispatch(setUser(user));
        const ModalData = {
          modalProps: {
            isOpen: true,
            props: successMsg,
          },
          modalType: ModalTypes.ALERT,
        };
        dispatch(showModal(ModalData));
      })
      .catch(() => {
        const ModalData = {
          modalProps: {
            isOpen: true,
            props: {
              title: 'Oops', message: 'Something went wrong, please retry later.',
            },
          },
          modalType: ModalTypes.ALERT,
        };
        dispatch(showModal(ModalData));
      });
  };

  onPageChange = (event) => {
    const { selected: selectedPage } = event;
    if (selectedPage !== this.currentPage) {
      this.currentPage = selectedPage;
      this.fetchUsers({ page: selectedPage });
    }
  };

  render() {
    const {
      title, button, users, totalPages, user, type, loading
    } = this.state;
    const { location } = this.props;
    const { pathname } = location;
    const cards = users.map(donor => (
      <Link
        to={`/profile/${donor.id}?type=${type}`}
        style={{ display: 'block', textDecoration: 'none', margin: '20px 0' }}
        key={donor.id}
      >
        <DonorsListCard donor={donor} location={location} />
      </Link>
    ));
    const requestViewMoreBtn = users.length > 0 && user != null
    && user.status !== USER_STATUS_APPROVED ? (
      <Button
        style={styles.requestViewMoreBtn}
        onClick={this.onRequestViewMore}
        disabled={button.disabled}
        text={button.text}
      />
      ) : null;
    const paginationBar = user != null
    && user.status === USER_STATUS_APPROVED && totalPages != null ? (
      <PaginationBar
        pageCount={totalPages}
        onPageChange={this.onPageChange}
      />
      ) : null;

    return (
      <Spin loading={loading}>
        <div className={Styles.container}>
          <Header fixed={false} allowTransparent={false} redirectPathAfterLogout={PATH_HOME_PAGE} />
          <h1 className={Styles.title}>{title}</h1>
          <div className={Styles.list}>
            {
              pathname === PATH_DONORS_LIST ? <FilterBar onFilterOptionsChanged={filterOptions => this.fetchUsers({ filterOptions })} />
                : <FilterBarSurrogate onFilterOptionsChanged={filterOptions => this.fetchUsers({ filterOptions })} />
            }

            {cards}
            <div style={{ margin: '50px auto 0 auto' }}>
              {paginationBar}
            </div>
          </div>
          {requestViewMoreBtn}
          <div style={{ marginBottom: '100px' }} />
          <Footer />
        </div>
      </Spin>
    );
  }
}

ProfilesList.defaultProps = {
};

ProfilesList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export default connect(mapStateToProps)(Radium(ProfilesList));
