import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { tify } from 'chinese-conv';
import * as classNames from 'classnames';
import Input from './components/Input';
import Styles from './BasicInformation.module.scss';
import DropdownMenu from './components/DropdownMenu';
import BasicInfoForm from './forms/BasicInfoForm';
import BaseForm from './forms/BaseForm';
import { LANG_EN, LANG_ZH_SIMPLIFIED } from '../../../../../res/constants';
import CheckBox from '../../../../../components/CheckBox/CheckBox';
import { shallHighlight } from '../../../../../Utils/Util';

const mapStateToProps = state => ({
  langState: state.langState,
});

class BasicInformation extends React.Component {
  constructor(props) {
    super(props);
    const { form = new BasicInfoForm({}) } = props;
    form.setComponent(this);
    this.state = {
      form,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
    }
  }

  handleInputChange = (event) => {
    const { target } = event;
    const { name: propName, value } = target;
    const { form } = this.state;
    const { user_attributes: user, form_attributes: formAttributes } = form;
    if (Object.prototype.hasOwnProperty.call(user, propName)) {
      user[propName].value = value;
    } else if (Object.prototype.hasOwnProperty.call(formAttributes, propName)) {
      formAttributes[propName].value = value;
    }
    this.setState({ form });
  };

  handleCheckboxChange = (event) => {
    const { name: propName, text } = event;
    const { form } = this.state;
    const { user_attributes: user } = form;
    if (Object.prototype.hasOwnProperty.call(user, propName)) {
      if (text.includes('Others')) {
        user[propName].value = text;
      } else {
        const { options } = user[propName];
        let { value } = user[propName];
        let selectedOptions = [];
        if (value.includes('Others')) {
          value = '';
        }
        // if value is not empty string
        if (value.trim().length > 0) {
          selectedOptions = value.split(',');
        }
        // if it was selected
        if (selectedOptions.indexOf(text) >= 0) {
          selectedOptions.splice(selectedOptions.indexOf(text), 1);
        } else if (options.indexOf(text) >= 0) {
          // if it was not selected and options contains such option
          selectedOptions.push(text);
        }
        user[propName].value = '';
        if (selectedOptions.length > 0) {
          user[propName].value = selectedOptions.reduce((accum, currentVal) => `${accum},${currentVal}`);
        }
      }
      this.setState({ form });
    }
  };

  handleFormCheckboxChange = (event) => {
    const { name: propName, text } = event;
    const { form } = this.state;
    const { form_attributes: formAttrs } = form;
    const zhOptions = [];
    if (Object.prototype.hasOwnProperty.call(formAttrs, propName)) {
      const { options, value } = formAttrs[propName];
      let selectedOptions = [];
      // if value is not empty string
      if (value.trim().length > 0) {
        selectedOptions = value.split(',');
      }
      if (text === 'Others') {
        if (selectedOptions.includes(text)) {
          selectedOptions = []
        } else {
          selectedOptions = ['Others']
        }
      } else {
        if (selectedOptions.includes(text)) {
          selectedOptions.splice(selectedOptions.indexOf(text), 1);
        } else if (selectedOptions.includes('Others')) {
          selectedOptions = [text]
        } else {
          selectedOptions.push(text);
        }
      }
      formAttrs[propName].value = '';
      if (selectedOptions.length > 0) {
        formAttrs[propName].value = selectedOptions.toString()
      }
      this.setState({ form });
    }
  };

  handleRadioBtnEvent = (event) => {
    const { propName, choice } = event;
    const { form } = this.state;
    const { form_attributes: formAttrs } = form;
    if (Object.prototype.hasOwnProperty.call(formAttrs, propName)) {
      formAttrs[propName].value = choice;
    }
    this.setState({ form });
  };

  renderOtherQuestions = () => {
    const { form } = this.state;
    const { langState, highlightRequiredField } = this.props;
    const { lang = LANG_EN } = langState;
    const {
      drive, support, evaluation, injection, attend,
    } = form.form_attributes;
    return BaseForm.renderYesOrNoTable({
      highlightRequiredField,
      formAttrs: {
        drive, support, evaluation, injection, attend,
      },
      radioBtnClickHandler: this.handleRadioBtnEvent,
      title: lang === LANG_EN ? '*Others' : '*其他',
      lang,
    });
  };

  renderQuestionsWithTextArea = () => {
    const { form } = this.state;
    const { personality, habit, talents } = form.user_attributes;
    const {
      countries, country_to_go, compen, frequency, source,
    } = form.form_attributes;
    return BaseForm.renderQuestionsWithTextArea({
      attrs: {
        personality,
        habit,
        talents,
        countries,
        compen,
        frequency,
        source,
      },
      inputHandler: this.handleInputChange,
    });
  };

  shallHighlight = attr => shallHighlight(attr);

  render() {
    const { form } = this.state;
    const { user_attributes: user, form_attributes: formAttrs } = form;
    const { langState, highlightRequiredField } = this.props;
    const { lang } = langState;
    const otherQuestions = this.renderOtherQuestions();
    const questionsWithTextArea = this.renderQuestionsWithTextArea();
    const title = lang === LANG_EN ? 'Basic Information' : '基本信息';
    return (
      <div className={Styles.container}>
        <h1>{lang === LANG_ZH_SIMPLIFIED ? title : tify(title)}</h1>
        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                && this.shallHighlight(user.first_name),
              })}
            >
              {`${user.first_name.required ? '*' : ''}${user.first_name.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.first_name.propName}
              value={user.first_name.value}
              highlight={highlightRequiredField
              && this.shallHighlight(user.first_name)}
            />
          </div>
          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.last_name),
              })}
            >
              {`${user.last_name.required ? '*' : ''}${user.last_name.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.last_name.propName}
              value={user.last_name.value}
              highlight={highlightRequiredField
              && this.shallHighlight(user.last_name)}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.date_of_birth),
              })}
            >
              {`${user.date_of_birth.required ? '*' : ''}${user.date_of_birth.title}`}
            </p>
            <Input
              type="date"
              onchange={this.handleInputChange}
              name={user.date_of_birth.propName}
              value={user.date_of_birth.value}
              highlight={highlightRequiredField
              && this.shallHighlight(user.date_of_birth)}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.blood_type),
              })}
            >
              {`${user.blood_type.required ? '*' : ''}${user.blood_type.title}`}
            </p>
            <DropdownMenu
              prop={user.blood_type}
              value={user.blood_type.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && this.shallHighlight(user.blood_type)}
            />
          </div>
          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.rh_factor),
              })}
            >
              {`${user.rh_factor.required ? '*' : ''}${user.rh_factor.title}`}
            </p>
            <DropdownMenu
              prop={user.rh_factor}
              value={user.rh_factor.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && this.shallHighlight(user.rh_factor)}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div className={classNames({
            [Styles.cell]: true,
            [Styles.mediumCell]: true,
            [Styles.cellMarginBottom]: true,
          })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.height),
              })}
            >
              {`${user.height.required ? '*' : ''}${user.height.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.height.propName}
              value={user.height.value}
              type={user.height.inputType}
              highlight={highlightRequiredField
              && this.shallHighlight(user.height)}
            />
          </div>
          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.weight),
              })}
            >
              {`${user.weight.required ? '*' : ''}${user.weight.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.weight.propName}
              value={user.weight.value}
              type={user.weight.inputType}
              highlight={highlightRequiredField
              && this.shallHighlight(user.weight)}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div className={classNames({
            [Styles.cell]: true,
            [Styles.shortCell]: true,
            [Styles.cellMarginBottom]: true,
          })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.phone),
              })}
            >
              {`${user.phone.required ? '*' : ''}${user.phone.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.phone.propName}
              value={user.phone.value}
              type={user.phone.inputType}
              highlight={highlightRequiredField
              && this.shallHighlight(user.phone)}
            />
          </div>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.shortCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.wechat),
              })}
            >
              {`${user.wechat.required ? '*' : ''}${user.wechat.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.wechat.propName}
              value={user.wechat.value}
              highlight={highlightRequiredField
              && this.shallHighlight(user.wechat)}
            />
          </div>
          <div className={classNames({ [Styles.cell]: true, [Styles.shortCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.line),
              })}
            >
              {`${user.line.required ? '*' : ''}${user.line.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.line.propName}
              value={user.line.value}
              highlight={highlightRequiredField
              && this.shallHighlight(user.line)}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.facebook),
              })}
            >
              {`${user.facebook.required ? '*' : ''}${user.facebook.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.facebook.propName}
              value={user.facebook.value}
              highlight={highlightRequiredField
              && this.shallHighlight(user.facebook)}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div className={classNames({ [Styles.cell]: true, [Styles.longCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.street_address),
              })}
            >
              {`${user.street_address.required ? '*' : ''}${user.street_address.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.street_address.propName}
              value={user.street_address.value}
              highlight={highlightRequiredField
              && this.shallHighlight(user.street_address)}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.shortCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.city),
              })}
            >
              {`${user.city.required ? '*' : ''}${user.city.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.city.propName}
              value={user.city.value}
              highlight={highlightRequiredField
              && this.shallHighlight(user.city)}
            />
          </div>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.shortCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.state),
              })}
            >
              {`${user.state.required ? '*' : ''}${user.state.title}`}
            </p>
            <DropdownMenu
              options={user.state.options}
              name={user.state.propName}
              value={user.state.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && this.shallHighlight(user.state)}
            />
          </div>
          <div className={classNames({ [Styles.cell]: true, [Styles.shortCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.zipcode),
              })}
            >
              {`${user.zipcode.required ? '*' : ''}${user.zipcode.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.zipcode.propName}
              value={user.zipcode.value}
              type={user.zipcode.inputType}
              highlight={highlightRequiredField
              && this.shallHighlight(user.zipcode)}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.country),
              })}
            >
              {`${user.country.required ? '*' : ''}${user.country.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.country.propName}
              value={user.country.value}
              highlight={highlightRequiredField
              && this.shallHighlight(user.country)}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.edu_level),
              })}
            >
              {`${user.edu_level.required ? '*' : ''}${user.edu_level.title}`}
            </p>
            <DropdownMenu
              options={user.edu_level.options}
              name={user.edu_level.propName}
              value={user.edu_level.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && this.shallHighlight(user.edu_level)}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.shortCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.school),
              })}
            >
              {`${user.school.required ? '*' : ''}${user.school.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.school.propName}
              value={user.school.value}
              highlight={highlightRequiredField
              && this.shallHighlight(user.school)}
            />
          </div>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.shortCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.major),
              })}
            >
              {`${user.major.required ? '*' : ''}${user.major.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.major.propName}
              value={user.major.value}
              highlight={highlightRequiredField
              && this.shallHighlight(user.major)}
            />
          </div>
          <div className={classNames({ [Styles.cell]: true, [Styles.shortCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.occupation),
              })}
            >
              {`${user.occupation.required ? '*' : ''}${user.occupation.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.occupation.propName}
              value={user.occupation.value}
              highlight={highlightRequiredField
              && this.shallHighlight(user.occupation)}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.marriage),
              })}
            >
              {`${user.marriage.required ? '*' : ''}${user.marriage.title}`}
            </p>
            <DropdownMenu
              options={user.marriage.options}
              name={user.marriage.propName}
              value={user.marriage.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && this.shallHighlight(user.marriage)}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.nationality),
              })}
            >
              {`${user.nationality.required ? '*' : ''}${user.nationality.title}`}
            </p>
            <DropdownMenu
              prop={user.nationality}
              value={user.nationality.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && this.shallHighlight(user.nationality)}
            />
          </div>

          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.nationality_others),
              })}
            >
              {`${user.nationality_others.required ? '*' : ''}${user.nationality_others.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.nationality_others.propName}
              value={user.nationality_others.value}
              highlight={highlightRequiredField
              && this.shallHighlight(user.nationality_others)}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.skin_color),
              })}
            >
              {`${user.skin_color.required ? '*' : ''}${user.skin_color.title}`}
            </p>
            <DropdownMenu
              prop={user.skin_color}
              value={user.skin_color.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && this.shallHighlight(user.skin_color)}
            />
          </div>

          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p className={Styles.fieldTitle}>{`${user.skin_color_others.required ? '*' : ''}${user.skin_color_others.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              name={user.skin_color_others.propName}
              value={user.skin_color_others.value}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.hair_color),
              })}
            >
              {`${user.hair_color.required ? '*' : ''}${user.hair_color.title}`}
            </p>
            <DropdownMenu
              prop={user.hair_color}
              value={user.hair_color.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && this.shallHighlight(user.hair_color)}
            />
          </div>

          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p className={Styles.fieldTitle}>{`${user.hair_color_others.required ? '*' : ''}${user.hair_color_others.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              name={user.hair_color_others.propName}
              value={user.hair_color_others.value}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.eye_color),
              })}
            >
              {`${user.eye_color.required ? '*' : ''}${user.eye_color.title}`}
            </p>
            <DropdownMenu
              prop={user.eye_color}
              value={user.eye_color.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && this.shallHighlight(user.eye_color)}
            />
          </div>

          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p className={Styles.fieldTitle}>{`${user.eye_color_others.required ? '*' : ''}${user.eye_color_others.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              name={user.eye_color_others.propName}
              value={user.eye_color_others.value}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div className={Styles.cell}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.ethnicity),
              })}
            >
              {`*${user.ethnicity.title}`}
            </p>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {user.ethnicity.options.map(item => (
                <CheckBox
                  text={item}
                  containerStyle={{ margin: '0 50px 20px 0' }}
                  name={user.ethnicity.propName}
                  onClick={this.handleCheckboxChange}
                  checked={user.ethnicity.value.includes(item)}
                  key={item}
                />
              ))}
            </div>
          </div>
        </div>

        {
          user.ethnicity.value.includes('Others') ? (
            <div className={Styles.row}>
              <div className={classNames({
                [Styles.cell]: true,
                [Styles.mediumCell]: true,
              })}
              >
                <p className={Styles.fieldTitle}>{`${user.ethnicity_others.required ? '*' : ''}${user.ethnicity_others.title}`}</p>
                <Input
                  onchange={this.handleInputChange}
                  name={user.ethnicity_others.propName}
                  value={user.ethnicity_others.value}
                />
              </div>
            </div>
          ) : null
        }

        {BaseForm.renderMultipleChoice({
          attr: user.eye_condition,
          checkboxHandler: this.handleCheckboxChange,
          highlight: highlightRequiredField
              && this.shallHighlight(user.eye_condition),
        })}

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(user.sex_orientation),
              })}
            >
              {`${user.sex_orientation.required ? '*' : ''}${user.sex_orientation.title}`}
            </p>
            <DropdownMenu
              prop={user.sex_orientation}
              value={user.sex_orientation.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
                && this.shallHighlight(user.sex_orientation)}
            />
          </div>
        </div>

        {questionsWithTextArea}

        {BaseForm.renderMultipleChoice({
          attr: formAttrs.country_to_go,
          checkboxHandler: this.handleFormCheckboxChange,
          highlight: highlightRequiredField && shallHighlight(formAttrs.country_to_go),
        })}

        <div className={Styles.row}>
          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && this.shallHighlight(formAttrs.relation),
              })}
            >
              {`${formAttrs.relation.required ? '*' : ''}${formAttrs.relation.title}`}
            </p>
            <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
              <DropdownMenu
                options={formAttrs.relation.options}
                name={formAttrs.relation.propName}
                value={formAttrs.relation.value}
                onchange={this.handleInputChange}
                highlight={highlightRequiredField
                && this.shallHighlight(formAttrs.relation)}
              />
            </div>
          </div>
        </div>

        <div className={Styles.row}>
          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.anonymous_video),
              })}
            >
              {`${formAttrs.anonymous_video.required ? '*' : ''}${formAttrs.anonymous_video.title}`}
            </p>
            <DropdownMenu
              onchange={this.handleInputChange}
              prop={formAttrs.anonymous_video}
              value={formAttrs.anonymous_video.value}
              highlight={highlightRequiredField
                && shallHighlight(formAttrs.anonymous_video)}
            />
          </div>
        </div>

        {otherQuestions}

        <div className={Styles.row} style={{ marginTop: '20px' }}>
          <div className={classNames({ [Styles.cell]: true, [Styles.longCell]: true })}>
            <p className={Styles.fieldTitle}>{`${formAttrs.more_info.required ? '*' : ''}${formAttrs.more_info.title}`}</p>
            <textarea
              className={Styles.textArea}
              onChange={this.handleInputChange}
              name={formAttrs.more_info.propName}
              value={formAttrs.more_info.value}
            />
          </div>
        </div>
      </div>
    );
  }
}

BasicInformation.defaultProps = {
  highlightRequiredField: false,
};

BasicInformation.propTypes = {
  form: PropTypes.instanceOf(BasicInfoForm).isRequired,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
  highlightRequiredField: PropTypes.bool,
};

export default connect(mapStateToProps)(Radium(BasicInformation));
