export const Categories = [
  {
    key: 'ethnicity',
    name: 'Race',
    options: ['All Races', 'Chinese / Taiwanese', 'Asian', 'Caucasian', 'African American', 'Hispanic / Latino', 'Native American'],
  },
  {
    key: 'edu_level',
    name: 'Educational Level',
    options: ['All Education Levels', 'High School', 'Associated', 'Bachelor', 'Master', 'Doctor'],
  },
  {
    key: 'age',
    name: 'Age',
    options: ['All Ages', '18-22', '23-27', '28-32'],
  },
  {
    key: 'height',
    name: 'Height',
    options: ['All Heights', '<160cm', '161cm-165cm', '166cm-170cm', '>170cm'],
  },
  {
    key: 'blood_type',
    name: 'Blood type',
    options: ['All Blood Types', 'A', 'B', 'O', 'AB'],
  },
  {
    key: 'nationality',
    name: 'Resident Country',
    options: ['All Countries', 'Taiwan/China/other Asian country', 'United States', 'Other countries'],
  },
  {
    key: 'donation_experience',
    name: 'Donation Experience',
    options: ['All', 'Yes', 'No'],
  },
  {
    key: 'avail_status',
    name: 'Status',
    options: ['All', "Available","Unavailable","Matched"],
  }
];

export const CategoriesZh = [
  {
  	key: 'ethnicity',
    name: '人种',
    options: [{ label: '所有人种', value: 'All Races' }, { label: '中国人 / 台湾人', value: 'Chinese / Taiwanese' },
      { label: '亚洲人', value: 'Asian' }, { label: '白人', value: 'Caucasian' }, { label: '黑人', value: 'African American' }, { label: '墨西哥人', value: 'Hispanic / Latino' },
      { label: '印第安人', value: 'Native American' }],
  },
  {
  	key: 'edu_level',
    name: '教育水平',
    options: [{ value: 'All Education Levels', label: '所有教育水平' }, { value: 'High School', label: '高中/同等文凭' },
      { value: 'Associated', label: '大专' }, { value: 'Bachelors', label: '学士/本科' }, { value: 'Masters', label: '硕士' },
      { value: 'Doctor', label: '博士' }],
  },
  {
  	key: 'age',
    name: '年龄',
    options: [{ value: 'All Ages', label: '所有年龄' }, '18-22', '23-27', '28-32'],
  },
  {
  	key: 'height',
    name: '身高',
    options: [{ value: 'All Heights', label: '所有身高' }, '< 160cm', '160cm-165cm', '166cm-170cm', '>170cm'],
  },
  {
  	key: 'blood_type',
    name: '血型',
    options: [{ value: 'All Blood Types', label: '所有血型' }, 'A', 'B', 'O', 'AB'],
  },
  {
  	key: 'nationality',
    name: '居住地',
    options: [{ value: 'All Countries', label: '所有国家' }, { value: 'Taiwan/China/Other Asian country', label: '台湾/中国/其他亚洲国家' },
      { value: 'United States', label: '美国' }, { value: 'Other countries', label: '其他国家' }],
  },
  {
  	key: 'donation_experience',
    name: '是否有捐卵经验',
    options: [{ value: 'All', label: '所有' }, { value: 'Yes', label: '是' }, { value: 'No', label: '否' }],
  },
  {
  	key: 'avail_status',
    name: '状态',
    options: [{ value: 'All', label: '所有' },{ value: 'Available', label: '可用' },{ value: 'Unavailable', label: '不可用' },{ value: 'Matched', label: '已配对' }],
  }
];
