import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { tify } from 'chinese-conv';
import { styles } from './PersonalInfo.css';
import { LANG_EN, LANG_ZH_TRADITIONAL } from '../../../../../../res/constants';
import { PersonalInfoColumns, PersonalInfoColumnsZh } from '../../PersonalInfo/constants';
import {
  parseAnonymousVideo, parseBloodType,
  parseEduLevel,
  parseEthnicity, parseEyeColor, parseEyeCondition, parseHairColor,
  parseMaritalStatus,
  parseNationality,
  parseRelation, parseSexOrientation, parseSkinColor, parseStatus,
} from '../../../../../../Utils/Util';

class PersonalInfo extends React.Component {
  constructor(props) {
    super(props);
    const { donor } = props;
    this.state = {
      donor,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { donor = null } = nextProps;
      this.setState({ donor });
    }
  }

  renderColumns = () => {
    const { donor } = this.state;
    const { lang } = this.props;
    const personalInfoColumns = lang === LANG_EN ? PersonalInfoColumns : PersonalInfoColumnsZh;
    return personalInfoColumns.map((column, index) => {
      let backgroundColor = '#fefafc';
      if (index % 2 === 0) {
        backgroundColor = '#ffffff';
      }
      let value = donor != null ? (donor[column.key] || '') : '';
      if (column.key === 'date_of_birth' && value.trim().length > 0) {
        const birthDate = new Date(value);
        const month = birthDate.getMonth() + 1;
        value = `${month >= 10 ? month : `0${month}`}/${birthDate.getFullYear()}`;
      }
      if (column.key === 'edu_level') {
        value = parseEduLevel(value);
      }
      if (column.key === 'marriage') {
        value = parseMaritalStatus(value);
      }
      if (column.key === 'nationality') {
        value = parseNationality({ lang, nationality: value });
      }
      if (column.key === 'ethnicity') {
        value = parseEthnicity({ lang, ethnicity: value });
      }
      if (column.key === 'skin_color') {
        value = parseSkinColor({ lang, skinColor: value });
      }
      if (column.key === 'hair_color') {
        value = parseHairColor({ lang, hairColor: value });
      }
      if (column.key === 'eye_color') {
        value = parseEyeColor({ lang, eyeColor: value });
      }
      if (column.key === 'eye_condition') {
        value = parseEyeCondition(value);
      }
      if (column.key === 'blood_type') {
        value = parseBloodType({ bloodType: value, lang });
      }
      if (column.key === 'avail_status') {
        value = parseStatus({ status: value, lang });
      }
      if (column.key === 'sex_orientation') {
        value = parseSexOrientation(value);
      }
      if (column.type === 'form_attrs') {
        if (donor && donor.donor_app) {
          const { form_attributes: formAttrs } = donor.donor_app.data;
          value = formAttrs[column.key] != null ? formAttrs[column.key] : '';
        }
        if (column.key === 'relation') {
          value = parseRelation({ lang, relation: value });
        }
        if (column.key === 'anonymous_video') {
          value = parseAnonymousVideo(value, lang);
        }
      }
      if (value != null && typeof value === 'string') {
        value = lang === LANG_ZH_TRADITIONAL ? tify(value) : value;
      }
      return (
        <div key={column.key} style={[styles.row, { backgroundColor }]}>
          <div style={styles.attrCell}>
            {`${lang === LANG_ZH_TRADITIONAL ? tify(column.name) : column.name}:`}
          </div>
          <div style={styles.valueCell}>
            {`${value}${column.unit || ''}`}
          </div>
        </div>
      );
    });
  };

  render() {
    const { donor } = this.state;
    const columns = this.renderColumns();
    const { lang } = this.props;
    const title = lang === LANG_EN ? 'Personal Information' : '个人信息';
    // note that img with src which includes https does not work...
    return (
      <div style={styles.container}>
        <h1 style={styles.container.title}>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
        {columns}
        <div style={{ height: '50px' }} />
        <div
          className="avatar"
          style={{ ...styles.container.avatar, backgroundImage: `url(${donor.avatar.replace('https', 'http')})` }}
        />
      </div>
    );
  }
}

PersonalInfo.defaultProps = {
  donor: null,
};

PersonalInfo.propTypes = {
  donor: PropTypes.shape({
    authentication_token: PropTypes.string,
  }),
  lang: PropTypes.string.isRequired,
};

export default Radium(PersonalInfo);
