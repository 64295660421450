import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import FAQs from '../../components/FAQ/FAQs';
import Styles from './ForEggDonor.scss';
import {
  EggDonationFAQs, EggDonationFAQsZh, EnKeyMaps, ZhKeyMaps,
} from './res/constants';
import BecomeEggDonor from './components/BecomeEggDonor/BecomeEggDonor';
import { LANG_EN, PATH_DONOR_APPLICATION, USER_STATUS_BECOME_EGG_DONOR } from '../../res/constants';
import IntroSection from '../../components/IntroSection/IntroSection';
import ModalTypes from '../../components/Modals/ModalTypes';
import { showModal } from '../../redux/Modal/Modalactions';
import Process from '../../components/Process/Process';
import { getValueWith } from '../../Utils/Util';
import ExtraIntro from '../../components/ExtraIntro/ExtraIntro';

const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});
const LangPacks = { ZhKeyMaps, EnKeyMaps };

class ForEggDonor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { eggDonationFAQs: [] };
  }

  componentDidMount() {
    const { langState } = this.props;
    this.setContents(langState.lang);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setContents(langState.lang);
    }
  }

  setContents = (lang) => {
    this.setState({
      eggDonationFAQs: lang === LANG_EN ? EggDonationFAQs : EggDonationFAQsZh,
      processTitle: getValueWith({ ...LangPacks, lang, key: 'process_title' }),
      process: lang === LANG_EN ? EnKeyMaps.get('process') : ZhKeyMaps.get('process'),
    });
  };

  onBecomeDonor = () => {
    const { user, dispatch, history } = this.props;
    if (user != null) {
      history.push(PATH_DONOR_APPLICATION);
    } else {
      const data = {
        modalProps: {
          isOpen: true,
          props: {
            options: { status: USER_STATUS_BECOME_EGG_DONOR },
          },
        },
        modalType: ModalTypes.SIGN_UP,
      };
      dispatch(showModal(data));
    }
  };

  render() {
    const { eggDonationFAQs, processTitle = '', process = [] } = this.state;
    const { langState } = this.props;
    const leaderTitle = langState.lang === LANG_EN ? EnKeyMaps.get('extra_title') : '';
    const leaderIntro = langState.lang === LANG_EN ? EnKeyMaps.get('extra_intro') : '';
    return (
      <div style={Styles.container}>
        <Header />

        <IntroSection
          LangPacks={LangPacks}
          bg="https://res.cloudinary.com/hyyxofhbh/image/upload/c_scale,q_80,w_1600/v1559753478/NewCare/Assets/Egg%20Donation/shutterstock_189429203.jpg"
          onBtnClicked={this.onBecomeDonor}
        />
        <ExtraIntro
          title={leaderTitle}
          details={leaderIntro}
          images={[
            'https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,q_80,w_800/v1559753487/NewCare/Assets/Egg%20Donation/shutterstock_1077628544.jpg',
            'https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,q_80,w_800/v1559753488/NewCare/Assets/Egg%20Donation/shutterstock_745857010.jpg',
            'https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,q_80,w_800/v1559753488/NewCare/Assets/Egg%20Donation/shutterstock_778642012.jpg',
          ]}
        />
        <Process title={processTitle} process={process} />
        <BecomeEggDonor />
        <FAQs faqs={eggDonationFAQs} />

        <Footer />
      </div>
    );
  }
}

ForEggDonor.defaultProps = {
  user: null,
};

ForEggDonor.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape(
    {
      display_name: PropTypes.string,
    },
  ),
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(ForEggDonor));
