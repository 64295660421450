import React from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import HomePage from './pages/HomePage/HomePage';
import * as reducers from './redux/reducers';
import ModalRoot from './components/Modals/ModalRoot';
import DonorApplication from './pages/DonorApplication/DonorApplication';
import EggDonationPage from './pages/EggDonationPage/EggDonationPage';
import EggFreezingPage from './pages/EggFreezingPage/EggFreezingPage';
import IVFPage from './pages/IVFPage/IVFPage';
import SuccessStories from './pages/SuccessStories/SuccessStories';
import { saveState } from './Utils/LocalStorage';
import { getAxiosInstance } from './Utils/WebRequest';
import { fetchUserById } from './Api/Apis/Users';
import { setUser } from './redux/User/UserActions';
import ProfilesList from './pages/ProfilesList/ProfilesList';
import ForSurrogate from './pages/ForSurrogate/ForSurrogate';
import ForEggDonor from './pages/ForEggDonor/ForEggDonor';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  PATH_CLINICS,
  PATH_DONOR_APPLICATION,
  PATH_DONORS_LIST,
  PATH_EGG_DONATION,
  PATH_EGG_FREEZING,
  PATH_FERTILITY_CENTER_DETAILS, PATH_FOR_EGG_DONOR, PATH_FOR_SURROGATE, PATH_HOME_PAGE,
  PATH_IVF, PATH_POST, PATH_PROFILE,
  PATH_SUCCESS_STORIES, PATH_SURROGACY, PATH_SURROGATE_APPLICATION, PATH_SURROGATE_MOTHERS_LIST,
} from './res/constants';
import Post from './pages/Post/Post';
import Surrogacy from './pages/Surrogacy/Surrogacy';
import SurrogateApplication from './pages/SurrogateApplication/SurrogateApplication';
import ScrollToTop from './components/ScrollToTop';
import Profile from './pages/Profile/Profile';

const store = createStore(combineReducers(reducers));
store.subscribe(() => {
  saveState({
    userState: store.getState().userState,
    langState: store.getState().langState,
  });
});
// update user's state if it exists
if (store.getState().userState != null) {
  const { userState: user } = store.getState();
  getAxiosInstance().defaults.headers
    .common.Authorization = user.authentication_token;
  fetchUserById(user.id)
    .then((res) => {
      store.dispatch(setUser(res.data.data));
    })
    .catch(() => {});
}

const App = () => (
  <div>
    <Provider store={store}>
      <Router>
        <ScrollToTop>
          <Route exact path={PATH_HOME_PAGE} component={HomePage} />
          <Route path={PATH_IVF} component={IVFPage} />
          <Route
            path={PATH_CLINICS}
            component={props => <IVFPage {...props} showFertilityCenterOnly />}
          />
          <Route path={PATH_SURROGACY} component={Surrogacy} />
          <Route path={PATH_SUCCESS_STORIES} component={SuccessStories} />
          <Route path={PATH_EGG_DONATION} component={EggDonationPage} />
          <Route path={PATH_EGG_FREEZING} component={EggFreezingPage} />
          <Route path={PATH_DONOR_APPLICATION} component={DonorApplication} />
          <Route path={PATH_SURROGATE_APPLICATION} component={SurrogateApplication} />
          <Route path={PATH_DONORS_LIST} component={ProfilesList} />
          <Route path={PATH_SURROGATE_MOTHERS_LIST} component={ProfilesList} />
          <Route path={`${PATH_PROFILE}/:id`} component={Profile} />
          <Route path={PATH_FOR_SURROGATE} component={ForSurrogate} />
          <Route path={PATH_FOR_EGG_DONOR} component={ForEggDonor} />
          <Route path={`${PATH_FERTILITY_CENTER_DETAILS}/:id`} component={Post} />
          <Route path={`${PATH_POST}/:id`} component={Post} />
          <ModalRoot />
        </ScrollToTop>
      </Router>
    </Provider>
  </div>
);
export default App;
