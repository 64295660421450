
export const EnKeyMaps = new Map([
  ['title', 'Cooperate Fertility Center'],
  ['btn_txt', 'Read More'],
]);

export const ZhKeyMaps = new Map([
  ['title', '合作机构'],
  ['btn_txt', '阅读更多'],
]);
