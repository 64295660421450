import { ERROR_TYPE_MISSED_FIELD, FIELD_TYPE_PICKER } from '../../../../../res/constants';
import {
  Prop,
} from '../../../../DonorApplication/components/forms/BasicInformation/forms/Utils';
import { uploadPhoto } from '../../../../../Api/Apis/Photos';
import BaseForm from '../../../../DonorApplication/components/forms/BasicInformation/forms/BaseForm';

class CompletionForm extends BaseForm {
  constructor(user, component) {
    super({ user, component });
    this.attrs = {
      consent_signature: {
        propName: 'consent_signature',
        ref: null,
        base64: null,
        saved: false,
      },
      consent_date: new Prop({
        propName: 'consent_date',
        type: FIELD_TYPE_PICKER,
        title: 'Date',
        inputType: 'date',
      }),
      consent_name: new Prop({ propName: 'consent_name', title: 'consent_name' }),
      gestational_carrier_signature: {
        propName: 'gestational_carrier_signature',
        ref: null,
        base64: null,
        saved: false,
      },
      gestational_carrier_date: new Prop({
        propName: 'gestational_carrier_date',
        type: FIELD_TYPE_PICKER,
        title: 'Date',
        inputType: 'date',
      }),
      gestational_carrier_name: new Prop({ propName: 'gestational_carrier_name', title: 'consent_name' }),
    };
    this.form_attributes = {
      single_gestation_comp: new Prop({ propName: 'single_gestation_comp', title: 'Single Gestation', inputType: 'number' }),
      double_gestation_comp: new Prop({ propName: 'double_gestation_comp', title: 'Double Gestation', inputType: 'number' }),
      triple_gestation_comp: new Prop({ propName: 'triple_gestation_comp', title: 'Triple_gestation', inputType: 'number' }),
    };
  }

  validateForm = () => {
    if (this.user == null) { return new Error('In order to update the form, you must log in first'); }
    const allAttrs = [...Object.values(this.user_attributes),
      ...Object.values(this.form_attributes)];
    if (allAttrs.filter(attr => attr.required)
      .some(attr => attr.value == null || (typeof attr.value === 'string' && attr.value.trim().length === 0))) {
      return {
        message: 'Please fill all required fields.',
        type: ERROR_TYPE_MISSED_FIELD,
      };
    }

    const {
      consent_signature: consentSignature, consent_date: consentDate, consent_name: consentName,
      gestational_carrier_signature: gestationalCarrierSignature,
      gestational_carrier_date: gestationalCarrierDate,
      gestational_carrier_name: gestationalCarrierName,
    } = this.attrs;
    if (consentSignature.base64 == null || gestationalCarrierSignature == null) { return new Error('Please sign first.'); }
    if (consentDate.value == null || consentDate.value.trim().length === 0
        || gestationalCarrierDate.value == null || gestationalCarrierDate.value.trim().length === 0) { return new Error('Please pick a date'); }
    if (consentName.value == null || consentName.value.trim().length === 0
      || gestationalCarrierName.value == null || gestationalCarrierName.value.trim().length === 0) { return new Error('Please enter your name'); }
    return null;
  };

  save = () => {
    const validationErrMsg = this.validateForm();
    if (validationErrMsg != null) { return Promise.reject(validationErrMsg); }
    const {
      consent_signature: consentSignature,
      gestational_carrier_signature: gestationalCarrierSignature,
    } = this.attrs;
    return Promise.all(this.getSignatureUploaders([consentSignature, gestationalCarrierSignature]));
  };

  getSignatureUploaders = signatures => signatures.map((signature) => {
    if (signature.saved) {
      return Promise.resolve('Signature saved');
    }
    return fetch(signature.base64)
      .then(res => res.blob())
      .then((blob) => {
        const formData = new FormData();
        const file = new File([blob], `${signature.propName}.jpeg`);
        formData.append('photo', file);
        formData.append('photo_type', signature.propName);
        formData.append('target_type', 'User');
        formData.append('target_id', this.user.id);
        return uploadPhoto(formData)
          .then((res) => {
            signature.saved = true;
            return res;
          })
          .catch(err => err);
      });
  })
}

export default CompletionForm;
