import {
  FIELD_TYPE_PICKER,
  FIELD_TYPE_TEXTAREA,
  FIELD_TYPE_YES_OR_NO,
} from '../../../../../res/constants';
import {
  Prop,
} from '../../../../DonorApplication/components/forms/BasicInformation/forms/Utils';
import BaseForm from '../../../../DonorApplication/components/forms/BasicInformation/forms/BaseForm';

class InsuranceInfo extends BaseForm {
  constructor(user = null, component = null) {
    super({ user, component });
    this.user = user;
    this.form_attributes = {
      regular_menstrual_period: new Prop({
        propName: 'regular_menstrual_period',
        title: 'Do you have regular menstrual period?',
        options: ['Yes', 'No'],
        type: [FIELD_TYPE_YES_OR_NO, FIELD_TYPE_PICKER],
      }),
      period_monthly_cycle: new Prop({
        propName: 'period_monthly_cycle',
        title: 'How long is your monthly cycle?',
        inputType: 'number',
        placeholder: 'days',
        required: false,
      }),
      bleeding_period: new Prop({
        propName: 'bleeding_period',
        options: ['Yes', 'No'],
        title: 'Do you have any bleeding between periods?',
        type: [FIELD_TYPE_YES_OR_NO, FIELD_TYPE_PICKER],
      }),
      period_last: new Prop({
        propName: 'period_last',
        title: 'How many days does your period last?',
        inputType: 'number',
        placeholder: 'days',
        required: false,
      }),

      using_birth_control: new Prop({
        propName: 'using_birth_control',
        title: 'Are you presently using birth control?',
        options: ['Yes', 'No'],
        type: [FIELD_TYPE_YES_OR_NO, FIELD_TYPE_PICKER],
      }),
      birth_control_method: new Prop({
        propName: 'birth_control_method',
        title: 'If yes, please state current method',
        required: false,
      }),
      birth_control_method_long: new Prop({
        propName: 'birth_control_method_long',
        title: 'How long have you used this method of birth control?',
        required: false,
      }),

      currently_breastfeeding: new Prop({
        propName: 'currently_breastfeeding',
        title: 'Are you currently breastfeeding?',
        options: ['Yes', 'No'],
        type: [FIELD_TYPE_YES_OR_NO, FIELD_TYPE_PICKER],
        required: false,
      }),
      date_stop_breastfeeding: new Prop({
        propName: 'date_stop_breastfeeding',
        title: 'If so, when do you plan to stop?',
        inputType: 'date',
        required: false,
      }),

      had_surgery: new Prop({
        propName: 'had_surgery',
        options: ['Yes', 'No'],
        title: 'Have you ever had surgery?',
        type: [FIELD_TYPE_YES_OR_NO, FIELD_TYPE_PICKER],
        required: false,
      }),
      surgery_reason_result: new Prop({
        propName: 'surgery_reason_result',
        title: 'If yes, reasons and results?',
        required: false,
      }),

      had_postpartum_depression: new Prop({
        propName: 'had_postpartum_depression',
        options: ['Yes', 'No'],
        title: 'Have you ever experienced any postpartum depression?',
        type: [FIELD_TYPE_YES_OR_NO, FIELD_TYPE_PICKER],
        required: false,
      }),
      postpartum_depression_details: new Prop({
        propName: 'postpartum_depression_details',
        title: 'If yes, please give the details and time periods\n',
        inputType: 'number',
        required: false,
      }),


      medical_problem: new Prop({
        propName: 'medical_problem',
        title: 'Do you have any medical problems?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      abnormal_pap_smear: new Prop({
        propName: 'abnormal_pap_smear',
        title: 'Have you ever had an abnormal pap smear?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      unusual_monthly_cycle: new Prop({
        propName: 'unusual_monthly_cycle',
        title: 'Is there anything unusual about your monthly cycle?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      child_health_problems: new Prop({
        propName: 'child_health_problems',
        title: 'Do any of your children have serious health problems?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      medications_mental: new Prop({
        propName: 'medications_mental',
        title: 'Have you ever been prescribed or taken any medications for depression or mental health?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      problems_drug_alcohol_abuse: new Prop({
        propName: 'problems_drug_alcohol_abuse',
        title: 'Have you ever had any problems with drug or alcohol abuse?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      seen_doc_infertility: new Prop({
        propName: 'seen_doc_infertility',
        title: 'Have you ever been seen by a doctor for infertility?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      delivered_child: new Prop({
        propName: 'delivered_child',
        title: 'Have you delivered any children with birth defects?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      parents_mental: new Prop({
        propName: 'parents_mental',
        title: 'Have your parents had any serious mental or physical illnesses?\n',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      told_infertile: new Prop({
        propName: 'told_infertile',
        title: 'Have you ever been told that you were infertile?',
        type: FIELD_TYPE_YES_OR_NO,
      }),

      all_serious_illnesses: new Prop({
        propName: 'all_serious_illnesses', title: 'List all serious illnesses and hospitalizations:', required: false, type: FIELD_TYPE_TEXTAREA,
      }),
      all_medications: new Prop({
        propName: 'all_medications', title: 'List all medications you are presently taking and the reasons for each:', required: false, type: FIELD_TYPE_TEXTAREA,
      }),
      children_deceased: new Prop({
        propName: 'children_deceased', title: 'If any of your children are deceased, what was the age and cause of death?', required: false, type: FIELD_TYPE_TEXTAREA,
      }),
      parents_deceased: new Prop({
        propName: 'parents_deceased', title: 'If either of your parents are deceased what was their age and cause of death?', required: false, type: FIELD_TYPE_TEXTAREA,
      }),
    };
    this.generateNotesAttrs();
  }
}

export default InsuranceInfo;
