import keyMirror from 'keymirror';

export const ModalActionTypes = keyMirror({
  HIDE_MODAL: null,
  SHOW_MODAL: null,
});


export function showModal({ modalProps, modalType }) {
  return {
    type: ModalActionTypes.SHOW_MODAL,
    modalProps,
    modalType,
  };
}

export function hideModal() {
  return {
    type: ModalActionTypes.HIDE_MODAL,
  };
}
