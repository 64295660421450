import React, { Fragment } from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './IVFPage.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import WeAreLeader from './components/WeAreLeader/WeAreLeader';
import FertilityCenter from './components/FertilityCenter/FertilityCenter';
import FAQs from '../../components/FAQ/FAQs';
import { LANG_EN, USER_STATUS_REQUEST_CONSULTATION } from '../../res/constants';
import IntroSection from '../../components/IntroSection/IntroSection';
import {
  EnKeyMaps, ZhKeyMaps, IvfFAQsZh, IvfFAQs,
} from './res/constants';
import { onGetConsultation } from '../../Utils/Util';
import ExtraIntro from '../../components/ExtraIntro/ExtraIntro';

const mapStateToProps = state => ({
  langState: state.langState,
  user: state.userState,
});
const LangPacks = { ZhKeyMaps, EnKeyMaps };

class IVFPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ivfFAQs: IvfFAQs,
    };
  }

  componentDidMount() {
    const { langState } = this.props;
    this.setContents(langState.lang);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setContents(langState.lang);
    }
  }

  setContents = (lang) => {
    this.setState({
      ivfFAQs: lang === LANG_EN ? IvfFAQs : IvfFAQsZh,
    });
  };

  render() {
    const { ivfFAQs } = this.state;
    const { user, showFertilityCenterOnly, langState } = this.props;
    const { lang } = langState;
    const advantagesTitle = lang === LANG_EN ? EnKeyMaps.get('advantage_title') : ZhKeyMaps.get('advantage_title');
    const advantages = lang === LANG_EN ? EnKeyMaps.get('advantages') : ZhKeyMaps.get('advantages');
    return (
      <div style={styles.container}>
        <Header allowTransparent={!showFertilityCenterOnly} />
        {showFertilityCenterOnly ? <FertilityCenter containerStyle={showFertilityCenterOnly ? { marginTop: '80px' } : {}} /> : (
          <Fragment>
            <IntroSection
              LangPacks={LangPacks}
              bg="https://res.cloudinary.com/hyyxofhbh/image/upload/c_scale,q_80,w_1600/v1557271595/NewCare/Assets/baby6.jpg"
              onBtnClicked={() => onGetConsultation({ ...this.props })}
              btnVisible={user == null || user.status !== USER_STATUS_REQUEST_CONSULTATION}
            />
            <WeAreLeader />
            <ExtraIntro title={advantagesTitle} details={advantages} />
            <FAQs faqs={ivfFAQs} />
          </Fragment>
        )}
        <Footer />
      </div>
    );
  }
}

IVFPage.defaultProps = {
  user: null,
  showFertilityCenterOnly: false,
};

IVFPage.propTypes = {
  user: PropTypes.shape(
    {
      display_name: PropTypes.string,
    },
  ),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
  showFertilityCenterOnly: PropTypes.bool,
};

export default connect(mapStateToProps)(Radium(IVFPage));
