import {
  EGG_DONOR_TYPE,
  INTENTED_PARENTS_TYPE,
  SURROGATE_MOTHER_TYPE,
  THIRD_PARTY_TYPE,
} from '../../../../res/constants';

export const LoginEnContents = {
  title: 'Sign up to join NewCare',
  alreadyHaveAccountTxt: 'Already have an account? ',
  logInTxt: 'Log in',
  policy: 'By signing up, you agree to our ',
  termsTxt: 'Terms',
  and: 'and',
  privacyPolicyTxt: 'Privacy Policy',
  emailInputPlaceholder: 'Email',
  passwordInputPlaceholder: 'Password',
  phoneInputPlaceholder: 'Phone Number',
  firstNamePlaceholder: 'First Name',
  lastNamePlaceholder: 'Last Name',
  wechatPlaceHolder: 'WeChat (Optional)',
  userTypePlaceHolder: 'You are...',
  btnSignUpTxt: 'Sign up',
  signingUpTxt: 'Signing Up',
  errorMessages: {
    fillAllFields: 'Please fill all fields.',
    invalidEmail: 'This does not look like an email address.',
    invalidPass: 'Password length must be minimum 6 characters.',
    registeredPhone: 'Phone number is already registered.',
    registeredEmail: 'Email address is already registered.',
    unknownErr: 'Something went wrong. Please retry later.',
  },
  userTypes: [
    { key: INTENTED_PARENTS_TYPE, name: 'Intented Parent' },
    { key: EGG_DONOR_TYPE, name: 'Egg Donor' },
    { key: SURROGATE_MOTHER_TYPE, name: 'Surrogate Mother' },
    { key: THIRD_PARTY_TYPE, name: 'Third Party Representative' },
  ],
};

export const LoginZhContents = {
  title: '注册加入NewCare',
  alreadyHaveAccountTxt: '已有账号？ ',
  logInTxt: '点击登录',
  policy: '您已经同意 ',
  termsTxt: '用户协议',
  and: '及',
  privacyPolicyTxt: '隐私协议',
  emailInputPlaceholder: '电子邮箱',
  passwordInputPlaceholder: '密码',
  phoneInputPlaceholder: '电话',
  firstNamePlaceholder: '名字',
  lastNamePlaceholder: '姓氏',
  userTypePlaceHolder: '您是...',
  wechatPlaceHolder: '微信 (选填)',
  btnSignUpTxt: '注册',
  signingUpTxt: '注册中...',
  errorMessages: {
    fillAllFields: '请在所有输入框内输入信息',
    invalidEmail: '无效邮箱地址',
    invalidPass: '至少六位密码',
    registeredPhone: '电话已被注册',
    registeredEmail: '电子邮箱已被注册',
    unknownErr: '出错啦，请稍后再试',
  },
  userTypes: [
    { key: INTENTED_PARENTS_TYPE, name: '准父母' },
    { key: EGG_DONOR_TYPE, name: '捐卵者' },
    { key: SURROGATE_MOTHER_TYPE, name: '代理孕母' },
    { key: THIRD_PARTY_TYPE, name: '第三方合作方' },
  ],
};
