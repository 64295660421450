import {
  FIELD_TYPE_PICKER,
  FIELD_TYPE_YES_OR_NO,
} from '../../../../../res/constants';
import {
  Prop,
  retrieveKeyValuesFrom,
} from '../../../../DonorApplication/components/forms/BasicInformation/forms/Utils';
import { HusbandRows, JobInfoCols, WifeRows } from './constants';
import BaseForm from '../../../../DonorApplication/components/forms/BasicInformation/forms/BaseForm';

class InsuranceInfo extends BaseForm {
  constructor(user = null, component = null) {
    super({ user, component });
    this.user = user;
    this.user_attributes = {
      edu_level: new Prop({
        propName: 'edu_level',
        title: 'Educational Level',
        type: FIELD_TYPE_PICKER,
        options: ['High School Degree', 'Vocational Degree', 'Associated Degree', 'Bachelors Degree', 'Masters Degree', 'Doctoral Degree', 'Post-Doctoral Degree', 'Professional Certification'],
      }),
      school: new Prop({ propName: 'school', title: 'School Name' }),
      major: new Prop({ propName: 'major', title: 'Major', required: false }),
    };
    this.form_attributes = {
      stay_home_mom: new Prop({
        propName: 'stay_home_mom',
        title: 'Are you a stay home mom? *\n',
        type: FIELD_TYPE_PICKER,
        options: ['Yes', 'No', 'Part time job', 'Full time job', 'Part time student', 'Full time student', 'Other'],
      }),
      stay_home_mom_notes: new Prop({ propName: 'stay_home_mom_notes', title: 'If other, please specify', required: false }),
      has_stable_income: new Prop({
        propName: 'has_stable_income',
        title: 'Do you have a stable income source?',
        type: [FIELD_TYPE_PICKER, FIELD_TYPE_YES_OR_NO],
        options: ['Yes', 'No'],
      }),
      num_ppl_you_support: new Prop({
        propName: 'num_ppl_you_support',
        title: 'How many persons do you support including yourself? ',
        inputType: 'number',
      }),
      receiving_food_stamps: new Prop({
        propName: 'receiving_food_stamps',
        title: 'Are you receiving food stamps or any other public assistance as part of your income?',
        type: [FIELD_TYPE_PICKER, FIELD_TYPE_YES_OR_NO],
        options: ['Yes', 'No'],
      }),
    };
    this.job_info_attributes = {};
    this.husband_job_info_attributes = {};
    this.generateJobInfoFormAttrs(WifeRows, 'job_info_attributes');
    this.generateJobInfoFormAttrs(HusbandRows, 'husband_job_info_attributes');
  }

  getFormAttrs = () => ({
    ...retrieveKeyValuesFrom(this.form_attributes),
    ...this.getTableFormAttrs(this.job_info_attributes),
    ...this.getTableFormAttrs(this.husband_job_info_attributes),
  });

  getTableFormAttrs = (attr) => {
    const result = {};
    const props = Object.keys(attr);
    props.forEach((prop) => {
      result[prop] = retrieveKeyValuesFrom(attr[prop]);
    });
    return result;
  };

  generateJobInfoFormAttrs = (rows, attrName) => {
    this[attrName] = {};
    rows.forEach((row) => {
      const propName = row.key;
      this[attrName][propName] = {};
      JobInfoCols.forEach((col) => {
        const subPropName = col.key;
        this[attrName][propName][subPropName] = new Prop({
          propName: subPropName,
          required: false,
        });
      });
    });
  };
}

export default InsuranceInfo;
