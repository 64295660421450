import { FIELD_TYPE_MULTIPLE_CHOICE, FIELD_TYPE_YES_OR_NO, LANG_EN } from '../../../../../res/constants';
import { Prop } from '../BasicInformation/forms/Utils';
import BaseForm from '../BasicInformation/forms/BaseForm';

const ContentsEn = new Map([
  ['double_eyelid', {
    title: 'Do you have double eyelid?',
  }],
  ['plastic_sur', {
    title: 'Had plastic surgery before?',
  }],
  ['drugs', {
    title: 'Had used any illegal drugs before?',
  }],
  ['criminal', {
    title: 'Do you have any legal dispute or criminal record?',
  }],
  ['arrested', {
    title: 'Have been arrested before?',
  }],
  ['donor_before', {
    title: 'Been an egg donor before? If yes, please tell us the location, date, and number of eggs that were retrieved.',
  }],
  ['anti_depression', {
    title: 'Have you ever used any anti-depression medication before?',
  }],
  ['medication', {
    title: 'Are you currently taking any medication? If yes, please list out all the medications.',
  }],
  ['hospital', {
    title: 'Have you ever been admitted to the hospital? If yes, please explain',
  }],
  ['surgery', {
    title: 'Had any surgery before? If yes, please explain',
  }],
  ['major_ill', {
    title: 'Do you have any major illness? If yes, please explain',
  }],
  ['transfusion', {
    title: 'Had blood transfusion before?',
  }],
  ['transplant', {
    title: 'Had organ transplant before?',
  }],
  ['medullary', {
    title: 'Had medullary transplant before?',
  }],
  ['psychiatric', {
    title: 'Had psychiatric treatments before?',
  }],
  ['std', {
    title: 'Are you or your sexual partners ever been diagnosed with any STDs before?',
  }],
  ['pregnant', {
    title: 'Have you been pregnant before? If yes, please tell us # of pregnancy, miscarriage, and abortions.',
  }],
  ['see_doctor', {
    title: 'Had you been to doctors within the past year? If yes, please tell us what specialist, date, reason, results.',
  }],
  ['smoke', {
    title: 'Do you smoke?',
  }],
  ['drink', {
    title: 'Do you drink alcohol?',
  }],
  ['period', {
    title: 'Do you have normal menstrual period? How often is your menstrual period? (the 1st day of period till the day before next period)',
  }],
  ['adopted', {
    title: 'Are you adopted?',
  }],
  ['family_disease', {
    title: 'Do you have any family history of heritable diseases?',
  }],
  ['twins', {
    title: 'Do you have any family history of twins or triplets?',
  }],
  ['contraception', {
    title: 'Current contraception method',
    options: ['Female condom', 'Intrauterine ring', 'Birth control pad', 'Birth control pill', 'Birth control injection', 'Condom', 'None', 'Others'],
  }],
]);

const ContentsZh = new Map([
  ['double_eyelid', {
    title: '是否是双眼皮？',
  }],
  ['plastic_sur', {
    title: '是否有接受过整形？',
  }],
  ['drugs', {
    title: '是否有使用过毒品或违禁药物？',
  }],
  ['criminal', {
    title: '是否有违法犯罪记录？',
  }],
  ['arrested', {
    title: '是否有被逮補過？',
  }],
  ['donor_before', {
    title: '是否有捐过卵子？如果有请在"备注"写出次数,时间,地点,取出卵子颗数',
  }],
  ['anti_depression', {
    title: '是否有使用过抗抑鬱药物？',
  }],
  ['medication', {
    title: '是否有正在使用的药物？如果有请在"备注"写出药物名称',
  }],
  ['hospital', {
    title: '是否有住院的记录? 如果有请在"备注"解释原因',
  }],
  ['surgery', {
    title: '是否有做过手术? 如果有, 请在"备注"解释时间及原因',
  }],
  ['major_ill', {
    title: '是否有重大疾病? 如果有, 请在"备注"写出病名及时间',
  }],
  ['transfusion', {
    title: '是否有接受过输血?',
  }],
  ['transplant', {
    title: '是否有接受过器官移植？',
  }],
  ['medullary', {
    title: '是否有接受过骨髓移植？',
  }],
  ['psychiatric', {
    title: '是否有接受过精神治疗？',
  }],
  ['std', {
    title: '本人/性伴侣是否有过被确诊过性病？',
  }],
  ['pregnant', {
    title: '是否有怀孕过？ 如果有, 请在"备注"写出怀孕，流产，人工流产次数',
  }],
  ['see_doctor', {
    title: '一年内是否有看过医生？如果有, 请在"备注"写出科别\b, 时间, 原因, 检查结果',
  }],
  ['smoke', {
    title: '是否抽烟？',
  }],
  ['drink', {
    title: '是否喝酒？',
  }],
  ['period', {
    title: '月经是否正常？请在"备注"填写每次月经间隔天数及每次月经天数',
  }],
  ['adopted', {
    title: '是否被领养的？',
  }],
  ['family_disease', {
    title: '是否有任何家族遗传疾病？',
  }],
  ['twins', {
    title: '家族裡是否有双胞胎或是三胞胎的历史？',
  }],
  ['contraception', {
    title: '现在的避孕方式',
    options: ['女性避孕套', '避孕环', '避孕贴', '避孕药', '避孕针', '避孕套', '没有使用避孕措施 ', '其他'],
  }],
]);

class HealthHistoryForm extends BaseForm {
  constructor({ user = null, component = null, lang = LANG_EN }) {
    super({
      user, component, lang, ContentsEn, ContentsZh,
    });
    this.user = user;
    this.form_attributes = {
      double_eyelid: new Prop({
        index: 0,
        propName: 'double_eyelid',
        title: 'Do you have double eyelid?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      plastic_sur: new Prop({
        index: 1,
        propName: 'plastic_sur',
        title: 'Had plastic surgery before?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      drugs: new Prop({
        index: 2,
        propName: 'drugs',
        title: 'Had used any illegal drugs before?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      criminal: new Prop({
        index: 3,
        propName: 'criminal',
        title: 'Do you have any legal dispute or criminal record?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      arrested: new Prop({
        index: 4,
        propName: 'arrested',
        title: 'Have been arrested before?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      donor_before: new Prop({
        index: 5,
        propName: 'donor_before',
        title: 'Been an egg donor before? If yes, please tell us the location, date, and number of eggs that were retrieved.',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      anti_depression: new Prop({
        index: 6,
        propName: 'anti_depression',
        title: 'Have you ever used any anti-depression medication before?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      medication: new Prop({
        index: 7,
        propName: 'medication',
        title: 'Are you currently taking any medication? If yes, please list out all the medications.',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      hospital: new Prop({
        index: 8,
        propName: 'hospital',
        title: 'Have you ever been admitted to the hospital? If yes, please explain',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      surgery: new Prop({
        index: 9,
        propName: 'surgery',
        title: 'Had any surgery before? If yes, please explain',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      major_ill: new Prop({
        index: 10,
        propName: 'major_ill',
        title: 'Do you have any major illness? If yes, please explain',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      transfusion: new Prop({
        index: 11,
        propName: 'transfusion',
        title: 'Had blood transfusion before?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      transplant: new Prop({
        index: 12,
        propName: 'transplant',
        title: 'Had organ transplant before?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      medullary: new Prop({
        index: 13,
        propName: 'medullary',
        title: 'Had medullary transplant before?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      psychiatric: new Prop({
        index: 14,
        propName: 'psychiatric',
        title: 'Had psychiatric treatments before?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      std: new Prop({
        index: 15,
        propName: 'std',
        title: 'Are you or your sexual partners ever been diagnosed with any STDs before?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      pregnant: new Prop({
        index: 16,
        propName: 'pregnant',
        title: 'Have you been pregnant before? If yes, please tell us # of pregnancy, miscarriage, and abortions.',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      see_doctor: new Prop({
        index: 17,
        propName: 'see_doctor',
        title: 'Had you been to doctors within the past year? If yes, please tell us what specialist, date, reason, results.',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      smoke: new Prop({
        index: 18,
        propName: 'smoke',
        title: 'Do you smoke?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      drink: new Prop({
        index: 19,
        propName: 'drink',
        title: 'Do you drink alcohol?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      period: new Prop({
        index: 20,
        propName: 'period',
        title: 'Do you have normal menstrual period? How often is your menstrual period? (the 1st day of period till the day before next period)',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      adopted: new Prop({
        index: 20,
        propName: 'adopted',
        title: 'Are you adopted?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      family_disease: new Prop({
        index: 21,
        propName: 'family_disease',
        title: 'Do you have any family history of heritable diseases?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      twins: new Prop({
        index: 22,
        propName: 'twins',
        title: 'Do you have any family history of twins or triplets?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      contraception: new Prop({
        index: 23,
        propName: 'contraception',
        title: 'Current contraception method',
        type: FIELD_TYPE_MULTIPLE_CHOICE,
        options: ['Female condom', 'Intrauterine ring', 'Birth control pad', 'Birth control pill', 'Birth control injection', 'Condom', 'None', 'Others'],
      }),
      contraception_notes: new Prop({
        propName: 'contraception_notes',
        title: 'Notes',
        required: false,
      }),
    };
    this.generateNotesAttrs();
    this.syncData(user);
  }
}

export default HealthHistoryForm;
