export const styles = {
  container: {
    display: 'flex',
    WebkitFlexDirection: 'column',
    WebkitAlignItems: 'center',
  },
  title: {
    margin: '99px 0 101px 0',
    fontFamily: 'Avenir-Roman',
    fontSize: '48px',
    textAlign: 'center',
    color: '#000000',
  },
};
