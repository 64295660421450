export const styles = {
  container: {
    width: '1060px',
    borderRadius: '30px',
    marginTop: '80px',
  },
  gridContainer: {
    height: '336px',
    // gridTemplateColumns: 'auto auto auto auto',
    // gridColumnGap: '18px',
    // gridRowGap: '34px',
    img: {
      maxWidth: '100%',
      maxHeight: '100%',
      borderRadius: '6px',
      marginBottom: '34px',
    },
  },
};
