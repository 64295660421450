import PropTypes from 'prop-types';
import Radium from 'radium';
import React from 'react';
import Styles from './Button.module.scss';

function Button(props) {
  const {
    text, style = {}, onClick = null, disabled = false,
  } = props;
  if (disabled) {
    style.backgroundColor = 'grey';
  } else {
    style.backgroundColor = '#99d8f1';
  }
  if (disabled) {
    style[':hover'] = { cursor: 'pointer' };
  }
  return (
    <div
      className={Styles.button}
      style={[style]}
      onClick={() => {
        if (!disabled && onClick instanceof Function) {
          onClick();
        }
      }}
    >
      <p style={style.p ? style.p : {}}>{text}</p>
    </div>
  );
}

Button.defaultProps = {
  style: {},
  disabled: false,
  onClick: null,
  text: '',
};

Button.propTypes = {
  style: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default Radium(Button);
