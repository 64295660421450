import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { tify } from 'chinese-conv';
import { connect } from 'react-redux';
import RadioButton from '../../../../../components/RadioButton/RadioButton';
import { FIELD_TYPE_YES_OR_NO, LANG_EN, LANG_ZH_TRADITIONAL } from '../../../../../res/constants';
import Styles from './MedicalInfo.module.scss';
import InsuranceInfoForm from '../../../../SurrogateApplication/components/forms/InsuranceInfo/InsuranceInfoForm';

const mapStateToProps = state => ({
  langState: state.langState,
});

class MedicalInfo extends React.Component {
  constructor(props) {
    super(props);
    const { surrogateMother = null } = props;
    const { langState } = this.props;
    const { lang } = langState;
    this.state = {
      surrogateMother,
      form: new InsuranceInfoForm({ user: surrogateMother, component: this, lang }),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { surrogateMother = null } = nextProps;
      const { form } = this.state;
      form.syncData(surrogateMother, 'surrogate_app');
      this.setState({ surrogateMother, form }, () => {
        const { langState } = this.props;
        const { lang } = langState;
        form.setContents(lang);
      });
    }
  }

  renderRows = () => {
    const { form } = this.state;
    const attrs = Object.values(form.form_attributes)
      .filter(attr => attr.type === FIELD_TYPE_YES_OR_NO);
    return attrs.map((attr, index) => {
      let backgroundColor = '#fefafc';
      if (index % 2 === 0) {
        backgroundColor = '#ffffff';
      }
      return (
        <div key={attr.propName} className={Styles.row} style={[{ backgroundColor }]}>
          <div className={Styles.attrCell}>{`${attr.title}`}</div>
          <div className={Styles.valuesWrapper}>
            <div className={Styles.column}>
              <RadioButton selectable={false} selected={attr.value === 1} />
            </div>
            <div className={Styles.column}>
              <RadioButton selectable={false} selected={attr.value === 2} />
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    const rows = this.renderRows();
    const { langState } = this.props;
    const { lang } = langState;
    const title = lang === LANG_EN ? 'Medical Information' : '身体状况';
    const yesTxt = lang === LANG_EN ? 'Yes' : '是';
    const noTxt = lang === LANG_EN ? 'No' : '否';
    return (
      <div className={Styles.container}>
        <h1>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
        <div className={Styles.row}>
          <div className={Styles.attrCell} />
          <div className={Styles.valuesWrapper}>
            <div className={Styles.column}>{yesTxt}</div>
            <div className={Styles.column}>{noTxt}</div>
          </div>
        </div>
        {rows}
        <div style={{ height: '50px' }} />
      </div>
    );
  }
}

MedicalInfo.defaultProps = {
  surrogateMother: null,
};

MedicalInfo.propTypes = {
  surrogateMother: PropTypes.shape({
    authentication_token: PropTypes.string,
  }),
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(MedicalInfo));
