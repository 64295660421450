import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { tify } from 'chinese-conv';
import HealthHistoryForm from '../../../../../DonorApplication/components/forms/HealthHistory/HealthHistoryForm';
import { FIELD_TYPE_YES_OR_NO, LANG_EN, LANG_ZH_TRADITIONAL } from '../../../../../../res/constants';
import { styles } from './MedicalInfo.css';

class MedicalInfo extends React.Component {
  constructor(props) {
    super(props);
    const { donor = null } = props;
    const { lang } = this.props;
    this.state = {
      donor,
      form: new HealthHistoryForm({ user: donor, component: this, lang }),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { donor = null } = nextProps;
      const { form } = this.state;
      form.syncData(donor);
      this.setState({ donor, form });
    }
  }

  renderRows = () => {
    const { form } = this.state;
    const { lang } = this.props;
    const yesTxt = lang === LANG_EN ? 'Yes' : '是';
    const noTxt = lang === LANG_EN ? 'No' : '否';
    const attrs = Object.values(form.form_attributes)
      .filter(attr => attr.type === FIELD_TYPE_YES_OR_NO);
    return attrs.map((attr, index) => {
      let backgroundColor = '#fefafc';
      let marginTop = 0;
      if (index % 2 === 0) {
        backgroundColor = '#ffffff';
      }
      if (lang === LANG_EN && index === 19) {
        marginTop = 4
      }
      return (
        <div key={attr.propName} style={[styles.row, { backgroundColor, marginTop }]}>
          <div style={styles.attrCell}>{`${attr.title}`}</div>
          <div style={{ ...styles.column, marginLeft: '135px' }}>
            {attr.value === 1 ? yesTxt : noTxt}
          </div>
          <div style={{ ...styles.column, maxWidth: '700px' }}>
            <span style={{whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>{form.form_attributes[`${attr.propName}_notes`].value}</span>
          </div>
        </div>
      );
    });
  };

  render() {
    const rows = this.renderRows();
    const { lang } = this.props;
    const title = lang === LANG_EN ? 'Medical Information' : '身体状况';
    return (
      <div style={styles.container}>
        <h1 style={styles.container.title}>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
        <div style={styles.row}>
          <div style={styles.attrCell} />
          <div style={styles.column} />
          <div style={styles.column} />
          <div style={styles.column} />
        </div>
        {rows}
        <div style={{ height: '50px' }} />
      </div>
    );
  }
}

MedicalInfo.defaultProps = {
  donor: null,
};

MedicalInfo.propTypes = {
  donor: PropTypes.shape({
    authentication_token: PropTypes.string,
  }),
  lang: PropTypes.string.isRequired,
};

export default Radium(MedicalInfo);
