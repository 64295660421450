import DataManager from './DataManager';
import EventsManager from './EventsManager';

export default class Managers {
  constructor(component) {
    this.component = component;
    this.dataManager = new DataManager(component);
    this.eventsManger = new EventsManager(component);
  }
}
