import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Styles from './ProgressBar.module.scss';
import styles from './ProgressBar.css';
import { LANG_EN } from '../../../../res/constants';

const mapStateToProps = state => ({
  langState: state.langState,
});

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions, false);
  }

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  render() {
    const { windowWidth } = this.state;
    const progressBarStyle = [styles.progressBar];
    const { progress = 0 } = this.props;
    const minWidthPercentage = windowWidth <= 768 ? 25 : 13.5;
    const progressBarWidthPercentage = progress < 100 ? minWidthPercentage + progress : 100;
    progressBarStyle.push({ width: `${progressBarWidthPercentage}%` });
    const { langState } = this.props;
    const { lang } = langState;
    const completedTxt = lang === LANG_EN ? 'Completed' : '已完成';
    const progressTxt = `${Math.ceil(progress)}% ${completedTxt}`;
    return (
      <div className={Styles.container}>
        <div style={progressBarStyle}>
          <p className={Styles.progressTxt}>{progressTxt}</p>
        </div>
      </div>
    );
  }
}

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(ProgressBar));
