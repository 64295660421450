
export const EnKeyMaps = new Map([
  ['future_parents', 'Future Parents'],
  ['find_egg_donor', 'Find an Egg Donor'],
  ['egg_donors', 'Egg Donors'],
  ['be_egg_donor', 'How to become an Egg Donor'],
  ['surrogate_moms', 'Surrogate Mothers'],
  ['be_surrogate_mom', 'How to become a Surrogate Mother'],
]);

export const ZhKeyMaps = new Map([
  ['future_parents', '准父母'],
  ['find_egg_donor', '捐卵天使档案库'],
  ['egg_donors', '捐卵天使'],
  ['be_egg_donor', '如何成为捐卵天使'],
  ['surrogate_moms', '代孕天使'],
  ['be_surrogate_mom', '如何成为代孕天使'],
]);
