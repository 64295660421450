import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { tify } from 'chinese-conv';
import Styles from './Success.module.scss';
import { LANG_EN, LANG_ZH_TRADITIONAL } from '../../../../../res/constants';

const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});

class Success extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageEn: 'Your form has been successfully submitted. Thank you for your time. Our Egg Donor Coordination Staff will contact you within 48 hrs.',
      messageZh: '感谢您的时间，您的申请已经提交。我们的工作人员将会在48小时之内联系您。',
    };
  }

  render() {
    const { messageEn, messageZh } = this.state;
    const { langState } = this.props;
    const { lang } = langState;
    const title = lang === LANG_EN ? 'Success' : '申请成功';
    const message = lang === LANG_EN ? messageEn : messageZh;
    return (
      <div className={Styles.container}>
        <h1>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
        <p>{lang === LANG_ZH_TRADITIONAL ? tify(message) : message}</p>
      </div>
    );
  }
}

Success.propTypes = {
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(Success));
