import React, { Fragment } from 'react';
import './HeaderForPhone.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  LANG_EN,
  PATH_CLINICS, PATH_EGG_DONATION,
  PATH_EGG_FREEZING,
  PATH_FOR_EGG_DONOR,
  PATH_FOR_SURROGATE,
  PATH_HOME_PAGE,
  PATH_IVF,
  PATH_SUCCESS_STORIES, PATH_SURROGACY,
} from '../../../../res/constants';
import logo from '../../../../assets/logo.png';
import ModalTypes from '../../../Modals/ModalTypes';
import { showModal } from '../../../../redux/Modal/Modalactions';
import { deleteUser } from '../../../../redux/User/UserActions';
import { getAxiosInstance } from '../../../../Utils/WebRequest';
import { setLang } from '../../../../redux/Lang/LangActions';
import { EnKeyMaps, ZhKeyMaps } from '../HeaderMenu/res/constants';
import { translate } from '../../../../Utils/Util';

const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});

class HeaderForPhone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      whiteBack: false,
      menuToggled: false,
      menu1: {
        ivf: 'IVF',
        eggFreezing: 'Egg Freezing',
        eggDonation: 'Egg Donation',
        surrogacy: 'Surrogacy',
      },
      menu: {
      	title: 'Menu',
        lang: 'Languages/语言',
        futureParents: 'Future Parents',
        forEggDonor: 'For Egg Donor',
        forSurrogate: 'For Surrogate',
        clinics: 'Clinics',
        stories: 'Success Stories',
        signUp: 'Sign Up',
        signIn: 'Sign In',
        logOut: 'Log Out',
      },
      langMenu: {
      	en: 'English',
        zh_simplified: '简体中文',
        zh_traditional: '繁體中文',
      },
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scroll);
    const { langState } = this.props;
    const { lang } = langState;
    this.setContents(lang);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scroll);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setContents(langState.lang);
    }
  }

	setContents = (lang = LANG_EN) => {
	  this.setState({
	    menu1: {
	      ivf: translate({ lang, value: lang === LANG_EN ? EnKeyMaps.get('IVF') : ZhKeyMaps.get('IVF') }),
	      eggFreezing: translate({ lang, value: lang === LANG_EN ? EnKeyMaps.get('EggFreezing') : ZhKeyMaps.get('EggFreezing') }),
	      eggDonation: translate({ lang, value: lang === LANG_EN ? EnKeyMaps.get('EggDonation') : ZhKeyMaps.get('EggDonation') }),
	      surrogacy: translate({ lang, value: lang === LANG_EN ? EnKeyMaps.get('Surrogacy') : ZhKeyMaps.get('Surrogacy') }),
	    },
	    menu: {
	    	title: translate({ lang, value: lang === LANG_EN ? EnKeyMaps.get('menu') : ZhKeyMaps.get('menu') }),
	    	lang: translate({ lang, value: 'Languages/语言' }),
	      futureParents: translate({ lang, value: lang === LANG_EN ? EnKeyMaps.get('FutureParents') : ZhKeyMaps.get('FutureParents') }),
	      forEggDonor: translate({ lang, value: lang === LANG_EN ? EnKeyMaps.get('EggDonor') : ZhKeyMaps.get('EggDonor') }),
	      forSurrogate: translate({ lang, value: lang === LANG_EN ? EnKeyMaps.get('Surrogate') : ZhKeyMaps.get('Surrogate') }),
	      clinics: translate({ lang, value: lang === LANG_EN ? EnKeyMaps.get('Clinics') : ZhKeyMaps.get('Clinics') }),
	      stories: translate({ lang, value: lang === LANG_EN ? EnKeyMaps.get('SuccessStories') : ZhKeyMaps.get('SuccessStories') }),
	      signUp: translate({ lang, value: lang === LANG_EN ? EnKeyMaps.get('SignUp') : ZhKeyMaps.get('SignUp') }),
	      logIn: translate({ lang, value: lang === LANG_EN ? EnKeyMaps.get('Login') : ZhKeyMaps.get('Login') }),
	      logOut: translate({ lang, value: lang === LANG_EN ? EnKeyMaps.get('Logout') : ZhKeyMaps.get('Logout') }),
	    },
	  });
	};

  scroll = () => {
    const { pageYOffset } = window;
    if (pageYOffset >= 80) {
      this.setState({ whiteBack: true });
    } else {
      this.setState({ whiteBack: false });
    }
  };

	menuMove = (moveItem) => {
	  this.setState({ moveItem });
	};

	menuOut = () => {
	  this.setState({ moveItem: '' });
	};

	onMenuBtnClicked = () => {
	  const { menuToggled } = this.state;
	  this.setState({ menuToggled: !menuToggled });
	};

	onLangMenuItemPicked = (selectedLanguage = LANG_EN) => {
	  const { dispatch, langState } = this.props;
	  const { lang: currentLang } = langState;
	  if (selectedLanguage !== currentLang) {
	    dispatch(setLang(selectedLanguage));
	  }
	};

	onFutureParentMenuItemPicked = (name) => {
	  const { history } = this.props;
	  switch (name) {
	    case 'ivf':
	      history.push(PATH_IVF);
	      break;
	    case 'eggFreezing':
	      history.push(PATH_EGG_FREEZING);
	      break;
	    case 'eggDonation':
	      history.push(PATH_EGG_DONATION);
	      break;
	    case 'surrogacy':
	      history.push(PATH_SURROGACY);
	      break;
	    default:
	  }
	};

	linkTo = (path) => {
	  const { dispatch, redirectPathAfterLogout = null, history } = this.props;
	  let data = {};
	  switch (path) {
	    case 'sign up':
	      data = { modalProps: { isOpen: true }, modalType: ModalTypes.SIGN_UP };
	      dispatch(showModal(data));
	      break;
	    case 'log in':
	      data = { modalProps: { isOpen: true }, modalType: ModalTypes.LOG_IN };
	      dispatch(showModal(data));
	      break;
	    case 'log out':
	      dispatch(deleteUser());
	      getAxiosInstance().defaults.headers.common.Authorization = null;
	      if (redirectPathAfterLogout != null) {
	        history.push(redirectPathAfterLogout);
	      }
	      break;
	    default:
	      history.push(path);
	  }
	};

	render() {
	  const {
	    whiteBack, menu1, langMenu, menu, menuToggled
	  } = this.state;
	  const { allowTransparent, user } = this.props;
	  return (
  <nav className={`navbar navbar-expand-md fixed-top nav-container ${!allowTransparent || whiteBack || menuToggled ? 'white-back' : ''}`} id="header">
    <div>
      <a
        href={PATH_HOME_PAGE}
        itemProp="url"
      >
        <img
          src={logo}
          className="logo"
          alt="new-care"
          itemProp="logo"
        />
      </a>
    </div>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={this.onMenuBtnClicked}>
      {/* <span className="navbar-toggler-icon"></span> */}
      <span className="">{menu.title}</span>
    </button>

    <div className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
      <ul className="navbar-nav mr-auto">
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {menu.lang}
          </a>
          <div
            className="dropdown-menu h-auto"
            aria-labelledby="navbarDropdown"
          >
            {
            	Object.keys(langMenu).map(lang => (
              <span
                className="dropdown-item"
                key={lang}
                onMouseMove={this.menuMove.bind(this, lang)}
                onClick={() => this.onLangMenuItemPicked(lang)}
              >
                {langMenu[lang]}
              </span>
            	))
						}
          </div>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {menu.futureParents}
          </a>
          <div
            className="dropdown-menu h-auto"
            aria-labelledby="navbarDropdown"
          >
            {
            	Object.keys(menu1).map(item => (
              <span
                className="dropdown-item"
                key={item}
                onMouseMove={this.menuMove.bind(this, item)}
                onClick={this.onFutureParentMenuItemPicked.bind(this, item)}
              >
                {menu1[item]}
              </span>
            	))
									}
          </div>
        </li>
        <li className="nav-item active">
          <a className="nav-link" href="javascript:void(0);" onClick={this.linkTo.bind(this, PATH_FOR_EGG_DONOR)}>{menu.forEggDonor}</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="javascript:void(0);" onClick={this.linkTo.bind(this, PATH_FOR_SURROGATE)}>{menu.forSurrogate}</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="javascript:void(0);" onClick={this.linkTo.bind(this, PATH_CLINICS)}>{menu.clinics}</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="javascript:void(0);" onClick={this.linkTo.bind(this, PATH_SUCCESS_STORIES)}>{menu.stories}</a>
        </li>
        {user != null ? (
          <li className="nav-item">
            <a className="nav-link" href="javascript:void(0);" onClick={this.linkTo.bind(this, 'log out')}>{menu.logOut}</a>
          </li>
        ) : (
          <Fragment>
            <li className="nav-item">
              <a className="nav-link" href="javascript:void(0);" onClick={this.linkTo.bind(this, 'sign up')}>{menu.signUp}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="javascript:void(0);" onClick={this.linkTo.bind(this, 'log in')}>{menu.logIn}</a>
            </li>
          </Fragment>
        )}
      </ul>
    </div>
  </nav>
	  );
	}
}

export default withRouter(connect(mapStateToProps)(HeaderForPhone));
