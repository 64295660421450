const styles = {
  container: {
    width: '100%',
    height: '80px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: '100',
    top: 0,
    left: 0,
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    width: '164px',
    margin: '0 30px 0 30px',
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
  },
};

export default styles;
