
export const EnKeyMaps = new Map([
  ['title', 'Become an Egg Donor'],
  ['intro', 'Egg Donors\' Requirements\n'
  + '1. Females between the ages of 21-29\n'
  + '2. With regular menstrual cycles\n'
  + '3. Non-smokers, Non-drug and alcohol users\n'
  + '4. BMI below 29\n'
  + '5. No criminal records\n'
  + '6. Physically and psychologically healthy\n'
  + '7. Not on IUD (copper IUD is okay but needs to be removed)\n'
  + ' or be using birth control shots\n'
  + '8. College is beneficial, but not necessary\n'
  + '9. Must be willing to complete medical & psychological testing\n'
  + '10. Must be willing to take a series of injectable medications\n'
  + '11. Must be willing to undergo outpatient surgical procedure\n'
  + 'for actual egg retrieval\n'
  + 'The entire process from time of selection by future parent to\n'
  + 'egg donation is typically 1-6 months'],
  ['btn_txt', 'Become an Egg Donor'],
]);

export const ZhKeyMaps = new Map([
  ['title', '成为捐卵天使'],
  ['intro', '• 年龄在21-29岁之间\n'
  + '• 有规律的月经，没有生殖问题或异常\n'
  + '• 身心健康, BMI在28或以下\n'
  + '• 不使用尼古丁，不吸烟，不吸毒\n'
  + '• 目前没有使用Depo-Provera注射\n'
  + '• 没有使用Implanon/Nexpon手臂植入物\n'
  + '• 愿意接受医学和心理评估\n'
  + '• 愿意接受注射药物治疗\n'
  + '• 愿意致力于这个过程\n',
  ],
  ['btn_txt', '成为捐卵天使'],
]);
