
export const EnKeyMaps = new Map([
  ['title', 'Become a Surrogate Mother'],
  ['intro', 'Surrogate Mothers\' Requirements\n'
  + '1. Females between the ages of 21-37\n'
  + '2. With regular menstrual cycles\n'
  + '3. Has had a least 1 successful, live birth.\n'
  + '4. No prior pregnancy complication.\n'
  + '5. No more than 2 C-sections.\n'
  + '6. Non-smokers, Non-drug and alcohol users.\n'
  + '7. BMI below 30. (Up to 32 may be considered.)\n'
  + '8. Financially stable\n'
  + '9. U.S. citizens or Permanent Residency status.\n'
  + '10. Must clear background checks and extensive testing\n'
  + '11. Physically and psychologically healthy\n'
  + '12. Not on IUD (copper IUD is okay but needs to be removed)\n'
  + 'or be using birth control shots.\n'
  + '13. Willing to take injectable medication\n'
  + '14. Willing to commit to the process\n'
  ],
  ['btn_txt', 'Become a Surrogate Mother'],
]);

export const ZhKeyMaps = new Map([
  ['title', '成为代孕天使'],
  ['intro', '• 21-37岁之间\n'
  + '• 至少有一次健康的怀孕，加不加上顺产？\n'
  + '• 既往无妊娠并发症\n'
  + '• 身心健康\n'
  + '• 不使用尼古丁，不吸烟，不吸毒\n'
  + '• BMI在33或以下\n'
  + '• 剖腹产不超过2次\n'
  + '• 无犯罪历史\n'
  + '• 经济条件稳定\n',
  +'• 必须通过背景调查和广泛的测试\n',
  +'• 愿意接受注射药物\n',
  ],
  ['btn_txt', '代孕天使申请表'],
]);
