import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { tify } from 'chinese-conv';
import MaritalStatusChildForm
  from '../../../../../SurrogateApplication/components/forms/MaritalChildStatus/MaritalStatusChildForm';
import {
  ChildrenRows, ChildrenInfoCols, ChildrenRowsZh, ChildrenInfoColsZh,
} from '../../BirthRecords/constants';
import { LANG_EN, LANG_ZH_SIMPLIFIED, LANG_ZH_TRADITIONAL } from '../../../../../../res/constants';
import { Styles } from './BirthRecords.css';

class BirthRecords extends React.Component {
  constructor(props) {
    super(props);
    const { surrogateMother = null } = props;
    const { lang } = props;
    this.state = {
      surrogateMother,
      form: new MaritalStatusChildForm({
        user: surrogateMother, component: this, lang, appType: 'surrogate_app',
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { surrogateMother = null } = nextProps;
      const { form } = this.state;
      form.syncData(surrogateMother, 'surrogate_app');
      this.setState({ surrogateMother, form }, () => {
        const { lang } = this.props;
        form.setContents(lang);
      });
    }
  }

  renderFormTable = ({
    RowsAttrs, ColsAttrs, formAttrs, inputHandler, lang = LANG_EN, readOnly = false,
  }) => {
    const rows = RowsAttrs.map((row, index) => {
      let backgroundColor = '#fefafc';
      if (index % 2 === 0) {
        backgroundColor = '#ffffff';
      }
      const cols = ColsAttrs.map(col => (
        <div key={col.key} style={{ ...Styles.column, height: '40px' }}>
          <p style={{ visibility: 'hidden', margin: 0 }}>
            {lang === LANG_ZH_SIMPLIFIED ? col.name : tify(col.name)}
          </p>
          <div style={{
            position: 'absolute', width: '100%', height: '100%', top: 0,
          }}
          >
            <p
              style={Styles.input}
            >
              {formAttrs[row.key][col.key] ? formAttrs[row.key][col.key].value : ''}
            </p>
          </div>
        </div>
      ));
      return (
        <div key={row.key} style={{ ...Styles.row, ...{ backgroundColor } }}>
          <div style={Styles.attrCell}>
            {lang === LANG_ZH_SIMPLIFIED ? row.name : tify(row.name)}
          </div>
          {cols}
        </div>
      );
    });
    const cols = ColsAttrs.map(col => (
      <div
        key={col.key}
        style={Styles.column}
      >
        {lang === LANG_ZH_SIMPLIFIED ? col.name : tify(col.name)}
      </div>
    ));
    const firstRow = (
      <div style={Styles.row}>
        <div style={Styles.attrCell} />
        {cols}
      </div>
    );
    return (
      <Fragment>
        {firstRow}
        {rows}
      </Fragment>
    );
  };

  render() {
    const { form } = this.state;
    const { lang } = this.props;
    const title = lang === LANG_EN ? 'Birth Records' : '生产记录';
    return (
      <div style={Styles.container}>
        <h1 style={Styles.title}>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
        {this.renderFormTable({
          RowsAttrs: lang === LANG_EN ? ChildrenRows : ChildrenRowsZh,
          ColsAttrs: lang === LANG_EN ? ChildrenInfoCols : ChildrenInfoColsZh,
          formAttrs: form.child_info_attributes,
          readOnly: true,
        })}
        <div style={{ height: '50px' }} />
      </div>
    );
  }
}

BirthRecords.defaultProps = {
  surrogateMother: null,
};

BirthRecords.propTypes = {
  surrogateMother: PropTypes.shape({
    authentication_token: PropTypes.string,
  }),
  lang: PropTypes.string.isRequired,
};

export default Radium(BirthRecords);
