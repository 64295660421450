import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Styles from './SurrogacyProcess.module.scss';
import { EnKeyMaps, ZhKeyMaps } from './res/constants';
import { getValueWith } from '../../../../Utils/Util';
import Process from '../../../../components/Process/Process';
import { LANG_EN } from '../../../../res/constants';

const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});
const LangPacks = { EnKeyMaps, ZhKeyMaps };

class SurrogacyProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intro: '',
    };
  }

  componentDidMount() {
    const { langState } = this.props;
    this.setContents(langState.lang);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setContents(langState.lang);
    }
  }

  setContents = (lang) => {
    this.setState({
      title: getValueWith({ key: 'title', lang, ...LangPacks }),
      process: lang === LANG_EN ? EnKeyMaps.get('process') : ZhKeyMaps.get('process'),
    });
  };

  render() {
    const { title = '', process = [] } = this.state;
    return (
      <div className={Styles.container}>
        <Process title={title} process={process} />
        <div className={Styles.images}>
          <img className={Styles.image} alt="" src="https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,e_shadow:40,q_80,w_800/v1559753507/NewCare/Assets/IP-Surrogacy/shutterstock_1054123112.jpg" />
          <img className={Styles.image} alt="" src="https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,e_shadow:40,q_80,w_800/v1559753502/NewCare/Assets/IP-Surrogacy/shutterstock_629076428.jpg" />
          <img className={Styles.image} alt="" src="https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,e_shadow:40,q_80,w_800/v1559753494/NewCare/Assets/IP-Surrogacy/shutterstock_1056424793.jpg" />
        </div>
      </div>
    );
  }
}

SurrogacyProcess.propTypes = {
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(SurrogacyProcess));
