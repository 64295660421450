import {
  FIELD_TYPE_PICKER,
  FIELD_TYPE_TEXTAREA,
  FIELD_TYPE_YES_OR_NO, LANG_EN,
} from '../../../../../res/constants';
import {
  Prop,
  retrieveKeyValuesFrom,
} from '../../../../DonorApplication/components/forms/BasicInformation/forms/Utils';
import BaseForm from '../../../../DonorApplication/components/forms/BasicInformation/forms/BaseForm';
import { ChildrenInfoCols, ChildrenRows } from './constants';

class MaritalStatusChildForm extends BaseForm {
  constructor({
    user = null, component = null, lang = LANG_EN, appType = 'surrogate_app',
  }) {
    super({
      user, component, lang, appType,
    });
    this.user = user;
    this.user_attributes = {
      marriage: new Prop({
        propName: 'marriage',
        title: 'Marital Status',
        type: FIELD_TYPE_PICKER,
        options: ['Single', 'Married', 'Divorced', 'Has Stable Boyfriend', 'Widowed'],
      }),
    };
    this.form_attributes = {
      marital_problems: new Prop({
        propName: 'marital_problems',
        title: 'Have you ever experienced any marital problems?',
        type: [FIELD_TYPE_PICKER, FIELD_TYPE_YES_OR_NO],
        options: ['Yes', 'No'],
        required: false,
      }),
      marital_problems_notes: new Prop({
        propName: 'marital_problems_notes', title: 'If yes, please explain', type: FIELD_TYPE_TEXTAREA, required: false,
      }),
      partner_name: new Prop({
        propName: 'partner_name', title: 'Partner / Spouse name', placeholder: 'Last, First Middle', required: false,
      }),
      partner_birth_date: new Prop({
        propName: 'partner_birth_date',
        title: 'Partner\'s Date of birth',
        inputType: 'date',
        required: false,
      }),
      live_together: new Prop({
        propName: 'live_together',
        title: 'Do you live together?',
        type: [FIELD_TYPE_PICKER, FIELD_TYPE_YES_OR_NO],
        options: ['Yes', 'No'],
        required: false,
      }),
      live_together_how_long: new Prop({
        propName: 'live_together_how_long', title: 'If yes, how long?', placeholder: 'Year', inputType: 'number', required: false,
      }),
      divorce_date: new Prop({
        propName: 'divorce_date',
        title: 'If divorced, when did it occur?',
        inputType: 'date',
        required: false,
      }),
      break_up_explain: new Prop({
        propName: 'break_up_explain', title: 'What was the cause of the breakup?', type: FIELD_TYPE_TEXTAREA, required: false,
      }),
      any_child: new Prop({
        propName: 'any_child',
        title: 'Do you have any children?',
        type: [FIELD_TYPE_PICKER, FIELD_TYPE_YES_OR_NO],
        options: ['Yes', 'No'],
      }),
      num_of_pregnancies: new Prop({
        propName: 'num_of_pregnancies', title: 'Number of pregnancies', inputType: 'number',
      }),
      num_of_miscarriages: new Prop({
        propName: 'num_of_miscarriages', title: 'Number of miscarriages', inputType: 'number',
      }),
      miscarriages_dates: new Prop({
        propName: 'miscarriages_dates', title: 'Dates of each miscarriages ', type: FIELD_TYPE_TEXTAREA,
      }),
      num_of_abortions: new Prop({
        propName: 'num_of_abortions', title: 'Number of abortions', inputType: 'number',
      }),
      abortions_dates: new Prop({
        propName: 'abortions_dates', title: 'Dates of each abortions ', type: FIELD_TYPE_TEXTAREA,
      }),
      num_of_stillbirths: new Prop({
        propName: 'num_of_stillbirths', title: 'Number of stillbirths', inputType: 'number',
      }),
      stillbirths_dates: new Prop({
        propName: 'stillbirths_dates', title: 'Dates of each stillbirths ', type: FIELD_TYPE_TEXTAREA,
      }),
      pregnancies_history: new Prop({
        propName: 'pregnancies_history', title: 'Give a history of all previous pregnancies, including physical and emotional problems during and after each pregnancy (give delivery date, sex and weight of baby and list any complications).', type: FIELD_TYPE_TEXTAREA,
      }),
      children_related_partner: new Prop({
        index: 0,
        propName: 'children_related_partner',
        title: 'Are your children biologically related to your husband/partner?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      have_more_children: new Prop({
        index: 1,
        propName: 'have_more_children',
        title: 'Would you like to have any more children of your own in the future?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
    };
    this.generateChildrenInfoFormAttrs();
    this.generateNotesAttrs();
    this.syncData(user, appType);
  }

  getFormAttrs = () => ({
    ...retrieveKeyValuesFrom(this.form_attributes),
    ...this.getChildInfoFormAttrs(),
  });

  getChildInfoFormAttrs = () => {
    const result = {};
    const props = Object.keys(this.child_info_attributes);
    props.forEach((prop) => {
      result[prop] = retrieveKeyValuesFrom(this.child_info_attributes[prop]);
    });
    return result;
  };

  generateChildrenInfoFormAttrs = () => {
    this.child_info_attributes = {};
    ChildrenRows.forEach((row) => {
      const propName = row.key;
      this.child_info_attributes[propName] = {};
      ChildrenInfoCols.forEach((col) => {
        const subPropName = col.key;
        this.child_info_attributes[propName][subPropName] = new Prop({
          propName: subPropName,
          required: false,
        });
      });
    });
  };

  syncData = (user, app = 'donor_app') => {
    if (user == null) { return; }
    this.user = user;
    Object.keys(user).forEach((attr) => {
      if (Object.hasOwnProperty.call(this.user_attributes, attr)) {
        const userAttr = this.user_attributes[attr];
        userAttr.value = user[attr] != null ? user[attr] : '';
        if (userAttr.type instanceof Array && userAttr.type.includes(FIELD_TYPE_PICKER)
            && userAttr.type.includes(FIELD_TYPE_YES_OR_NO)) {
          if (typeof userAttr.value === 'number') {
            userAttr.value = userAttr.value === 1 ? this.yesTxt : this.noTxt;
          } else if (typeof userAttr.value === 'boolean') {
            userAttr.value = userAttr.value ? this.yesTxt : this.noTxt;
          }
        }
      }
    });
    Object.keys(user).forEach((attr) => {
      if (Object.hasOwnProperty.call(this.user_attributes, attr)) {
        const userAttr = this.user_attributes[attr];
        userAttr.value = user[attr] != null ? user[attr] : '';
        if (userAttr.type instanceof Array && userAttr.type.includes(FIELD_TYPE_PICKER)
            && userAttr.type.includes(FIELD_TYPE_YES_OR_NO)) {
          if (typeof userAttr.value === 'number') {
            userAttr.value = userAttr.value === 1 ? this.yesTxt : this.noTxt;
          } else if (typeof userAttr.value === 'boolean') {
            userAttr.value = userAttr.value ? this.yesTxt : this.noTxt;
          }
        }
      }
    });
    if (user[app] == null) { return; }
    const { form_attributes: formAttrs } = user[app].data;
    if (formAttrs != null) {
      Object.keys(formAttrs).forEach((attr) => {
        if (Object.hasOwnProperty.call(this.form_attributes, attr)) {
          const formAttr = this.form_attributes[attr];
          formAttr.value = formAttrs[attr] != null ? formAttrs[attr] : '';
          if (formAttr.type instanceof Array && formAttr.type.includes(FIELD_TYPE_PICKER)
              && formAttr.type.includes(FIELD_TYPE_YES_OR_NO)) {
            if (typeof formAttr.value === 'number') {
              formAttr.value = formAttr.value === 1 ? this.yesTxt : this.noTxt;
            } else if (typeof formAttr.value === 'boolean') {
              formAttr.value = formAttr.value ? this.yesTxt : this.noTxt;
            }
          }
        }
      });
      Object.keys(this.child_info_attributes).forEach((attr) => {
        if (Object.hasOwnProperty.call(formAttrs, attr)) {
          Object.keys(formAttrs[attr]).forEach((subAttr) => {
            if (Object.hasOwnProperty.call(this.child_info_attributes[attr], subAttr)) {
              this.child_info_attributes[attr][subAttr].value = formAttrs[attr][subAttr] != null ? formAttrs[attr][subAttr] : '';
            }
          });
        }
      });
    }
    this.setContents(this.lang);
  };
}

export default MaritalStatusChildForm;
