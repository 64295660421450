export const SurrogacyFAQs = [
  {
    Q: 'What is surrogacy?',
    A: 'Surrogacy is the legal and social arrangement between intended parents and a surrogate. This surrogate becomes pregnant through an embryo transfer and carries the pregnancy for the intended parents. The intended parents are the legal parents of any child born during the surrogacy agreement and the surrogate is compensated for the service of carrying the child(ren).',
  },
  {
    Q: 'Who works with a surrogate?',
    A: 'Surrogacy has become a very common choice for parents to start or even add to their families. The following people may consider using a surrogate:\n'
        + '• People who have struggled with infertility\n'
        + '• Same-sex couples\n'
        + '• Prospective single parents\n'
        + '• Anyone who is unable to carry a pregnancy safely to term\n',
  },
  {
    Q: 'What are the chances of a successful pregnancy with surrogacy?',
    A: 'Surrogacy success rates usually are provided by the IVF clinic you work with. There are many factors that can be taken into account such as the quality of the embryo and the fertility history of the surrogate. Typically, there is a thorough screening process to make sure that the surrogate is in outstanding health, both mentally and physically.',
  },
  {
    Q: 'Who are surrogates and how are they screened?',
    A: 'Surrogates are women of all different backgrounds that want to help other prospective families add to their own. All surrogates must meet minimum requirements and be fully screened before proceeding. Some of the common requirements for surrogates are:\n'
        + '• Between a specific age range\n'
        + '• Have a healthy BMI\n'
        + '• No smoking or drug use\n'
        + '• Have at least one child with no major pregnancy complications\n'
        + '• Be financially stable\n'
        + '• No felony convictions\n'
        + '• Submit medical and drug history information\n'
        + '• Complete background checks\n'
        + '• Complete a psychological evaluation and medical workup\n',
  },
  {
    Q: 'How long does the surrogacy process take?',
    A: 'The length of the surrogacy process is based on many different factors:\n'
        + '• How long it takes to make embryos\n'
        + '• Finding a surrogate and having her fully screened\n'
        + '• Your personal timeline goals\n'
        + '• The number of cycles and embryo transfers that are required to achieve a successful pregnancy\n'
        + '• The legal services required\n'
        + '• Typically, you should plan 1-2 years from the time you sign up for surrogacy until you have a child\n',
  },
  {
    Q: 'How do surrogacy agencies match intended parents with surrogates?',
    A: 'Generally, the agency will make the IPs a profile and get to know them better. During this process, the agency finds out what you are looking for when it comes to a surrogate. This process will help you match better with a surrogate that has the same values and goals as you. Once the agency has a prospective surrogate for you, they will present you the profile(s). If you choose you want to proceed, the surrogate’s medical records are then sent to your IVF clinic. This clinic is where your embryos are stored and where the embryo transfer will take place. Once her medical records are approved, the agency will then schedule a video call with you and the prospective surrogate to find out if you are comfortable working with each other. If everything goes well, this is what we call a match!',
  },
  {
    Q: 'What happens after being matched with a surrogate?',
    A: 'Your surrogate will then go through the medical and psychological evaluations. Once these are completed, it will be time to start on the surrogacy contract. Once the contracts are finalized, the embryo transfer can be scheduled.',
  },
  {
    Q: 'How much contact will we have with our surrogate?',
    A: 'This is completely between you and your surrogate. It is always good to be up front with the kind of relationship you want to have during the matching process so you can match with a surrogate that shares the same interests in communication. ',
  },
  {
    Q: 'Whose name goes on the birth certificate?',
    A: 'The names that are entered on the birth certificate will generally depend on the state laws of surrogacy. The “surrogate-friendly” states allow intended parents to file a pre-birth order. This instructs the hospital to enter the intended parents name on the birth certificate. In other cases, depending on the state, the birth certificate may need to be re-issued with the intended parents name following the additional legal paperwork after the baby is born.',
  },
  {
    Q: 'What if the surrogate wants to keep the baby?',
    A: 'The surrogate has no biological relation to your child. She will not have any legal standing to keep any child born through the surrogacy agreement. The chance of this happening is very low as the surrogate undergoes psychological screening to make sure she is mentally prepared for the process.',
  },
];

export const SurrogacyFAQsZh = [
  {
    Q: '在美国生的宝宝就一定能成为美国公民吗？',
    A: '是的，根据美国1868年宪法第14条修正案所述：所有在美国本土出生的新生儿即可自动获得美国国籍，从而享有相应的福利，不管父母有没有美国国籍。',
  },
  {
    Q: '美国宝宝在中国/台湾待多久必须回美国？',
    A: '没有规定，待多久都可以。孩子也可以在任何学年都可以回到美国继续接受教育，父母也可以赴美陪读。',
  },
  {
    Q: '美国宝宝是否在中国/台湾久了不回去，就会自动失去美国国籍？',
    A: '没有这个法律规定，绝对不会。',
  },
  {
    Q: '美国宝宝在美国读书真的不花钱吗？',
    A: '是的，从小学的学前班开始，一直到高中都是免费的。经济困难的，美国政府还给予补贴。大学期间，可以申请无息贷款，毕业后再慢慢偿还。',
  },
  {
    Q: '美国代孕合法吗？',
    A: '合法的，您将会受到加州的法律保护。加州有二十多年的助孕案立法历史，被人们称之为“代孕天堂”。我们中心拥有经验丰富的律师团队。整个代孕过程中，律师将是您的合法代表人，协助您完成与代孕妈妈之间签署合的起草与定稿，以及办理出生证等证件，保障您是孩子合法监护人的权益。',
  },
  {
    Q: '不会说英文怎么办？',
    A: '我们公司会提供VIP一对一翻译服务。',
  },
  {
    Q: '可以选择医生及医院吗',
    A: '可以。客户直接与医生/医院签约，我们只是提供寻访医生/医院服务。',
  },
  {
    Q: '宝宝的性别或双胞胎可以选择吗？',
    A: '可以，只要提出要求。双胞胎代母的补偿金将会增加$7,000美金。',
  },
];


export const EnKeyMaps = new Map([
  ['title', 'Gestational Surrogacy'],
  ['intro', 'Gestational surrogacy is done by transferring a couple\'s IVF embryos into a surrogate mother. It can allow a woman without a uterus to have her own genetic children.'],
  ['btn_txt', 'View Surrogate Profile'],
  ['group_title', 'Who needs surrogacy?'],
  ['group', '• The woman is unable to get pregnant due to various reasons. For example: the woman is unable to be pregnant due to age or medical reasons.\n'
  + '• Single men or same-sex couples want children\n'
  + '• Other personal reasons.\n'],
  ['advantage_title', ''],
  ['advantages', ''],
]);

export const ZhKeyMaps = new Map([
  ['title', '代理妊娠'],
  ['intro', '第三方妊娠又称作代理孕母，简称代孕。代孕是一种协议：一位妇女同意为代孕诉求者怀孕，并最终将生产的孩子交给代孕诉求者哺育。这样的一位妇女就叫做代母。NewCare 提供的是代理妊娠的服务，卵子由代孕诉求者或捐卵者提供，胚胎是通过试管婴儿的医疗手段体外受精得到的，然后植入代母体内。整个过程保证孩子不会与代母有任何血缘关系。'],
  ['btn_txt', '浏览代孕天使'],
  ['group_title', '代理妊娠适用人群'],
  ['group', '• 女方各种原因导致无法自己怀孕的，或者怀孕后有可能加重原有疾病导致危险\n'
  + '• 单身男性或同性伴侣想要孩子的\n'
  + '• 其他个人原因\n'],
  ['advantage_title', '美国代孕优势'],
  ['advantages', '• 美国的代孕法律完善，充分保障准父母和代母的权益\n'
  + '• 美国的医疗水平全世界最先进\n'
  + '• 孩子出生即获得美国国籍\n'],
]);
