import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import FAQs from '../../components/FAQ/FAQs';
import Styles from './ForSurrogate.module.scss';
import {
  SurrogateFAQs, SurrogateFAQsZh, EnKeyMaps, ZhKeyMaps,
} from './constants';
import {
  LANG_EN, LANG_ZH_SIMPLIFIED, LANG_ZH_TRADITIONAL,
  PATH_SURROGATE_APPLICATION,
} from '../../res/constants';
import IntroSection from '../../components/IntroSection/IntroSection';
import ModalTypes from '../../components/Modals/ModalTypes';
import { showModal } from '../../redux/Modal/Modalactions';
import Process from '../../components/Process/Process';
import { translate } from '../../Utils/Util';
import BecomeSurrogateMother from './components/BecomeSurrogateMother/BecomeSurrogateMother';
import ExtraIntro from '../../components/ExtraIntro/ExtraIntro';

const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});
const LangPacks = { ZhKeyMaps, EnKeyMaps };

class ForSurrogate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { surrogateFAQs: [], benefitImgLink: 'https://res.cloudinary.com/hyyxofhbh/image/upload/v1558949628/NewCare/Assets/SBP-eng.jpg' };
  }

  componentDidMount() {
    const { langState } = this.props;
    this.setContents(langState.lang);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setContents(langState.lang);
    }
  }

  setContents = (lang) => {
    const buttonTxt = lang === LANG_EN ? 'Surrogate Benefit Package Sample' : '代母福利计划';
    let benefitImgLink = 'https://res.cloudinary.com/hyyxofhbh/image/upload/v1558949628/NewCare/Assets/SBP-eng.jpg';
    if (lang === LANG_ZH_SIMPLIFIED) {
      benefitImgLink = 'https://res.cloudinary.com/hyyxofhbh/image/upload/v1558949628/NewCare/Assets/SBP-%E7%AE%80%E4%BD%93.jpg';
    } else if (lang === LANG_ZH_TRADITIONAL) {
      benefitImgLink = 'https://res.cloudinary.com/hyyxofhbh/image/upload/v1558949628/NewCare/Assets/SBP-%E7%B9%81%E9%AB%94.jpg';
    }
    this.setState({
      surrogateFAQs: lang === LANG_EN ? SurrogateFAQs : SurrogateFAQsZh,
      processTitle: lang === LANG_EN ? EnKeyMaps.get('process_title') : ZhKeyMaps.get('process_title'),
      process: lang === LANG_EN ? EnKeyMaps.get('process') : ZhKeyMaps.get('process'),
      buttonTxt: translate({ lang, value: buttonTxt }),
      benefitImgLink,
    });
  };

  onBecomeSurrogateMom = () => {
    const { user, dispatch, history } = this.props;
    if (user != null) {
      history.push(PATH_SURROGATE_APPLICATION);
    } else {
      const data = {
        modalProps: {
          isOpen: true,
        },
        modalType: ModalTypes.SIGN_UP,
      };
      dispatch(showModal(data));
    }
  };

  onBenefitBtnPressed = () => {
    window.alert('what');
  };

  render() {
    const { langState } = this.props;
    const {
      surrogateFAQs, processTitle = '', process = [], buttonTxt = '', benefitImgLink,
    } = this.state;
    const leaderTitle = langState.lang === LANG_EN ? EnKeyMaps.get('extra_title') : '';
    const leaderIntro = langState.lang === LANG_EN ? EnKeyMaps.get('extra_intro') : '';
    return (
      <div className={Styles.container}>
        <Header />
        <IntroSection
          LangPacks={LangPacks}
          bg="https://res.cloudinary.com/hyyxofhbh/image/upload/c_scale,q_80,w_1600/v1559753484/NewCare/Assets/Surrogacy/shutterstock_538455151.jpg"
          onBtnClicked={this.onBecomeSurrogateMom}
        />
        <ExtraIntro
          title={leaderTitle}
          details={leaderIntro}
          images={[
            'https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,q_80,w_800/v1559753476/NewCare/Assets/Surrogacy/shutterstock_232623784.jpg',
            'https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,q_80,w_800/v1559753470/NewCare/Assets/Surrogacy/shutterstock_529087087.jpg',
            'https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,q_80,w_800/v1559753469/NewCare/Assets/Surrogacy/shutterstock_604426694.jpg',
          ]}
        />
        <Process title={processTitle} process={process} />
        <div className={Styles.buttonWrapper}>
          <a target="_blank" rel="noopener noreferrer" href={benefitImgLink} style={{ textDecoration: 'none' }}>
            <div className={Styles.button}>
              <p style={{fontSize: 26}}>{buttonTxt}</p>
            </div>
          </a>
        </div>
        <BecomeSurrogateMother />
        <FAQs faqs={surrogateFAQs} />

        <Footer />
      </div>
    );
  }
}

ForSurrogate.defaultProps = {
  user: null,
};

ForSurrogate.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape(
    {
      display_name: PropTypes.string,
    },
  ),
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(ForSurrogate));
