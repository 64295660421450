
import { getAxiosInstance } from '../../Utils/WebRequest';

export const DonorAppRoute = '/api/donor_apps';

export function createOrUpdateDonorApplication(formAttrs, completed = false) {
  const data = { form_attributes: formAttrs };
  if (completed) {
    data.completed = true;
  }
  return getAxiosInstance().post(DonorAppRoute, data);
}

// Note: server's page is 1 based but front-end is 0 based
export function fetchDonors({ page = 0, filterOptions = {} }) {
  if (page == null) { return Promise.reject(new Error('page is not provided.')); }
  return getAxiosInstance().get(DonorAppRoute, {
    params: { ...filterOptions, page: page + 1 },
  });
}
