export const CompensationNotes = '<span><em>&nbsp; &nbsp; &nbsp;In the attached “GC Information Packet”, you will find estimated reimbursements and compensation amounts. Please understand that if you should choose to be a gestational carrier, these figures are just an <strong><u>ESTIMATE</u></strong>. You MUST ASK YOURSELF what you feel you should be compensated for this undertaking. We encourage you, if you have specific amount in mind please list them here and return this sheet you’re your application. Thank you!</em></span>';

export const ConsentNotes = `<div class="full_width_space"><p><span style="font-size: 16px;"><em>All information provided in this application is true, accurate, complete and to the best of my knowledge.</em></span>&nbsp; &nbsp;</p>
<p><span style="font-size: 16px;">I believe my wife’s/partner’s response to this application is true, accurate and complete and to the best of her knowledge. I am in support of her desire to become a gestational carrier.</span></p>
<ol>
<li><span style="font-size: 12px;">NewCare Fertility Consulting did not influence, induce or coerce me in my decision to become a gestational carrier; 
</span></li>
<li><span style="font-size: 12px;">NewCare Fertility Consulting is not a party to my agreement with the biological mother and/or the biological father; 
</span></li>
<li><span style="font-size: 12px;">NewCare Fertility Consulting cannot predict the future behavior of the biological mother and/or the biological father and has not guaranteed or warranted that the biological father and/or biological mother will comply with the terms of any agreement; and 
</span></li>
<li><span style="font-size: 12px;">NewCare Fertility Consulting will be providing this application to potential parents both through hard copy and electronic formats.</span></li>
</ol>
<div><span style="font-size: 12px;">&nbsp; &nbsp; Therefore, I hereby agree to release and discharge NewCare Fertility Consulting and any of its representatives, agents, employees and servants from all liability and all manners of action, suits, causes of actions, proceedings, debts, contracts, judgments, damages, claims and demands whatsoever in law or equity in connection with my decision to become a gestational carrier or any adverse consequences which may arise in my connection with or as a result of my participation in this process. I hereby further agree to indemnify NewCare Fertility Consulting against any and all costs incurred in defending any such actions arising out of this process.</span></div></div>`;

export const ConsentOfGestationalCarrier = `<div>
<p><span style="font-size: 12px;">&nbsp; &nbsp; Gestational Carrier and NewCare Fertility Consulting are entering into an agreement whereby NewCare Fertility Consulting, owned by Catherine Fan, will attempt to match Gestational Carrier with Intended Parents for the purpose of Gestational Carrier carrying and delivering a child for those Intended Parents.</span></p>
<p><span style="font-size: 12px;">&nbsp; &nbsp; Gestational Carrier understands that NewCare Fertility Consulting is not a medical facility, does not employ any doctors or medical staff, and does not provide any medical advice to Gestational Carrier. NewCare Fertility Consulting is not advising the Gestational Carrier of the risks associated with pregnancy. Nor does NewCare Fertility Consulting make any representations as to the fitness of the Gestational Carrier to carry a pregnancy.</span></p>
<p><span style="font-size: 12px;">&nbsp; &nbsp; Gestational Carrier understands that there is some possibility that there will be medical expenses incurred as a result of her pregnancy for the Intended Parents which will not be covered by any health insurance carrier. Gestational Carrier further understands that, while the Gestational Carrier Agreement executed between the Gestational Carrier and the Intended Parents may require Intended Parents to pay these uncovered medical expenses, NewCare Fertility Consulting cannot guarantee that the Gestational Carrier Agreement will be enforced and/or that these expenses will be paid by the Intended Parents.</span></p>
<p><span style="font-size: 12px;">&nbsp; &nbsp; Gestational Carrier understands that NewCare Fertility Consulting is not providing legal advice or any legal services to Gestational Carrier. At no time will NewCare Fertility Consulting make legal representations to the Gestational Carrier. However, NewCare Fertility Consulting will assist Gestational Carrier in locating and contacting independent legal counsel to review the Gestational Carrier Agreement.</span></p>
<p><span style="font-size: 12px;">&nbsp; &nbsp; Gestational Carrier understands that NewCare Fertility Consulting will open and maintain an escrow account on behalf of Intended Parents. The trust company will make payments to Gestational Carrier from the funds in the escrow account; however, neither escrow company nor NewCare Fertility Consulting can or will act as guarantor of the monies due to Gestational Carrier.</span></p>
<span style="font-size: 12px;">&nbsp; &nbsp; Having been informed of all of the above, Gestational Carrier and Gestational Carrier’s Spouse/Partner, if any, consent and desire to have NewCare Fertility Consulting match Gestational Carrier with Intended Parents for the purpose of carrying and delivering a child for Intended Parents.</span></div>`;
