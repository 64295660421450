import React from 'react';
import Styles from './Footer.module.scss';
import LeftMenu from './components/LeftMenu/LeftMenu';
import SectionRight from './components/SectionRight/SectionRight';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={Styles.container}>
        <LeftMenu />
        <SectionRight />
      </div>
    );
  }
}

export default Footer;
