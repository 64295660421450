import React, { Fragment } from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { tify } from 'chinese-conv';
import Styles from './Application.module.scss';
import Header from '../../components/Header/Header';
import ProgressBar from './components/ProgressBar/ProgressBar';
import Button from './components/Button/Button';
import EventsManager from './managers/EventsManager';
import DonorApplicationForm from './DonorApplicationForm';
import ModalTypes from '../../components/Modals/ModalTypes';
import { showModal } from '../../redux/Modal/Modalactions';
import {
  LANG_EN, LANG_ZH_SIMPLIFIED, LANG_ZH_TRADITIONAL, PATH_HOME_PAGE,
} from '../../res/constants';
import './Application.scss';

const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});

class DonorApplication extends React.Component {
  constructor(props) {
    super(props);
    const { user, langState } = props;
    const { lang } = langState;
    this.eventsManager = new EventsManager(this);
    const donorApplicationForm = new DonorApplicationForm(user, this, lang);
    this.state = {
      user,
      formIndex: 0,
      progress: donorApplicationForm.isCompleted() ? 100 : 0,
      formCounts: 6,
      buttonsDisabled: donorApplicationForm.isCompleted(),
      saveBtnTxt: 'SAVE',
      nextBtnTxt: 'NEXT>>',
      buttonsVisible: !donorApplicationForm.isCompleted(),
      alert: {
        message: 'Saved successfully!',
        visible: false,
        color: 'red',
      },
      donorApplicationForm,
      highlightRequiredField: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { user } = this.state;
    const { dispatch } = this.props;
    this.setButtonsTxt();
    if (user == null) {
      const data = { modalProps: { isOpen: true }, modalType: ModalTypes.SIGN_UP };
      dispatch(showModal(data));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { user, langState } = nextProps;
      this.setState({ user });
      const { donorApplicationForm, formIndex, formCounts } = this.state;
      const { lang } = langState;
      const { langState: currentLangState } = this.props;
      if (currentLangState.lang !== lang) {
        // go back to first form, unless it's last form (already submitted)
        let newFormIndex = 0;
        if (formIndex === formCounts - 1) {
          newFormIndex = formCounts - 1;
        }
        this.setState({ formIndex: newFormIndex }, () => {
          donorApplicationForm.onLangChanged(lang);
          this.setButtonsTxt();
        });
      }
    }
  }

  setButtonsTxt = () => {
    const { langState } = this.props;
    const { lang } = langState;
    const { formIndex, formCounts, buttonsDisabled } = this.state;
    let saveBtnTxt = lang === LANG_EN ? 'SAVE' : '保存';
    if (buttonsDisabled) {
      saveBtnTxt = lang === LANG_EN ? 'SAVING...' : '保存中...';
    }
    let nextBtnTxt = lang === LANG_EN ? 'NEXT>>' : '下一步>>';
    let previousBtnTxt = lang === LANG_EN ? '<<PREVIOUS' : '<<上一步';
    if (formIndex === formCounts - 2) {
      nextBtnTxt = lang === LANG_EN ? 'SUBMIT' : '提交';
    }
    if (lang === LANG_ZH_TRADITIONAL) {
      saveBtnTxt = tify(saveBtnTxt);
      nextBtnTxt = tify(nextBtnTxt);
      previousBtnTxt = tify(previousBtnTxt);
    }
    this.setState({ previousBtnTxt, saveBtnTxt, nextBtnTxt });
  };

  getButtons = () => {
    const {
      formIndex, buttonsDisabled, saveBtnTxt, nextBtnTxt, buttonsVisible, donorApplicationForm,
      previousBtnTxt,
    } = this.state;
    if (!buttonsVisible || donorApplicationForm.isCompleted()) {
      return null;
    }
    const {
      onPrevious, onSave, onNext,
    } = this.eventsManager;
    const ButtonPrevious = formIndex > 0 ? (
      <Button
        text={previousBtnTxt}
        onClick={onPrevious}
        disabled={buttonsDisabled}
      />
    )
      : null;
    return (
      <div className={Styles.buttonsWrapper}>
        {ButtonPrevious}
        <Button text={saveBtnTxt} disabled={buttonsDisabled} onClick={onSave} />
        <Button text={nextBtnTxt} onClick={onNext} disabled={buttonsDisabled} />
      </div>
    );
  };

  render() {
    const { onDismissAlert } = this.eventsManager;
    const {
      progress, alert, user, donorApplicationForm, formIndex,
    } = this.state;
    const buttons = this.getButtons();
    const { langState } = this.props;
    const { lang } = langState;
    const title = lang === LANG_EN ? 'Egg Donor Application Form' : '捐卵者申请表';
    return (
      <div className={Styles.container}>
        {alert.visible ? (
          <div className=" alert-dismissible fade show my-alert" role="alert" style={{backgroundColor: alert.color}}>
            <button type="button" aria-label="Close" onClick={onDismissAlert}><span aria-hidden="true">×</span></button>
            {lang === LANG_ZH_TRADITIONAL ? tify(alert.message) : alert.message}
          </div>
        ) : null}
        <Header fixed={false} allowTransparent={false} redirectPathAfterLogout={PATH_HOME_PAGE} />
        <h1 className={Styles.title}>{lang === LANG_ZH_SIMPLIFIED ? title : tify(title)}</h1>
        {user == null ? null : (
          <Fragment>
            <ProgressBar progress={progress} />
            <div style={{ height: '20px' }} />
            {donorApplicationForm.getForm(formIndex)}
            <div style={{ height: '60px' }} />
            {buttons}
          </Fragment>
        )}
      </div>
    );
  }
}

DonorApplication.defaultProps = {
  user: null,
};

DonorApplication.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape({
    display_name: PropTypes.string,
  }),
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(DonorApplication));
