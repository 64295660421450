export const IvfFAQs = [
  {
    Q: 'What is IVF?',
    A: 'IVF stands for in vitro fertilization. This is the method of fertilizing eggs with sperm in a specialized laboratory to create an embryo that will then be transferred to the uterus. The embryo may also be frozen for later use.',
  },
  {
    Q: 'How are the embryos made?',
    A: 'During IVF, a woman’s eggs are surgically retrieved and fertilized by mixing with their partners (or a donors) sperm. Once the egg is fertilized, it is left to grow for 2-5 days. During this time, the embryologist will determine if the embryos have grown correctly and decide if they can be used. They are then transferred to the uterus, or frozen for later.',
  },
  {
    Q: 'When should I start speaking with a fertility doctor about IVF?',
    A: 'Generally, a woman will seek advice from a fertility doctor after one year of unsuccessfully trying to get pregnant. Women over 35 may also be recommended to consult with a fertility doctor if she has been trying to get pregnant for at least 6 months with no success. After the age of 40, it is highly recommended to meet with a fertility doctor as soon as you decide you are ready to have a child.',
  },
  {
    Q: 'Am I a candidate for IVF?',
    A: 'There are many different factors that might lead to IVF:\n'
        + '     • Low sperm counts\n'
        + '     • Endometriosis\n'
        + '     • Problems with the uterus or fallopian tubes\n'
        + '     • Ovulation disorders\n'
        + '     • Any other health issues that cause unexplained infertility\n',
  },
  {
    Q: 'How successful is IVF?',
    A: 'The success rates of IVF can depend on several different things. One of the biggest factors is age. Egg quality tends to drop with age. The older the eggs are at the time of retrieval, the lower chance of having high quality eggs. Age may also affect how many eggs can be retrieved at once. Other factors include weight, height, sperm counts, reproductive history and number of previous pregnancies, miscarriages and births.',
  },
  {
    Q: 'How long does IVF take?',
    A: 'This process purely depends on when you choose to use your embryos. If you are wanting to do a fresh embryo transfer, the process is estimated to take 4 to 6 weeks. If you are wanting to do a frozen embryo transfer (FET) the process depends on when you decide you are ready to transfer the embryo. Not all patients are successful on their first IVF attempt and some will need to go through multiple IVF cycles.',
  },
  {
    Q: 'Are there any restrictions while undergoing IVF?',
    A: 'Yes. There are many restrictions that the IVF doctor will discuss with you. Basically, you want to treat your body the same as you would while you are pregnant. This includes but is not limited to:\n'
        + '     • No Smoking\n'
        + '     • No Drinking\n'
        + '     • No Medications that aren’t prescribed\n'
        + '     • No Vigorous exercise\n',
  },
  {
    Q: 'How long does egg retrieval take?',
    A: 'The egg retrieval procedure typically takes 30 minutes or less depending on how many follicles are present.',
  },
  {
    Q: 'Is the egg retrieval painful?',
    A: 'No. Egg retrieve is done under anesthesia. This means that you will not feel or remember anything from the procedure. There may be some soreness afterwards.',
  },
  {
    Q: 'What do we do if we need an egg donor?',
    A: 'Please read our Egg Donation Q&A provided here.',
  },
  {
    Q: 'What happens during the IVF procedure?',
    A: 'Prior to the procedure, the patient is placed on medications that will help her uterine lining become susceptible for the embryo. She is also administered hormones that will help support the pregnancy. During the IVF procedure, the patient is placed in stirrups. While using ultrasound guidance, the IVF doctor will use a speculum to open the vagina and insert a catheter into the uterus. Once everything is in place, the IVF doctor will call the embryologist who will bring the embryo(s). The embryo is then transferred into the uterus through a saline solution.',
  },
  {
    Q: 'Does the embryo transfer hurt?',
    A: 'No. The procedure is very similar to a routine pap smear. There may be some discomfort and cramping afterwards.',
  },
  {
    Q: 'How soon will I know if the embryo transfer was a success?',
    A: 'Typically, a blood draw will be done in 10 days to test for pregnancy. This blood draw is repeated a couple of days later in order to make sure the blood levels are rising properly. An ultrasound can be done around 6 weeks pregnant to check for a heartbeat.',
  },
  {
    Q: 'What do we do if the procedure did not work?',
    A: 'Generally, the patient would stop all medications and wait for her next cycle. It is then up to the IVF doctor (and you) to figure out how to proceed.',
  },
];


export const IvfFAQsZh = [
  {
    Q: '美国试管婴儿需要做哪些检查？',
    A: '女性需要检查的项目：激素六项（要在月经周期的第2天或者第3天进行空腹抽血检测）；血液AMH，阴超（检查左右卵巢的所有卵泡数量）；乙肝、丙肝、梅毒、艾滋；甲状腺素；衣原体和淋病；子宫超声影像术。\n'
        + '男性需要检查的项目：精子分析和检测；衣原体和淋病；乙肝、丙肝、梅毒、艾滋。\n',
  },
  {
    Q: '美国试管婴儿生育的宝宝和自然分娩的孩子有什么区别吗？',
    A: '试管婴儿和自然分娩的孩子一样健康，在身体、心理、智力及社交能力发展方面都正常，而且同自然妊辰分娩的宝宝相比，由于使用了PGD／PGS技术，美国试管宝宝的先天缺陷发生率要明显更低，更多的遗传了爸爸妈妈的优秀基因。',
  },
  {
    Q: '美国试管婴儿过程中用排卵药排的卵子 ，与正常身体排出的卵子有差别吗？',
    A: '没有差别。这主要看促排卵药物的质量，以美国西海岸生殖医疗中心为例，其使用的是一种卵泡刺激素，对女性的身体副作用非常小，一个促排卵用药周期是7～9天时间，通常一个月经周期就可以代谢完毕，不会给身体带来额外的负担。可能会出现腹水、水肿的现象。使用促排药主要是使卵泡长大，一次可以取出多个卵子，培育多个胚胎，保证受孕成功，并且剩余的胚胎可以进行冷冻，需要的话直接解冻后植入即可。',
  },
  {
    Q: '不打促排卵针，就每个自然周期取出一个卵子，然后取出一定数量再做胚胎，这样行吗？',
    A: '美国试管婴儿专家不建议客户这样做。不用促卵药，每个月取出的卵子太少，质量也无法保证，而且耽误时间。每次取卵子，都需要使用麻醉药，那这样取10次对身体影响可能更大。在美国西海岸生殖医疗中心所有促卵药是纯天然的，对身体的伤害很小的，所以不用担心会对身体造成什么伤害。',
  },
  {
    Q: '美国试管婴儿取卵应注意什么呢？',
    A: '取卵前一晚睡觉后禁食禁水，具体时间听从医护人员安排；取卵当天起床后只需要简单洗漱，不可涂抹任何护肤品，不可以佩戴任何饰品，不可以涂抹指甲油；去到医院后听从医疗人员的安排，手术采用无创伤的阴道取卵技术，在注射生理盐水后再加睡眠式的麻醉剂进行取卵，在轻松的睡眠状态下完成，不会疼痛难忍无法起身，出现腹水等的现象较轻且易恢复；取卵之后按时用消炎药即可，但建议需要继续休息，可正常作息活动。',
  },
  {
    Q: '美国试管婴儿会出现早产、流产吗？',
    A: '美国试管婴儿胚胎植入母体后能否顺利着床、生产，与孕妈妈自身的子宫环境、身体条件以及所处环境有很大关联的，因此接受美国试管婴儿怀孕之后同自然受孕一样，仍然有可能发生流产、早产的风险，因此我们建议大家定期进行必要的孕期检查、孕妈妈也要注意自身的衣食住行。',
  },
];

export const EnKeyMaps = new Map([
  ['title', 'IVF-ET'],
  ['intro', 'In-Vitro Fertilization - Embryo Transfer technology, also known as IVFET, is a fertilization process that starts with extracting eggs and retrieving a sperm sample, then manually combining an egg and sperm in a laboratory dish, and ultimately the embryo(s) is transferred into uterus.'],
  ['btn_txt', 'Get a Free Consultation'],
  ['modal_title', 'Success'],
  ['modal_msg', 'Your request has been successfully submitted. Our stuff will contact you within 48 hrs.'],
  ['advantage_title', 'Who needs IVF-ET?'],
  ['advantages', '◦ Women who have\n'
  + '    • Severe fallopian tube problems\n'
  + '    • Endometriosis\n'
  + '    • Adenomyosis\n'
  + '    • Immune Infertility\n'
  + '◦ Male Factor\n'
  + '    • Oligozoospermia\n'
  + '    • Asthenospermia\n'
  + '    • Teratogenicity\n'
  + '◦ Unexplained infertility\n'
  + '◦ Failure to respond to other infertility treatments\n'
  + '◦ People with inherited diseases that need to be diagnosed before embryo transfer.\n',
  ],
]);

export const ZhKeyMaps = new Map([
  ['title', '试管婴儿'],
  ['intro', '试管婴儿技术适用人群:\n'
    + '1.严重输卵管疾病\n'
    + '2.子宫内膜异位症、子宫腺肌症\n'
    + '3.免疫性不孕症\n'
    + '4.男性因素，即少精症、弱精症、畸精症\n'
    + '5.原因不明性不孕症\n'
    + '6.其它原因的不孕治疗无效者\n'
    + '7.有遗传性疾病需要做移植前诊断者\n'
    + '其它：如卵泡不破裂综合症等\n'],
  ['btn_txt', '免费咨询'],
  ['modal_title', '请求成功'],
  ['modal_msg', '您的请求已经成功发送，我们会在48小时之内尽快与您联系。'],
  ['advantage_title', '美国试管优势'],
  ['advantages', '美国第三代试管婴儿技术的发展，在一定程度上可以让错过最佳生育年龄的夫妻生育孩子，并且通过独有的PGD/PGS基因诊断筛查技术挑选出最优质的囊胚移植怀孕，确保胎儿的健康，然而试管婴儿的成功率和年龄密切相关：\n\n'
  + '• 25-35岁成功率为80%左右\n'
  + '• 35-37岁的成功率为75%左右\n'
  + '• 38-40岁的成功率为70%左右\n'
  + '• 40岁以上成功率降低至50%左右\n\n'
  + '期间还要考虑到女性身体状态、心理状态等因素。美国第三代试管婴儿独特的PGD/PGS基因诊断筛查技术，在移植前对胚胎进行筛查，排除问题胚胎，选择优质胚胎移植，保证优生优育，就目前而言，美国试管婴儿PGS/PGD技术可以检测125种常见遗传疾病，这是提高试管婴儿成功率最关键的一个环节。\n'],
]);
