import React from 'react';
import PropTypes from 'prop-types';
import * as classNames from 'classnames';
import Radium from 'radium';
import Styles from './RadioButton.module.scss';

function RadioButton(props) {
  const {
    selected, text, selectable, containerStyle = {},
    onClick,
  } = props;
  let btnText = null;
  if (text != null) {
    btnText = (
      <p className={Styles.btnTxt}>{text}</p>
    );
  }
  const innerStyle = classNames({ [Styles.radioBtnInner]: true, [Styles.selected]: selected });
  return (
    <div
      className={Styles.container}
      style={[containerStyle, {
        ':hover': {
          cursor: selectable ? 'pointer' : 'default',
        },
      }]}
      onClick={() => {
        if (selectable && onClick instanceof Function) {
          onClick({ selected });
        }
      }}
    >
      <div className={Styles.radioBtnOuter}>
        <div className={innerStyle} />
      </div>
      {btnText}
    </div>
  );
}

RadioButton.defaultProps = {
  selected: false,
  selectable: true,
  text: null,
  containerStyle: {},
  onClick: null,
};

RadioButton.propTypes = {
  selected: PropTypes.bool,
  selectable: PropTypes.bool,
  text: PropTypes.string,
  containerStyle: PropTypes.object,
  onClick: PropTypes.func,
};

export default Radium(RadioButton);
