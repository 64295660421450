import keyMirror from 'keymirror';
import { LANG_EN } from '../../res/constants';

export const LangActionTypes = keyMirror({
  SET_LANGUAGE: null,
});

export function setLang(lang = LANG_EN) {
  return {
    type: LangActionTypes.SET_LANGUAGE,
    language: { lang },
  };
}
