import React from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Styles from './SectionRight.module.scss';
import { PATH_DONORS_LIST, PATH_FOR_EGG_DONOR, PATH_FOR_SURROGATE } from '../../../../res/constants';
import { EnKeyMaps, ZhKeyMaps } from './res/constants';
import { getValueWith } from '../../../../Utils/Util';

const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});
const LangPacks = { EnKeyMaps, ZhKeyMaps };

class SectionRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuList: [],
    };
  }

  componentDidMount() {
    const { langState } = this.props;
    this.setContents(langState.lang);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setContents(langState.lang);
    }
  }

  setContents = (lang) => {
    this.setState({
      menuList: [
        {
          key: 'future_parents',
          title: getValueWith({ key: 'future_parents', lang, ...LangPacks }),
          items: [
            { key: 'find_egg_donor', title: getValueWith({ key: 'find_egg_donor', lang, ...LangPacks }), url: PATH_DONORS_LIST },
          ],
        }, {
          key: 'egg_donors',
          title: getValueWith({ key: 'egg_donors', lang, ...LangPacks }),
          items: [
            { key: 'be_egg_donor', title: getValueWith({ key: 'be_egg_donor', lang, ...LangPacks }), url: PATH_FOR_EGG_DONOR },
          ],
        }, {
          key: 'surrogate_moms',
          title: getValueWith({ key: 'surrogate_moms', lang, ...LangPacks }),
          items: [
            { key: 'be_surrogate_mom', title: getValueWith({ key: 'be_surrogate_mom', lang, ...LangPacks }), url: PATH_FOR_SURROGATE },
          ],
        },
      ],
    });
  };

  render() {
    const { menuList } = this.state;
    const MenuList = menuList.map((menu, index) => {
      const marginRight = index === menuList.length - 1 ? 0 : '60px';
      const items = menu.items.map(item => (
        <a
          className={Styles.menuItem}
          key={item.key}
          href={item.url}
        >
          {item.title}
        </a>
      ));
      return (
        <div className={Styles.menu} style={{ marginRight }} key={menu.key}>
          <h1 className={Styles.menuTitle}>{menu.title}</h1>
          {items}
        </div>
      );
    });
    return (
      <div className={Styles.container}>
        <div className={Styles.menuList}>
          {MenuList}
        </div>
      </div>
    );
  }
}

SectionRight.propTypes = {
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(SectionRight));
