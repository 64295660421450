import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './EggFreezingPage.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import EggFreezingProcess from '../../components/Process/Process';
import {
  EggFreezingFAQs, EggFreezingFAQsZh, EnKeyMaps, ZhKeyMaps,
} from './res/constants';
import FAQs from '../../components/FAQ/FAQs';
import { LANG_EN } from '../../res/constants';
import IntroSection from '../../components/IntroSection/IntroSection';
import ModalTypes from '../../components/Modals/ModalTypes';
import { showModal } from '../../redux/Modal/Modalactions';
import { getValueWith } from '../../Utils/Util';
import ExtraIntro from '../../components/ExtraIntro/ExtraIntro';


const mapStateToProps = state => ({
  langState: state.langState,
  user: state.userState,
});
const LangPacks = { ZhKeyMaps, EnKeyMaps };

class EggFreezingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eggFreezingFQAs: [],
    };
  }

  componentDidMount() {
    const { langState } = this.props;
    this.setContents(langState.lang);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setContents(langState.lang);
    }
  }

  setContents = (lang) => {
    this.setState({
      eggFreezingFQAs: lang === LANG_EN ? EggFreezingFAQs : EggFreezingFAQsZh,
      processTitle: getValueWith({ ...LangPacks, lang, key: 'process_title' }),
      process: lang === LANG_EN ? EnKeyMaps.get('process') : ZhKeyMaps.get('process'),
    });
  };

  onSignUp = () => {
    const { user } = this.state;
    if (user == null) {
      const { dispatch } = this.props;
      const data = { modalProps: { isOpen: true }, modalType: ModalTypes.SIGN_UP };
      dispatch(showModal(data));
    }
  };

  render() {
    const { eggFreezingFQAs, processTitle = '', process = [] } = this.state;
    const { user, langState } = this.props;
    const { lang } = langState;
    const leaderTitle = lang === LANG_EN ? EnKeyMaps.get('leader_title') : ZhKeyMaps.get('leader_title');
    const leaderIntro = lang === LANG_EN ? EnKeyMaps.get('we_are_leader') : ZhKeyMaps.get('we_are_leader');
    return (
      <div style={styles.container}>
        <Header />

        <IntroSection
          LangPacks={LangPacks}
          bg="https://res.cloudinary.com/hyyxofhbh/image/upload/c_scale,q_80,w_1600/v1559753480/NewCare/Assets/IP-EF/shutterstock_566796565.jpg"
          onBtnClicked={this.onSignUp}
          btnVisible={user == null}
        />
        <ExtraIntro
          title={leaderTitle}
          details={leaderIntro}
          images={[
            'https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,e_shadow:40,q_80,w_800/v1559753486/NewCare/Assets/IP-EF/shutterstock_608685662.jpg',
            'https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,e_shadow:40,q_80,w_800/v1559753485/NewCare/Assets/IP-EF/shutterstock_374384548.jpg',
            'https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,e_shadow:40,q_80,w_800/v1559753476/NewCare/Assets/IP-EF/shutterstock_1170977998.jpg',
          ]}
        />
        <EggFreezingProcess process={process} title={processTitle} />
        <FAQs faqs={eggFreezingFQAs} />

        <Footer />
      </div>
    );
  }
}

EggFreezingPage.defaultProps = {
  user: null,
};

EggFreezingPage.propTypes = {
  user: PropTypes.shape(
    {
      display_name: PropTypes.string,
    },
  ),
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(EggFreezingPage));
