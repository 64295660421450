import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { tify } from 'chinese-conv';
import Styles from './FAQs.module.scss';
import icArrow from '../../assets/ic-carousel-left-control.png';
import { LANG_ZH_SIMPLIFIED } from '../../res/constants';

class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      answerIsVisible: false,
    };
  }

  onQuestionClick = () => {
    const { answerIsVisible } = this.state;
    this.setState({ answerIsVisible: !answerIsVisible });
  };

  render() {
    const { answerIsVisible } = this.state;
    const { question, lang } = this.props;
    const indicatorStyle = [];
    if (answerIsVisible) {
      indicatorStyle.push({ transform: 'rotate(270deg)' });
    }
    return (
      <div className={Styles.questionWrapper}>
        <div className={Styles.question} onClick={this.onQuestionClick}>
          <p>{lang === LANG_ZH_SIMPLIFIED ? question.Q : tify(question.Q)}</p>
          <img alt="" src={icArrow} style={indicatorStyle} />
        </div>
        {answerIsVisible ? (
          <p className={Styles.answer}>
            {lang === LANG_ZH_SIMPLIFIED ? question.A : tify(question.A)}
          </p>
        ) : null}
      </div>
    );
  }
}

FAQ.propTypes = {
  question: PropTypes.shape({
    Q: PropTypes.string,
    A: PropTypes.string,
  }).isRequired,
  lang: PropTypes.string.isRequired,
};

export default Radium(FAQ);
