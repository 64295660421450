export const EggDonationFAQs = [
  {
    Q: 'What is an egg donor?',
    A: 'An egg donor has her eggs retrieved so that couples and individuals can use these eggs to make a child.',
  },
  {
    Q: 'When is an egg donor needed?',
    A: 'Usually, an egg donor is needed when a woman cannot use her own eggs. Egg donors are also needed for single male parents or same-sex male couples.',
  },
  {
    Q: 'Are egg donors screened?',
    A: 'Extensively. First, the egg donor submits an application. The egg donor is asked many questions about their medical history including their families medical and educational backgrounds. After the initial application, the donors receive a medical exam with bloodwork to make sure there are no infectious diseases. A counseling appointment is then scheduled to provide support to the egg donor and make sure she is mentally prepared for the process.',
  },
  {
    Q: 'What information can I receive about a potential donor?',
    A: 'A wide array of information about your egg donor is gathered including their education, ethnicity and interests. ',
  },
  {
    Q: 'What happens during the matching process?',
    A: 'Once the egg donor has passed all screenings, her photo and information will be put together in a profile for perspective recipients to review. This database is completely confidential and no identifiable information is given. Once a recipient chooses to work with an egg donor, the process to retrieve the eggs begins. A final screening is done and the donor is prepped for her egg donation cycle. She will be given medication to help her make more follicles that will (hopefully) produce a high number of eggs.',
  },
  {
    Q: 'How long does the egg donation process last?',
    A: 'Typically, the egg donation process will take 4 to 6 weeks. Once the egg donor is screened, she is put on medications that will help her create more follicles. The egg retrieval procedure generally takes around 30 minutes.',
  },
];

export const EggDonationFAQsZh = [
  {
    Q: '什么样的人适合捐卵?',
    A: '您必须是年龄在18至30岁之间的健康女性，能够灵活安排自己的时间，因为诊所会在为期两周的过程进行必要的检测',
  },
  {
    Q: '成为捐卵人后的待遇如何？',
    A: '美国生殖医学会: 尽管没有对捐卵者的准确报酬达成一致，但是5000美元或5000美元以上是合理的; 超过10000美元也是合理的。我们衷心致力于为捐卵者提供充分的补偿的同时降低受赠家庭的成本。',
  },
  {
    Q: '生活在不同地区的受赠家庭选择了我,该如何处理?',
    A: '我们的个案经理将安排您到受赠家庭指定的诊所进行一次筛查以及在最后一周进行最后一个阶段的卵子提取， 在取卵的旅行过程中我们会帮您报销因捐卵而引起的身体检查及旅行费用。',
  },
  {
    Q: '整个捐卵过程持续多长时间？ 捐卵如何进行？',
    A: '捐卵天使需要接受6到8次的诊所检查，在这8到9天的时间里自行每天注射荷尔蒙以变刺激排卵 整个过程将由诊所的医生通过阴道超声波和荷尔蒙验血进行密切监督 一旦期卵子成熟将安排其进行卵子的提取这一过程将在麻醉后实行没有任何痛苦',
  },
  {
    Q: '捐卵过程有伤害吗？',
    A: '您也许在卵子提取后会有轻微的腹绞痛或类似痛经的不适，建议您在卵子提取当天的其余时间休息。多数捐卵天使在次日开始工作或上学。',
  },
  {
    Q: '卵子捐赠过程安全吗？',
    A: '卵子捐赠有大约30年的历史，超过100000个婴儿通过这种方法出生。全部的医疗过程不是没有任何风险的。然而，卵子的提取在穿刺过程中的风险是较低的，如今您只有不到1%的机率会面临任何严重的副作用。卵子捐赠不会影响您的生育，提取的卵子只是从您当月月经中产生的，同样的这些卵子也会从您体内排出。您未来的卵子是安全的，您今后的妊娠不会有任何问题。',
  },
  {
    Q: '我需要服用避孕药吗？',
    A: '是的，您需要服用诊所开出的避孕药。',
  },
  {
    Q: '如果我的输卵管结扎了可以捐卵吗？',
    A: '当然可以，卵子的生成和提取直接与卵巢有关系，不需要使用输卵管。',
  },
  {
    Q: '如果我把自己的卵子捐赠给他人我自己会耗尽我的卵子吗？',
    A: '不会，每个妇女在其卵巢中有几百万个卵子，在正常的月经中，妇女的卵巢会生产很多卵细胞。挑选并提取一个成熟卵子后，其余的卵子将由身体排出或吸收。卵子捐赠刺激这些卵细胞的成熟。多数捐卵天使在每次捐赠过程中会提取10～20个卵子。',
  },
  {
    Q: '卵子捐赠过程会影响我将来的生育吗？',
    A: '不会，卵子捐赠不影响将来的生育。',
  },
  {
    Q: '我会见到接受我卵子的受赠家庭吗？',
    A: '加州法律规定卵子捐赠需是匿名的，提取卵子后，双方之间不会产生任何维系关系的义务。',
  },
  {
    Q: '使用我捐赠的卵子出生的小孩是否可以找到我？',
    A: '这个匿名的捐赠，对方夫妻不会找到您的个人身份，如您的姓名和个人联系方式。他们只能从您的捐赠档案中了解您的特征。然而，如果孩子想找到捐卵天使，其父母和捐卵天使也同意，建议他／她在成年后再寻找。',
  },
  {
    Q: '我会失去很多的工作和上学的时间吗？',
    A: '卵子提取过程将需要花费一整天的时间，非常重要的是，您要知道，进行或延续这样的安排需要承担一定的责任，再卵子捐赠过程中，需要明确对自己及工作、学习是否有影响。',
  },
  {
    Q: '我可以捐赠卵子多少次？我多久可以捐赠一次？',
    A: '您最多可以捐赠卵子6次，每2～3个月捐赠一次。',
  },
  {
    Q: '如果我不是100%的中国人，我也可以申请进行卵子捐赠吗？',
    A: '是的，我们接受所有具有中国背景的捐卵天使。事实上，最近我们很多的受卵夫妻对具有多种族背景的中国捐卵天使的兴趣很高。',
  },
  {
    Q: '卵子捐赠有哪些风险？',
    A: '主要的风险可能会面临卵巢过度刺激综合症，患上卵巢过度刺激综合征的机率非常小（辅助生育只有1%～3%的机率）。医生会进行检测以防患上卵巢过度刺激综合征，卵巢过度刺激综合症包括体重增加和感觉非常胀。与任何其他手术一样，卵子捐赠还会有感染的的风险，您很有可能要服用抗生素来预防。',
  },
  {
    Q: '我在完成卵子捐赠后，还需要做什么吗？',
    A: '我们需要您继续与我们保持联系，及时更新您的联系信息，和您的身体状况！',
  },
];


export const EnKeyMaps = new Map([
  ['title', 'Egg Donation'],
  ['intro', 'Oocytes (eggs) from a young woman are fertilized in the laboratory with the sperm of the recipient\'s partner or selected sperm donor to create embryos. These embryos are transferred into the patient\'s (recipient/surrogate mother) uterus to develop into a baby. This procedure enables women with severely diminished ovarian function, or genetic abnormalities, to carry and deliver their own child.\n'],
  ['extra_title', 'Why Choose NewCare?'],
  ['extra_intro', 'NewCare was founded with the intentions of going above and beyond in the donor community. When you become a part of the NewCare family, you can expect to have personal guidance every step of the way, making each part of the process a smooth transition. We honor our donors with the utmost compassion that is unparalleled to other providers. Our team is committed to making your experience with NewCare not only worry-free, but ultimately gratifying.\n'],
  ['btn_txt', 'Become an Egg Donor'],
  ['process_title', 'Egg Donation Process'],
  ['process', [
    'Fill out the "Egg Donor Application"',
    'Start matching (can take 1-6 months)',
    'Arranging the initial medical screening',
    'Psychological screening and Genetic counseling',
    'Egg donation legal contract',
    'Donation cycle starts',
    'Egg Retrieval',
    'Home!',
  ]],
]);

export const ZhKeyMaps = new Map([
  ['title', '关于捐卵'],
  ['intro', '又称卵子捐赠, 是使用促进排卵激素, 一次产生众多卵子后人为取出,以帮助年龄偏大卵巢功能不好, 早衰等女性。我们提供免费专业谘询服务以及完善的生育功能检测。捐卵是一个助人有爱心的捐赠计划并同时可获得补偿费。\n\n'
  + '服务优势：免费机票、住宿、高额伙食费、中文翻译人员、接送机服务、提供回台术后恢复追踪、专业律师团队一同保护双方利益、生殖中心护理师全程陪同周期，给予完整的卫教及指导。'
  ],
  ['btn_txt', '成为捐卵天使'],
  ['process_title', '捐卵流程'],
  ['process', [
    '个案经理咨询',
    '填写捐卵申请表',
    '开始配对（约1-6个月不等）',
    '安排初步医疗检查',
    '心理谘询和基因谘询',
    '捐卵合同',
    '赴美开始进入疗程',
    '取卵',
    '回家了喔！',
  ]],
]);
