import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Styles from './IntroSection.module.scss';
import './IntroSection.scss';
import { getValueWith } from '../../Utils/Util';

const mapStateToProps = state => ({
  langState: state.langState,
});

class IntroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { langState } = this.props;
    this.setContents(langState.lang);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setContents(langState.lang);
    }
  }

  setContents = (lang) => {
    const { LangPacks } = this.props;
    this.setState({
      title: getValueWith({ key: 'title', lang, ...LangPacks }),
      intro: getValueWith({ key: 'intro', lang, ...LangPacks }),
      buttonTxt: getValueWith({ key: 'btn_txt', lang, ...LangPacks }),
    });
  };

  render() {
    const {
      title, intro, buttonTxt,
    } = this.state;
    const {
      bg, btnVisible, onBtnClicked,
    } = this.props;
    return (
      <div className={Styles.container} style={{ backgroundImage: `url(${bg})` }}>
        <div className={Styles.container}>
          <div className={`${Styles.contents} exploreMore-warp`}>
            <h1 className={`${Styles.title} exploreMore-h1`}>{title}</h1>
            <h2 className={`${Styles.intro} exploreMore-h2`}>{intro}</h2>
            {!btnVisible ? null : (
              <div
                role="presentation"
                className={Styles.btn}
                onClick={onBtnClicked}
              >
                {buttonTxt}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

IntroSection.defaultProps = {
  btnVisible: true,
};

IntroSection.propTypes = {
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
  LangPacks: PropTypes.shape({
    EnKeyMaps: PropTypes.instanceOf(Map),
    ZhKeyMaps: PropTypes.instanceOf(Map),
  }).isRequired,
  bg: PropTypes.string.isRequired,
  btnVisible: PropTypes.bool,
  onBtnClicked: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Radium(IntroSection));
