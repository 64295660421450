import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { tify } from 'chinese-conv';
import { styles } from './PersonalInfo.css';
import { LANG_EN, LANG_ZH_TRADITIONAL } from '../../../../../../res/constants';
import { PersonalInfoColumns, PersonalInfoColumnsZh } from '../../PersonalInfo/constants';
import {
  parseBloodType, parseEduLevel, parseMaritalStatus, parseSexualOrientation, parseStatus,
} from '../../../../../../Utils/Util';

class PersonalInfo extends React.Component {
  constructor(props) {
    super(props);
    const { surrogateMother } = props;
    this.state = {
      surrogateMother,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { surrogateMother = null } = nextProps;
      this.setState({ surrogateMother });
    }
  }

  renderColumns = () => {
    const { surrogateMother } = this.state;
    const { lang } = this.props;
    const personalInfoColumns = lang === LANG_EN ? PersonalInfoColumns : PersonalInfoColumnsZh;
    return personalInfoColumns.map((column, index) => {
      let backgroundColor = '#fefafc';
      let marginTop = 0;
      if (index % 2 === 0) {
        backgroundColor = '#ffffff';
      }
      if (index === 12) {
        marginTop = 10;
      }
      let value = '';
      if (surrogateMother != null) { value = surrogateMother[column.key] != null ? surrogateMother[column.key] : ''; }
      if (column.key === 'date_of_birth' && value.trim().length > 0) {
        const birthDate = new Date(value);
        const month = birthDate.getMonth() + 1;
        value = `${month >= 10 ? month : `0${month}`}/${birthDate.getFullYear()}`;
      }
      if (column.type === 'form_attrs') {
        if (surrogateMother && surrogateMother.surrogate_app) {
          const { form_attributes: formAttrs } = surrogateMother.surrogate_app.data;
          value = formAttrs[column.key] != null ? formAttrs[column.key] : '';
        }
        if (column.key === 'single_gestation_comp') { value = `US$${value}`; }
      }
      if (column.key === 'edu_level') {
        value = parseEduLevel(value);
      }
      if (column.key === 'blood_type') {
        value = parseBloodType({ bloodType: value, lang });
      }
      if (column.key === 'avail_status') {
        value = parseStatus({ status: value, lang });
      }
      if (column.key === 'marriage') {
        value = parseMaritalStatus(value);
      }
      if (column.key === 'sexual_contact_with') {
        value = parseSexualOrientation(value);
      }
      if (value != null && typeof value === 'string') {
        value = lang === LANG_ZH_TRADITIONAL ? tify(value) : value;
      }
      return (
        <div key={column.key} style={[styles.row, { backgroundColor, marginTop }]}>
          <div style={styles.attrCell}>
            {`${lang === LANG_ZH_TRADITIONAL ? tify(column.name) : column.name}:`}
          </div>
          <div style={styles.valueCell}>
            {`${value}${column.unit || ''}`}
          </div>
        </div>
      );
    });
  };

  render() {
    const { surrogateMother } = this.state;
    const columns = this.renderColumns();
    const { lang } = this.props;
    const title = lang === LANG_EN ? 'Personal Information' : '个人信息';
    // note that img with src which includes https does not work...
    return (
      <div style={styles.container}>
        <h1 style={styles.container.title}>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
        {columns}
        <div style={{ height: '50px' }} />
        <div
          className="avatar"
          style={{ ...styles.container.avatar, backgroundImage: `url(${surrogateMother.avatar.replace('https', 'http')})` }}
        />
      </div>
    );
  }
}

PersonalInfo.defaultProps = {
  surrogateMother: null,
};

PersonalInfo.propTypes = {
  surrogateMother: PropTypes.shape({
    authentication_token: PropTypes.string,
  }),
  lang: PropTypes.string.isRequired,
};

export default Radium(PersonalInfo);
