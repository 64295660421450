import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './Surrogacy.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Process from './components/SurrogacyProcess/SurrogacyProcess';
import FAQs from '../../components/FAQ/FAQs';
import { LANG_EN, PATH_SURROGATE_MOTHERS_LIST } from '../../res/constants';
import {
  EnKeyMaps, ZhKeyMaps, SurrogacyFAQs, SurrogacyFAQsZh,
} from './res/constants';
import IntroSection from '../../components/IntroSection/IntroSection';
import ExtraIntro from '../../components/ExtraIntro/ExtraIntro';

const mapStateToProps = state => ({
  langState: state.langState,
  user: state.userState,
});
const LangPacks = { ZhKeyMaps, EnKeyMaps };

class Surrogacy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { langState } = this.props;
    this.setContents(langState.lang);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setContents(langState.lang);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions, false);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  setContents = (lang) => {
    this.setState({
      surrogacyFAQs: lang === LANG_EN ? SurrogacyFAQs : SurrogacyFAQsZh,
    });
  };

  viewSurrogateMothers = () => {
    const { history } = this.props;
    history.push(PATH_SURROGATE_MOTHERS_LIST);
  };

  render() {
    const { surrogacyFAQs } = this.state;
    const { langState } = this.props;
    const { lang } = langState;
    const advantagesTitle = lang === LANG_EN ? EnKeyMaps.get('advantage_title') : ZhKeyMaps.get('advantage_title');
    const advantages = lang === LANG_EN ? EnKeyMaps.get('advantages') : ZhKeyMaps.get('advantages');
    const groupTitle = lang === LANG_EN ? EnKeyMaps.get('group_title') : ZhKeyMaps.get('group_title');
    const group = lang === LANG_EN ? EnKeyMaps.get('group') : ZhKeyMaps.get('group');
    return (
      <div style={styles.container}>
        <Header />

        <IntroSection
          LangPacks={LangPacks}
          bg="https://res.cloudinary.com/hyyxofhbh/image/upload/c_scale,q_80,w_1600/v1559753507/NewCare/Assets/IP-Surrogacy/shutterstock_1197651829.jpg"
          onBtnClicked={() => this.viewSurrogateMothers()}
        />
        <ExtraIntro title={groupTitle} details={group} styles={{ marginBottom: '50px' }} />
        <ExtraIntro title={advantagesTitle} details={advantages} styles={{ marginTop: 0 }} />
        <Process />
        <FAQs faqs={surrogacyFAQs} />

        <Footer />
      </div>
    );
  }
}

Surrogacy.defaultProps = {
  user: null,
};

Surrogacy.propTypes = {
  user: PropTypes.shape(
    {
      display_name: PropTypes.string,
    },
  ),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(Surrogacy));
