import { getAxiosInstance } from '../../Utils/WebRequest';

export const PhotosApiRoute = '/api/photos';

export function uploadPhoto(formData) {
  return getAxiosInstance().post(PhotosApiRoute, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
