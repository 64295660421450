
export const DonorsListCardRows = [
  {
    col: [
      {
        propName: 'id',
        title: 'Donor ID:',
      },
      {
        propName: 'height',
        title: 'Height:',
        unit: 'cm',
      },
    ],
  },
  {
    col: [
      {
        propName: 'date_of_birth',
        title: 'Birthday:',
      },
      {
        propName: 'weight',
        title: 'Weight:',
        unit: 'kg',
      },
    ],
  },
  {
    col: [
      {
        propName: 'blood_type',
        title: 'Blood Type:',
      },
      {
        propName: 'marriage',
        title: 'Marital Status:',
      },
    ],
  },
];

export const DonorsListCardRowsZh = [
  {
    col: [
      {
        propName: 'id',
        title: '捐卵天使 ID:',
      },
      {
        propName: 'height',
        title: '身高:',
        unit: 'cm',
      },
    ],
  },
  {
    col: [
      {
        propName: 'date_of_birth',
        title: '生日:',
      },
      {
        propName: 'weight',
        title: '体重:',
        unit: 'kg',
      },
    ],
  },
  {
    col: [
      {
        propName: 'blood_type',
        title: '血型:',
      },
      {
        propName: 'marriage',
        title: '婚姻状况:',
      },
    ],
  },
];


export const SurrogatesListCardRows = [
  {
    col: [
      {
        propName: 'id',
        title: 'Surrogate ID:',
      },
      {
        propName: 'height',
        title: 'Height:',
        unit: 'cm',
      },
    ],
  },
  {
    col: [
      {
        propName: 'date_of_birth',
        title: 'Birthday:',
      },
      {
        propName: 'weight',
        title: 'Weight:',
        unit: 'kg',
      },
    ],
  },
  {
    col: [
      {
        propName: 'blood_type',
        title: 'Blood Type:',
      },
      {
        propName: 'marriage',
        title: 'Marital Status:',
      },
    ],
  },
];

export const SurrogatesListCardRowsZh = [
  {
    col: [
      {
        propName: 'id',
        title: '代孕天使 ID:',
      },
      {
        propName: 'height',
        title: '身高:',
        unit: 'cm',
      },
    ],
  },
  {
    col: [
      {
        propName: 'date_of_birth',
        title: '生日:',
      },
      {
        propName: 'weight',
        title: '体重:',
        unit: 'kg',
      },
    ],
  },
  {
    col: [
      {
        propName: 'blood_type',
        title: '血型:',
      },
      {
        propName: 'marriage',
        title: '婚姻状况:',
      },
    ],
  },
];

