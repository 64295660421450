import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { tify } from 'chinese-conv';
import Styles from './Process.module.scss';
import icArrow from './assets/arrow.png';
import './Process.scss';
import { LANG_ZH_TRADITIONAL } from '../../res/constants';

const mapStateToProps = state => ({
  langState: state.langState,
  user: state.userState,
});

class Process extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  getProcess = () => {
    const { process = [], langState } = this.props;
    const { lang } = langState;
    return process.map((step, index) => (
      <div className={`${Styles.processContainer} `} key={step}>
        <div className={`${Styles.processBubble} egg-process-item`}>
          <p className="description-step">
            {lang === LANG_ZH_TRADITIONAL ? tify(step) : step}
          </p>
        </div>
        {index < process.length - 1 ? <img className={Styles.iconNext} alt="" src={icArrow} /> : null}
      </div>
    ));
  };

  render() {
    const process = this.getProcess();
    const { title = '', langState } = this.props;
    const { lang } = langState;
    return (
      <div className={Styles.container}>
        <div className="egg-process-container">
          <h1 className={Styles.title}>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
          {process}
        </div>
      </div>
    );
  }
}

Process.propTypes = {
  title: PropTypes.string.isRequired,
  process: PropTypes.arrayOf(PropTypes.string).isRequired,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(Process));
