import React from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import { tify } from 'chinese-conv';
import Styles from './DonorProfile.module.scss';
import Header from '../../../components/Header/Header';
import PersonalInfo from './components/PersonalInfo/PersonalInfo';
import MedicalInfo from './components/MedicalInfo/MedicalInfo';
import FamilyInfo from './components/FamiltyInfo/FamilyInfo';
import Footer from '../../../components/Footer/Footer';
import { showModal } from '../../../redux/Modal/Modalactions';
import ModalTypes from '../../../components/Modals/ModalTypes';
import Carousel from './components/Carousel/Carousel';
import { downloadProfilePdf, fetchUserById } from '../../../Api/Apis/Users';
import Button from '../../DonorApplication/components/Button/Button';
import PDF from './components/PDF/PDF';
import { styles } from './DonorProfile.css';
import { LANG_EN, LANG_ZH_TRADITIONAL, PATH_HOME_PAGE } from '../../../res/constants';

const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});

class DonorProfile extends React.Component {
  constructor(props) {
    super(props);
    const { id: donorId } = props.match.params;
    const { user } = props;
    this.state = {
      user,
      donorId,
      donor: null,
      button: {
        text: 'Download PDF',
        disabled: false,
      },
    };
  }

  componentDidMount() {
    this.setButtonText();
    this.fetchUserInfo();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { id: donorId } = nextProps.match.params;
      const { user } = nextProps;
      this.setButtonText();
      this.setState({ user, donorId }, () => this.fetchUserInfo());
    }
  }

  fetchUserInfo = () => {
    const { donorId } = this.state;
    fetchUserById(donorId)
      .then((res) => {
        const { data: donor } = res.data;
        this.setState({ donor });
      })
      .catch((err) => {});
  };

  setButtonText = () => {
    const { button } = this.state;
    if (button.disabled) {
      this.disableButton();
    } else {
      this.enableButton();
    }
  };

  disableButton = () => {
    const { langState } = this.props;
    const { lang } = langState;
    const buttonText = lang === LANG_EN ? 'Downloading' : '正在下载';
    this.setState({ button: { disabled: true, text: buttonText } });
  };

  enableButton = () => {
    const { langState } = this.props;
    const { lang } = langState;
    const buttonText = lang === LANG_EN ? 'Download PDF' : '下载 PDF';
    this.setState({ button: { disabled: false, text: buttonText } });
  };

  onDownloadPdf = () => {
    const { donor } = this.state;
    const { langState } = this.props;
    const { lang } = langState;
    if (donor != null) {
      this.disableButton();
      const html = ReactDOMServer.renderToStaticMarkup(<PDF donor={donor} lang={lang} />);
      console.log(donor);
      downloadProfilePdf(donor.id, html)
        .then((res) => {
          // Create a Blob from the PDF Stream
          const file = new Blob(
            [res.data],
            { type: 'application/pdf' },
          );
          // Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', `${donor.cus_donor_app_id}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          // Open the URL on new Window
          window.open(fileURL);
          this.enableButton();
        }).catch((err) => {});
    }
  };

  getDonorPhotos = () => {
    const { donor } = this.state;
    let photos = [];
    if (donor != null && donor.donor_app != null) {
      const { form_attributes: formAttrs } = donor.donor_app.data;
      let {
        full_photos: fullPhotos,
        life_photos: lifePhotos, child_photos: childPhotos,
      } = formAttrs;
      fullPhotos = fullPhotos || [];
      lifePhotos = lifePhotos || [];
      childPhotos = childPhotos || [];
      photos = [...fullPhotos, ...lifePhotos, ...childPhotos];
    }
    return photos;
  };

  render() {
    const { user, donor, button } = this.state;
    const { dispatch, langState } = this.props;
    const { lang } = langState;
    const title = lang === LANG_EN ? 'Donor Profile' : '捐卵天使档案';
    if (user != null) {
      const photos = this.getDonorPhotos();
      return (
        <div className={Styles.container}>
          <Header fixed={false} allowTransparent={false} redirectPathAfterLogout={PATH_HOME_PAGE} />
          <h1 className={Styles.title}>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
          <div className={Styles.contents}>
            {donor != null ? <Carousel photos={photos} /> : null}
            <PersonalInfo donor={donor} />
            <MedicalInfo donor={donor} />
            <FamilyInfo donor={donor} />
          </div>
          <div style={{ height: '76px' }} />
          <Button
            text={lang === LANG_ZH_TRADITIONAL ? tify(button.text) : button.text}
            style={styles.downloadBtn}
            disabled={button.disabled}
            onClick={this.onDownloadPdf}
          />
          <div style={{ height: '76px' }} />
          <Footer />
        </div>
      );
    }
    dispatch(showModal({ modalProps: { isOpen: true }, modalType: ModalTypes.LOG_IN }));
    return null;
  }
}

DonorProfile.defaultProps = {
  user: null,
  dispatch: null,
  match: null,
};

DonorProfile.propTypes = {
  user: PropTypes.shape({
    authentication_token: PropTypes.string,
  }),
  dispatch: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }),
  }),
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(DonorProfile));
