import keyMirror from 'keymirror';

const ModalTypes = keyMirror({
  SIGN_UP: null,
  LOG_IN: null,
  FORGET_PASSWORD: null,
  ALERT: null,
});

export default ModalTypes;
