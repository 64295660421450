import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import * as classNames from 'classnames';
import Styles from '../../../../DonorApplication/components/forms/BasicInformation/BasicInformation.module.scss';
import SexualHistoryForm from './SexualHistoryForm';
import { FIELD_TYPE_MULTIPLE_CHOICE } from '../../../../../res/constants';
import Input from '../../../../DonorApplication/components/forms/BasicInformation/components/Input';
import DropdownMenu from '../../../../DonorApplication/components/forms/BasicInformation/components/DropdownMenu';
import CheckBox from '../../../../../components/CheckBox/CheckBox';
import BaseForm from '../../../../DonorApplication/components/forms/BasicInformation/forms/BaseForm';

class SexualHistory extends React.Component {
  constructor(props) {
    super(props);
    const { form = new SexualHistoryForm({}) } = props;
    this.state = {
      form,
    };
  }

  handleInputChange = (event) => {
    const { target } = event;
    const { name: propName, value } = target;
    const { form } = this.state;
    const { user_attributes: userAttrs, form_attributes: formAttrs } = form;
    if (Object.prototype.hasOwnProperty.call(userAttrs, propName)) {
      userAttrs[propName].value = value;
    } else if (Object.prototype.hasOwnProperty.call(formAttrs, propName)) {
      formAttrs[propName].value = value;
    }
    this.setState({ form });
  };

  handleRadioBtnEvent = (event) => {
    const { propName, choice } = event;
    const { form } = this.state;
    const { form_attributes: formAttrs } = form;
    if (Object.prototype.hasOwnProperty.call(formAttrs, propName)) {
      formAttrs[propName].value = choice;
    }
    this.setState({ form });
  };

  handleCheckboxChange = (event) => {
    const { name: propName, text } = event;
    const { form } = this.state;
    const { form_attributes: formAttrs } = form;
    if (Object.prototype.hasOwnProperty.call(formAttrs, propName)) {
      const { options, value } = formAttrs[propName];
      let selectedOptions = [];
      // if value is not empty string
      if (value.trim().length > 0) {
        selectedOptions = value.split(',');
      }
      // if it was selected
      if (selectedOptions.indexOf(text) >= 0) {
        selectedOptions.splice(selectedOptions.indexOf(text), 1);
      } else if (options.indexOf(text) >= 0) {
        // if it was not selected and options contains such option
        selectedOptions.push(text);
      }
      formAttrs[propName].value = '';
      if (selectedOptions.length > 0) {
        formAttrs[propName].value = selectedOptions.reduce((accum, currentVal) => `${accum},${currentVal}`);
      }
      this.setState({ form });
    }
  };

  renderMultipleChoice = () => {
    const { form } = this.state;
    const {
      form_attributes: formAttrs,
    } = form;
    const others = Object.values(formAttrs)
      .filter(attr => attr.type === FIELD_TYPE_MULTIPLE_CHOICE);
    const rows = others.map((item, index) => {
      let backgroundColor = '#fefafc';
      if (index % 2 === 0) {
        backgroundColor = '#ffffff';
      }
      let borderBottom = 'none';
      if (index < others.length - 1) {
        borderBottom = '2px solid rgba(238, 163, 201, 0.5)';
      }
      return (
        <div
          className={Styles.othersRow}
          key={item.propName}
          style={{ backgroundColor, borderBottom }}
        >
          <p className={Styles.fieldTitle} style={{ margin: '0', width: '663px' }}>
            {item.title}
          </p>
          <div className={Styles.fieldTitle} style={{ margin: '0' }}>
            <CheckBox
              text="You"
              textVisible={false}
              name={item.propName}
              onClick={this.handleCheckboxChange}
              checked={item.value.includes('You')}
            />
          </div>
          <div className={Styles.fieldTitle} style={{ margin: '0 0 0 43px' }}>
            <CheckBox
              text="Spouse"
              textVisible={false}
              name={item.propName}
              onClick={this.handleCheckboxChange}
              checked={item.value.includes('Spouse')}
            />
          </div>
        </div>
      );
    });
    return (
      <div className={Styles.othersContainer}>
        <div className={Styles.othersRow}>
          <p className={Styles.fieldTitle} style={{ margin: '0', width: '663px' }}>
            Have you or your partner ever diagnosed with any of the following diseases?
          </p>
          <div className={Styles.fieldTitle} style={{ margin: '0' }}>You</div>
          <div className={Styles.fieldTitle} style={{ margin: '0 0 0 43px' }}>Spouse</div>
        </div>
        {rows}
      </div>
    );
  };

  render() {
    const { form } = this.state;
    const { form_attributes: formAttrs } = form;
    // const yesOrNo = this.renderOtherQuestions();
    const multipleChoices = this.renderMultipleChoice();
    return (
      <div className={Styles.container}>
        <h1>Sexual History</h1>
        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.sexual_contact_with.required ? '*' : ''}${formAttrs.sexual_contact_with.title}`}</p>
            <DropdownMenu
              className={Styles.fieldContainer}
              prop={formAttrs.sexual_contact_with}
              value={formAttrs.sexual_contact_with.value}
              onchange={this.handleInputChange}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.num_sexual_partners.required ? '*' : ''}${formAttrs.num_sexual_partners.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.num_sexual_partners}
            />
          </div>
        </div>

        {BaseForm.renderYesOrNoTable({
          formAttrs,
          title: 'Please answer following questions',
          radioBtnClickHandler: this.handleRadioBtnEvent,
        })}

        <div style={{ height: '20px' }} />
        {multipleChoices}
      </div>
    );
  }
}

SexualHistory.defaultProps = {
};

SexualHistory.propTypes = {
  form: PropTypes.instanceOf(SexualHistoryForm).isRequired,
};

export default Radium(SexualHistory);
