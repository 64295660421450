const styles = {
  progressBar: {
    height: '100%',
    borderRadius: '13px',
    backgroundColor: '#e679b1',
    display: 'flex',
    flexDirection: 'row',
    padding: '0',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default styles;
