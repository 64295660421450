import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Styles from './WeAreLeader.module.scss';
import { EnKeyMaps, ZhKeyMaps } from './res/constants';
import { getValueWith } from '../../../../Utils/Util';
import { ReactComponent as ImgGene } from './assets/ivf_process.svg';
import { ReactComponent as ImgGeneZh } from './assets/ivf_process_zh.svg';
import { LANG_EN } from '../../../../res/constants';


const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});
const LangPacks = { EnKeyMaps, ZhKeyMaps };

class WeAreLeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intro: '',
    };
  }

  componentDidMount() {
    const { langState } = this.props;
    this.setContents(langState.lang);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setContents(langState.lang);
    }
  }

  setContents = (lang) => {
    this.setState({
      title: getValueWith({ key: 'title', lang, ...LangPacks }),
      intro: getValueWith({ key: 'intro', lang, ...LangPacks }),
    });
  };

  render() {
    const { title, intro } = this.state;
    const { langState } = this.props;
    const { lang } = langState;
    return (
      <div className={Styles.container}>
        <div className={Styles.contents}>
          <h1 className={Styles.title}>{title}</h1>
          <p className={Styles.intro}>{intro}</p>
          {lang === LANG_EN ? <ImgGene className={Styles.imgGene} /> : <ImgGeneZh className={Styles.imgGene} />}
        </div>
        <div className={Styles.images}>
          <img className={Styles.image} alt="" src="https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,e_shadow:40,q_80,w_800/v1559753511/NewCare/Assets/IP-%20IVF/shutterstock_607661867.jpg" />
          <img className={Styles.image} alt="" src="https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,e_shadow:40,q_80,w_800/v1559753496/NewCare/Assets/IP-%20IVF/shutterstock_155725736.jpg" />
          <img className={Styles.image} alt="" src="https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,e_shadow:40,q_80,w_800/v1560909046/NewCare/Assets/donor_photo.jpg" />
        </div>
      </div>
    );
  }
}

WeAreLeader.propTypes = {
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(WeAreLeader));
