import React from 'react';
import Radium from 'radium';
import SignatureCanvas from 'react-signature-canvas';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { tify } from 'chinese-conv';
import * as classNames from 'classnames';
import Styles from './Completion.module.scss';
import Input from '../BasicInformation/components/Input';
import CompletionForm from './CompletionForm';
import { LANG_EN, LANG_ZH_TRADITIONAL } from '../../../../../res/constants';
import { shallHighlight } from '../../../../../Utils/Util';

const mapStateToProps = state => ({
  langState: state.langState,
});

class Completion extends React.Component {
  constructor(props) {
    super(props);
    const { completionForm } = props;
    this.base64 = null;
    this.state = {
      completionForm,
      subtitleEn: '*I certify that the information I have provided above is accurate to the best of my knowledge.',
      subtitleZh: '*我保证上面提供的信息在我所知的范围内是准确的。',
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions, false);
  }

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  bindSignaturePadRef = (ref) => {
    // note that, this function is invoked for every render so
    const { completionForm } = this.state;
    const { signature } = completionForm.attrs;
    signature.ref = ref;
    const { base64 } = signature;
    // if signature pad ref is not null and signature image exists, draw it to canvas
    if (ref != null && base64 != null && this.base64 !== base64) {
      signature.saved = false;
      this.base64 = base64;
      ref.clear();
      ref.fromDataURL(base64);
    }
  };

  onEndSign = () => {
    const { completionForm } = this.state;
    const { signature } = completionForm.attrs;
    const { ref } = signature;
    // update signature in form base64
    if (ref != null) {
      signature.saved = false;
      this.base64 = ref.toDataURL();
      completionForm.attrs.signature.base64 = this.base64;
    }
  };

  onClear = () => {
    const { completionForm } = this.state;
    const { signature } = completionForm.attrs;
    const { ref } = signature;
    if (ref != null) {
      signature.saved = false;
      this.base64 = null;
      completionForm.attrs.signature.base64 = null;
      ref.clear();
    }
  };

  handleInputChange = (event) => {
    const { target } = event;
    const { name: propName, value } = target;
    const { completionForm } = this.state;
    if (Object.prototype.hasOwnProperty.call(completionForm.attrs, propName)) {
      completionForm.attrs[propName].value = value;
    }
    this.setState({ completionForm });
  };

  render() {
    const {
      completionForm, subtitleEn, subtitleZh, windowWidth,
    } = this.state;
    if (windowWidth == null) { return null; }
    const { langState, highlightRequiredField } = this.props;
    const { lang } = langState;
    const title = lang === LANG_EN ? 'Completion' : '签名';
    const subtitle = lang === LANG_EN ? subtitleEn : subtitleZh;
    const dateTxt = lang === LANG_EN ? 'Date' : '日期';
    const nameTxt = lang === LANG_EN ? 'Name' : '姓名';
    return (
      <div className={Styles.container}>
        <h1>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
        <p className={Styles.subtitle}>
          {lang === LANG_ZH_TRADITIONAL ? tify(subtitle) : subtitle}
        </p>
        <div className={Styles.signatureWrapper}>
          <SignatureCanvas
            canvasProps={{ width: windowWidth <= 768 ? `${windowWidth * 0.9 - 20}px` : '790px', height: '196px' }}
            ref={ref => this.bindSignaturePadRef(ref)}
            onEnd={this.onEndSign}
          />
        </div>
        <p className={Styles.clear} onClick={this.onClear}>Clear</p>
        <div className={Styles.inputs}>
          <div className={Styles.cell}>
            <p className={classNames({
              [Styles.fieldTitle]: true,
              [Styles.highlightRequired]: highlightRequiredField
              && shallHighlight(completionForm.attrs.date),
            })}
            >
              {`*${lang === LANG_ZH_TRADITIONAL ? tify(dateTxt) : dateTxt}`}
            </p>
            <Input
              style={{ width: '365px' }}
              type="date"
              onchange={this.handleInputChange}
              name={completionForm.attrs.date.propName}
              value={completionForm.attrs.date.value}
              highlight={highlightRequiredField
              && shallHighlight(completionForm.attrs.date)}
            />
          </div>
          <div className={Styles.cell}>
            <p className={classNames({
              [Styles.fieldTitle]: true,
              [Styles.highlightRequired]: highlightRequiredField
              && shallHighlight(completionForm.attrs.name),
            })}
            >
              {`*${lang === LANG_ZH_TRADITIONAL ? tify(nameTxt) : nameTxt}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={completionForm.attrs.name.propName}
              value={completionForm.attrs.name.value}
              highlight={highlightRequiredField
              && shallHighlight(completionForm.attrs.name)}
            />
          </div>
        </div>
      </div>
    );
  }
}

Completion.defaultProps = {
  highlightRequiredField: false,
};

Completion.propTypes = {
  completionForm: PropTypes.instanceOf(CompletionForm).isRequired,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
  highlightRequiredField: PropTypes.bool,
};

export default connect(mapStateToProps)(Radium(Completion));
