import {
  FIELD_TYPE_FILE,
  FIELD_TYPE_PICKER,
  FIELD_TYPE_YES_OR_NO, LANG_EN, PHOTO_TYPE_INSURANCE,
} from '../../../../../res/constants';
import {
  Prop,
} from '../../../../DonorApplication/components/forms/BasicInformation/forms/Utils';
import BaseForm from '../../../../DonorApplication/components/forms/BasicInformation/forms/BaseForm';

const ContentsEn = new Map([
  ['medical_insurance', {
    title: 'Do you have medical insurance?',
  }],
  ['surrogacy_experience', {
    title: 'Do you have Surrogacy experience?',
  }],
  ['carry_twins', {
    title: 'Are you willing to carry twins?',
  }],
  ['reduction_process', {
    title: 'Are you willing to under the process of reduction?',
  }],
  ['do_amniocentesis', {
    title: 'Are you willing to do amniocentesis?',
  }],
  ['terminate_pregnancy', {
    title: 'Are you willing to terminate the pregnancy?',
  }],
  ['hepatitis_b_vaccine', {
    title: 'Have you had Hepatitis B vaccine?',
  }],
  ['rho_injection', {
    title: 'Are you willing to take Rho(D) injection?',
  }],
  ['weight_changed', {
    title: 'Has your weight changed significantly over the past five years except during pregnancy?',
  }],
  ['smoke', {
    title: 'Do you smoke?',
  }],
  ['household_smoke', {
    title: 'Does anyone in your household smoke?',
  }],
  ['drink_alcohol', {
    title: 'Do you drink alcohol beverages?',
  }],
  ['tattoo', {
    title: 'Have you gotten a tattoo or any body piercing within the last year and a half?',
  }],
  ['driver_license', {
    title: 'Do you have Driver\'s License?',
  }],
  ['blood_transfusion', {
    title: 'Had blood transfusion before?',
  }],
  ['marrow_transplant', {
    title: 'Had bone marrow transplant before?',
  }],
  ['organ_transplant', {
    title: 'Had organ transplant before?',
  }],
  ['psychological_counseling', {
    title: 'Have you ever received psychological counseling (including marriage counseling)?',
  }],
  ['mental_illness', {
    title: 'Have you ever been hospitalized for mental illness?',
  }],
  ['physical_abuse', {
    title: 'Have you suffered physical abuse?',
  }],
  ['sexual_abuse', {
    title: 'Have you suffered sexual abuse?',
  }],
  ['tried_suicide', {
    title: 'Have you ever tried suicide or had suicidal thoughts?',
  }],
  ['anxious', {
    title: 'Do you feel anxious or depressed?',
  }],
  ['currently_ill', {
    title: 'Do you currently have any illnesses?',
  }],
  ['currently_medication', {
    title: 'Are you currently using any medication?',
  }],
  ['hospitalized_other', {
    title: 'Have you been hospitalized for any reason other than giving birth?',
  }],
  ['birth_control', {
    title: 'Are you currently using any birth control?',
  }],
  ['other_sexual_partners', {
    title: 'Do you currently have nay other sexual partners other than the husband/spouse/partner you had mention previously?',
  }],
  ['arrested', {
    title: 'Have you ever been arrested?',
  }],
  ['lawsuit', {
    title: 'Have you been involved in any lawsuit?',
  }],
  ['illegal_drugs', {
    title: 'Have you used any illegal drugs?',
  }],
  ['bankruptcy', {
    title: 'Have you ever filed bankruptcy?',
  }],
]);

const ContentsZh = new Map([
  ['medical_insurance', {
    title: '是否有医疗保险？',
  }],
  ['surrogacy_experience', {
    title: '是否有代孕经验？',
  }],
  ['carry_twins', {
    title: '是否愿意怀双胞胎？',
  }],
  ['reduction_process', {
    title: '是否愿意减胎？',
  }],
  ['do_amniocentesis', {
    title: '是否愿意做羊膜穿刺？',
  }],
  ['terminate_pregnancy', {
    title: '是否愿意终止怀孕？',
  }],
  ['hepatitis_b_vaccine', {
    title: '是否注射过乙肝疫苗？',
  }],
  ['rho_injection', {
    title: '是否接受注射免疫球蛋白？',
  }],
  ['weight_changed', {
    title: '过去五年内，除了怀孕外，体重是否有明显改变？',
  }],
  ['smoke', {
    title: '是否抽烟？',
  }],
  ['household_smoke', {
    title: '住宅内是否有人抽烟？',
  }],
  ['drink_alcohol', {
    title: '是否喝酒精饮料？',
  }],
  ['tattoo', {
    title: '过去一年是否有穿洞或纹身？',
  }],
  ['driver_license', {
    title: '是否有驾照？',
  }],
  ['blood_transfusion', {
    title: '是否接受过输血？',
  }],
  ['marrow_transplant', {
    title: '是否接受过骨髓捐赠？',
  }],
  ['organ_transplant', {
    title: '是否接受过器官捐赠？',
  }],
  ['psychological_counseling', {
    title: '是否接受过心理谘询（包括婚姻谘询）？',
  }],
  ['mental_illness', {
    title: '是否有因精神疾病住院過？',
  }],
  ['physical_abuse', {
    title: '是否遭受过身体虐待？',
  }],
  ['sexual_abuse', {
    title: '是否遭受过性侵犯？',
  }],
  ['tried_suicide', {
    title: '是否尝试过自杀或有自杀想法？',
  }],
  ['anxious', {
    title: '是否有焦虑或抑郁感觉？',
  }],
  ['currently_ill', {
    title: '目前是否有任何疾病？',
  }],
  ['currently_medication', {
    title: '目前是否在使用任何药物？',
  }],
  ['hospitalized_other', {
    title: '是否因除去生产以外其他原因住院过？',
  }],
  ['birth_control', {
    title: '目前是否在使用避孕措施？',
  }],
  ['other_sexual_partners', {
    title: '除了上述丈夫/ 配偶外，您目前是否有其他性伴侣？',
  }],
  ['arrested', {
    title: '是否被逮补过？',
  }],
  ['lawsuit', {
    title: '是否卷入过任何法律诉讼？',
  }],
  ['illegal_drugs', {
    title: '是否使用非法药物？',
  }],
  ['bankruptcy', {
    title: '是否申请过破产？',
  }],
]);

class InsuranceInfoForm extends BaseForm {
  constructor({
    user = null, component = null, lang = LANG_EN, appType = 'surrogate_app',
  }) {
    super({
      user, component, lang, ContentsEn, ContentsZh, appType,
    });
    this.photos_attributes = {
      insurance_photos: new Prop({
        propName: PHOTO_TYPE_INSURANCE,
        type: FIELD_TYPE_FILE,
        inputType: 'file',
        required: false,
        title: 'Please attach a copy of your insurance card',
        filesNumLimit: [0, 1],
        fileType: 'image/*',
      }),
    };
    this.form_attributes = {
      has_health_insurance: new Prop({
        propName: 'has_health_insurance',
        title: 'Health insurance?',
        type: [FIELD_TYPE_PICKER, FIELD_TYPE_YES_OR_NO],
        options: ['Yes', 'No'],
      }),
      maternity_coverage: new Prop({
        propName: 'maternity_coverage',
        title: 'If so, does it have maternity coverage?',
        type: [FIELD_TYPE_PICKER, FIELD_TYPE_YES_OR_NO],
        options: ['Yes', 'No'],
        required: false,
      }),
      through_agency: new Prop({
        propName: 'through_agency',
        title: 'Is your health insurance provided through a state agency or program?',
        type: [FIELD_TYPE_PICKER, FIELD_TYPE_YES_OR_NO],
        options: ['Yes', 'No'],
        required: false,
      }),
      insurance_company: new Prop({
        propName: 'insurance_company',
        title: 'Health insurance company',
        required: false,
      }),
      insurance_company_phone: new Prop({
        propName: 'insurance_company_phone', title: 'Phone Number', required: false,
      }),

      medical_insurance: new Prop({
        propName: 'medical_insurance',
        title: 'Do you have medical insurance?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      surrogacy_experience: new Prop({
        propName: 'surrogacy_experience',
        title: 'Do you have Surrogacy experience?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      carry_twins: new Prop({
        propName: 'carry_twins',
        title: 'Are you willing to carry twins?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      reduction_process: new Prop({
        propName: 'reduction_process',
        title: 'Are you willing to under the process of reduction?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      do_amniocentesis: new Prop({
        propName: 'do_amniocentesis',
        title: 'Are you willing to do amniocentesis?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      terminate_pregnancy: new Prop({
        propName: 'terminate_pregnancy',
        title: 'Are you willing to terminate the pregnancy?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      hepatitis_b_vaccine: new Prop({
        propName: 'hepatitis_b_vaccine',
        title: 'Have you had Hepatitis B vaccine?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      rho_injection: new Prop({
        propName: 'rho_injection',
        title: 'Are you willing to take Rho(D) injection?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      weight_changed: new Prop({
        propName: 'weight_changed',
        title: 'Has your weight changed significantly over the past five years except during pregnancy?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      smoke: new Prop({
        propName: 'smoke',
        title: 'Do you smoke?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      household_smoke: new Prop({
        propName: 'household_smoke',
        title: 'Does anyone in your household smoke?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      drink_alcohol: new Prop({
        propName: 'drink_alcohol',
        title: 'Do you drink alcohol beverages?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      tattoo: new Prop({
        propName: 'tattoo',
        title: 'Have you gotten a tattoo or any body piercing within the last year and a half?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      driver_license: new Prop({
        propName: 'driver_license',
        title: 'Do you have Driver\'s License?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      blood_transfusion: new Prop({
        propName: 'blood_transfusion',
        title: 'Had blood transfusion before?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      marrow_transplant: new Prop({
        propName: 'marrow_transplant',
        title: 'Had bone marrow transplant before?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      organ_transplant: new Prop({
        propName: 'organ_transplant',
        title: 'Had organ transplant before?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      psychological_counseling: new Prop({
        propName: 'psychological_counseling',
        title: 'Have you ever received psychological counseling (including marriage counseling)?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      mental_illness: new Prop({
        propName: 'mental_illness',
        title: 'Have you ever been hospitalized for mental illness?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      physical_abuse: new Prop({
        propName: 'physical_abuse',
        title: 'Have you suffered physical abuse?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      sexual_abuse: new Prop({
        propName: 'sexual_abuse',
        title: 'Have you suffered sexual abuse?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      tried_suicide: new Prop({
        propName: 'tried_suicide',
        title: 'Have you ever tried suicide or had suicidal thoughts?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      anxious: new Prop({
        propName: 'anxious',
        title: 'Do you feel anxious or depressed?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      currently_ill: new Prop({
        propName: 'currently_ill',
        title: 'Do you currently have any illnesses?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      currently_medication: new Prop({
        propName: 'currently_medication',
        title: 'Are you currently using any medication?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      hospitalized_other: new Prop({
        propName: 'hospitalized_other',
        title: 'Have you been hospitalized for any reason other than giving birth?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      birth_control: new Prop({
        propName: 'birth_control',
        title: 'Are you currently using any birth control?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      other_sexual_partners: new Prop({
        propName: 'other_sexual_partners',
        title: 'Do you currently have nay other sexual partners other than the husband/spouse/partner you had mention previously?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      arrested: new Prop({
        propName: 'arrested',
        title: 'Have you ever been arrested?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      lawsuit: new Prop({
        propName: 'lawsuit',
        title: 'Have you been involved in any lawsuit?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      illegal_drugs: new Prop({
        propName: 'illegal_drugs',
        title: 'Have you used any illegal drugs?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      bankruptcy: new Prop({
        propName: 'bankruptcy',
        title: 'Have you ever filed bankruptcy?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
    };
    this.syncData(user, appType);
  }
}

export default InsuranceInfoForm;
