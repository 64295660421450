import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { tify } from 'chinese-conv';
import { FIELD_TYPE_MULTIPLE_CHOICE, LANG_EN, LANG_ZH_TRADITIONAL } from '../../../../../../res/constants';
import { Styles } from './DiseasesHistory.css';
import SexualHistoryForm from '../../../../../SurrogateApplication/components/forms/SexualHistory/SexualHistoryForm';
import CheckBox from './CheckBox/CheckBox';

class DiseasesHistory extends React.Component {
  constructor(props) {
    super(props);
    const { surrogateMother = null } = props;
    const { lang } = this.props;
    this.state = {
      surrogateMother,
      form: new SexualHistoryForm({
        user: surrogateMother, component: this, lang, appType: 'surrogate_app',
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { surrogateMother = null } = nextProps;
      const { form } = this.state;
      form.syncData(surrogateMother, 'surrogate_app');
      this.setState({ surrogateMother, form }, () => {
        const { lang } = this.props;
        form.setContents(lang);
      });
    }
  }

  renderMultipleChoice = () => {
    const { form } = this.state;
    const { lang } = this.props;
    const {
      form_attributes: formAttrs,
    } = form;
    const others = Object.values(formAttrs)
      .filter(attr => attr.type === FIELD_TYPE_MULTIPLE_CHOICE);
    const rows = others.map((item, index) => {
      let backgroundColor = '#fefafc';
      let marginTop = 0;
      if (index % 2 === 0) {
        backgroundColor = '#ffffff';
      }
      if (lang !== LANG_EN && index === 6) {
        marginTop = 16;
      }
      return (
        <div
          style={{ ...Styles.othersRow, backgroundColor, marginTop }}
          key={item.propName}
        >
          <p style={{ ...Styles.fieldTitle, margin: '0', width: '910px' }}>
            {item.title}
          </p>
          <div style={Styles.answersWrapper}>
            <div style={{ ...Styles.fieldTitle, margin: '0' }}>
              <CheckBox
                text="You"
                textVisible={false}
                name={item.propName}
                selectable={false}
                checked={item.value.includes('You')}
              />
            </div>
            <div style={{ ...Styles.fieldTitle, margin: '0 0 0 70px' }}>
              <CheckBox
                text="Spouse"
                textVisible={false}
                name={item.propName}
                selectable={false}
                checked={item.value.includes('Spouse')}
              />
            </div>
            <div style={Styles.cell}>
              <div style={{ ...Styles.fieldTitle, margin: '0 20px 0 75px' }}>
                <CheckBox
                  text="Neither"
                  textVisible={false}
                  name={item.propName}
                  selectable={false}
                  checked={!item.value.includes('Spouse') && !item.value.includes('You')}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
    const gc = lang === LANG_EN ? 'GC' : '代母';
    const spouse = lang === LANG_EN ? 'Spouse' : '配偶';
    const neither = lang === LANG_EN ? 'Neither' : '都没有';
    return (
      <div style={Styles.othersContainer}>
        <div style={Styles.othersRow}>
          <p style={{ ...Styles.fieldTitle, margin: '0', width: '900px' }} />
          <div style={Styles.answersWrapper}>
            <div style={{ ...Styles.fieldTitle, margin: '0' }}>{lang === LANG_ZH_TRADITIONAL ? tify(gc) : gc}</div>
            <div style={{ ...Styles.fieldTitle, margin: '0 0 0 43px' }}>{lang === LANG_ZH_TRADITIONAL ? tify(spouse) : spouse}</div>
            <div style={{ ...Styles.fieldTitle, margin: '0 0 0 43px' }}>{lang === LANG_ZH_TRADITIONAL ? tify(neither) : neither}</div>
          </div>
        </div>
        {rows}
      </div>
    );
  };

  render() {
    const { lang } = this.props;
    const title = lang === LANG_EN ? 'Have GC or GC’s partner ever diagnosed any of the following diseases' : '代母或代母的伴侣是否曾经检测出以下疾病';
    return (
      <div style={Styles.container}>
        <h1 style={Styles.title}>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
        {this.renderMultipleChoice()}
        <div style={{ height: '50px' }} />
      </div>
    );
  }
}

DiseasesHistory.defaultProps = {
  surrogateMother: null,
};

DiseasesHistory.propTypes = {
  surrogateMother: PropTypes.shape({
    authentication_token: PropTypes.string,
  }),
  lang: PropTypes.string.isRequired,
};

export default Radium(DiseasesHistory);
