import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from './EggDonationPage.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import EggDonationProcess from './components/EggDonationProcess/EggDonationProcess';
import ChooseEggDonor from './components/ChooseEggDonor/ChooseEggDonor';
import FAQs from '../../components/FAQ/FAQs';
import IntroSection from '../../components/IntroSection/IntroSection';
import {
  EnKeyMaps, ZhKeyMaps, EggDonationFAQs, EggDonationFAQsZh,
} from './res/constants';
import { LANG_EN, PATH_DONORS_LIST, USER_STATUS_VIEW_DONOR_PROFILES } from '../../res/constants';
import ModalTypes from '../../components/Modals/ModalTypes';
import { showModal } from '../../redux/Modal/Modalactions';
import ExtraIntro from '../../components/ExtraIntro/ExtraIntro';

const mapStateToProps = state => ({
  langState: state.langState,
  user: state.userState,
});
const LangPacks = { ZhKeyMaps, EnKeyMaps };

class EggDonationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { langState } = this.props;
    this.setContents(langState.lang);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setContents(langState.lang);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions, false);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  setContents = (lang) => {
    this.setState({
      eggDonationFAQs: lang === LANG_EN ? EggDonationFAQs : EggDonationFAQsZh,
    });
  };

  onViewDonors = () => {
    const { user, dispatch, history } = this.props;
    if (user != null) {
      history.push(PATH_DONORS_LIST);
    } else {
      const data = {
        modalProps: {
          isOpen: true,
          props: {
            options: { status: USER_STATUS_VIEW_DONOR_PROFILES, pathAfterSignUp: PATH_DONORS_LIST },
          },
        },
        modalType: ModalTypes.SIGN_UP,
      };
      dispatch(showModal(data));
    }
  };

  render() {
    const { eggDonationFAQs } = this.state;
    const { langState } = this.props;
    const { lang } = langState;
    const requirementTitle = lang === LANG_EN ? EnKeyMaps.get('requirement_title') : ZhKeyMaps.get('requirement_title');
    const requirements = lang === LANG_EN ? EnKeyMaps.get('requirements') : ZhKeyMaps.get('requirements');
    const advantageTitle = lang === LANG_EN ? EnKeyMaps.get('advantage_title') : ZhKeyMaps.get('advantage_title');
    const advantages = lang === LANG_EN ? EnKeyMaps.get('advantages') : ZhKeyMaps.get('advantages');
    return (
      <div style={styles.container}>
        <Header />

        <IntroSection
          LangPacks={LangPacks}
          bg="https://res.cloudinary.com/hyyxofhbh/image/upload/c_scale,q_80,w_1600/v1557100363/NewCare/Assets/baby2.jpg"
          onBtnClicked={this.onViewDonors}
        />
        <ExtraIntro title={requirementTitle} details={requirements} styles={{ marginBottom: 0 }} />
        <ExtraIntro
          title={advantageTitle}
          details={advantages}
          images={[
            'https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,e_shadow:40,q_80,w_800/v1559753508/NewCare/Assets/IP-ED/shutterstock_490357867.jpg',
            'https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,e_shadow:40,q_80,w_800/v1559753505/NewCare/Assets/IP-ED/shutterstock_345101900.jpg',
            'https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,e_shadow:40,q_80,w_800/v1559753504/NewCare/Assets/IP-ED/shutterstock_128043524.jpg',
          ]}
        />
        <EggDonationProcess />
        <ChooseEggDonor />
        <FAQs faqs={eggDonationFAQs} />

        <Footer />
      </div>
    );
  }
}

EggDonationPage.defaultProps = {
  user: null,
};

EggDonationPage.propTypes = {
  user: PropTypes.shape(
    {
      display_name: PropTypes.string,
    },
  ),
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default withRouter(connect(mapStateToProps)(Radium(EggDonationPage)));
