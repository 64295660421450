import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { tify } from 'chinese-conv';
import Styles from './ShowYourself.module.scss';
import { formatFileSize } from '../../../../../Utils/Util';
import SelectFileBtn from './SelectFileBtn';
import ShowYourselfForm from './ShowYourselfForm';
import icClose from '../../../../../assets/close.png';
import { LANG_EN, LANG_ZH_TRADITIONAL } from '../../../../../res/constants';

const mapStateToProps = state => ({
  langState: state.langState,
});

export function SelectedFiles(props) {
  const { name = '', files, onDelete } = props;
  return files.map((file, index) => (
    <div className={Styles.selectedFiles} key={index}>
      <p className={Styles.fileName}>{`${file.name}(${formatFileSize(file.size)})`}</p>
      <img
        className={Styles.deleteIcon}
        alt=""
        src={icClose}
        onClick={() => {
          if (onDelete instanceof Function) {
            onDelete({ name, index });
          }
        }}
      />
    </div>
  ));
}

class ShowYourself extends React.Component {
  constructor(props) {
    super(props);
    const { form } = props;
    form.setComponent(this);
    this.state = {
      form,
      instructionEn: 'Video Instructions: \n'
          + '1. Do not say your name. Simply state your age, blood type, hobbies\n'
          + '2. At the end of the video, please finish it up with "I\'m really happy that I\'m able to help your family out. I hope your wishes come true."\n'
          + '3. Please only wear light makeup, and no colored eye contacts',
      instructionZh: '小视频秘诀: \n'
          + '1. 不要说出自己的名字。\n'
          + '2. 可以简单介绍自己的年龄，血型，平时喜欢做什麽，最后加一句我高兴帮能够帮到您们，希望您们梦想成真。\n'
          + '3. 小视频要素颜，不要带美瞳，可以涂淡口红，打一点粉底，盡量在小視頻中展示自己的五官。',
    };
  }

  onSelectFile = (event) => {
    const { form } = this.state;
    const { target } = event;
    const { name: propName } = target;
    const newSelectedFiles = Array.from(event.target.files);
    const { files } = form.photos_attributes[propName];
    form.photos_attributes[propName].files = files.concat(newSelectedFiles.map(file => ({
      localFile: file, saved: false, name: file.name, size: file.size,
    })));
    this.setState({ form });
  };

  onDeleteFile = (event) => {
    const { name: propName, index } = event;
    const { form } = this.state;
    if (Object.hasOwnProperty.call(form.photos_attributes, propName)) {
      form.photos_attributes[propName].files.splice(index, 1);
    }
    this.setState({ form });
  };

  renderFilesSelectors = () => {
    const { form } = this.state;
    const { langState } = this.props;
    const { lang } = langState;
    const attrs = Object.values(form.photos_attributes);
    return attrs.map((attr, index) => (
      <div key={attr.propName}>
        <p className={Styles.subtitle}>{attr.title}</p>
        <div className={Styles.selectBox}>
          <SelectFileBtn
            onChange={this.onSelectFile}
            name={attr.propName}
            fileType={attr.fileType}
            lang={lang}
          />
          <div style={{ width: '73px' }} />
        </div>
        <SelectedFiles name={attr.propName} files={attr.files} onDelete={this.onDeleteFile} />
        <div style={{ height: index === attrs.length - 1 ? '10px' : '50px' }} />
      </div>
    ));
  };

  render() {
    const { instructionEn, instructionZh } = this.state;
    const filesSelectors = this.renderFilesSelectors();
    const { langState } = this.props;
    const { lang } = langState;
    const title = lang === LANG_EN ? 'Show Yourself' : '展示自己';
    const instruction = lang === LANG_EN ? instructionEn : instructionZh;
    return (
      <div className={Styles.container}>
        <h1>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
        {filesSelectors}
        <p className={Styles.instruction}>{lang === LANG_ZH_TRADITIONAL ? tify(instruction) : instruction}</p>
      </div>
    );
  }
}

ShowYourself.propTypes = {
  form: PropTypes.instanceOf(ShowYourselfForm).isRequired,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(ShowYourself));
