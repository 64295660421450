export const Styles = {
  container: {
    display: '-webkit-box',
    WebkitFlexDirection: 'row',
    ':hover': {
      cursor: 'pointer',
    },
  },
  checkbox: {
    width: '18px',
    height: '18px',
    borderRadius: '4px',
    border: 'solid 1px #5a5f63',
    backgroundColor: '#ffffff',
    outline: 'none',
    display: 'flex',
  },
  checked: {
    backgroundColor: '#c3c8cc',
    border: 'none',
  },
  btnTxt: {
    fontFamily: 'Avenir-Roman',
    fontSize: '15px',
    color: '#52585f',
    margin: '0 0 0 10px',
    paddingTop: '2px',
  },
  icCheck: {
    width: '13px',
    height: '8px',
    marginLeft: '2.5px',
    marginTop: '5px',
  },
};
