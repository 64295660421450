export const Styles = {
  container: {
    width: '100%',
    borderRadius: '30px',
    margin: '0 0 150px 0',
    boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.04)',
    backgroundColor: '#ffffff',
    display: 'flex',
    WebkitFlexDirection: 'column',
    WebkitAlignItems: 'center',
  },
  title: {
    fontFamily: 'Avenir',
    fontSize: '40px',
    fontWeight: '500',
    textAlign: 'center',
    color: '#000000',
    margin: '40px 0 18px 0',
  },
  row: {
    width: '100%',
    padding: '25px 0 25px 0',
    display: '-webkit-box',
    WebkitFlexDirection: 'row',
    WebkitAlignItems: 'center',
  },
  attrCell: {
    width: '230px',
    padding: '0 40px 0 60px',
    boxSizing: 'border-box',
    fontFamily: 'Avenir',
    fontSize: '20px',
    fontWeight: '500',
    textAlign: 'justify',
    color: '#000000',
  },
  column: {
    // whiteSpace: 'pre-line',
    margin: '0 18px 0 0',
    padding: 0,
    minWidth: '180px',
    fontFamily: 'Avenir-Roman',
    fontSize: '20px',
    textAlign: 'center',
    color: '#000000',
    position: 'relative',
  },
  input: {
    // width: '140px',
    // height: '50px',
    borderRadius: '10px',
    border: 'none',
    backgroundColor: 'transparent',
    fontFamily: 'Avenir-Roman',
    fontSize: '16px',
    textAlign: 'center',
    color: '#000000',
    outline: 'none',
    // padding: '0 5px 0 5px',
    boxSizing: 'border-box',
    margin: 0,
  },
};
