import { getAxiosInstance } from '../../Utils/WebRequest';

export const PostsRoute = '/api/posts';

export function getStories() {
  return getAxiosInstance().get(PostsRoute);
}

export function getPostWithId(id) {
  if (id == null) { return Promise.reject(new Error('id is not provided')); }
  return getAxiosInstance().get(`${PostsRoute}/${id}`);
}
