export const PersonalInfoColumns = [
  {
    name: 'Donor ID',
    key: 'cus_donor_app_id',
  },
  {
    name: 'Status',
    key: 'avail_status',
  },
  {
    name: 'Birthday',
    key: 'date_of_birth',
  },
  {
    name: 'Blood Type',
    key: 'blood_type',
  },
  {
    name: 'Height',
    key: 'height',
    unit: 'cm',
  },
  {
    name: 'Weight',
    key: 'weight',
    unit: 'kg',
  },
  {
    name: 'Highest Educational Level',
    key: 'edu_level',
  },
  {
    name: 'School Name',
    key: 'school',
  },
  {
    name: 'Major',
    key: 'major',
  },
  {
    name: 'Current Occupation',
    key: 'occupation',
  },
  {
    name: 'Marital Status',
    key: 'marriage',
  },
  {
    name: 'Nationality',
    key: 'nationality',
  },
  {
    name: 'Ethnicity',
    key: 'ethnicity',
  },
  {
    name: 'Skin Color',
    key: 'skin_color',
  },
  {
    name: 'Hair Color',
    key: 'hair_color',
  },
  {
    name: 'Eye Color',
    key: 'eye_color',
  },
  {
    name: 'Eye Condition',
    key: 'eye_condition',
  },
  {
    name: 'Sex Orientation',
    key: 'sex_orientation',
  },
  {
    name: 'Personality',
    key: 'personality',
  },
  {
    name: 'Habit',
    key: 'habit',
  },
  {
    name: 'Talents',
    key: 'talents',
  },
  {
    name: 'Exercise Frequency',
    key: 'frequency',
    type: 'form_attrs',
  },
  {
    name: 'What kind of relationship would you like to have with the receiver family',
    key: 'relation',
    type: 'form_attrs',
  },
  {
    name: 'Are you willing to do an anonymous video phone call with the intended parents',
    key: 'anonymous_video',
    type: 'form_attrs',
  },
];

export const PersonalInfoColumnsZh = [
  {
    name: '捐卵天使ID',
    key: 'cus_donor_app_id',
  },
  {
    name: '状态',
    key: 'avail_status',
  },
  {
    name: '生日',
    key: 'date_of_birth',
  },
  {
    name: '血型',
    key: 'blood_type',
  },
  {
    name: '身高',
    key: 'height',
    unit: 'cm',
  },
  {
    name: '体重',
    key: 'weight',
    unit: 'kg',
  },
  {
    name: '最高教育水平',
    key: 'edu_level',
  },
  {
    name: '学校',
    key: 'school',
  },
  {
    name: '专业/科系',
    key: 'major',
  },
  {
    name: '职业',
    key: 'occupation',
  },
  {
    name: '婚姻状况',
    key: 'marriage',
  },
  {
    name: '国籍',
    key: 'nationality',
  },
  {
    name: '种族',
    key: 'ethnicity',
  },
  {
    name: '自然肤色',
    key: 'skin_color',
  },
  {
    name: '自然发色',
    key: 'hair_color',
  },
  {
    name: '眼睛颜色',
    key: 'eye_color',
  },
  {
    name: '眼睛状况',
    key: 'eye_condition',
  },
  {
    name: '性取向',
    key: 'sex_orientation',
  },
  {
    name: '性格',
    key: 'personality',
  },
  {
    name: '爱好',
    key: 'habit',
  },
  {
    name: '才能',
    key: 'talents',
  },
  {
    name: '运动频率',
    key: 'frequency',
    type: 'form_attrs',
  },
  {
    name: '希望和受卵家庭保持什么关系',
    key: 'relation',
    type: 'form_attrs',
  },
  {
    name: '是否愿意与准父母匿名视频',
    key: 'anonymous_video',
    type: 'form_attrs',
  },
];
