import {ERROR_TYPE_MISSED_FIELD} from "../../../res/constants";

class EventsManager {
  constructor(component) {
    this.component = component;
  }

  disabledButtons = () => {
    this.component.setState({ buttonsDisabled: true, saveBtnTxt: 'SAVING...' });
  };

  enableButtons =() => {
    this.component.setState({ buttonsDisabled: false, saveBtnTxt: 'SAVE' });
  };

  showAlert = (message = '', color = 'red') => {
    const { alert } = this.component.state;
    if (alert.timeout) { clearTimeout(alert.timeout); }
    alert.message = message;
    alert.visible = true;
    alert.color = color;
    this.component.setState({ alert }, () => {
      alert.timeout = setTimeout(() => {
        this.onDismissAlert();
      }, 3000);
    });
  };

  onDismissAlert = () => {
    const { alert } = this.component.state;
    alert.visible = false;
    if (alert.timeout) {
      clearTimeout(alert.timeout);
    }
    this.component.setState({ alert });
  };

  onSubmit = () => {
    this.disabledButtons();
    const { surrogateApplicationForm } = this.component.state;
    this.requestHandler(surrogateApplicationForm.submit())
      .then(() => {
        this.toNextForm();
        this.showAlert('Submitted successfully!', 'rgb(153, 216, 241)');
      })
      .catch(() => {});
  };

  onPrevious = () => {
    window.scrollTo(0, 0);
    const { formIndex, formCounts } = this.component.state;
    const newIndex = formIndex - 1;
    let nextBtnTxt = 'NEXT>>';
    if (newIndex === formCounts - 2) {
      nextBtnTxt = 'SUBMIT';
    }
    const progress = (newIndex / (formCounts - 1)) * 100;
    this.component.setState({ formIndex: newIndex, progress, nextBtnTxt });
  };

  onNext = () => {
    const { nextBtnTxt } = this.component.state;
    if (nextBtnTxt === 'SUBMIT') {
      this.onSubmit();
    } else {
      this.onSave()
        .then(() => {
          this.toNextForm();
        });
    }
  };

  onSave = () => {
    this.disabledButtons();
    const { surrogateApplicationForm, formIndex } = this.component.state;
    return this.requestHandler(surrogateApplicationForm.save(formIndex));
  };

  toNextForm = () => {
    const { formIndex, formCounts } = this.component.state;
    window.scrollTo(0, 0);
    const newIndex = formIndex + 1;
    const progress = (newIndex / (formCounts - 1)) * 100;
    let nextBtnTxt = 'NEXT>>';
    if (newIndex === formCounts - 2) {
      nextBtnTxt = 'SUBMIT';
    }
    let buttonsVisible = true;
    if (newIndex === formCounts - 1) {
      buttonsVisible = false;
    }
    this.component.setState({
      formIndex: newIndex, progress, nextBtnTxt, buttonsVisible,
    });
  };

  requestHandler = request => new Promise((resolve, reject) => {
    request
      .then((res) => {
        this.showAlert('Saved successfully!', 'rgb(153, 216, 241)');
        this.enableButtons();
        this.component.setState({ highlightRequiredField: false });
        resolve();
      })
      .catch((err) => {
        let errMsg = 'Failed saving data, please retry later.';
        if (err.message) {
          errMsg = err.message;
        }
        if (err.type) {
          switch (err.type) {
            case ERROR_TYPE_MISSED_FIELD:
              this.component.setState({ highlightRequiredField: true });
              break;
            default:
          }
        }
        this.showAlert(errMsg);
        this.enableButtons();
      });
  });
}

export default EventsManager;
