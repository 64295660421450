import React from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './HeaderMenu.css';
import { showModal } from '../../../../redux/Modal/Modalactions';
import ModalTypes from '../../../Modals/ModalTypes';
import DropDownMenu from './DropDownMenu';
import { deleteUser } from '../../../../redux/User/UserActions';
import { getAxiosInstance } from '../../../../Utils/WebRequest';
import {
  PATH_CLINICS,
  PATH_EGG_DONATION,
  PATH_EGG_FREEZING,
  PATH_FOR_EGG_DONOR,
  PATH_FOR_SURROGATE,
  PATH_IVF,
  PATH_SUCCESS_STORIES,
  PATH_SURROGACY,
} from '../../../../res/constants';
import './HeaderMenu.scss';
import { getValueWith } from '../../../../Utils/Util';
import { ZhKeyMaps, EnKeyMaps } from './res/constants';

const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});

const LangPacks = { EnKeyMaps, ZhKeyMaps };

class HeaderMenu extends React.Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      user,
      Menu: [],
      profileMenuLoggedOut: [],
      profileMenuLoggedIn: [{
        key: 'Logout',
        title: '',
        onClick: () => {},
      }],
    };
  }

  componentDidMount() {
    const { langState } = this.props;
    this.setContents(langState.lang);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { user, langState } = nextProps;
      this.setContents(langState.lang);
      this.setState({ user });
    }
  }

  setContents = (lang) => {
    this.setState({
      Menu: [
        {
          key: 'FutureParents',
          title: getValueWith({ key: 'FutureParents', lang, ...LangPacks }),
          menu: [
            {
              key: 'IVF',
              title: getValueWith({ key: 'IVF', lang, ...LangPacks }),
              linkTo: PATH_IVF,
            }, {
              key: 'EggFreezing',
              title: getValueWith({ key: 'EggFreezing', lang, ...LangPacks }),
              linkTo: PATH_EGG_FREEZING,
            }, {
              key: 'EggDonation',
              title: getValueWith({ key: 'EggDonation', lang, ...LangPacks }),
              linkTo: PATH_EGG_DONATION,
            }, {
              key: 'Surrogacy',
              title: getValueWith({ key: 'Surrogacy', lang, ...LangPacks }),
              linkTo: PATH_SURROGACY,
            },
          ],
        }, {
          key: 'EggDonor',
          title: getValueWith({ key: 'EggDonor', lang, ...LangPacks }),
          linkTo: PATH_FOR_EGG_DONOR,
        }, {
          key: 'Surrogate',
          title: getValueWith({ key: 'Surrogate', lang, ...LangPacks }),
          linkTo: PATH_FOR_SURROGATE,
        }, {
          key: 'Clinics',
          title: getValueWith({ key: 'Clinics', lang, ...LangPacks }),
          linkTo: PATH_CLINICS,
        }, {
          key: 'SuccessStories',
          title: getValueWith({ key: 'SuccessStories', lang, ...LangPacks }),
          linkTo: PATH_SUCCESS_STORIES,
        },
      ],
      profileMenuLoggedOut: [
        {
          key: 'SignUp',
          title: getValueWith({ key: 'SignUp', lang, ...LangPacks }),
          onClick: () => {
            const { dispatch } = this.props;
            const data = { modalProps: { isOpen: true }, modalType: ModalTypes.SIGN_UP };
            dispatch(showModal(data));
          },
        }, {
          key: 'Login',
          title: getValueWith({ key: 'Login', lang, ...LangPacks }),
          onClick: () => {
            const { dispatch } = this.props;
            const data = { modalProps: { isOpen: true }, modalType: ModalTypes.LOG_IN };
            dispatch(showModal(data));
          },
        },
      ],
      profileMenuLoggedIn: [
        {
          key: 'Logout',
          title: getValueWith({ key: 'Logout', lang, ...LangPacks }),
          onClick: () => {
            const { dispatch, redirectPathAfterLogout, history } = this.props;
            dispatch(deleteUser());
            getAxiosInstance().defaults.headers.common.Authorization = null;
            if (redirectPathAfterLogout != null) {
              history.push(redirectPathAfterLogout);
            }
          },
        },
      ],
    });
  };

  getMenuJsx = () => {
    const { Menu } = this.state;
    return Menu.map(item => <DropDownMenu item={item} key={item.key} />);
  };

  getProfileMenu = () => {
    const { user, profileMenuLoggedOut, profileMenuLoggedIn } = this.state;
    let items = [];
    const itemStyle = [styles.menuItem, { margin: '0 25px' }];
    if (user != null) {
      items.push(
        <Link to={`/profile/${user.id}`} style={{ textDecoration: 'none' }} key="Profile">
          <div key="Profile" style={styles.profileMenu.profile.container}>
            <img alt="avatar" style={styles.profileMenu.profile.avatar} src={user.avatar} />
          </div>
        </Link>,
      );
      items.push(
        <div
          role="presentation"
          style={itemStyle}
          key={profileMenuLoggedIn[0].key}
          onClick={profileMenuLoggedIn[0].onClick}
        >
          {profileMenuLoggedIn[0].title}
        </div>,
      );
    } else {
      items = profileMenuLoggedOut.map(item => (
        <div
          role="presentation"
          style={item.key === 'SignUp' ? styles.signUp : itemStyle}
          key={item.key}
          onClick={item.onClick}
        >
          {item.title}
        </div>
      ));
    }
    return (
      <div style={styles.profileMenu.container} className="header-login-show">
        {items}
      </div>
    );
  };

  render() {
    const menu = this.getMenuJsx();
    const profileMenu = this.getProfileMenu();
    return (
      <div style={styles.container} className="header-menu-container">
        {menu}
        {profileMenu}
      </div>
    );
  }
}

HeaderMenu.defaultProps = {
  dispatch: null,
  user: null,
  redirectPathAfterLogout: null,
};

HeaderMenu.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  redirectPathAfterLogout: PropTypes.string,
  user: PropTypes.shape(
    {
      display_name: PropTypes.string,
    },
  ),
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default withRouter(connect(mapStateToProps)(Radium(HeaderMenu)));
