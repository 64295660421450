import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { tify } from 'chinese-conv';
import { styles } from './FamilyInfo.css';
import {
  FamilyInfoCols,
  FamilyInfoColsZh,
  FamilyInfoRows, FamilyInfoRowsZh,
} from '../../../../../DonorApplication/components/forms/FamilyHistory/constants';
import FamilyHistoryForm from '../../../../../DonorApplication/components/forms/FamilyHistory/FamilyHistoryForm';
import { LANG_EN, LANG_ZH_TRADITIONAL } from '../../../../../../res/constants';
import { parseEyeColor, parseHairColor, parseHealthCondition } from '../../../../../../Utils/Util';

class FamilyInfo extends React.Component {
  constructor(props) {
    super(props);
    const { donor = null } = props;
    this.state = {
      donor,
      form: new FamilyHistoryForm({ user: donor, component: this }),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { donor = null, lang } = nextProps;
      const { form } = this.state;
      form.syncData(donor);
      form.setContents(lang);
      this.setState({ donor, form });
    }
  }

  renderColumns = () => {
    const { form } = this.state;
    const { lang } = this.props;
    const familyInfoCols = lang === LANG_EN ? FamilyInfoCols : FamilyInfoColsZh;
    const familyInfoRows = lang === LANG_EN ? FamilyInfoRows : FamilyInfoRowsZh;
    return familyInfoRows.map((row, index) => {
      let backgroundColor = '#fefafc';
      if (index % 2 === 0) {
        backgroundColor = '#ffffff';
      }
      const cols = familyInfoCols.map((col) => {
        let { value } = form.form_attributes[row.key][col.key];
        if (col.key === 'hair_color') {
          value = parseHairColor({
            lang,
            hairColor: value,
          });
        }
        if (col.key === 'eye_color') {
          value = parseEyeColor({
            lang,
            eyeColor: value,
          });
        }
        if (col.key === 'health') {
          value = parseHealthCondition({
            lang,
            healthCondition: value,
          });
        }
        return (
          <div key={col.key} style={[styles.column, { height: '50px', marginRight: col.key === 'edu_level' ? '118px' : '18px' }]}>
            <p style={{ visibility: 'hidden', margin: 0 }}>
              {lang === LANG_ZH_TRADITIONAL ? tify(col.name) : col.name}
            </p>
            <div style={styles.valueWrapper}>
              <p
                style={{ ...styles.valueWrapper.input, ...{ width: col.key === 'edu_level' ? '180px' : 'auto' } }}
              >
                {value}
              </p>
            </div>
          </div>
        );
      });
      return (
        <div key={row.key} style={[styles.row, { backgroundColor }]}>
          <div style={styles.attrCell}>
            {lang === LANG_ZH_TRADITIONAL ? tify(row.name) : row.name}
          </div>
          {cols}
        </div>
      );
    });
  };

  renderFirstRow = () => {
    const { lang } = this.props;
    const familyInfoCols = lang === LANG_EN ? FamilyInfoCols : FamilyInfoColsZh;
    const cols = familyInfoCols.map(col => (
      <div key={col.key} style={{ ...styles.column, ...{ marginRight: col.key === 'edu_level' ? '118px' : '18px' } }}>
        {lang === LANG_ZH_TRADITIONAL ? tify(col.name) : col.name}
      </div>
    ));
    return (
      <div style={styles.row}>
        <div style={styles.attrCell} />
        {cols}
      </div>
    );
  };

  render() {
    const columns = this.renderColumns();
    const firstRow = this.renderFirstRow();
    const { lang } = this.props;
    const title = lang === LANG_EN ? 'Family Information' : '家族历史';
    return (
      <div style={styles.container}>
        <h1 style={styles.container.title}>
          {lang === LANG_ZH_TRADITIONAL ? tify(title) : title}
        </h1>
        {firstRow}
        {columns}
        <div style={{ height: '50px' }} />
      </div>
    );
  }
}

FamilyInfo.defaultProps = {
  donor: null,
};

FamilyInfo.propTypes = {
  donor: PropTypes.shape({
    authentication_token: PropTypes.string,
  }),
  lang: PropTypes.string.isRequired,
};

export default Radium(FamilyInfo);
