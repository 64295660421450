import React from 'react';
import BasicInfoForm from './components/forms/BasicInformation/BasicInfoForm';
import ShowYourselfForm from './components/forms/ShowYourself/ShowYourselfForm';
import CompletionForm from './components/forms/Completion/CompletionForm';
import { fetchUserById, updateUserInfo } from '../../Api/Apis/Users';
import { setUser } from '../../redux/User/UserActions';
import MaritalStatusChildForm from './components/forms/MaritalChildStatus/MaritalStatusChildForm';
import GeneralQuestionsForm from './components/forms/GeneralQuestions/GeneralQuestionsForm';
import InsuranceInfoForm from './components/forms/InsuranceInfo/InsuranceInfoForm';
import SexualHistoryForm from './components/forms/SexualHistory/SexualHistoryForm';
import JobEducationForm from './components/forms/JobEducationInfo/JobEducationForm';
import HealthInfoForm from './components/forms/HealthInfo/HealthInfoForm';
import { createOrUpdateSurrogateApplication } from '../../Api/Apis/SurrogateApp';
import BasicInformation from './components/forms/BasicInformation/BasicInformation';
import MaritalChildStatus from './components/forms/MaritalChildStatus/MaritalChildStatus';
import GeneralQuestions from './components/forms/GeneralQuestions/GeneralQuestions';
import InsuranceInfo from './components/forms/InsuranceInfo/InsuranceInfo';
import HealthInfo from './components/forms/HealthInfo/HealthInfo';
import SexualHistory from './components/forms/SexualHistory/SexualHistory';
import JobEducationInfo from './components/forms/JobEducationInfo/JobEducationInfo';
import ShowYourself from './components/forms/ShowYourself/ShowYourself';
import Completion from './components/forms/Completion/Completion';
import Success from './components/forms/Success/Success';

class SurrogateApplication {
  constructor(user = null, component = null) {
    this.user = user;
    this.component = component;
    this.basicInfoForm = new BasicInfoForm(user);
    this.maritalChildStatus = new MaritalStatusChildForm({ user });
    this.generalQuestionsForm = new GeneralQuestionsForm(user);
    this.insuranceInfoForm = new InsuranceInfoForm({ user });
    this.healthInfoForm = new HealthInfoForm(user);
    this.sexualHistoryForm = new SexualHistoryForm({ user });
    this.jobEducationForm = new JobEducationForm(user);
    this.showYourselfForm = new ShowYourselfForm(user);
    this.completionForm = new CompletionForm(user);
    this.forms = [this.basicInfoForm, this.maritalChildStatus,
      this.generalQuestionsForm, this.insuranceInfoForm, this.healthInfoForm,
      this.sexualHistoryForm, this.jobEducationForm, this.showYourselfForm, this.completionForm];
    this.syncData(user);
  }

  syncData = (user = null) => {
    if (user != null) {
      this.syncAllFormData(user);
      fetchUserById(user.id)
        .then((res) => {
          const { dispatch } = this.component.props;
          const { data: userInfo } = res.data;
          dispatch(setUser(userInfo));
          this.syncAllFormData(userInfo);
        })
        .catch(() => {});
    }
  };

  syncAllFormData = (user) => {
    if (user != null) {
      this.forms.forEach(form => form.syncData(user, 'surrogate_app'));
    }
  };

  save = (formIndex) => {
    if (formIndex == null) { return Promise.reject(new Error('formIndex cannot be null')); }
    if (formIndex < 0 || formIndex > this.forms.length - 1) { return Promise.reject(new Error('index not valid')); }
    const currentForm = this.forms[formIndex];
    return currentForm.save()
      .then(() => {
        let userAttrs = {};
        this.forms.forEach((form) => {
          if (typeof form.getUserInfo === 'function') {
            userAttrs = Object.assign({}, userAttrs, form.getUserInfo());
          }
        });
        let formAttrs = {};
        this.forms.forEach((form) => {
          if (typeof form.getFormAttrs === 'function') {
            formAttrs = Object.assign({}, formAttrs, form.getFormAttrs());
          }
        });
        const completed = formIndex === this.forms.length - 1;
        return Promise.all([updateUserInfo(userAttrs),
          createOrUpdateSurrogateApplication(formAttrs, completed)])
          .then((res) => {
            const { data: updatedUserInfo } = res[0].data;
            const { data: updatedSurrogateApp } = res[1];
            updatedUserInfo.surrogate_app = updatedSurrogateApp;
            const { dispatch } = this.component.props;
            dispatch(setUser(updatedUserInfo));
            return res;
          });
      })
      .catch(err => Promise.reject(err));
  };

  submit = () => {
    const lastFormIndex = this.forms.length - 1;
    return this.save(lastFormIndex);
  };

  getForm = (formIndex) => {
    if (this.user == null) { return null; }
    if (this.isCompleted()) {
      return <Success />;
    }
    const { highlightRequiredField } = this.component.state;
    switch (formIndex) {
      case 0:
        return (
          <BasicInformation
            basicInfoForm={this.basicInfoForm}
            highlightRequiredField={highlightRequiredField}
          />
        );
      case 1:
        return (
          <MaritalChildStatus
            form={this.maritalChildStatus}
            highlightRequiredField={highlightRequiredField}
          />
        );
      case 2:
        return (
          <GeneralQuestions
            form={this.generalQuestionsForm}
            highlightRequiredField={highlightRequiredField}
          />
        );
      case 3:
        return (
          <InsuranceInfo
            form={this.insuranceInfoForm}
            highlightRequiredField={highlightRequiredField}
          />
        );
      case 4:
        return (
          <HealthInfo
            form={this.healthInfoForm}
            highlightRequiredField={highlightRequiredField}
          />
        );
      case 5:
        return <SexualHistory form={this.sexualHistoryForm} />;
      case 6:
        return (
          <JobEducationInfo
            form={this.jobEducationForm}
            highlightRequiredField={highlightRequiredField}
          />
        );
      case 7:
        return <ShowYourself showYourselfForm={this.showYourselfForm} />;
      case 8:
        return (
          <Completion
            completionForm={this.completionForm}
            highlightRequiredField={highlightRequiredField}
          />
        );
      case 9:
        return <Success />;
      default:
        return null;
    }
  };

  isCompleted = (user = this.user) => user != null && user.surrogate_app != null
      && user.surrogate_app.data != null && user.surrogate_app.data.completed;
}

export default SurrogateApplication;
