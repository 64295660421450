import React from 'react';
import Radium from 'radium';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { tify } from 'chinese-conv';
import Styles from './ChooseEggDonor.module.scss';
import { LANG_EN, LANG_ZH_TRADITIONAL, PATH_DONORS_LIST } from '../../../../res/constants';

const mapStateToProps = state => ({
  langState: state.langState,
  user: state.userState,
});

class ChooseEggDonor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [
        'https://res.cloudinary.com/hyyxofhbh/image/upload/v1554863403/NewCare/Assets/Testimonials/image4.jpg',
        'https://res.cloudinary.com/hyyxofhbh/image/upload/v1554863492/NewCare/Assets/Testimonials/180128_0003.jpg',
        'https://res.cloudinary.com/hyyxofhbh/image/upload/v1554863600/NewCare/Assets/Testimonials/10000_180119_0004.jpg',
        'https://res.cloudinary.com/hyyxofhbh/image/upload/v1554863679/NewCare/Assets/Testimonials/Leon_3.jpg',
      ],
    };
  }

  componentDidMount() {
    const { langState } = this.props;
    this.setContents(langState.lang);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setContents(langState.lang);
    }
  }

  setContents = (lang) => {
    this.setState({
      title: lang === LANG_EN ? 'Choose Your Egg Donor' : '选择捐卵天使',
      btnTxt: lang === LANG_EN ? 'View Egg Donor Profiles' : '浏览捐卵天使',
    });
  };

  render() {
    const { images, title = '', btnTxt = '' } = this.state;
    const { langState } = this.props;
    const { lang } = langState;
    return (
      <div className={Styles.container}>
        <div className={Styles.contents}>
          <h1 className={Styles.title}>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
          <div className={Styles.images}>
            <img className={Styles.image} alt="" style={{ marginRight: '45px' }} src={images[0]} />
            <img className={Styles.image} alt="" style={{ marginRight: '45px' }} src={images[1]} />
            <img className={Styles.image} alt="" style={{ marginRight: '45px' }} src={images[2]} />
            <img className={Styles.image} alt="" src={images[3]} />
          </div>
          <Link to={PATH_DONORS_LIST} style={{ textDecoration: 'none' }}>
            <div className={Styles.btn}>
              <p>{lang === LANG_ZH_TRADITIONAL ? tify(btnTxt) : btnTxt}</p>
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

ChooseEggDonor.propTypes = {
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(ChooseEggDonor));
