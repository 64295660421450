import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { tify } from 'chinese-conv';
import Styles from './FamilyHistory.module.scss';
import {
  FamilyInfoCols, FamilyInfoColsZh, FamilyInfoRows, FamilyInfoRowsZh,
} from './constants';
import FamilyHistoryForm from './FamilyHistoryForm';
import BaseForm from '../BasicInformation/forms/BaseForm';
import { LANG_EN, LANG_ZH_SIMPLIFIED } from '../../../../../res/constants';

const mapStateToProps = state => ({
  langState: state.langState,
});

class FamilyHistory extends React.Component {
  constructor(props) {
    super(props);
    const { form } = props;
    form.setComponent(this);
    this.state = {
      form,
    };
  }

  handleInputChange = (event) => {
    const { target, row, col } = event;
    const { name: propName, value } = target;
    const { form } = this.state;
    const attr = form.form_attributes[row.key][col.key];
    if (attr.propName === propName) {
      attr.value = value;
    }
    this.setState({ form });
  };

  render() {
    const { form } = this.state;
    const { form_attributes: formAttrs } = form;
    const { langState } = this.props;
    const { lang = LANG_EN } = langState;
    const title = lang === LANG_EN ? 'Family History' : '家族历史';
    const subTitle = lang === LANG_EN ? '*If family member passed away, please note the age and reason of death.'
      : '*年龄及健康状况请务必填写。如已过世，请填写过世年龄及原因（自然过世，因病过世，意外过世）。';
    return (
      <div className={Styles.container}>
        <h1>{lang === LANG_ZH_SIMPLIFIED ? title : tify(title)}</h1>
        <p className={Styles.subTitle}>
          {lang === LANG_ZH_SIMPLIFIED ? subTitle : tify(subTitle)}
        </p>
        {BaseForm.renderFormTable({
          RowsAttrs: lang === LANG_EN ? FamilyInfoRows : FamilyInfoRowsZh,
          ColsAttrs: lang === LANG_EN ? FamilyInfoCols : FamilyInfoColsZh,
          formAttrs,
          inputHandler: this.handleInputChange,
          lang,
        })}
      </div>
    );
  }
}

FamilyHistory.propTypes = {
  form: PropTypes.instanceOf(FamilyHistoryForm).isRequired,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(FamilyHistory));
