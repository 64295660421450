import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Styles from './BecomeSurrogateMother.module.scss';
import { PATH_SURROGATE_APPLICATION } from '../../../../res/constants';
import { EnKeyMaps, ZhKeyMaps } from './res/constants';
import { getValueWith } from '../../../../Utils/Util';

const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});
const LangPacks = { EnKeyMaps, ZhKeyMaps };

class BecomeSurrogateMother extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { langState } = this.props;
    this.setContents(langState.lang);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setContents(langState.lang);
    }
  }

  setContents = (lang) => {
    this.setState({
      title: getValueWith({ key: 'title', lang, ...LangPacks }),
      intro: getValueWith({ key: 'intro', lang, ...LangPacks }),
      buttonTxt: getValueWith({ key: 'btn_txt', lang, ...LangPacks }),
    });
  };

  render() {
    const { title, intro, buttonTxt } = this.state;
    return (
      <div className={Styles.container}>
        <h1>{title}</h1>
        <p>{intro}</p>
        <Link to={PATH_SURROGATE_APPLICATION} style={{ textDecoration: 'none' }}>
          <div className={Styles.button}>
            <p>{buttonTxt}</p>
          </div>
        </Link>
      </div>
    );
  }
}

BecomeSurrogateMother.propTypes = {
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(BecomeSurrogateMother);
