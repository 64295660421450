const styles = {
  language: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px 5px 10px',
    boxSizing: 'border-box',
    ':hover': {
      cursor: 'pointer',
    },
    fontFamily: 'Avenir',
    fontSize: '15px',
    fontWeight: '500',
    color: '#ffffff',
  },
};

export default styles;
