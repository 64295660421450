import React from 'react';
import Radium from 'radium';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { tify } from 'chinese-conv';
import Styles from './SuccessStories.module.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { getStories } from '../../Api/Api';
import {
  LANG_EN, LANG_ZH_SIMPLIFIED, LANG_ZH_TRADITIONAL, PATH_POST,
} from '../../res/constants';
import { EnKeyMaps, ZhKeyMaps } from './res/constants';
import { getValueWith } from '../../Utils/Util';

const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});
const LangPacks = { EnKeyMaps, ZhKeyMaps };

class SuccessStories extends React.Component {
  constructor(props) {
    super(props);
    this.state = { stories: [], allStories: [] };
  }

  componentDidMount() {
    const { langState } = this.props;
    const { lang } = langState;
    this.setContents(lang);
    getStories()
      .then((res) => {
        const { data: stories = [] } = res.data.data;
        this.setState({ allStories: stories }, () => this.setContents(lang));
      })
      .catch((err) => {});
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setContents(langState.lang);
    }
  }

  setContents = (lang) => {
    const { allStories } = this.state;
    const filteredStoriesByLang = allStories.filter((story) => {
      if (lang === LANG_EN) {
        return story.lang === lang;
      }
      return story.lang === LANG_ZH_SIMPLIFIED || story.lang === LANG_ZH_TRADITIONAL;
    });
    this.setState({ title: getValueWith({ key: 'title', lang, ...LangPacks }), stories: filteredStoriesByLang });
  };

  onStoryClicked = (story) => {
    const { history } = this.props;
    history.push(`${PATH_POST}/${story.id}`);
  };

  render() {
    const { stories, title } = this.state;
    const { langState } = this.props;
    const { lang } = langState;
    const cards = stories.map(story => (
      <div
        role="presentation"
        className={Styles.storyCard}
        style={{ backgroundImage: `url(${story.cover_photo_url})` }}
        key={story.id}
        onClick={() => this.onStoryClicked(story)}
      >
        <div>
          <h1>{lang === LANG_ZH_TRADITIONAL ? tify(story.title) : story.title}</h1>
        </div>
      </div>
    ));
    return (
      <div className={Styles.container}>
        <Header allowTransparent={false} />
        <div className={Styles.contents}>
          <h1 className={Styles.pageTitle}>{title}</h1>
          <div className={Styles.storiesBoard}>
            {cards}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

SuccessStories.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default withRouter(connect(mapStateToProps)(Radium(SuccessStories)));
