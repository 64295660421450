import { getAxiosInstance } from '../../Utils/WebRequest';

export const SurrogateAppRoute = '/api/surrogate_apps';

export function createOrUpdateSurrogateApplication(formAttrs, completed = false) {
  const data = { form_attributes: formAttrs };
  if (completed) {
    data.completed = true;
  }
  return getAxiosInstance().post(SurrogateAppRoute, data);
}

// Note: server's page is 1 based but front-end is 0 based
export function fetchSurrogateMothers({ page, filterOptions = {} }) {
  if (page == null) { return Promise.reject(new Error('page is not provided.')); }
  return getAxiosInstance().get(SurrogateAppRoute, {
    params: { ...filterOptions, page: page + 1 },
  });
}
