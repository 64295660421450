import axios from 'axios';

export let axiosInstance;

function newAxiosInstance() {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });
  // default response handler
  instance.interceptors.response.use(response => response,
    error => Promise.reject(error));
  return instance;
}

export function getAxiosInstance(independentInstance = false) {
  if (independentInstance) {
    return newAxiosInstance();
  }
  if (!axiosInstance) {
    axiosInstance = newAxiosInstance();
  }
  return axiosInstance;
}
