import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sify, tify } from 'chinese-conv';
import Styles from '../FilterBar/FilterBar.module.scss';
import { Categories, CategoriesZh } from './constants';
import { LANG_EN, LANG_ZH_TRADITIONAL } from '../../../../res/constants';

const mapStateToProps = state => ({
  langState: state.langState,
});

class FilterBarSurrogate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    	filterOptions: {
        ethnicity: 'All Races',
        edu_level: 'All Education Levels',
        age: 'All Ages',
        surrogacy_experience: 'All',
        avail_status: 'All'
      },
      inputValue: ''
    };
  }

	onOptionClicked = ({ categoryKey = null, option = null }) => {
  	if (categoryKey && option) {
	    const { filterOptions } = this.state;
	    filterOptions[categoryKey] = option;
	    this.setState({ filterOptions });
	    const { onFilterOptionsChanged } = this.props;
	    if (onFilterOptionsChanged) {
	    	onFilterOptionsChanged(filterOptions);
	    }
	  }
  };
  
  handleInput = (e) => {
    const inputValue = e.target.value
    this.setState({ inputValue })
  }

  handleClick = () => {
    const { inputValue } = this.state
    if (!inputValue) return
    const { onFilterOptionsChanged } = this.props;
    onFilterOptionsChanged && onFilterOptionsChanged({surrogate_app_id: inputValue})
  }

	render() {
  	const { filterOptions } = this.state;
  	const { langState } = this.props;
  	const { lang } = langState;
  	const categories = lang === LANG_EN ? Categories : CategoriesZh;
  	const filterBarContents = categories.map(category => (
    <div className={Styles.category} key={category.key}>
      <span style={{ paddingLeft: 0 }}>
        <p style={{ paddingLeft: 0, fontWeight: 600 }}>
          {lang === LANG_ZH_TRADITIONAL ? tify(category.name) : sify(category.name)}
        </p>
      </span>
      <div className={Styles.filterOptions}>
        {category.options.map((option) => {
        	const label = typeof option === 'object' ? option.label : option;
        	const value = typeof option === 'object' ? option.value : option;
        	return (
          <span
            key={label}
            onClick={() => this.onOptionClicked({ categoryKey: category.key, option: value })}
          >
            <p className={filterOptions[category.key] === value ? Styles.selected : Styles.unselected}>
              {lang === LANG_ZH_TRADITIONAL ? tify(label) : sify(label)}
            </p>
          </span>
          );
        })}
      </div>
    </div>
	  ));
    const btnStyle = {
      color: '#fff',
      backgroundColor: '#1890ff',
      borderColor: '#1890ff',
      border: '1px solid transparent',
      padding: '0 15px',
      borderRadius: 4,
      fontSize: 14,
    }
    const inputStyle = {
      marginRight: 20,
      border: '1px solid #d9d9d9',
      color: 'rgba(0,0,0,0.65)',
      padding: '4px 11px',
      borderRadius: 4,
      fontSize: 14
    }
  	return (
    <div className={Styles.container}>
      {filterBarContents}
      <div className={Styles.category}>
        <span style={{ paddingLeft: 0, paddingRight: 20 }}>
          <p style={{ paddingLeft: 0, fontWeight: 600, marginBottom: 0 }}>
          Surrogate ID
          </p>
        </span>
        <div className={Styles.filterOptions}>
          <input onInput={this.handleInput} style={inputStyle}/>
          <button onClick={this.handleClick} style={btnStyle}>Search</button>
        </div>
      </div>
    </div>
	  );
	}
}

FilterBarSurrogate.defaultProps = {
  onFilterOptionsChanged: null,
};

FilterBarSurrogate.propTypes = {
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
  onFilterOptionsChanged: PropTypes.func,
};

export default connect(mapStateToProps)(FilterBarSurrogate);
