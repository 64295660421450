export const styles = {
  requestViewMoreBtn: {
    padding: '14px 66px',
    borderRadius: '10px',
    width: '350px',
    height: 'auto',
    margin: '50px auto',
    transition: '0.3s',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: '#7dceef',
    },
    p: {
      fontFamily: 'AvenirNext',
      fontSize: '22px',
      fontWeight: '600',
    },
  },
};
