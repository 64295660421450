export const EnKeyMaps = new Map([
  ['title', 'Donor Profiles'],
  ['btnTxt', 'Request to View More'],
  ['btnWaitingTxt', 'Please wait...'],
  ['errMsg', {
    title: 'Oops', message: 'Something went wrong, please retry later.',
  }],
  ['successMsg', {
    title: 'Success', message: 'Your request has been successfully submitted. Our stuff will contact you within 48 hours.',
  }],
]);

export const ZhKeyMaps = new Map([
  ['title', '捐卵天使档案'],
  ['btnTxt', '申请浏览更多'],
  ['btnWaitingTxt', '请稍等...'],
  ['errMsg', {
    title: '错误', message: '出错啦，请稍后再试',
  }],
  ['successMsg', {
    title: '申请成功', message: '您的请求已经成功发送，我们会尽快在48小时之内与您联系。',
  }],
]);
