import {
  FIELD_TYPE_MULTIPLE_CHOICE,
  FIELD_TYPE_PICKER,
  FIELD_TYPE_TEXTAREA, FIELD_TYPE_YES_OR_NO, LANG_EN,
} from '../../../../../../res/constants';
import BaseForm from './BaseForm';
import { Prop, retrieveKeyValuesFrom } from './Utils';
import { EthnicityOptions } from '../../../../../SurrogateApplication/components/forms/BasicInformation/BasicInfoForm';

const NationalityOptions = ['Taiwan/China/Other Asian country', 'United States', 'Other countries'];
const SkinColorOptions = ['Pale', 'Fair', 'Bronze', 'Dark Brown', 'Others'];
const HairColorOptions = ['Black', 'Dark Brown', 'Brown', 'Light Brown', 'Blond', 'Dirty Blond', 'Red', 'Others'];
const EyeColorOptions = ['Black', 'Dark Brown', 'Brown', 'Blue', 'Green', 'Others'];
const SexOrientationOptions = ['Heterosexual', 'Homosexual', 'Bisexual'];
const BasicInfoFormContentsEn = new Map([
  ['last_name', {
    title: 'Last Name',
  }],
  ['first_name', {
    title: 'First Name',
  }],
  ['date_of_birth', {
    title: 'Date of birth',
  }],
  ['height', {
    title: 'Height (cm)',
  }],
  ['weight', {
    title: 'Weight (kg)',
  }],
  ['blood_type', {
    title: 'Blood Type',
    options: [{
      name: 'A',
      value: 'A',
    },
    {
      name: 'B',
      value: 'B',
    },
    {
      name: 'AB',
      value: 'AB',
    },
    {
      name: 'O',
      value: 'O',
    },
    {
      name: 'Don\'t know',
      value: 'Don\'t know',
    }],
  }],
  [
    'rh_factor', {
      title: 'Rh Factor',
      options: [{
        name: '+',
        value: '+',
      }, {
        name: '-',
        value: '-',
      },
      {
        name: 'Don\'t know',
        value: 'Don\'t know',
      }],
    },
  ],
  ['street_address', {
    title: 'street address',
  }],
  ['city', {
    title: 'City',
  }],
  ['state', {
    title: 'State',
  }],
  ['zipcode', {
    title: 'Zip Code',
  }],
  ['country', {
    title: 'Country',
  }],
  ['phone', {
    title: 'Phone Number',
  }],
  ['wechat', {
    title: 'WeChat',
  }],
  ['edu_level', {
    title: 'Educational Level',
    options: ['High School Degree', 'Vocational Degree', 'Associated Degree', 'Bachelors Degree', 'Masters Degree', 'Doctoral Degree', 'Post-Doctoral Degree', 'Professional Certification'],
  }],
  ['school', {
    title: 'School Name',
  }],
  ['major', {
    title: 'Major',
  }],
  ['occupation', {
    title: 'Occupation',
  }],
  ['marriage', {
    title: 'Marital Status',
    options: ['Single', 'Married', 'Divorced', 'Widowed'],
  }],
  ['ethnicity', {
    title: 'Ethnicity',
    options: EthnicityOptions,
  }],
  ['ethnicity_others', {
    title: 'If others, please specify',
  }],
  ['nationality', {
    title: 'Nationality',
    options: NationalityOptions,
  }],
  ['nationality_others', {
    title: 'If others, please specify',
  }],
  ['skin_color', {
    title: 'Skin Color',
    options: SkinColorOptions,
  }],
  ['skin_color_others', {
    title: 'If others, please specify',
  }],
  ['hair_color', {
    title: 'Hair Color',
    options: HairColorOptions,
  }],
  ['hair_color_others', {
    title: 'If others, please specify',
  }],
  ['eye_color', {
    title: 'Eye Color',
    options: EyeColorOptions,
  }],
  ['eye_color_others', {
    title: 'If others, please specify',
  }],
  ['eye_condition', {
    title: 'Eyes Condition',
    options: ['Very good', 'Myopia - Nearsighted', 'Hypermetropia - Farsighted', 'Amblyopia - Weaksighted', 'Astigmatism'],
  }],
  ['sex_orientation', {
    title: 'Sex Orientation',
    options: SexOrientationOptions,
  }],
  ['habit', {
    title: 'Habit',
  }],
  ['talents', {
    title: 'Talents',
  }],
  ['countries', {
    title: 'Countries you have been/planning to go within a year.',
  }],
  ['country_to_go', {
    title: 'Which countries are you willing to go for the egg donation?',
    options: ['United States 美国', 'Russia 俄罗斯', 'Japan 日本', 'Malaysia 马来西亚', 'Thailand 泰国'],
  }],
  ['compen', {
    title: 'Desired Compensation (USD)',
  }],
  ['frequency', {
    title: 'Exercise Frequency (times/wk)',
  }],
  ['source', {
    title: 'How do you know NewCare? (if friends, please give us the name) ',
  }],
  ['relation', {
    title: 'What kind of relationship would you like to have with the receiver family?',
    options: ['No relationship', 'Can be in contact', 'Always be in contact'],
  }],
  ['anonymous_video', {
    title: 'Are you willing to do an anonymous video phone call with the intended parents?',
    options: ['Yes', 'No'],
  }],
  ['drive', {
    title: 'Do you have Driver\'s License and know how to driver?',
  }],
  ['support', {
    title: 'Does your family support you to become an egg donor?',
  }],
  ['evaluation', {
    title: 'Are you willing to get a psychological evaluation?',
  }],
  ['injection', {
    title: 'Are you willing to take injection medication?',
  }],
  ['attend', {
    title: 'Are you willing to attend?',
  }],
  ['more_info', {
    title: 'Anything you would like us to know about yourself?',
  }],
]);

const BasicInfoFormContentsZh = new Map([
  ['last_name', {
    title: '姓氏',
  }],
  ['first_name', {
    title: '名字',
  }],
  ['date_of_birth', {
    title: '出生日期',
  }],
  ['height', {
    title: '身高 (cm)',
  }],
  ['weight', {
    title: '体重 (kg)',
  }],
  ['blood_type', {
    title: '血型',
    options: [{
      name: 'A',
      value: 'A',
    },
    {
      name: 'B',
      value: 'B',
    },
    {
      name: 'AB',
      value: 'AB',
    },
    {
      name: 'O',
      value: 'O',
    },
    {
      name: '不知道',
      value: 'Don\'t know',
    }],
  }],
  [
    'rh_factor', {
      title: '血型Rh',
      options: [{
        name: '+',
        value: '+',
      }, {
        name: '-',
        value: '-',
      },
      {
        name: '不知道',
        value: 'Don\'t know',
      }],
    },
  ],
  ['street_address', {
    title: '地址',
  }],
  ['city', {
    title: '城市',
  }],
  ['state', {
    title: '州/省',
  }],
  ['zipcode', {
    title: '邮递区号',
  }],
  ['country', {
    title: '国家',
  }],
  ['phone', {
    title: '电话',
  }],
  ['wechat', {
    title: '微信',
  }],
  ['edu_level', {
    title: '学历',
    options: [{ name: '高中/同等文凭', value: 'High School Degree' },
      { name: '职业学位', value: 'Vocational Degree' },
      { name: '大专', value: 'Associated Degree' },
      { name: '学士/本科', value: 'Bachelors Degree' },
      { name: '硕士', value: 'Masters Degree' },
      { name: '博士', value: 'Doctoral Degree' },
      { name: '博士后', value: 'Post-Doctoral Degree' },
      { name: '职业证书', value: 'Professional Certification' }],
  }],
  ['school', {
    title: '学校名称',
  }],
  ['major', {
    title: '专业/科系',
  }],
  ['occupation', {
    title: '职业',
  }],
  ['marriage', {
    title: '婚姻状况',
    options: [{ name: '单身', value: 'Single' },
      { name: '已婚', value: 'Married' },
      { name: '离婚', value: 'Divorced' },
      { name: '寡妇', value: 'Widowed' }],
  }],
  ['ethnicity', {
    title: '种族',
    options: EthnicityOptions,
    notUnsimplified: true
  }],
  ['ethnicity_others', {
    title: '如果选择了其他，请阐明',
  }],
  ['nationality', {
    title: '国籍',
    options: [{ value: 'Taiwan/China/Other Asian country', name: '台湾/中国/其他亚洲国家' },
      { value: 'United States', name: '美国' },
      { value: 'Other countries', name: '其他国家' }],
  }],
  ['nationality_others', {
    title: '如果选择了其他，请阐明',
  }],
  ['skin_color', {
    title: '肤色',
    options: [{ value: 'Pale', name: '白皙' }, { value: 'Fair', name: '健康' }, { value: 'Bronze', name: '古铜' },
      { value: 'Dark Brown', name: '深咖啡' }, { value: 'Others', name: '其他' }],
  }],
  ['skin_color_others', {
    title: '如果选择了其他，请阐明',
  }],
  ['hair_color', {
    title: '髮色',
    options: [{ value: 'Black', name: '黑色' }, { value: 'Dark Brown', name: '深咖啡色' }, { value: 'Brown', name: '咖啡色' },
      { value: 'Light Brown', name: '浅咖啡色' }, { value: 'Blond', name: '金色' }, { value: 'Dirty Blond', name: '深金色' },
      { value: 'Red', name: '红色' }, { value: 'Others', name: '其他' }],
  }],
  ['hair_color_others', {
    title: '如果选择了其他，请阐明',
  }],
  ['eye_color', {
    title: '瞳孔颜色',
    options: [{ value: 'Black', name: '黑色' }, { value: 'Dark Brown', name: '黑咖啡色' },
      { value: 'Brown', name: '咖啡色' }, { value: 'Blue', name: '蓝色' }, { value: 'Green', name: '绿色' },
      { value: 'Others', name: '其他' }],
  }],
  ['eye_color_others', {
    title: '如果选择了其他，请阐明',
  }],
  ['eye_condition', {
    title: '眼睛状况',
    options: ['很好', '近视', '远视', '弱视', '散光'],
  }],
  ['sex_orientation', {
    title: '性取向',
    options: [
      { value: 'Heterosexual', name: '异性恋' },
      { value: 'Homosexual', name: '同性恋' },
      { value: 'Bisexual', name: '双性恋' },
    ],
  }],
  ['personality', {
    title: '性格',
  }],
  ['habit', {
    title: '爱好',
  }],
  ['talents', {
    title: '才能',
  }],
  ['countries', {
    title: '近一年内去过（或将会去）的国家。',
  }],
  ['country_to_go', {
    title: '愿意去哪些国家捐卵？',
    options: ['United States 美国', 'Russia 俄罗斯', 'Japan 日本', 'Malaysia 马来西亚', 'Thailand 泰国'],
    notUnsimplified: true
  }],
  ['compen', {
    title: '期待报酬金额（美金）',
  }],
  ['frequency', {
    title: '运动频率 （每週几次）',
  }],
  ['source', {
    title: '您是如何知道NewCare的？(如果是朋友推荐，请留下其姓名)',
  }],
  ['relation', {
    title: '希望和受卵家庭保持什麽关係？',
    options: [{ name: '无关系', value: 'No relationship' },
      { name: '可以联络', value: 'Can be in contact' },
      { name: '一直保持联络', value: 'Always be in contact' }],
  }],
  ['anonymous_video', {
    title: '是否愿意与准父母匿名视频？',
    options: [{ value: 'Yes', name: '是' }, { value: 'No', name: '否' }],
  }],
  ['drive', {
    title: '是否有驾照且会开车？',
  }],
  ['support', {
    title: '您的家人是否支持您成为捐卵女生？',
  }],
  ['evaluation', {
    title: '您是否愿意完成心理评估测试?',
  }],
  ['injection', {
    title: '您是否愿意接受注射行药物？',
  }],
  ['attend', {
    title: '您同意出席6-8次左右当地诊所的检查和检测吗?',
  }],
  ['more_info', {
    title: '任何您想告诉我们有关您自己的事？',
  }],
]);

class BasicInfoForm extends BaseForm {
  constructor({ user = null, component = null, lang = LANG_EN }) {
    super({
      user,
      component,
      lang,
      ContentsZh: BasicInfoFormContentsZh,
      ContentsEn: BasicInfoFormContentsEn,
    });
    this.user_attributes = {
      last_name: new Prop({ propName: 'last_name', title: 'Last Name' }),
      first_name: new Prop({ propName: 'first_name', title: 'First Name' }),
      date_of_birth: new Prop({
        propName: 'date_of_birth',
        type: FIELD_TYPE_PICKER,
        title: 'Date of birth',
        inputType: 'date',
      }),
      height: new Prop({ propName: 'height', title: 'Height (cm)', inputType: 'number' }),
      weight: new Prop({ propName: 'weight', title: 'Weight (kg)', inputType: 'number' }),
      blood_type: new Prop({
        propName: 'blood_type',
        type: FIELD_TYPE_PICKER,
        title: 'Blood Type',
        options: [{
          name: 'A',
          value: 'A',
        },
        {
          name: 'B',
          value: 'B',
        },
        {
          name: 'AB',
          value: 'AB',
        },
        {
          name: 'O',
          value: 'O',
        },
        {
          name: 'Don\'t know',
          value: 'Don\'t know',
        }],
      }),
      rh_factor: new Prop({
        propName: 'rh_factor',
        title: 'Rh Factor',
        type: FIELD_TYPE_PICKER,
        options: [{
          name: '+',
          value: '+',
        }, {
          name: '-',
          value: '-',
        },
        {
          name: 'Don\'t know',
          value: 'Don\'t know',
        }],
      }),
      street_address: new Prop({ propName: 'street_address', title: 'Street Address' }),
      city: new Prop({ propName: 'city', title: 'City' }),
      state: new Prop({
        propName: 'state',
        type: FIELD_TYPE_PICKER,
        title: 'state',
        options: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin,', 'Wyoming', 'Others'],
      }),
      country: new Prop({ propName: 'country', title: 'Country' }),
      zipcode: new Prop({ propName: 'zipcode', title: 'Zip Code', inputType: 'number' }),
      phone: new Prop({ propName: 'phone', title: 'Phone Number', inputType: 'number' }),
      wechat: new Prop({ propName: 'wechat', title: 'WeChat', required: false }),
      line: new Prop({ propName: 'line', title: 'Line', required: false }),
      facebook: new Prop({ propName: 'facebook', title: 'Facebook', required: false }),
      edu_level: new Prop({
        propName: 'edu_level',
        title: 'Educational Level',
        type: FIELD_TYPE_PICKER,
        options: ['High School Degree', 'Vocational Degree', 'Associated Degree', 'Bachelors Degree', 'Masters Degree', 'Doctoral Degree', 'Post-Doctoral Degree', 'Professional Certification'],
      }),
      school: new Prop({ propName: 'school', title: 'School Name' }),
      major: new Prop({ propName: 'major', title: 'Major' }),
      occupation: new Prop({ propName: 'occupation', title: 'Occupation' }),
      marriage: new Prop({
        propName: 'marriage',
        title: 'Marital Status',
        type: FIELD_TYPE_PICKER,
        options: ['Single', 'Married', 'Divorced', 'Widowed'],
      }),
      ethnicity: new Prop({
        propName: 'ethnicity',
        title: 'Ethnicity',
        type: FIELD_TYPE_MULTIPLE_CHOICE,
        options: EthnicityOptions,
      }),
      ethnicity_others: new Prop({
        required: false,
        propName: 'ethnicity_others',
        title: 'If others, please specify',
      }),
      nationality: new Prop({
        propName: 'nationality', title: 'Nationality', type: FIELD_TYPE_PICKER, options: NationalityOptions,
      }),
      nationality_others: new Prop({ propName: 'nationality_others', title: 'If others, please specify', required: false }),
      skin_color: new Prop({
        propName: 'skin_color', title: 'Skin Color', type: FIELD_TYPE_PICKER, options: SkinColorOptions,
      }),
      skin_color_others: new Prop({ propName: 'skin_color_others', title: 'If others, please specify', required: false }),
      hair_color: new Prop({
        propName: 'hair_color', title: 'Hair Color', type: FIELD_TYPE_PICKER, options: HairColorOptions,
      }),
      hair_color_others: new Prop({ propName: 'hair_color_others', title: 'If others, please specify', required: false }),
      eye_color: new Prop({
        propName: 'eye_color', title: 'Eye Color', type: FIELD_TYPE_PICKER, options: EyeColorOptions,
      }),
      eye_color_others: new Prop({ propName: 'eye_color_others', title: 'If others, please specify', required: false }),
      eye_condition: new Prop({
        propName: 'eye_condition',
        title: 'Eyes Condition',
        type: FIELD_TYPE_MULTIPLE_CHOICE,
        ZhOptions: ['很好', '近视', '远视', '弱视', '散光'],
        EnOptions: ['Very good', 'Myopia - Nearsighted', 'Hypermetropia - Farsighted', 'Amblyopia - Weaksighted', 'Astigmatism'],
        options: ['Very good', 'Myopia - Nearsighted', 'Hypermetropia - Farsighted', 'Amblyopia - Weaksighted', 'Astigmatism'],
      }),
      sex_orientation: new Prop({
        propName: 'sex_orientation', title: 'Sex Orientation', type: FIELD_TYPE_PICKER, options: SexOrientationOptions,
      }),
      personality: new Prop({
        propName: 'personality', title: 'Personality', type: FIELD_TYPE_TEXTAREA, required: false,
      }),
      habit: new Prop({
        propName: 'habit', title: 'Habit', type: FIELD_TYPE_TEXTAREA, required: false,
      }),
      talents: new Prop({
        propName: 'talents', title: 'Talents', type: FIELD_TYPE_TEXTAREA, required: false,
      }),
    };
    this.form_attributes = {
      countries: new Prop({
        propName: 'countries', title: 'Countries you have been/planning to go within a year.', type: FIELD_TYPE_TEXTAREA, required: false,
      }),
      country_to_go: new Prop({
        propName: 'country_to_go',
        title: 'Which countries are you willing to go for the egg donation?',
        type: FIELD_TYPE_MULTIPLE_CHOICE,
        ZhOptions: ['United States 美国', 'Russia 俄罗斯', 'Japan 日本', 'Malaysia 马来西亚', 'Thailand 泰国'],
        EnOptions: ['United States 美国', 'Russia 俄罗斯', 'Japan 日本', 'Malaysia 马来西亚', 'Thailand 泰国'],
        options: ['United States 美国', 'Russia 俄罗斯', 'Japan 日本', 'Malaysia 马来西亚', 'Thailand 泰国'],
        required: false,
      }),
      compen: new Prop({
        propName: 'compen', title: 'Desired Compensation (USD)', required: false, type: FIELD_TYPE_TEXTAREA,
      }),
      frequency: new Prop({
        propName: 'frequency', title: 'Exercise Frequency (times/wk)', required: false, type: FIELD_TYPE_TEXTAREA,
      }),
      source: new Prop({
        propName: 'source', title: 'How do you know NewCare? (if friends, please give us the name)', required: false, type: FIELD_TYPE_TEXTAREA,
      }),
      relation: new Prop({
        propName: 'relation',
        title: 'What kind of relationship would you like to have with the receiver family?',
        type: FIELD_TYPE_PICKER,
        options: ['No relationship', 'Can be in contact', 'Always be in contact'],
      }),
      anonymous_video: new Prop({
        propName: 'anonymous_video',
        type: [FIELD_TYPE_PICKER, FIELD_TYPE_YES_OR_NO],
        title: 'Are you willing to do an anonymous video phone call with the intended parents?',
        options: ['Yes', 'No'],
      }),
      drive: new Prop({
        propName: 'drive',
        title: 'Do you have Driver\'s License and know how to driver?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      support: new Prop({
        propName: 'support',
        title: 'Does your family support you to become an egg donor?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      evaluation: new Prop({
        propName: 'evaluation',
        title: 'Are you willing to get a psychological evaluation?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      injection: new Prop({
        propName: 'injection',
        title: 'Are you willing to take injection medication?',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      attend: new Prop({
        propName: 'attend',
        title: 'Are you willing to attend',
        type: FIELD_TYPE_YES_OR_NO,
      }),
      more_info: new Prop({
        propName: 'more_info',
        title: 'Anything you would like us to know about yourself?',
        type: FIELD_TYPE_YES_OR_NO,
        required: false,
      }),
    };
    this.setContents(this.lang);
  }

  syncData = (user, app = this.appType) => {
    if (user == null) { return; }
    this.user = user;
    Object.keys(user).forEach((attr) => {
      if (Object.hasOwnProperty.call(this.user_attributes, attr)) {
        const userAttr = this.user_attributes[attr];
        userAttr.value = user[attr] != null ? user[attr] : '';
        if (userAttr.type instanceof Array && userAttr.type.includes(FIELD_TYPE_PICKER)
            && userAttr.type.includes(FIELD_TYPE_YES_OR_NO)) {
          if (typeof userAttr.value === 'number') {
            userAttr.value = userAttr.value === 1 ? this.yesTxt : this.noTxt;
          } else if (typeof userAttr.value === 'boolean') {
            userAttr.value = userAttr.value ? this.yesTxt : this.noTxt;
          }
        }
        if (attr === 'ethnicity') {
          if (!EthnicityOptions.some(option => userAttr.value.includes(option))) {
            this.user_attributes.ethnicity_others.value = userAttr.value;
            if (userAttr.value.trim().length > 0) {
              userAttr.value = 'Others 其他';
            }
          }
        }
        if (attr === 'nationality') {
          if (!NationalityOptions.includes(userAttr.value)) {
            this.user_attributes.nationality_others.value = userAttr.value;
            if (userAttr.value.trim().length > 0) {
              userAttr.value = 'Other countries';
            }
          }
        }
        if (attr === 'skin_color') {
          if (!SkinColorOptions.includes(userAttr.value)) {
            this.user_attributes.skin_color_others.value = userAttr.value;
            if (userAttr.value.trim().length > 0) {
              userAttr.value = 'Others';
            }
          }
        }
        if (attr === 'hair_color') {
          if (!HairColorOptions.includes(userAttr.value)) {
            this.user_attributes.hair_color_others.value = userAttr.value;
            if (userAttr.value.trim().length > 0) {
              userAttr.value = 'Others';
            }
          }
        }
        if (attr === 'eye_color') {
          if (!EyeColorOptions.includes(userAttr.value)) {
            this.user_attributes.eye_color_others.value = userAttr.value;
            if (userAttr.value.trim().length > 0) {
              userAttr.value = 'Others';
            }
          }
        }
        if (attr === 'eye_condition' && this.lang !== LANG_EN) {
          const selectedChoices = userAttr.value.split(',');
          const zhChoices = [];
          selectedChoices.forEach((choice) => {
            const choiceIndex = userAttr.EnOptions.indexOf(choice);
            if (choiceIndex > -1) {
              zhChoices.push(userAttr.ZhOptions[choiceIndex]);
            }
          });
          if (zhChoices.length > 0) {
            userAttr.value = zhChoices.reduce((accum, currentVal) => `${accum},${currentVal}`);
          }
        }
      }
    });
    if (user[app] == null) { return; }
    const { form_attributes: formAttrs } = user[app].data;
    if (formAttrs != null) {
      Object.keys(formAttrs).forEach((attr) => {
        if (Object.hasOwnProperty.call(this.form_attributes, attr)) {
          const formAttr = this.form_attributes[attr];
          formAttr.value = formAttrs[attr] != null ? formAttrs[attr] : '';
          if (formAttr.type instanceof Array && formAttr.type.includes(FIELD_TYPE_PICKER)
              && formAttr.type.includes(FIELD_TYPE_YES_OR_NO)) {
            if (typeof formAttr.value === 'number') {
              formAttr.value = formAttr.value === 1 ? 'Yes' : 'No';
            } else if (typeof formAttr.value === 'boolean') {
              formAttr.value = formAttr.value ? this.yesTxt : this.noTxt;
            }
          }
        }
        if (Object.hasOwnProperty.call(this.photos_attributes, attr)) {
          this.photos_attributes[attr].files = formAttrs[attr] || [];
          this.photos_attributes[attr].files = this.photos_attributes[attr].files.map(file => ({
            remoteFile: file, saved: true, name: file.file_name, size: file.size,
          }));
        }
      });
    }
    this.setContents(this.lang);
  };

  getUserInfo = () => {
    const userAttrs = retrieveKeyValuesFrom(this.user_attributes, this.lang);
    const { ethnicity, ethnicity_others: others } = this.user_attributes;
    const { nationality, nationality_others: nationalityOthers } = this.user_attributes;
    const { skin_color: skinColor, skin_color_others: skinColorOthers } = this.user_attributes;
    const { hair_color: hairColor, hair_color_others: hairColorOthers } = this.user_attributes;
    const { eye_color: eyeColor, eye_color_others: eyeColorOthers } = this.user_attributes;
    if (ethnicity.value.includes('Others')) {
      userAttrs.ethnicity = others.value;
    }
    if (nationality.value === 'Other countries') {
      userAttrs.nationality = nationalityOthers.value;
    }
    if (skinColor.value === 'Others') {
      userAttrs.skin_color = skinColorOthers.value;
    }
    if (hairColor.value === 'Others') {
      userAttrs.hair_color = hairColorOthers.value;
    }
    if (eyeColor.value === 'Others') {
      userAttrs.eye_color = eyeColorOthers.value;
    }
    return userAttrs;
  };
}

export default BasicInfoForm;
