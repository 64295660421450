import { ERROR_TYPE_MISSED_FIELD, FIELD_TYPE_PICKER, LANG_EN } from '../../../../../res/constants';
import { Prop } from '../BasicInformation/forms/Utils';
import { uploadPhoto } from '../../../../../Api/Apis/Photos';
import BaseForm from '../BasicInformation/forms/BaseForm';

class CompletionForm extends BaseForm {
  constructor({ user = null, component = null, lang = LANG_EN }) {
    super({ user, component, lang });
    this.attrs = {
      signature: {
        propName: 'signature',
        ref: null,
        base64: null,
        saved: false,
      },
      date: new Prop({
        propName: 'date',
        type: FIELD_TYPE_PICKER,
        title: 'Date',
        inputType: 'date',
      }),
      name: new Prop({ propName: 'name', title: 'Name' }),
    };
  }

  validateForm = () => {
    const { signature, date, name } = this.attrs;
    if (signature.base64 == null) {
      const noSignErr = this.lang === LANG_EN ? 'Please sign first.' : '请签名';
      return new Error(noSignErr);
    }
    if (date.value == null || date.value.trim().length === 0) {
      const noDateErr = this.lang === LANG_EN ? 'Please pick a date' : '请选择日期';
      return {
        message: noDateErr,
        type: ERROR_TYPE_MISSED_FIELD,
      };
    }
    if (name.value == null || name.value.trim().length === 0) {
      const noNameErr = this.lang === LANG_EN ? 'Please enter your name' : '请输入您的姓名';
      return {
        message: noNameErr,
        type: ERROR_TYPE_MISSED_FIELD,
      };
    }
    return null;
  };

  save = () => {
    if (this.user == null) { return Promise.reject(new Error('Login in first')); }
    const validationErrMsg = this.validateForm();
    if (validationErrMsg != null) { return Promise.reject(validationErrMsg); }
    const { signature } = this.attrs;
    if (signature.saved) {
      return Promise.resolve('Signature saved');
    }
    return fetch(signature.base64)
      .then(res => res.blob())
      .then((blob) => {
        const formData = new FormData();
        const file = new File([blob], 'signature.jpeg');
        formData.append('photo', file);
        formData.append('photo_type', signature.propName);
        formData.append('target_type', 'User');
        formData.append('target_id', this.user.id);
        return uploadPhoto(formData)
          .then((res) => {
            signature.saved = true;
            return res;
          })
          .catch(err => err);
      });
  };
}

export default CompletionForm;
