import ReactPaginate from 'react-paginate';
import React from 'react';
import PropTypes from 'prop-types';
import Styles from './PaginationBar.module.scss';

function PaginationBar(props) {
  const { pageCount, onPageChange } = props;
  return (
    <ReactPaginate
      containerClassName={Styles.container}
      pageCount={pageCount}
      pageRangeDisplayed={5}
      marginPagesDisplayed={2}
      previousLabel="<"
      nextLabel=">"
      breakLabel="..."
      initialPage={0}
      activeLinkClassName={Styles.active}
      previousClassName={Styles.previous}
      nextClassName={Styles.next}
      onPageChange={onPageChange}
    />
  );
}

PaginationBar.propTypes = {
  pageCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default PaginationBar;
