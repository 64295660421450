export const WifeRows = [
  {
    name: 'Job 1.',
    key: 'job_1',
  },
  {
    name: 'Job 2.',
    key: 'job_2',
  },
  {
    name: 'Job 3.',
    key: 'job_3',
  },
  {
    name: 'Job 4.',
    key: 'job_4',
  },
];

export const HusbandRows = [
  {
    name: 'Job 1.',
    key: 'husband_job_1',
  },
];


export const JobInfoCols = [
  {
    name: 'Company',
    key: 'company',
  },
  {
    name: 'Position',
    key: 'position',
  },
  {
    name: 'Starting & Ending Date',
    key: 'start_end_date',
  },
  {
    name: 'Ending Salary',
    key: 'ending_salary',
  },
];
