import { sify } from 'chinese-conv';
import {
  FIELD_TYPE_FILE,
  FIELD_TYPE_INPUT, FIELD_TYPE_MULTIPLE_CHOICE,
  FIELD_TYPE_PICKER,
  FIELD_TYPE_YES_OR_NO, LANG_EN,
} from '../../../../../../res/constants';

export class Prop {
  constructor(_options) {
    const {
      propName = '', value = '', required = true, type = FIELD_TYPE_INPUT,
      inputType = 'text', title = '', index = 0, files = [], filesNumLimit = [3, 5], fileType = '',
      options = [], placeholder = '', ZhOptions = null, EnOptions = null,
    } = _options;
    this.propName = propName;
    this.value = value;
    this.required = required;
    this.type = type;
    this.inputType = inputType;
    this.title = title;
    this.options = options;
    this.index = index;
    this.files = files;
    this.filesNumLimit = filesNumLimit;
    this.fileType = fileType;
    this.placeholder = placeholder;
    this.ZhOptions = ZhOptions;
    this.EnOptions = EnOptions;
  }
}

export function retrieveKeyValuesFrom(object, lang = LANG_EN) {
  const result = {};
  Object.values(object).forEach((attr) => {
    result[attr.propName] = attr.value;
    if (attr.type instanceof Array) {
      if (attr.type.includes(FIELD_TYPE_PICKER) && attr.type.includes(FIELD_TYPE_YES_OR_NO) && typeof attr.value === 'string') {
        const value = attr.value.toLowerCase();
        if (value === 'yes' || value === 'no') {
          result[attr.propName] = value === 'yes' ? 1 : 2;
        }
        if (attr.propName === 'us_citizen') {
          result[attr.propName] = value === 'yes';
        }
      }
    }
    if (attr.type === FIELD_TYPE_FILE) {
      const { files = [] } = attr;
      result[attr.propName] = files.map(file => file.remoteFile);
    }
    if (attr.type === FIELD_TYPE_MULTIPLE_CHOICE && lang !== LANG_EN) {
      if (attr.EnOptions && attr.ZhOptions) {
        const sifiedValue = sify(attr.value);
        const selectedChoices = sifiedValue.split(',');
        const enChoices = [];
        selectedChoices.forEach((choice) => {
          const choiceIndex = attr.ZhOptions.indexOf(choice);
          if (choiceIndex > -1) {
            enChoices.push(attr.EnOptions[choiceIndex]);
          }
        });
        if (enChoices.length > 0) {
          result[attr.propName] = enChoices.reduce((accum, currentVal) => `${accum},${currentVal}`);
        }
      }
    }
  });
  return result;
}
