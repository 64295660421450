import React, { Fragment } from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { tify } from 'chinese-conv';
import Styles from './ShowYourself.module.scss';
import { LANG_EN, LANG_ZH_TRADITIONAL } from '../../../../../res/constants';

function SelectFileBtn(props) {
  const {
    onChange, fileType = '', name = 'file', lang = LANG_EN,
  } = props;
  const id = `${name}_file`;
  const buttonTxt = lang === LANG_EN ? 'Select File' : ' 选择文件';
  return (
    <Fragment>
      <input type="file" name={name} id={id} onChange={onChange} className={Styles.inputFile} multiple accept={fileType} />
      <label className={Styles.selectFileBtn} htmlFor={id}>
        <p>{lang === LANG_ZH_TRADITIONAL ? tify(buttonTxt) : buttonTxt}</p>
      </label>
    </Fragment>
  );
}

SelectFileBtn.defaultProps = {
  onChange: () => {},
  fileType: '',
  name: 'file',
  lang: LANG_EN,
};

SelectFileBtn.propTypes = {
  onChange: PropTypes.func,
  fileType: PropTypes.string,
  name: PropTypes.string,
  lang: PropTypes.string,
};

export default Radium(SelectFileBtn);
