import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { tify } from 'chinese-conv';
import Styles from './FamilyInfo.module.scss';
import {
  FamilyInfoCols,
  FamilyInfoColsZh,
  FamilyInfoRows, FamilyInfoRowsZh,
} from '../../../../DonorApplication/components/forms/FamilyHistory/constants';
import FamilyHistoryForm from '../../../../DonorApplication/components/forms/FamilyHistory/FamilyHistoryForm';
import { LANG_EN, LANG_ZH_TRADITIONAL } from '../../../../../res/constants';
import { parseEyeColor, parseHairColor, parseHealthCondition } from '../../../../../Utils/Util';

const mapStateToProps = state => ({
  langState: state.langState,
});

class FamilyInfo extends React.Component {
  constructor(props) {
    super(props);
    const { donor = null } = props;
    this.state = {
      form: new FamilyHistoryForm({ user: donor, component: this }),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { donor = null, langState } = nextProps;
      const { form } = this.state;
      form.syncData(donor);
      form.setContents(langState.lang);
      this.setState({ donor, form });
    }
  }

  renderColumns = () => {
    const { form } = this.state;
    const { langState } = this.props;
    const { lang } = langState;
    const familyInfoCols = lang === LANG_EN ? FamilyInfoCols : FamilyInfoColsZh;
    const familyInfoRows = lang === LANG_EN ? FamilyInfoRows : FamilyInfoRowsZh;
    return familyInfoRows.map((row, index) => {
      let backgroundColor = '#fefafc';
      if (index % 2 === 0) {
        backgroundColor = '#ffffff';
      }
      const cols = familyInfoCols.map((col) => {
        let { value } = form.form_attributes[row.key][col.key];
        if (col.key === 'hair_color') {
          value = parseHairColor({
            lang,
            hairColor: value,
          });
        }
        if (col.key === 'eye_color') {
          value = parseEyeColor({
            lang,
            eyeColor: value,
          });
        }
        if (col.key === 'health') {
          value = parseHealthCondition({
            lang,
            healthCondition: value,
          });
        }

        return (
          <div key={col.key} className={Styles.column} style={{ height: '50px' }}>
            <p style={{ visibility: 'hidden', margin: 0 }}>
              {lang === LANG_ZH_TRADITIONAL ? tify(col.name) : col.name}
            </p>
            <div className={Styles.valueWrapper}>
              <textarea value={value} readOnly />
            </div>
          </div>
        );
      });
      return (
        <div className={Styles.row} key={row.key} style={[{ backgroundColor }]}>
          <div className={Styles.attrCell}>
            {lang === LANG_ZH_TRADITIONAL ? tify(row.name) : row.name}
          </div>
          {cols}
        </div>
      );
    });
  };

  renderFirstRow = () => {
    const { langState } = this.props;
    const { lang } = langState;
    const familyInfoCols = lang === LANG_EN ? FamilyInfoCols : FamilyInfoColsZh;
    const cols = familyInfoCols.map(col => (
      <div key={col.key} className={Styles.column}>
        {lang === LANG_ZH_TRADITIONAL ? tify(col.name) : col.name}
      </div>
    ));
    return (
      <div className={Styles.row}>
        <div className={Styles.attrCell} />
        {cols}
      </div>
    );
  };

  render() {
    const columns = this.renderColumns();
    const firstRow = this.renderFirstRow();
    const { langState } = this.props;
    const { lang } = langState;
    const title = lang === LANG_EN ? 'Family Information' : '家族历史';
    return (
      <div className={Styles.container}>
        <h1>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
        {firstRow}
        {columns}
        <div style={{ height: '50px' }} />
      </div>
    );
  }
}

FamilyInfo.defaultProps = {
  donor: null,
};

FamilyInfo.propTypes = {
  donor: PropTypes.shape({
    authentication_token: PropTypes.string,
  }),
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(FamilyInfo));
