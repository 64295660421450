import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import * as classNames from 'classnames';
import Styles from '../BasicInformation.module.scss';
import icDropDown from '../../../../../../assets/ic-dorp-down.png';
import { translate } from '../../../../../../Utils/Util';

const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});

function DropdownMenu(props) {
  let {
    margin = '0', options, value, onchange, name, prop, highlight,
  } = props;
  const { langState } = props;
  const { lang } = langState;
  if (prop) {
    options = prop.options;
    value = prop.value;
    name = prop.propName;
  }
  const optionsJsx = options.map(option => (typeof option === 'object' ? (
    <option
      value={option.value}
      key={option.name}
    >
      {translate({ lang, value: option.name })}
    </option>
  ) : (
    <option
      value={option}
      key={option}
    >
      {option}
    </option>
  )));
  return (
    <div
      className={classNames({
        [Styles.dropdownContainer]: true,
        [Styles.containerHighlight]: highlight,
      })}
      style={{ margin }}
    >
      <select value={value} onChange={event => onchange(event)} name={name}>
        <option value="" />
        {optionsJsx}
      </select>
      <img alt="" src={icDropDown} />
    </div>
  );
}

DropdownMenu.defaultProps = {
  width: '365px',
  margin: '0',
  options: [],
  value: '',
  onchange: () => {},
  name: '',
  prop: null,
  highlight: false,
};

DropdownMenu.propTypes = {
  width: PropTypes.string,
  margin: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string, PropTypes.number, PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })])),
  onchange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  prop: PropTypes.shape({
    value: PropTypes.string,
  }),
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
  highlight: PropTypes.bool,
};

export default connect(mapStateToProps)(DropdownMenu);
