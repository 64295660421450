import {
  FIELD_TYPE_PICKER,
  FIELD_TYPE_TEXTAREA, FIELD_TYPE_YES_OR_NO,
  FIELD_TYPE_MULTIPLE_CHOICE,
} from '../../../../../res/constants';
import {
  Prop, retrieveKeyValuesFrom,
} from '../../../../DonorApplication/components/forms/BasicInformation/forms/Utils';
import BaseForm from '../../../../DonorApplication/components/forms/BasicInformation/forms/BaseForm';

export const EthnicityOptions = ['Chinese / Taiwanese 中国人 / 台湾人', 'African American 黑人', 'Asian 亚洲人', 'Caucasian 白人', 'Hispanic / Latino 墨西哥人', 'Native American 印地安人', 'Others 其他'];
export const ReligiousOptions = ['No Religious 无信仰', 'Christian 基督教', 'Catholic 天主教', 'Others 其他'];

class BasicInfoForm extends BaseForm {
  constructor(user = null, component = null) {
    super({ user, component });
    this.user = user;
    this.user_attributes = {
      first_name: new Prop({ propName: 'first_name', title: 'First Name' }),
      middle_name: new Prop({ propName: 'middle_name', title: 'Middle Name', required: false }),
      maiden_name: new Prop({ propName: 'maiden_name', title: 'Maiden Name (if different)', required: false }),
      last_name: new Prop({ propName: 'last_name', title: 'Last Name' }),
      date_of_birth: new Prop({
        propName: 'date_of_birth',
        type: FIELD_TYPE_PICKER,
        title: 'Date of birth',
        inputType: 'date',
      }),
      height: new Prop({ propName: 'height', title: 'Height', inputType: 'number' }),
      weight: new Prop({ propName: 'weight', title: 'Weight', inputType: 'number' }),
      bmi: new Prop({ propName: 'bmi', title: 'BMI', inputType: 'number' }),
      blood_type: new Prop({
        propName: 'blood_type',
        type: FIELD_TYPE_PICKER,
        title: 'Blood Type',
        options: ['A', 'B', 'AB', 'O', 'Don’t know'],
      }),
      rh_factor: new Prop({
        propName: 'rh_factor',
        title: 'Rh Factor',
        type: FIELD_TYPE_PICKER,
        options: ['+', '-', 'Don\'t know'],
      }),
      street_address: new Prop({ propName: 'street_address', title: 'Address' }),
      city: new Prop({ propName: 'city', title: 'City' }),
      state: new Prop({
        propName: 'state',
        type: FIELD_TYPE_PICKER,
        title: 'state',
        options: ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin,', 'Wyoming', 'Others'],
      }),
      country: new Prop({ propName: 'country', title: 'Country' }),
      zipcode: new Prop({ propName: 'zipcode', title: 'Zip Code', inputType: 'number' }),
      phone: new Prop({ propName: 'phone', title: 'Phone', inputType: 'number' }),
      wechat: new Prop({ propName: 'wechat', title: 'WeChat', required: false }),
      line: new Prop({ propName: 'line', title: 'Line', required: false }),
      facebook: new Prop({ propName: 'facebook', title: 'Facebook', required: false }),
      instagram: new Prop({ propName: 'instagram', title: 'Instagram', required: false }),
      skype: new Prop({ propName: 'skype', title: 'Skype', required: false }),
      ethnicity: new Prop({
        propName: 'ethnicity',
        title: 'Ethnicity',
        type: FIELD_TYPE_MULTIPLE_CHOICE,
        options: EthnicityOptions,
      }),
      ethnicity_others: new Prop({ propName: 'ethnicity_others', title: 'If others, please specify', required: false }),
      religious: new Prop({
        propName: 'religious',
        title: 'Religious background',
        type: FIELD_TYPE_MULTIPLE_CHOICE,
        options: ReligiousOptions,
      }),
      religious_others: new Prop({ propName: 'religious_others', title: 'If others, please specify', required: false }),
      nationality: new Prop({ propName: 'nationality', title: 'Nationality' }),
      us_citizen: new Prop({
        propName: 'us_citizen',
        type: [FIELD_TYPE_PICKER, FIELD_TYPE_YES_OR_NO],
        title: 'U.S. citizen / permanent resident',
        options: ['Yes', 'No'],
      }),
    };
    this.form_attributes = {
      how_long_addr: new Prop({
        propName: 'how_long_addr', title: 'How long have you lived here?', required: false, placeholder: 'year', inputType: 'number',
      }),
      prior_addr: new Prop({
        propName: 'prior_addr', title: 'If less than 2 years, list prior addresses for the last 2 years:', required: false, type: FIELD_TYPE_TEXTAREA,
      }),
      prior_states: new Prop({
        propName: 'prior_states', title: 'Please list all the states you have lived in for the past 10 years:', required: false, type: FIELD_TYPE_TEXTAREA,
      }),
      source: new Prop({ required: false, propName: 'source', title: 'How do you know NewCare? (if friends, please give us the name) ' }),
    };
  }

  syncData = (user, app = this.appType) => {
    if (user == null) { return; }
    this.user = user;
    Object.keys(user).forEach((attr) => {
      if (Object.hasOwnProperty.call(this.user_attributes, attr)) {
        const userAttr = this.user_attributes[attr];
        userAttr.value = user[attr] != null ? user[attr] : '';
        if (userAttr.type instanceof Array && userAttr.type.includes(FIELD_TYPE_PICKER)
            && userAttr.type.includes(FIELD_TYPE_YES_OR_NO)) {
          if (typeof userAttr.value === 'number') {
            userAttr.value = userAttr.value === 1 ? this.yesTxt : this.noTxt;
          } else if (typeof userAttr.value === 'boolean') {
            userAttr.value = userAttr.value ? this.yesTxt : this.noTxt;
          }
        }
        if (attr === 'ethnicity') {
          if (!EthnicityOptions.some(option => userAttr.value.includes(option))) {
            this.user_attributes.ethnicity_others.value = userAttr.value;
            userAttr.value = 'Others 其他';
          }
        }
        if (attr === 'religious') {
          if (!ReligiousOptions.some(option => userAttr.value.includes(option))) {
            this.user_attributes.religious_others.value = userAttr.value;
            userAttr.value = 'Others 其他';
          }
        }
      }
    });
    if (user[app] == null) { return; }
    const { form_attributes: formAttrs } = user[app].data;
    if (formAttrs != null) {
      Object.keys(formAttrs).forEach((attr) => {
        if (Object.hasOwnProperty.call(this.form_attributes, attr)) {
          const formAttr = this.form_attributes[attr];
          formAttr.value = formAttrs[attr] != null ? formAttrs[attr] : '';
          if (formAttr.type instanceof Array && formAttr.type.includes(FIELD_TYPE_PICKER)
              && formAttr.type.includes(FIELD_TYPE_YES_OR_NO)) {
            if (typeof formAttr.value === 'number') {
              formAttr.value = formAttr.value === 1 ? this.yesTxt : this.noTxt;
            } else if (typeof formAttr.value === 'boolean') {
              formAttr.value = formAttr.value ? this.yesTxt : this.noTxt;
            }
          }
        }
        if (Object.hasOwnProperty.call(this.photos_attributes, attr)) {
          this.photos_attributes[attr].files = formAttrs[attr] || [];
          this.photos_attributes[attr].files = this.photos_attributes[attr].files.map(file => ({
            remoteFile: file, saved: true, name: file.file_name, size: file.size,
          }));
        }
      });
    }
    this.setContents(this.lang);
  };

  getUserInfo = () => {
    const userAttrs = retrieveKeyValuesFrom(this.user_attributes);
    const { ethnicity, ethnicity_others: others } = this.user_attributes;
    const { religious, religious_others: religiousOthers } = this.user_attributes;
    if (ethnicity.value.includes('Others')) {
      userAttrs.ethnicity = others.value;
    }
    if (religious.value.includes('Others')) {
      userAttrs.religious = religiousOthers.value;
    }
    return userAttrs;
  };
}

export default BasicInfoForm;
