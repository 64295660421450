import { Prop } from '../BasicInformation/forms/Utils';
import {
  FIELD_TYPE_FILE,
  PHOTO_TYPE_CHILD_PHOTOS,
  PHOTO_TYPE_FULL_PHOTOS, PHOTO_TYPE_ID_PHOTOS,
  PHOTO_TYPE_LIFE_PHOTOS, PHOTO_TYPE_VIDEO,
} from '../../../../../res/constants';
import BaseForm from '../BasicInformation/forms/BaseForm';

const ContentsEn = new Map([
  ['life_photos', {
    title: 'Please upload 3-10 daily life pictures. No heavy makeup, crazy outwear, or using filter.',
  }],
  ['full_photos', {
    title: 'Please upload 3-5 full body pictures.',
  }],
  ['child_photos', {
    title: 'Please upload 3-5 childhood pictures.',
  }],
  ['id_photos', {
    title: 'Please upload 2-3 ID pictures (passport, ID, Driver license, school ID, diploma, any licenses...).',
  }],
  ['video', {
    title: 'Please record a 15 -20 seconds short video.',
  }],
]);

const ContentsZh = new Map([
  ['life_photos', {
    title: '请上传3-10张生活照。不要妆容过重，夸张衣著，美颜过多的照片。',
  }],
  ['full_photos', {
    title: '请上传3-5张全身照。',
  }],
  ['child_photos', {
    title: '请上传3-5张小时候的照片。',
  }],
  ['id_photos', {
    title: '2-3张证件照（护照或身份证），如果您是本科或以上学历，请提供学位证书。',
  }],
  ['video', {
    title: '请提供15-20秒钟的小视频。',
  }],
]);

class ShowYourselfForm extends BaseForm {
  constructor({ user = null, component = null, lang = null }) {
    super({
      user, component, ContentsEn, ContentsZh, lang,
    });
    this.photos_attributes = {
      life_photos: new Prop({
        propName: PHOTO_TYPE_LIFE_PHOTOS,
        type: FIELD_TYPE_FILE,
        inputType: 'file',
        title: 'Please upload 3-10 daily life pictures. No heavy makeup, crazy outwear, or using filter.',
        filesNumLimit: [3, 10],
        fileType: 'image/*',
        required: false,
      }),
      full_photos: new Prop({
        propName: PHOTO_TYPE_FULL_PHOTOS,
        type: FIELD_TYPE_FILE,
        inputType: 'file',
        title: 'Please upload 3-5 full body pictures.',
        filesNumLimit: [3, 5],
        fileType: 'image/*',
        required: false,
      }),
      child_photos: new Prop({
        propName: PHOTO_TYPE_CHILD_PHOTOS,
        type: FIELD_TYPE_FILE,
        inputType: 'file',
        title: 'Please upload 3-5 childhood pictures.',
        filesNumLimit: [3, 5],
        fileType: 'image/*',
        required: false,
      }),
      id_photos: new Prop({
        propName: PHOTO_TYPE_ID_PHOTOS,
        type: FIELD_TYPE_FILE,
        inputType: 'file',
        title: 'Please upload 2-3 ID pictures (passport, ID, Driver license, school ID, diploma, any licenses...).',
        filesNumLimit: [2, 3],
        fileType: 'image/*',
        required: false,
      }),
      video: new Prop({
        propName: PHOTO_TYPE_VIDEO,
        type: FIELD_TYPE_FILE,
        inputType: 'file',
        title: 'Please record a 15 -20 seconds short video.',
        filesNumLimit: [1, 1],
        fileType: 'video/mp4,video/x-m4v,video/*',
        required: false,
      }),
    };
  }
}

export default ShowYourselfForm;
