import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import renderHTML from 'react-render-html';
import Styles from './ExtraIntro.module.scss';
import { translate } from '../../Utils/Util';

const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});

class ExtraIntro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      title = '', images = [], langState, styles, richText,
    } = this.props;
    let { details = '' } = this.props;
    const { lang } = langState;
    details = translate({ lang, value: details });
    details = details.replace('「', '“');
    details = details.replace('」', '”');
    return (
      <div className={Styles.container} style={styles}>
        {title.length > 0 ? (
          <div className={Styles.contents}>
            <h1 className={Styles.title}>{translate({ lang, value: title })}</h1>
            {richText ? renderHTML(details)
              : <p>{details}</p>}
          </div>
        ) : null}
        {images.length > 0 ? (
          <div className={Styles.images}>
            <img className={Styles.image} alt="" src={images[0]} />
            <img className={Styles.image} alt="" src={images[1]} />
            <img className={Styles.image} alt="" src={images[2]} />
          </div>
        ) : null}
      </div>
    );
  }
}

ExtraIntro.defaultProps = {
  title: '',
  details: '',
  images: [],
  styles: {},
  richText: false,
};

ExtraIntro.propTypes = {
  title: PropTypes.string,
  styles: PropTypes.shape({ margin: PropTypes.string }),
  details: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  richText: PropTypes.bool,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(ExtraIntro));
