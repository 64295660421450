
export default class DataManager {
  constructor(component) {
    this.component = component;
    this.initState();
  }

  initState = () => {
  }
}
