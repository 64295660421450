import React from 'react';
import Radium from 'radium';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import styles from './LanguageMenu.css';
import Styles from './LanguageMenu.module.scss';
import './language-menu-show.scss';
import { setLang } from '../../../../redux/Lang/LangActions';
import { LANG_EN, LANG_ZH_SIMPLIFIED, LANG_ZH_TRADITIONAL } from '../../../../res/constants';
import { mapLangToReadable, syncLang } from '../../../../Utils/Util';

const mapStateToProps = state => ({
  langState: state.langState,
});

class LanguageMenu extends React.Component {
  constructor(props) {
    super(props);
    const { langState = LANG_EN } = props;
    syncLang(props);
    this.state = {
      Languages: ['EN', '简', '繁'],
      selectedLanguage: mapLangToReadable(langState.lang),
    };
  }

  componentDidMount() {
    // on back or forward you have to check the lang query
    const { history } = this.props;
    if (history.action === 'POP') {
      const { location } = this.props;
      const params = queryString.parse(location.search);
      const { lang = LANG_EN } = params;
      this.onLanguageSelected(mapLangToReadable(lang));
      // if no lang query, add current lang to query
      if (params.lang == null) {
        syncLang(this.props);
      }
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const { langState } = this.props;
    if (nextProps.langState.lang !== langState.lang) {
      syncLang(nextProps);
    }
  };

  componentWillUnmount() {
    if (this.unbindBackListener != null) {
      this.unbindBackListener();
    }
  }

  onLanguageSelected = (selectedLanguage = 'EN') => {
    const { dispatch, langState } = this.props;
    let lang = LANG_EN;
    switch (selectedLanguage) {
      case '简':
        lang = LANG_ZH_SIMPLIFIED;
        break;
      case '繁':
        lang = LANG_ZH_TRADITIONAL;
        break;
      default:
        lang = LANG_EN;
    }
    const { lang: currentLang } = langState;
    if (lang !== currentLang) {
      dispatch(setLang(lang));
    }
    this.setState({ selectedLanguage });
  };

  getMenuJsx = () => {
    const { Languages, selectedLanguage } = this.state;
    const { onLanguageSelected } = this;
    return Languages.map((language, index) => {
      const langStyles = [styles.language];
      if (index < Languages.length - 1) {
        langStyles.push({ borderRight: 'solid 1.5px #ffffff' });
      }
      if (language === selectedLanguage) {
        langStyles.push({ color: '#e679b1', backgroundColor: '#ffffff' });
      }
      return (
        <div
          style={langStyles}
          key={language}
          role="presentation"
          onClick={() => onLanguageSelected(language)}
        >
          {language}
        </div>
      );
    });
  };

  render() {
    const menu = this.getMenuJsx();
    return (
      <div className={`${Styles.container} language-menu-show`}>
        {menu}
      </div>
    );
  }
}

LanguageMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default withRouter(connect(mapStateToProps)(Radium(LanguageMenu)));
