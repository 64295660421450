import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import styles from './Modal.css';
import { hideModal } from '../../redux/Modal/Modalactions';
import ModalTypes from './ModalTypes';
import SignUpCard from './SignUpCard/SignUpCard';
import LogInCard from './LoginCard/LogInCard';
import ForgetPasswordCard from './ForgetPasswordCard/ForgetPasswordCard';
import AlertCard from './AlertCard/AlertCard';

const mapStateToProps = state => ({
  ...state.modalState,
});

class ModalRoot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalProps: null,
      modalType: null,
      shouldCloseOnOverlayClick: true,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { modalProps, modalType } = nextProps;
      this.setState({ modalProps, modalType });
    }
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  getModalContent = (modalType) => {
    const { modalProps } = this.state;
    const { props = {} } = modalProps;
    switch (modalType) {
      case ModalTypes.SIGN_UP:
        return (<SignUpCard {...props} />);
      case ModalTypes.LOG_IN:
        return (<LogInCard />);
      case ModalTypes.FORGET_PASSWORD:
        return (<ForgetPasswordCard />);
      case ModalTypes.ALERT:
        return (<AlertCard {...props} />);
      default:
        return null;
    }
  };

  render() {
    const { modalType, modalProps } = this.state;
    const { dispatch } = this.props;
    if (!modalType) {
      return null;
    }
    const { shouldCloseOnOverlayClick } = this.state;
    const content = this.getModalContent(modalType);
    return (
      <ReactModal
        isOpen={modalProps.isOpen}
        style={styles.modalStyle}
        onRequestClose={() => dispatch(hideModal())}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        ariaHideApp={false}
      >
        {content}
      </ReactModal>
    );
  }
}

export default connect(mapStateToProps)(ModalRoot);
