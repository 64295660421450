export const LoginEnContents = {
  title: 'Welcome back!',
  dontHaveAccountTxt: "Don't have an account? ",
  signUpTxt: 'Sign up',
  emailInputPlaceholder: 'Email',
  passwordInputPlaceholder: 'Password',
  btnLoginTxt: 'Log In',
  loggingInTxt: 'Logging In',
  errorMessages: {
  	fillAllFields: 'Please fill all fields.',
    invalidPass: 'Error with your login or password.',
    unknownErr: 'Something went wrong. Please retry later.',
  },
};

export const LoginZhContents = {
  title: '欢迎回来!',
  dontHaveAccountTxt: '还没有账号?',
  signUpTxt: '新用户注册',
  emailInputPlaceholder: '邮箱',
  passwordInputPlaceholder: '密码',

  btnLoginTxt: '登录',
  loggingInTxt: '登录中...',
  errorMessages: {
    fillAllFields: '请输入您的邮箱及密码',
    invalidPass: '对不起，您的邮箱或密码不匹配',
    unknownErr: '出错啦，请稍后再试',
  },
};
