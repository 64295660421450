import React from 'react';
import Radium from 'radium';
import Styles from '../../../../DonorApplication/components/forms/Success/Success.module.scss';

class Success extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: 'Your form has been successfully submitted. Thank you for your time. Our surrogate coordination staff will contact you within 2 business days.',
    };
  }

  render() {
    const { message } = this.state;
    return (
      <div className={Styles.container}>
        <h1>Success</h1>
        <p>{message}</p>
      </div>
    );
  }
}

export default Radium(Success);
