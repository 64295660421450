export const SurrogateFAQs = [
  {
    Q: 'What does it take to be a Surrogate Mother in your program?',
    A: 'Becoming a Surrogate Mother is a big responsibility. Once you are officially matched with a couple or individual, the screening process will begin. There will be several appointments in the beginning that you will be responsible for attending. You will also be responsible for taking IVF medications and following an IVF protocol accordingly. You will be on IVF medications anywhere between 8-14 weeks depending on the IVF physician\'s protocol. This is why having an agency for guidance is important.',
  },
  {
    Q: 'How long does it take to be matched with a couple?',
    A: 'Once you turn in your completed application, consent forms and pictures, we can sit down with you and go over the entire process and answer any questions you may have. At this time, we are getting to know you so we can then get an idea on the right couple or individual to match you with. We want to be sure to do our best to find that right match for you, to ensure you are comfortable. It could take anywhere from 1-3 months to find the right couple or individual for you.',
  },
  {
    Q: 'Do I have the final say when an intended parents\' profile is presented to me?',
    A: 'Absolutely! We will never pressure you into working with a couple or individual you are not comfortable with. We want your first experience with surrogacy and your first experience with our company to be pleasurable and exciting! Intended parents also want their surrogate to be comfortable and happy working with them.\n\n'
				+ 'To simplify how our program works, is that once you are immediately accepted into our program, your profile becomes “Available”. We will then begin to present your profile to couples or individuals that we feel would meet each other\'s needs. Once we know future intended parents are interested in you as their surrogate, we will then send the intended parents profile directly to you. Once you receive their profile, this is a time to learn about the couple or individual. If you feel you want to move forward with meeting the couple or individual, we will then set up the match meeting. The match meeting is important because it\'s a time to get to know one another. You can share with them details about your family and your hobbies. It\'s also a great time for everyone to share with each other the expectations for contact during the journey.',
  },
  {
    Q: 'What happens after I am matched?',
    A: 'Once you agree to work with your couple or individual, the screening process will begin. The first step will be your medical screening. After that, you will undergo a psychological evaluation. Our team will do our best to move the screening process quickly, so be sure to keep all your necessary appointments. We know how excited everyone is to get started, we have been in your shoes, too! Once the screening process is completed, and the legal contracts between you and your intended parents are signed and notarized, then you and your future intended parents can attempt your first IVF cycle together. Your legal clearance will be sent to your IVF center. Then the IVF center will proceed with writing up an IVF cycle calendar for all parties.',
  },
  {
    Q: 'How does the surrogacy compensation work?',
    A: 'Once you sign your legal agreement with your future intended parent\'s or parent, you will be paid according to the legal agreement in monthly installments. This is usually divided into 10 months with the final remaining compensation paid after the birth.',
  },
  {
    Q: 'Does it cost me anything to be a Surrogate?',
    A: 'No, the surrogate mother is not responsible for any fees. The intended parents are responsible for paying all fees and costs, in association to the surrogacy arrangement. There are times where you may have to pay for certain costs up front such as prenatal vitamins and birth control pills, but this cost is always reimbursed.',
  },
  {
    Q: 'How much contact will I have with the intended parents?',
    A: 'This depends on many factors. You may work full time, or the intended parents may work full time. Everyone typically has busy schedules in life. We are very thankful for today\'s technology. You can video call on WeChat, facetime, text, and email to build the friendship. We feel through that through a typical journey, communication begins slow and then progresses as the intended parents start to feel comfortable and secure about their pregnancy. At this time, you may start to see them open up more. You may also start to open up more.  At NewCare Surrogacy LLC our goal is to support the friendship you have with your intended parents. We love to give your intended parents support and ideas on how to sustain the friendship and we will always be there for you to guide and support you through this new journey. So please, ask questions!',
  },
  {
    Q: 'Are there any health risks at a Surrogate Mother?',
    A: 'A surrogate pregnancy has the same health risks that a normal pregnancy will have. Part of the medical screening process is to make sure that you are healthy enough to carry another pregnancy.',
  },
  {
    Q: 'Do I have to take medications as a Surrogate Mother?',
    A: 'Yes, this is one of the most important responsibilities as a surrogate. You will be instructed by your IVF physician on what medications you will have to take. Every IVF clinic has a different protocol to prepare your body to accept the embryo or embryos. Some medications are injectable, some are not.',
  },
  {
    Q: 'What happens once I become pregnant as a Surrogate?',
    A: 'If you become pregnant after having your embryo transfer, you will continue your medications and instructions by the IVF clinic. During this time, you will want to contact your OB and schedule your first appointment. OB\'s usually book out a few weeks that is why it is good to call as soon as you know you are pregnant. You will still be going to your IVF physician until he feels it\'s necessary to release you from his care. You will also still be taking the medications until he feels it\'s safe to stop the medications. Please make sure that your OB is in your insurance network.  Once you attend your first appointment with your OB, you will continue to update your intended parents with the progress of the pregnancy. We hope at this time that you will start to adjust to the surrogate pregnancy and enjoy the journey ahead.',
  },
];

export const SurrogateFAQsZh = [
  {
    Q: '怎样才能成为代孕母亲?',
    A: '做代孕妈妈是一个很大的责任。一旦您与一对夫妇或个人正式配对，筛选过程就会开始。在开始的时候，您将负责参加几个约会。您还将负责服用试管婴儿药物，并遵循相应的试管婴儿协议。您将在8-14周之间的任何时间接受体外受精药物治疗，这取决于体外受精医生的建议。这就是为什么有一个（专业）指导机构是重要的。',
  },
  {
    Q: '配对一对需要多长时间?',
    A: '一旦您把填好的申请表、同意书和照片交上来，我们就可以和您坐下来讨论整个过程，并回答您可能提出的任何问题。如果您住在圣地亚哥县以外，我们可以通过skype或facetime与您联系。此时，我们也在加深对您的了解，这样我们就能找到一对合适的父母或个人来匹配您。我们希望尽我们最大的努力为您找到合适的合作父母和和个人，以确保您感到舒适。这可能需要1-3个月的时间才能找到适合您的合作父母或个人。我们在积极代孕中有句话，这不是关于快速配对，而是关于正确的配对!',
  },
  {
    Q: '我是否有最终决定权，何时向我提交一份意向父母简介?',
    A: '绝对!我们不会强迫您和一对不合拍的夫妇或个人一起工作。我们希望您的第一次代孕经验是愉快和令人兴奋的！准父母也希望代孕妈妈和他们一起合作时感到舒适和愉快。\n'
        + '简单描述我们的工作程序：当您的个人资料通过审核，您的资料将会被标注为“可用的”。然后，我们将开始向我们认为能够满足彼此需求的夫妇或个人展示您的个人资料。一旦我们知道未来的意向父母对您作为他们的代理感兴趣，我们将直接发送意向父母的个人资料给您。当您收到他们的资料，便会加深对这对对夫妇或个人的了解，如果您觉得您想和这对情侣或个人见面，我们会安排见面会。您可以和他们分享您的家庭情况和爱好细节，这个也很重要，因为这是一个面对面直接互相了解的机会，也能分享彼此的期望。',
  },
  {
    Q: '我被匹配后会发生什么?',
    A: '一旦您同意与您的伴侣或个人合作，筛选程序就会开始。第一步是心理筛查。第二步是体检，第三步是法律程序。我们的团队将尽我们最大的努力迅速推进筛选过程，所以一切预约都非常必要和重要。我们知道每个代孕妈妈/母亲都很兴奋，我们非常理解您!一旦筛选过程完成，您和您的预期父母之间的法律合同签署并公证，然后您和您未来的预期父母可以一起尝试您的第一个试管婴儿周期。您的合法许可将被发送到您的体外受精中心。然后，试管婴儿中心将为各方制定一个试管婴儿周期日历。',
  },
  {
    Q: '代孕补偿是如何运作的?',
    A: '一旦您和您即将合作的父母或个人签署了法律协议，您将收到按月分期付款。您有责任在每月15日前提交费用报销单，以便在法律合同保护下在每月1号收到您的支票。',
  },
  {
    Q: '我做代孕要花多少钱?',
    A: '代孕妈妈不负责任何费用。与代孕有关的所有费用都由准父母承担。',
  },
  {
    Q: '我将与未来的父母有多少接触?',
    A: '这取决于很多因素。您可以全职工作，或者您的合作父母也可能全职工作，每个人在生活中都有繁忙的日程安排。我们非常感谢今天的先进技术，您们可以通过skype、facetime、短信和电子邮件来建立友谊。我们觉得这个奇妙的旅程，也是准父母对代孕母亲帮助他们的孕产小孩感到舒适和安全的过程，准父母会变得更加开放自然，您也可以对他们敞开心扉。在积极代孕方面，我们的目标是支持您和您的合作父母之间的友谊。我们很乐意为您的合作父母提供支持，并就如何维系这份友谊提出建议，我们将永远在您身边指导和支持您度过这段新的旅程。所以，请提问!',
  },
  {
    Q: '代孕母亲是否有健康风险?',
    A: '代孕和正常妊娠有相同的健康风险',
  },
];


export const EnKeyMaps = new Map([
  ['title', 'Gestational Surrogacy'],
  ['intro', 'Gestational surrogacy is done by transferring a couple\'s IVF embryos into a surrogate mother.\n'
  + 'It can allow a woman without a uterus to have her own genetic children.'],
  ['btn_txt', 'Become a Surrogate'],
  ['extra_title', 'Why Choose NewCare?'],
  ['extra_intro', 'When you partner with NewCare, you can rest assured that we will take all of the necessary steps in order to guide you throughout this process, while upholding the dignity, respect, and confidentiality that you deserve. We understand that choosing to become a surrogate is a monumental commitment, which is why we guarantee that you will receive the highest level of care.'],
  ['process_title', 'Gestational Surrogacy Process'],
  ['process', [
    'Phone Consultation',
    'Fill Out Surrogate Application',
    'Video chat interview',
    'Newcare surrogacy package',
    'Medical records',
    'Profile available to intended parents',
    'Meeting with intended parents',
    'Match confirmation',
    'Medical screening and psychological evaluation',
    'Legal contract',
    'Injection medication',
    'Embryo transfer',
    'Pregnancy confirmation',
    'The pregnancy',
    'Birth - congratulations!!!',
  ]],
]);

export const ZhKeyMaps = new Map([
  ['title', '给代理孕母'],
  ['intro', '代孕是通过ＩＶＦ对代孕母亲植入试管婴儿胚胎来完成的。它可以让没有子宫的女性拥有自己的基因孩子。\n\n'
  ],
  ['btn_txt', '成为代孕天使'],
  ['process_title', '代孕流程'],
  ['process', [
    '填写代孕妈妈申请表',
    '电话咨询',
    '代孕妈妈同意书',
    '开始匹配',
    '与准父母视频聊天',
    '匹配的确认',
    '体检筛查和心理评估',
    '医疗许可',
    '法律许可',
    '胚胎移植',
    '怀孕的确认',
    '宝宝出生',
  ]],
]);
