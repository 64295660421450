import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Styles from './FAQs.module.scss';
import FAQ from './FAQ';
import './FAQ.scss';
import { EnKeyMaps, ZhKeyMaps } from './res/constants';
import { getValueWith } from '../../Utils/Util';

const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});
const LangPacks = { EnKeyMaps, ZhKeyMaps };

class FAQs extends React.Component {
  constructor(props) {
    super(props);
    const { faqs } = props;
    this.state = {
      questions: faqs,
    };
  }

  componentDidMount() {
    const { langState } = this.props;
    this.setContents(langState.lang);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState, faqs } = nextProps;
      this.setContents(langState.lang);
      this.setState({ questions: faqs });
    }
  }

  setContents = (lang) => {
    this.setState({
      title: getValueWith({ key: 'title', lang, ...LangPacks }),
    });
  };

  getFAQs = () => {
    const { questions } = this.state;
    const { langState } = this.props;
    return questions.map(question => <FAQ question={question} key={question.Q} lang={langState.lang} />);
  };

  render() {
    const { title } = this.state;
    const questions = this.getFAQs();
    return (
      <div className="fqa-container">
        <div className="fqa-wrap">
          <h1 className={Styles.title}>{title}</h1>
          {questions}
        </div>
      </div>
    );
  }
}

FAQs.defaultProps = {
  faqs: [],
};

FAQs.propTypes = {
  faqs: PropTypes.arrayOf(PropTypes.shape({
    Q: PropTypes.string,
    A: PropTypes.string,
  })),
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(FAQs));
