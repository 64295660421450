export const FamilyInfoRows = [
  {
    name: 'Father',
    key: 'father',
  },
  {
    name: 'Mother',
    key: 'mother',
  },
  {
    name: 'Paternal Grandfather',
    key: 'grandpa',
  },
  {
    name: 'Paternal Grandmother',
    key: 'grandma',
  },
  {
    name: 'Maternal Grandfather',
    key: 'mat_grandpa',
  },
  {
    name: 'Maternal Grandmother',
    key: 'mat_grandma',
  },
  {
    name: 'Sibling',
    key: 'sibling_1',
  },
  {
    name: 'Sibling',
    key: 'sibling_2',
  },
  {
    name: 'Sibling',
    key: 'sibling_3',
  },
  {
    name: 'Child',
    key: 'child',
  },
];

export const FamilyInfoCols = [
  {
    name: 'Age',
    key: 'age',
  },
  {
    name: 'Natural\n'
        + 'Hair Color',
    key: 'hair_color',
    options: [{ label: 'Black' }, { label: 'Dark Brown' }, { label: 'Brown' }, { label: 'Light Brown' }, { label: 'Blond' }, { label: 'Dirty Blond' }, { label: 'Red' }],
  },
  {
    name: 'Natural\n'
        + 'Eyes Color',
    key: 'eye_color',
    options: [
      { label: 'Black' },
      { label: 'Dark Brown' },
      { label: 'Brown' },
      { label: 'Blue' },
      { label: 'Green' },
    ],
  },
  {
    name: 'Height\n'
        + '(cm)',
    key: 'height',
  },
  {
    name: 'Weight\n'
        + '(kg)',
    key: 'weight',
  },
  {
    name: 'Occupation/\n'
        + 'Educational Level',
    key: 'edu_level',
  },
  {
    name: 'Health\n'
        + 'Condition',
    key: 'health',
    options: [
      { label: 'Good' },
      { label: 'Sick' },
      { label: 'Passed away' },
      { label: 'Passed away accidentally' },
    ],
  },
];

export const FamilyInfoRowsZh = [
  {
    name: '父亲',
    key: 'father',
  },
  {
    name: '母亲',
    key: 'mother',
  },
  {
    name: '祖父',
    key: 'grandpa',
  },
  {
    name: '祖母',
    key: 'grandma',
  },
  {
    name: '外祖父',
    key: 'mat_grandpa',
  },
  {
    name: '外祖母',
    key: 'mat_grandma',
  },
  {
    name: '兄弟姐妹',
    key: 'sibling_1',
  },
  {
    name: '兄弟姐妹',
    key: 'sibling_2',
  },
  {
    name: '兄弟姐妹',
    key: 'sibling_3',
  },
  {
    name: '孩子',
    key: 'child',
  },
];

export const FamilyInfoColsZh = [
  {
    name: '年龄',
    key: 'age',
  },
  {
    name: '发色',
    key: 'hair_color',
    options: [{ label: '黑色' }, { label: '深咖啡色' }, { label: '咖啡色' }, { label: '浅咖啡色' }, { label: '金色' }, { label: '深金色' }, { label: '红色' }],
  },
  {
    name: '瞳色',
    key: 'eye_color',
    options: [
      { label: '黑色' },
      { label: '深咖啡色' },
      { label: '咖啡色' },
      { label: '蓝色' },
      { label: '绿色' },
    ],
  },
  {
    name: '身高\n'
        + '(cm)',
    key: 'height',
  },
  {
    name: '体重\n'
        + '(kg)',
    key: 'weight',
  },
  {
    name: '职业/\n'
        + '学历',
    key: 'edu_level',
  },
  {
    name: '健康状况',
    key: 'health',
    options: [
      { label: '良好' },
      { label: '生病' },
      { label: '过世' },
      { label: '意外过世' },
    ],
  },
];
