import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Radium from 'radium';
import { tify } from 'chinese-conv';
import { FIELD_TYPE_MULTIPLE_CHOICE, LANG_EN, LANG_ZH_TRADITIONAL } from '../../../../../res/constants';
import Styles from './DiseasesHistory.module.scss';
import SexualHistoryForm from '../../../../SurrogateApplication/components/forms/SexualHistory/SexualHistoryForm';
import CheckBox from '../../../../../components/CheckBox/CheckBox';

const mapStateToProps = state => ({
  langState: state.langState,
});

class DiseasesHistory extends React.Component {
  constructor(props) {
    super(props);
    const { surrogateMother = null } = props;
    const { langState } = this.props;
    const { lang } = langState;
    this.state = {
      surrogateMother,
      form: new SexualHistoryForm({ user: surrogateMother, component: this, lang }),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { surrogateMother = null } = nextProps;
      const { form } = this.state;
      form.syncData(surrogateMother, 'surrogate_app');
      this.setState({ surrogateMother, form }, () => {
        const { langState } = this.props;
        const { lang } = langState;
        form.setContents(lang);
      });
    }
  }

  renderMultipleChoice = () => {
    const { form } = this.state;
    const { langState } = this.props;
    const { lang } = langState;
    const {
      form_attributes: formAttrs,
    } = form;
    const others = Object.values(formAttrs)
      .filter(attr => attr.type === FIELD_TYPE_MULTIPLE_CHOICE);
    const rows = others.map((item, index) => {
      let backgroundColor = '#fefafc';
      if (index % 2 === 0) {
        backgroundColor = '#ffffff';
      }
      return (
        <div
          className={Styles.othersRow}
          key={item.propName}
          style={{ backgroundColor }}
        >
          <p className={Styles.fieldTitle} style={{ margin: '0' }}>
            {item.title}
          </p>
          <div className={Styles.answersWrapper}>
            <div className={Styles.fieldTitle} style={{ margin: '0' }}>
              <CheckBox
                text="You"
                textVisible={false}
                name={item.propName}
                selectable={false}
                checked={item.value.includes('You')}
              />
            </div>
            <div className={Styles.fieldTitle} style={{ margin: '0 0 0 70px' }}>
              <CheckBox
                text="Spouse"
                textVisible={false}
                name={item.propName}
                selectable={false}
                checked={item.value.includes('Spouse')}
              />
            </div>
            <div className={Styles.cell}>
              <div className={Styles.fieldTitle} style={{ margin: '0 20px 0 75px' }}>
                <CheckBox
                  text="Neither"
                  textVisible={false}
                  name={item.propName}
                  selectable={false}
                  checked={!item.value.includes('Spouse') && !item.value.includes('You')}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
    const gc = lang === LANG_EN ? 'GC' : '代母';
    const spouse = lang === LANG_EN ? 'Spouse' : '配偶';
    const neither = lang === LANG_EN ? 'Neither' : '都没有';
    return (
      <div className={Styles.othersContainer}>
        <div className={Styles.othersRow}>
          <p className={Styles.fieldTitle} style={{ margin: '0' }} />
          <div className={Styles.answersWrapper}>
            <div className={Styles.fieldTitle} style={{ margin: '0' }}>{lang === LANG_ZH_TRADITIONAL ? tify(gc) : gc}</div>
            <div className={Styles.fieldTitle} style={{ margin: '0 0 0 43px' }}>{lang === LANG_ZH_TRADITIONAL ? tify(spouse) : spouse}</div>
            <div className={Styles.fieldTitle} style={{ margin: '0 0 0 43px' }}>{lang === LANG_ZH_TRADITIONAL ? tify(neither) : neither}</div>
          </div>
        </div>
        {rows}
      </div>
    );
  };

  render() {
    const { langState } = this.props;
    const { lang } = langState;
    const title = lang === LANG_EN ? 'Have GC or GC’s partner ever diagnosed any of the following diseases' : '代母或代母的伴侣是否曾经检测出以下疾病';
    return (
      <div className={Styles.container}>
        <h1>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
        {this.renderMultipleChoice()}
        <div style={{ height: '50px' }} />
      </div>
    );
  }
}

DiseasesHistory.defaultProps = {
  surrogateMother: null,
};

DiseasesHistory.propTypes = {
  surrogateMother: PropTypes.shape({
    authentication_token: PropTypes.string,
  }),
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(DiseasesHistory));
