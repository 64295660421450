import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Styles from './AlertCard.module.scss';
import { translate } from '../../../Utils/Util';

const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});

class AlertCard extends React.Component {
  constructor(props) {
    super(props);
    const { title, message } = props;
    this.state = { title, message };
  }

  render() {
    const { title, message } = this.state;
    const { langState } = this.props;
    const { lang } = langState;
    return (
      <div className={Styles.container}>
        <h1>{translate({ lang, value: title })}</h1>
        <p>{translate({ lang, value: message })}</p>
      </div>
    );
  }
}

AlertCard.defaultProps = {
  title: '',
  message: '',
};

AlertCard.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(AlertCard));
