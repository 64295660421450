import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import * as classNames from 'classnames';
import Styles from '../../../../DonorApplication/components/forms/BasicInformation/BasicInformation.module.scss';
import HealthInfoForm from './HealthInfoForm';
import Input from '../../../../DonorApplication/components/forms/BasicInformation/components/Input';
import DropdownMenu from '../../../../DonorApplication/components/forms/BasicInformation/components/DropdownMenu';
import BaseForm from '../../../../DonorApplication/components/forms/BasicInformation/forms/BaseForm';
import { shallHighlight } from '../../../../../Utils/Util';

class HealthInfo extends React.Component {
  constructor(props) {
    super(props);
    const { form = new HealthInfoForm() } = props;
    this.state = {
      form,
    };
  }

  handleInputChange = (event) => {
    const { target } = event;
    const { name: propName, value } = target;
    const { form } = this.state;
    const { user_attributes: userAttrs, form_attributes: formAttrs } = form;
    if (Object.prototype.hasOwnProperty.call(userAttrs, propName)) {
      userAttrs[propName].value = value;
    } else if (Object.prototype.hasOwnProperty.call(formAttrs, propName)) {
      formAttrs[propName].value = value;
    }
    this.setState({ form });
  };

  handleRadioBtnEvent = (event) => {
    const { propName, choice } = event;
    const { form } = this.state;
    const { user_attributes: userAttrs, form_attributes: formAttrs } = form;
    const attrs = { ...userAttrs, ...formAttrs };
    if (Object.prototype.hasOwnProperty.call(attrs, propName)) {
      attrs[propName].value = choice;
    }
    this.setState({ form });
  };

  render() {
    const { form } = this.state;
    const { form_attributes: formAttrs } = form;
    const { highlightRequiredField } = this.props;
    return (
      <div className={Styles.container}>
        <h1>Health Information</h1>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.regular_menstrual_period),
              })}
            >
              {`${formAttrs.regular_menstrual_period.required ? '*' : ''}${formAttrs.regular_menstrual_period.title}`}
            </p>
            <DropdownMenu
              className={Styles.fieldContainer}
              prop={formAttrs.regular_menstrual_period}
              value={formAttrs.regular_menstrual_period.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && shallHighlight(formAttrs.regular_menstrual_period)}
            />
          </div>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.period_monthly_cycle.required ? '*' : ''}${formAttrs.period_monthly_cycle.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.period_monthly_cycle}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.bleeding_period),
              })}
            >
              {`${formAttrs.bleeding_period.required ? '*' : ''}${formAttrs.bleeding_period.title}`}
            </p>
            <DropdownMenu
              className={Styles.fieldContainer}
              prop={formAttrs.bleeding_period}
              value={formAttrs.bleeding_period.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && shallHighlight(formAttrs.bleeding_period)}
            />
          </div>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.period_last),
              })}
            >
              {`${formAttrs.period_last.required ? '*' : ''}${formAttrs.period_last.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.period_last}
              highlight={highlightRequiredField
              && shallHighlight(formAttrs.period_last)}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.using_birth_control),
              })}
            >
              {`${formAttrs.using_birth_control.required ? '*' : ''}${formAttrs.using_birth_control.title}`}
            </p>
            <DropdownMenu
              className={Styles.fieldContainer}
              prop={formAttrs.using_birth_control}
              value={formAttrs.using_birth_control.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && shallHighlight(formAttrs.using_birth_control)}
            />
          </div>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.birth_control_method.required ? '*' : ''}${formAttrs.birth_control_method.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.birth_control_method}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.birth_control_method_long.required ? '*' : ''}${formAttrs.birth_control_method_long.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.birth_control_method_long}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.currently_breastfeeding.required ? '*' : ''}${formAttrs.currently_breastfeeding.title}`}</p>
            <DropdownMenu
              className={Styles.fieldContainer}
              prop={formAttrs.currently_breastfeeding}
              value={formAttrs.currently_breastfeeding.value}
              onchange={this.handleInputChange}
            />
          </div>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.date_stop_breastfeeding.required ? '*' : ''}${formAttrs.date_stop_breastfeeding.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.date_stop_breastfeeding}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.had_surgery.required ? '*' : ''}${formAttrs.had_surgery.title}`}</p>
            <DropdownMenu
              className={Styles.fieldContainer}
              prop={formAttrs.had_surgery}
              value={formAttrs.had_surgery.value}
              onchange={this.handleInputChange}
            />
          </div>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.surgery_reason_result.required ? '*' : ''}${formAttrs.surgery_reason_result.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.surgery_reason_result}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.had_postpartum_depression.required ? '*' : ''}${formAttrs.had_postpartum_depression.title}`}</p>
            <DropdownMenu
              className={Styles.fieldContainer}
              prop={formAttrs.had_postpartum_depression}
              value={formAttrs.had_postpartum_depression.value}
              onchange={this.handleInputChange}
            />
          </div>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.postpartum_depression_details.required ? '*' : ''}${formAttrs.postpartum_depression_details.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.postpartum_depression_details}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start', margin: '10px 0 0 0' }}>
          <div className={Styles.cell}>
            <p className={Styles.fieldTitle} style={{ fontSize: '18px', fontWeight: '500' }}>*Please answer all:</p>
          </div>
        </div>
        {BaseForm.renderYesOrNoWithNotesTable({
          formAttrs,
          notesText: 'If yes, please explain',
          radioBtnClickHandler: this.handleRadioBtnEvent,
          notesInputHandler: this.handleInputChange,
          highlightRequiredField,
        })}

        <div style={{ marginTop: '30px' }} />
        {BaseForm.renderQuestionsWithTextArea({
          attrs: formAttrs,
          inputHandler: this.handleInputChange,
        })}
      </div>
    );
  }
}

HealthInfo.defaultProps = {
  highlightRequiredField: false,
};

HealthInfo.propTypes = {
  form: PropTypes.instanceOf(HealthInfoForm).isRequired,
  highlightRequiredField: PropTypes.bool,
};

export default Radium(HealthInfo);
