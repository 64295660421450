export const PersonalInfoColumns = [
  {
    name: 'ID',
    key: 'cus_surrogate_app_id',
  },
  {
    name: 'Status',
    key: 'avail_status',
  },
  {
    name: 'Birthday',
    key: 'date_of_birth',
  },
  {
    name: 'Age',
    key: 'age',
  },
  {
    name: 'Current Occupation',
    key: 'occupation',
  },
  {
    name: 'Blood Type',
    key: 'blood_type',
  },
  {
    name: 'Height',
    key: 'height',
    unit: 'cm',
  },
  {
    name: 'Weight',
    key: 'weight',
    unit: 'kg',
  },
  {
    name: 'BMI',
    key: 'bmi',
  },
  {
    name: 'City',
    key: 'city',
  },
  {
    name: 'State',
    key: 'state',
  },
  {
    name: 'Country',
    key: 'country',
  },
  {
    name: 'Ethnicity',
    key: 'ethnicity',
  },
  {
    name: 'Highest Educational Level',
    key: 'edu_level',
  },
  {
    name: 'Marital Status',
    key: 'marriage',
  },
  {
    name: 'Sexual Orientation',
    key: 'sexual_contact_with',
    type: 'form_attrs',
  },
  {
    name: 'Compensation',
    key: 'single_gestation_comp',
    type: 'form_attrs',
  },
  {
    name: 'Relationship Description',
    key: 'marriage_description',
    type: 'form_attrs',
  },
  {
    name: 'Personality',
    key: 'personality',
  },
  {
    name: 'Hobbies and talents',
    key: 'talents',
  },
  {
    name: 'Why want to become GC',
    key: 'why_surrogate_mother',
    type: 'form_attrs',
  },
  {
    name: 'Life Goals',
    key: 'life_goals',
    type: 'form_attrs',
  },
  {
    name: 'Description',
    key: 'description',
  },
];

export const PersonalInfoColumnsZh = [
  {
    name: 'ID',
    key: 'cus_surrogate_app_id',
  },
  {
    name: '状态',
    key: 'avail_status',
  },
  {
    name: '生日',
    key: 'date_of_birth',
  },
  {
    name: '年龄',
    key: 'age',
  },
  {
    name: '职业',
    key: 'occupation',
  },
  {
    name: '血型',
    key: 'blood_type',
  },
  {
    name: '城市',
    key: 'city',
  },
  {
    name: '州/省',
    key: 'state',
  },
  {
    name: '国家',
    key: 'country',
  },
  {
    name: '种族',
    key: 'ethnicity',
  },
  {
    name: '身高',
    key: 'height',
    unit: 'cm',
  },
  {
    name: '体重',
    key: 'weight',
    unit: 'kg',
  },
  {
    name: 'BMI',
    key: 'bmi',
  },
  {
    name: '最高教育水平',
    key: 'edu_level',
  },
  {
    name: '婚姻状况',
    key: 'marriage',
  },
  {
    name: '性取向',
    key: 'sexual_contact_with',
    type: 'form_attrs',
  },
  {
    name: '补偿金',
    key: 'single_gestation_comp',
    type: 'form_attrs',
  },
  {
    name: '婚姻描述',
    key: 'marriage_description',
    type: 'form_attrs',
  },
  {
    name: '个性',
    key: 'personality',
  },
  {
    name: '兴趣, 爱好, 天分',
    key: 'talents',
  },
  {
    name: '为什么想做代母',
    key: 'why_surrogate_mother',
    type: 'form_attrs',
  },
  {
    name: '生活目标',
    key: 'life_goals',
    type: 'form_attrs',
  },
  {
    name: '简介',
    key: 'description',
  },
];
