
export const FIELD_TYPE_INPUT = 'input';
export const FIELD_TYPE_PICKER = 'picker';
export const FIELD_TYPE_TEXTAREA = 'textarea';
export const FIELD_TYPE_MULTIPLE_CHOICE = 'multiple_choice';
export const FIELD_TYPE_SINGLE_CHOICE = 'single_choice';
export const FIELD_TYPE_YES_OR_NO = 'yes_no';
export const FIELD_TYPE_FILE = 'file';

export const USER_STATUS_REQUEST_CONSULTATION = 'request_consultation';
export const USER_STATUS_VIEW_DONOR_PROFILES = 'view_donor_profiles';
export const USER_STATUS_BECOME_EGG_DONOR = 'become_egg_donor';
export const USER_STATUS_APPROVED = 'approved';

// route path
export const PATH_HOME_PAGE = '/';
export const PATH_DONOR_APPLICATION = '/donor_application';
export const PATH_SURROGATE_APPLICATION = '/surrogate_application';
export const PATH_DONORS_LIST = '/donors';
export const PATH_SURROGATE_MOTHERS_LIST = '/surrogate_mothers';
export const PATH_EGG_DONATION = '/egg_donation';
export const PATH_EGG_FREEZING = '/egg_freezing';
export const PATH_IVF = '/IVF';
export const PATH_CLINICS = '/clinics';
export const PATH_FERTILITY_CENTER_DETAILS = '/fertility_center';
export const PATH_POST = '/post';
export const PATH_STORIES = '/success_stories';
export const PATH_SURROGACY = '/surrogacy';
export const PATH_SUCCESS_STORIES = '/success_stories';
export const PATH_FOR_SURROGATE = '/for_surrogate';
export const PATH_FOR_EGG_DONOR = '/for_egg_donor';
export const PATH_PROFILE = '/profile';

// photo types
export const PHOTO_TYPE_LIFE_PHOTOS = 'life_photos';
export const PHOTO_TYPE_HEAD_SHOT = 'headshot_photos';
export const PHOTO_TYPE_INSURANCE = 'insurance_photos';
export const PHOTO_TYPE_FULL_PHOTOS = 'full_photos';
export const PHOTO_TYPE_CHILD_PHOTOS = 'child_photos';
export const PHOTO_TYPE_ID_PHOTOS = 'id_photos';
export const PHOTO_TYPE_VIDEO = 'video';

// languages
export const LANG_EN = 'en';
export const LANG_ZH_SIMPLIFIED = 'zh_simplified';
export const LANG_ZH_TRADITIONAL = 'zh_traditional';

// profile types
export const PROFILE_TYPE_DONOR = 'donor';
export const PROFILE_TYPE_SURROGATE = 'surrogate_mother';

// user types
export const INTENTED_PARENTS_TYPE = 'intented_parents';
export const EGG_DONOR_TYPE = 'egg_donor';
export const SURROGATE_MOTHER_TYPE = 'surrogate_mother';
export const THIRD_PARTY_TYPE = 'third_party';

// error type
export const ERROR_TYPE_MISSED_FIELD = 'missed_filed';
