import { ModalActionTypes } from './Modalactions';


const initialState = {
  modalType: null,
  modalProps: {},
};

export function modalState(state = initialState, action) {
  switch (action.type) {
    case ModalActionTypes.SHOW_MODAL:
      return {
        modalProps: action.modalProps,
        modalType: action.modalType,
        type: action.type,
      };
    case ModalActionTypes.HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
}
