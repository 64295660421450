export const styles = {
  container: {
    width: '100%',
    borderRadius: '30px',
    margin: '100px 0 200px 0',
    boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.04)',
    backgroundColor: '#ffffff',
    position: 'relative',
    display: 'flex',
    WebkitFlexDirection: 'column',
    WebkitAlignItems: 'center',
    title: {
      fontFamily: 'Avenir',
      fontSize: '40px',
      fontWeight: 500,
      textAlign: 'center',
      color: '#000000',
      margin: '40px 0 18px 0',
    },
    avatar: {
      width: '300px',
      height: '400px',
      backgroundSize: 'cover',
      position: 'absolute',
      top: '120px',
      right: '50px',
      borderRadius: '10px',
    },
  },
  row: {
    width: '100%',
    padding: '15px 0 15px 0',
    display: '-webkit-box',
    WebkitFlexDirection: 'row',
    WebkitAlignItems: 'center',
  },
  attrCell: {
    width: '365px',
    padding: '0 0 0 60px',
    fontFamily: 'Avenir',
    fontSize: '20px',
    fontWeight: 500,
    textAlign: 'justify',
    color: '#000000',
  },
  valueCell: {
    height: 74,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    width: '700px',
    fontFamily: 'Avenir',
    fontSize: '20px',
    textAlign: 'justify',
    color: '#000000',
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
