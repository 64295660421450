export const styles = {
  container: {
    width: '100%',
    borderRadius: '30px',
    margin: '0 0 60px 0',
    boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.04)',
    backgroundColor: '#ffffff',
    display: 'flex',
    WebkitFlexDirection: 'column',
    WebkitAlignItems: 'center',
    title: {
      fontFamily: 'Avenir',
      fontSize: '40px',
      fontWeight: 500,
      textAlign: 'center',
      color: '#000000',
      margin: '40px 0 18px 0',
    },
  },
  row: {
    width: '100%',
    height: 'fit-content',
    padding: '15px 0 15px 0',
    display: '-webkit-box',
    WebkitFlexDirection: 'row',
    WebkitAlignItems: 'center',
  },
  attrCell: {
    width: '400px',
    padding: '0 0 0 60px',
    fontFamily: 'Avenir',
    fontSize: '20px',
    fontWeight: '500',
    textAlign: 'justify',
    color: '#000000',
  },
  valueCell: {
    fontFamily: 'Avenir',
    fontSize: '20px',
    textAlign: 'justify',
    color: '#000000',
  },
  column: {
    maxWidth: '430px',
    minWidth: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    whiteSpace: 'initial',
    marginLeft: '45px',
    fontFamily: 'Avenir',
    fontSize: '20px',
    fontWeight: 500,
    color: '#000000',
    wordBreak: 'break-word',
  },
};
