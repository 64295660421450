export const Styles = {
  container: {
    width: '100%',
    borderRadius: '30px',
    margin: '0 0 150px 0',
    boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.04)',
    backgroundColor: '#ffffff',
    display: 'flex',
    WebkitFlexDirection: 'column',
    WebkitAlignItems: 'center',
  },
  title: {
    fontFamily: 'Avenir',
    fontSize: '40px',
    fontWeight: '500',
    textAlign: 'center',
    color: '#000000',
    margin: '40px 0 18px 0',
  },
  fieldTitle: {
    fontFamily: 'Avenir',
    fontSize: '20px',
    fontWeight: 500,
    color: '#000000',
    margin: '0 0 10px 0',
  },
  cell: {
    position: 'relative',
  },
  othersContainer: {
    width: '100%',
    display: 'flex',
    WebkitFlexDirection: 'column',
  },
  othersRow: {
    width: '100%',
    padding: '15px 60px 15px 60px',
    boxSizing: 'border-box',
    display: '-webkit-box',
    WebkitFlexDirection: 'row',
    WebkitAlignItems: 'center',
  },
  answersWrapper: {
    display: '-webkit-box',
    WebkitFlexDirection: 'row',
  },
};
