import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import * as HttpStatus from 'http-status-codes';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Styles from './SignUp.module.scss';
import { hideModal, showModal } from '../../../redux/Modal/Modalactions';
import ModalTypes from '../ModalTypes';
import { signUp } from '../../../Api/Api';
import { setUser } from '../../../redux/User/UserActions';
import { getAxiosInstance } from '../../../Utils/WebRequest';
import icClose from '../../../assets/close.png';
import { LANG_EN } from '../../../res/constants';
import { LoginEnContents, LoginZhContents } from './res/constants';
import { translate } from '../../../Utils/Util';
import * as classNames from "classnames";

const mapStateToProps = state => ({
  langState: state.langState,
});

class SignUpCard extends React.Component {
  constructor(props) {
    super(props);
    const { options } = props;
    this.state = {
      options,
      alert: {
        visible: false,
        message: '',
      },
      button: {
        disabled: false,
        text: 'Sign up',
      },
      user: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        phone: '',
        wechat: '',
        user_type: '',
      },
      title: '',
      policy: '',
      alreadyHaveAccountTxt: '',
    };
  }

  componentDidMount() {
    const { langState } = this.props;
    this.setContents(langState.lang);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setContents(langState.lang);
    }
  }

  setContents = (lang) => {
    const contents = lang === LANG_EN ? LoginEnContents : LoginZhContents;
    this.setState({
      ...contents,
    }, () => this.setButtonText());
  };

  setButtonText = () => {
    const { button, btnSignUpTxt, signingUpTxt } = this.state;
    if (button.disabled) {
      button.text = signingUpTxt;
    } else {
      button.text = btnSignUpTxt;
    }
    this.setState({ button });
  };

  validateForm = () => {
    const { user, errorMessages } = this.state;
    if (Object.keys(user).some((attr) => {
      if (attr !== 'wechat') {
        const value = user[attr];
        return value == null || (typeof value === 'string' && value.trim().length === 0);
      }
      return false;
    })) {
      return new Error(errorMessages.fillAllFields);
    }
    if (!user.email.includes('@')) {
      return new Error(errorMessages.invalidEmail);
    }
    if (user.password.length < 6) {
      return new Error(errorMessages.invalidPass);
    }
    return null;
  };

  showAlert = (message = '') => {
    this.setState({ alert: { visible: true, message } });
  };

  dismissAlert = () => {
    this.setState({ alert: { visible: false, message: '' } });
  };

  enableButton = (text = 'Sign up') => {
    const { langState } = this.props;
    const { lang } = langState;
    this.setState({ button: { disabled: false, text: translate({ lang, value: text }) } });
  };

  disableButton = (text = 'Signing up') => {
    const { langState } = this.props;
    const { lang } = langState;
    this.setState({ button: { disabled: true, text: translate({ lang, value: text }) } });
  };

  onSignUp = () => {
    const {
      user, button, options, errorMessages, btnSignUpTxt, signingUpTxt,
    } = this.state;
    if (button.disabled) { return; }
    const validationErr = this.validateForm();
    if (validationErr != null) {
      this.showAlert(validationErr.message);
    } else {
      this.dismissAlert();
      this.disableButton(signingUpTxt);
      if (options != null) {
        const { status } = options;
        if (status != null) { user.status = status; }
      }
      signUp(user)
        .then((res) => {
          const { data: userInfo } = res.data;
          const { history } = this.props;
          getAxiosInstance().defaults.headers.common.Authorization = userInfo.authentication_token;
          const { dispatch } = this.props;
          dispatch(setUser(userInfo));
          const { pathAfterSignUp = null } = options;
          if (pathAfterSignUp != null) {
            history.push(pathAfterSignUp);
          }
          dispatch(hideModal());
        })
        .catch((err) => {
          const { response } = err;
          if (response) {
            const { em } = response.data;
            switch (response.status) {
              case HttpStatus.BAD_REQUEST:
                if (em) {
                  if (em.includes('exist_email')) { this.showAlert(errorMessages.registeredEmail); }
                  if (em.includes('exist_phone')) { this.showAlert(errorMessages.registeredPhone); }
                }
                break;
              default:
                this.showAlert(errorMessages.unknownErr);
            }
          }
          this.enableButton(btnSignUpTxt);
        });
    }
  };

  handleInputChange = (event) => {
    const { target } = event;
    const { name, value } = target;
    const { user } = this.state;
    user[name] = value;
    this.setState({ user });
  };

  render() {
    const {
      title, user, alert, button, alreadyHaveAccountTxt,
      firstNamePlaceholder = '', lastNamePlaceholder = '', emailInputPlaceholder = '',
      passwordInputPlaceholder = '', phoneInputPlaceholder = '', logInTxt = '',
      policy, termsTxt = '', and = '', privacyPolicyTxt = '', wechatPlaceHolder = '',
      userTypes = [], userTypePlaceHolder = '',
    } = this.state;
    const { dispatch, langState } = this.props;
    const { lang } = langState;
    const alertBanner = alert.visible ? (
      <div className={Styles.alert}>
        <p>{translate({ lang, value: alert.message })}</p>
      </div>
    ) : null;
    return (
      <div className={Styles.card}>
        <img alt="" className={Styles.btnClose} src={icClose} onClick={() => dispatch(hideModal())} />
        <h1 className={Styles.title}>{translate({ lang, value: title })}</h1>
        <div className={Styles.nameInputContainer}>
          <div className={classNames({[Styles.nameInput]: true, [Styles.nameInputRowOne]: true})}>
            <input
              type="text"
              placeholder={translate({ lang, value: firstNamePlaceholder })}
              name="first_name"
              onChange={this.handleInputChange}
              value={user.first_name}
            />
          </div>
          <div className={Styles.nameInput}>
            <input
              type="text"
              placeholder={translate({ lang, value: lastNamePlaceholder })}
              name="last_name"
              onChange={this.handleInputChange}
              value={user.last_name}
            />
          </div>
        </div>
        <div className={Styles.field}>
          <input
            type="email"
            placeholder={translate({ lang, value: emailInputPlaceholder })}
            name="email"
            onChange={this.handleInputChange}
            value={user.email}
          />
        </div>
        <div className={Styles.field}>
          <input
            type="phone"
            placeholder={translate({ lang, value: phoneInputPlaceholder })}
            name="phone"
            maxLength="11"
            onChange={this.handleInputChange}
            value={user.phone}
          />
        </div>
        <div className={Styles.field}>
          <input
            placeholder={translate({ lang, value: wechatPlaceHolder })}
            name="wechat"
            onChange={this.handleInputChange}
            value={user.wechat}
          />
        </div>
        <div className={Styles.field}>
          <select
            value={user.user_type}
            name="user_type"
            onChange={this.handleInputChange}
            style={{ color: user.user_type.length === 0 ? '#c6c6d4' : '#52585f' }}
          >
            <option value="" disabled defaultValue hidden>{translate({ lang, value: userTypePlaceHolder })}</option>
            {userTypes.map(type => (
              <option value={type.key} key={type.key}>
                {translate({ lang, value: type.name })}
              </option>
            ))}
          </select>
        </div>
        <div className={Styles.field}>
          <input
            type="password"
            placeholder={translate({ lang, value: passwordInputPlaceholder })}
            name="password"
            onChange={this.handleInputChange}
            value={user.password}
          />
        </div>

        {alertBanner}

        <p className={Styles.policy}>
          {translate({ lang, value: policy })}
          <a href="/terms">{translate({ lang, value: termsTxt })}</a>
          <span>
            {' '}
            {translate({ lang, value: and })}
            {' '}
          </span>
          <a href="/privacy_policy">{translate({ lang, value: privacyPolicyTxt })}</a>
        </p>

        <div
          className={Styles.btnSignUp}
          style={button.disabled ? { backgroundColor: 'grey', backgroundImage: 'none' } : {}}
          onClick={this.onSignUp}
        >
          <p className={Styles.signUpTxt}>{translate({ lang, value: button.text })}</p>
        </div>

        <div className={Styles.divider} />

        <p
          className={Styles.alreadyHaveAccount}
        >
          {alreadyHaveAccountTxt}
          <span
            onClick={() => dispatch(showModal({
              modalProps: { isOpen: true },
              modalType: ModalTypes.LOG_IN,
            }))}
          >
            {translate({ lang, value: logInTxt })}
          </span>
        </p>
      </div>
    );
  }
}

SignUpCard.defaultProps = {
  dispatch: null,
  options: { pathAfterSignUp: null },
};

SignUpCard.propTypes = {
  dispatch: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  options: PropTypes.shape({
    status: PropTypes.string,
    pathAfterSignUp: PropTypes.string,
  }),
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};


export default withRouter(connect(mapStateToProps)(Radium(SignUpCard)));
