import React from 'react';
import Radium from 'radium';
import { Link } from 'react-router-dom';
import styles from './HeaderMenu.css';

class DropDownMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuIsOpened: false,
    };
  }

  toggleMenu = () => {
    const { menuIsOpened } = this.state;
    this.setState({ menuIsOpened: !menuIsOpened });
  };

  render() {
    const { menuIsOpened } = this.state;
    const { item } = this.props;
    const itemStyle = [styles.menuItem];
    let menu = null;
    if (menuIsOpened) {
      itemStyle.push({ borderBottom: 'solid 2px #ffffff' });
      let menuItems = [];
      if (item.menu != null && item.menu.length > 0) {
        menuItems = item.menu.map((menuItem, index) => {
          const containerStyle = [styles.dropDownMenu.menuItemContainer];
          if (index < item.menu.length - 1) {
            containerStyle.push({ borderBottom: 'solid 1px #ffffff' });
          }
          const renderedItem = (
            <div style={containerStyle} key={menuItem.key}>
              {menuItem.title}
            </div>
          );
          if (menuItem.linkTo) {
            return (
              <Link to={menuItem.linkTo} style={{ textDecoration: 'none' }} key={menuItem.key}>
                {renderedItem}
              </Link>
            );
          }
          return renderedItem;
        });
      }
      menu = (
        <div style={styles.dropDownMenu.items}>
          {menuItems}
        </div>
      );
    }
    let title = <div role="presentation" style={itemStyle} key={item.key}>{item.title}</div>;
    title = item.linkTo ? (
      <Link to={item.linkTo} style={{ textDecoration: 'none' }}>
        {title}
      </Link>
    ) : title;
    return (
      <div
        className="response-show"
        style={[styles.dropDownMenu.container, { marginLeft: '25px' }]}
        onMouseLeave={() => this.setState({ menuIsOpened: false })}
        onClick={this.toggleMenu}
      >
        {title}
        {menu}
      </div>
    );
  }
}

export default Radium(DropDownMenu);
