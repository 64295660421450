import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { tify } from 'chinese-conv';
import { connect } from 'react-redux';
import Styles from './SuccessStories.module.scss';
import { getStories } from '../../../../Api/Apis/Posts';
import {
  PATH_STORIES, PATH_POST, LANG_EN, LANG_ZH_SIMPLIFIED, LANG_ZH_TRADITIONAL,
} from '../../../../res/constants';
import { EnKeyMaps, ZhKeyMaps } from './res/constants';
import { getValueWith } from '../../../../Utils/Util';

const mapStateToProps = state => ({
  langState: state.langState,
});
const LangPacks = { EnKeyMaps, ZhKeyMaps };

class SuccessStories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stories: [],
      allStories: [],
    };
  }

  componentDidMount() {
    const { langState } = this.props;
    const { lang } = langState;
    this.setContents(lang);
    getStories()
      .then((res) => {
        const { data: stories = [] } = res.data.data;
        this.setState({ allStories: stories }, () => this.setContents(lang));
      })
      .catch((err) => {});
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setContents(langState.lang);
    }
  }

  setContents = (lang) => {
    const { allStories } = this.state;
    const filteredStoriesByLang = allStories.filter((story) => {
      if (lang === LANG_EN) {
        return story.lang === lang;
      }
      return story.lang === LANG_ZH_SIMPLIFIED || story.lang === LANG_ZH_TRADITIONAL;
    });
    this.setState({
      title: getValueWith({ key: 'title', lang, ...LangPacks }),
      readMoreTxt: getValueWith({ key: 'read_more_txt', lang, ...LangPacks }),
      viewAllTxt: getValueWith({ key: 'view_all_txt', lang, ...LangPacks }),
      stories: filteredStoriesByLang.slice(0, 3),
    });
  };

  onReadMore = (story) => {
    if (story && story.description && story.description.length > 0) {
      const { history } = this.props;
      history.push(`${PATH_POST}/${story.id}`);
    }
  };

  render() {
    const {
      stories, readMoreTxt, viewAllTxt, title,
    } = this.state;
    const { langState } = this.props;
    const { lang } = langState;
    const cards = stories.map((story) => {
      let { description = '' } = story;
      description = description || '';
      if (description.length > 150) {
        description = `${description.substring(0, 150 - 3).trim()}...`;
      }
      return (
        <div
          className={Styles.card}
          key={story.id}
          onClick={() => this.onReadMore(story)}
        >
          <img alt="" src={story.cover_photo_url} />
          <div className={Styles.cardIntro}>
            <h1>{lang === LANG_ZH_TRADITIONAL ? tify(story.title) : story.title}</h1>
            <p>{lang === LANG_ZH_TRADITIONAL ? tify(description) : description}</p>
          </div>
          <div className={Styles.footer}>
            <p>
              {readMoreTxt}
            </p>
          </div>
        </div>
      );
    });
    return (
      <div className={Styles.section}>
        <div className={Styles.container}>
          <h1 className={Styles.title}>{title}</h1>
          <div className={`${Styles.storiesContainer} row`}>
            {cards}
          </div>
          <Link to={PATH_STORIES} style={{ textDecoration: 'none' }}>
            {stories.length > 0 ? <p className={Styles.viewAll}>{viewAllTxt}</p> : null}
          </Link>
        </div>
      </div>
    );
  }
}

SuccessStories.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default withRouter(connect(mapStateToProps)(Radium(SuccessStories)));
