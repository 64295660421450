import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import Styles from '../../../../DonorApplication/components/forms/ShowYourself/ShowYourself.module.scss';
import ShowYourselfForm from './ShowYourselfForm';
import SelectFileBtn from '../../../../DonorApplication/components/forms/ShowYourself/SelectFileBtn';
import { SelectedFiles } from '../../../../DonorApplication/components/forms/ShowYourself/ShowYourself';

class ShowYourself extends React.Component {
  constructor(props) {
    super(props);
    const { showYourselfForm } = props;
    showYourselfForm.setShowYourselfComponent(this);
    this.state = {
      showYourselfForm,
      instruction: '',
    };
  }

  onSelectFile = (event) => {
    const { showYourselfForm } = this.state;
    const { target } = event;
    const { name: propName } = target;
    const newSelectedFiles = Array.from(event.target.files);
    const { files } = showYourselfForm.photos_attributes[propName];
    showYourselfForm
      .photos_attributes[propName].files = files.concat(newSelectedFiles.map(file => ({
        localFile: file, saved: false, name: file.name, size: file.size,
      })));
    this.setState({ showYourselfForm });
  };

  onDeleteFile = (event) => {
    const { name: propName, index } = event;
    const { showYourselfForm } = this.state;
    if (Object.hasOwnProperty.call(showYourselfForm.photos_attributes, propName)) {
      showYourselfForm.photos_attributes[propName].files.splice(index, 1);
    }
    this.setState({ showYourselfForm });
  };

  renderFilesSelectors = () => {
    const { showYourselfForm } = this.state;
    const attrs = Object.values(showYourselfForm.photos_attributes);
    return attrs.map((attr, index) => (
      <div key={attr.propName}>
        <p className={Styles.subtitle}>{`${attr.title}`}</p>
        <div className={Styles.selectBox}>
          <SelectFileBtn
            onChange={this.onSelectFile}
            name={attr.propName}
            fileType={attr.fileType}
          />
          <div style={{ width: '73px' }} />
        </div>
        <SelectedFiles name={attr.propName} files={attr.files} onDelete={this.onDeleteFile} />
        <div style={{ height: index === attrs.length - 1 ? '10px' : '50px' }} />
      </div>
    ));
  };

  render() {
    const { instruction } = this.state;
    const filesSelectors = this.renderFilesSelectors();
    return (
      <div className={Styles.container}>
        <h1>Show Yourself</h1>
        {filesSelectors}
        <p className={Styles.instruction}>{instruction}</p>
      </div>
    );
  }
}

ShowYourself.propTypes = {
  showYourselfForm: PropTypes.instanceOf(ShowYourselfForm).isRequired,
};

export default Radium(ShowYourself);
