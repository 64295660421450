import React, { Fragment } from 'react';
import Radium from 'radium';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { tify } from 'chinese-conv';
import Styles from './FertilityCenter.module.scss';
import Swiper from './Swiper';
import { getHospitals } from '../../../../Api/Apis/Hospitals';
import {
  LANG_EN,
  LANG_ZH_SIMPLIFIED,
  LANG_ZH_TRADITIONAL,
  PATH_FERTILITY_CENTER_DETAILS,
} from '../../../../res/constants';
import { EnKeyMaps, ZhKeyMaps } from './res/constants';
import { getValueWith } from '../../../../Utils/Util';

const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});
const LangPacks = { EnKeyMaps, ZhKeyMaps };

class FertilityCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hospitals: [], allHospitals: [],
    };
  }

  componentDidMount() {
    const { langState } = this.props;
    getHospitals()
      .then((res) => {
        const { data: hospitals } = res.data.data;
        this.setState({ allHospitals: hospitals, selectedHospitalIndex: 0 },
          () => this.setContents(langState.lang));
      })
      .catch((err) => {});
    this.setContents(langState.lang);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setState({ selectedHospitalIndex: 0 });
      this.setContents(langState.lang);
    }
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  setContents = (lang) => {
    const { allHospitals } = this.state;
    const filteredHospitalsByLang = allHospitals.filter((hospital) => {
      if (!hospital.lang) {
        return true;
      }
      if (lang === LANG_EN) {
        return hospital.lang === lang;
      }
      return hospital.lang === LANG_ZH_SIMPLIFIED || hospital.lang === LANG_ZH_TRADITIONAL;
    });
    this.setState({
      title: getValueWith({ key: 'title', lang, ...LangPacks }),
      buttonTxt: getValueWith({ key: 'btn_txt', lang, ...LangPacks }),
      hospitals: filteredHospitalsByLang,
    });
  };

  onHospitalSelected = (selectedHospitalIndex) => {
    this.setState({ selectedHospitalIndex });
  };

  onReadMore = () => {
    const { hospitals, selectedHospitalIndex } = this.state;
    const selectedHospital = hospitals[selectedHospitalIndex];
    const { history } = this.props;
    history.push(`${PATH_FERTILITY_CENTER_DETAILS}/${selectedHospital.id}`);
  };

  render() {
    const {
      hospitals, selectedHospitalIndex, title, buttonTxt, width,
    } = this.state;
    const { containerStyle, langState } = this.props;
    const { lang } = langState;
    const wrapperHeight = width <= 768 ? 'fit-content' : '379px';
    const intros = hospitals.map((hospital, index) => (
      <div
        className={Styles.introWrapper}
        style={{
          height: index === selectedHospitalIndex ? wrapperHeight : 0,
          opacity: index === selectedHospitalIndex ? 1 : 0,
        }}
        key={hospital.id}
      >
        <img alt="" src={hospital.cover_photo_url} />
        <div className={Styles.details}>
          <h1>{hospital.name}</h1>
          <p className={Styles.detailsBody}>
            {lang === LANG_ZH_TRADITIONAL ? tify(hospital.description) : hospital.description}
          </p>
          <div className={Styles.btn} onClick={this.onReadMore}>
            <p>{buttonTxt}</p>
          </div>
        </div>
      </div>
    ));
    return (
      <div className={Styles.container} style={{ ...containerStyle }}>
        <div className={Styles.contents}>
          <h1 className={Styles.title}>{title}</h1>
          {hospitals.length > 0 && selectedHospitalIndex != null ? (
            <Fragment>
              <div style={{ position: 'relative' }}>
                {intros}
              </div>
              <Swiper hospitals={hospitals} onHospitalSelected={this.onHospitalSelected} />
            </Fragment>
          ) : null}
        </div>
      </div>
    );
  }
}

FertilityCenter.defaultProps = {
  containerStyle: {},
};

FertilityCenter.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
  containerStyle: PropTypes.shape({ margin: PropTypes.string }),
};

export default withRouter(connect(mapStateToProps)(Radium(FertilityCenter)));
