export const DefaultReactModalStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'auto',
    padding: 0,
    zIndex: '1050',
  },
  content: {
    backgroundColor: 'transparent',
    width: 'fit-content',
    height: 'fit-content',
    border: 'transparent 0 solid',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: 0,
    margin: 'auto',
  },
};

const styles = { modalStyle: { ...DefaultReactModalStyle } };

export default styles;
