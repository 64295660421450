import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { tify } from 'chinese-conv';
import { connect } from 'react-redux';
import Styles from './HealthHistory.module.scss';
import Input from '../BasicInformation/components/Input';
import HealthHistoryForm from './HealthHistoryForm';
import BaseForm from '../BasicInformation/forms/BaseForm';
import { LANG_EN, LANG_ZH_SIMPLIFIED } from '../../../../../res/constants';
import { shallHighlight } from '../../../../../Utils/Util';

const mapStateToProps = state => ({
  langState: state.langState,
});

class HealthHistory extends React.Component {
  constructor(props) {
    super(props);
    const { form = new HealthHistoryForm({ component: this }) } = this.props;
    form.setComponent(this);
    this.state = {
      form,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions, false);
  }

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  handleInputChange = (event) => {
    const { target } = event;
    const { name: propName, value } = target;
    const { form } = this.state;
    const { form_attributes: formAttributes } = form;
    if (Object.prototype.hasOwnProperty.call(formAttributes, propName)) {
      formAttributes[propName].value = value;
    }
    this.setState({ form });
  };

  handleCheckboxChange = (event) => {
    const { name: propName, text } = event;
    const { form } = this.state;
    const { form_attributes: formAttrs } = form;
    if (Object.prototype.hasOwnProperty.call(formAttrs, propName)) {
      const { options, value } = formAttrs[propName];
      let selectedOptions = [];
      // if value is not empty string
      if (value.trim().length > 0) {
        selectedOptions = value.split(',');
      }
      if (text === 'Others') {
        if (selectedOptions.includes(text)) {
          selectedOptions = []
        } else {
          selectedOptions = ['Others']
        }
      } else {
        if (selectedOptions.includes(text)) {
          selectedOptions.splice(selectedOptions.indexOf(text), 1);
        } else if (selectedOptions.includes('Others')) {
          selectedOptions = [text]
        } else {
          selectedOptions.push(text);
        }
      }
      formAttrs[propName].value = '';
      if (selectedOptions.length > 0) {
        formAttrs[propName].value = selectedOptions.toString()
      }
      this.setState({ form });
    }
  };

  handleRadioBtnEvent = (event) => {
    const { propName, choice } = event;
    const { form } = this.state;
    const { form_attributes: formAttrs } = form;
    if (Object.prototype.hasOwnProperty.call(formAttrs, propName)) {
      formAttrs[propName].value = choice;
    }
    this.setState({ form });
  };

  render() {
    const { form, windowWidth } = this.state;
    const { form_attributes: formAttrs } = form;
    const { langState, highlightRequiredField } = this.props;
    const { lang } = langState;
    const title = lang === LANG_EN ? 'Health History' : '健康背景';
    const subtitle = lang === LANG_EN ? '*Background and Medical History' : '*背景及医疗历史';
    const notesText = lang === LANG_EN ? 'Notes' : '备注';
    return (
      <div className={Styles.container}>
        <h1>{lang === LANG_ZH_SIMPLIFIED ? title : tify(title)}</h1>
        <p className={Styles.subTitle}>{lang === LANG_ZH_SIMPLIFIED ? subtitle : tify(subtitle)}</p>
        {BaseForm.renderYesOrNoWithNotesTable({
          formAttrs,
          notesInputHandler: this.handleInputChange,
          radioBtnClickHandler: this.handleRadioBtnEvent,
          notesText: lang === LANG_ZH_SIMPLIFIED ? notesText : tify(notesText),
          lang,
          windowWidth,
          highlightRequiredField,
        })}
        <div style={{ marginTop: '20px' }} />
        {BaseForm.renderMultipleChoice({
          attr: formAttrs.contraception,
          checkboxHandler: this.handleCheckboxChange,
          highlight: highlightRequiredField && shallHighlight(formAttrs.contraception),
        })}
        <div style={{ width: '100%' }}>
          <Input
            onchange={this.handleInputChange}
            prop={formAttrs.contraception_notes}
          />
        </div>
      </div>
    );
  }
}

HealthHistory.defaultProps = {
  highlightRequiredField: false,
};

HealthHistory.propTypes = {
  form: PropTypes.instanceOf(HealthHistoryForm).isRequired,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
  highlightRequiredField: PropTypes.bool,
};

export default connect(mapStateToProps)(Radium(HealthHistory));
