
import { getAxiosInstance } from '../../Utils/WebRequest';

export const HospitalsRoute = '/api/hospitals';

export function getHospitals() {
  return getAxiosInstance().get(HospitalsRoute);
}

export function getHospitalWithId(id) {
  if (id == null) { return Promise.reject(new Error('id is not provided')); }
  return getAxiosInstance().get(`${HospitalsRoute}/${id}`);
}
