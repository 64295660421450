import PropTypes from 'prop-types';
import React from 'react';
import * as classNames from 'classnames';
import Styles from '../BasicInformation.module.scss';
import icCalendar from '../../../../../../assets/ic-calendar.png';
import { Prop } from '../forms/Utils';

function Input(props) {
  let {
    margin = '0', placeholder, type = 'text', onchange, name = '', value = '',
    readOnly, prop, highlight = false,
  } = props;
  if (prop) {
    placeholder = prop.placeholder;
    type = prop.inputType;
    name = prop.propName;
    value = prop.value;
  }
  let img;
  switch (type) {
    case 'date':
      img = (
        <img
          alt=""
          src={icCalendar}
          style={{
            width: '24px', height: '24px', margin: '0 20px 0 20px',
          }}
        />
      );
      break;
    default:
      img = null;
  }
  return (
    <div
      className={classNames({
        [Styles.inputContainer]: true,
        [Styles.containerHighlight]: highlight,
      })}
      style={{ margin }}
    >
      <input
        placeholder={placeholder}
        type={type}
        style={{ width: type === 'date' ? '75%' : '100%' }}
        onChange={event => onchange(event)}
        name={name}
        value={value}
        readOnly={readOnly}
      />
      {img}
    </div>
  );
}

Input.defaultProps = {
  width: '365px',
  margin: '0',
  placeholder: '',
  onchange: () => {},
  name: '',
  value: '',
  readOnly: false,
  type: 'text',
  prop: null,
  highlight: false,
};

Input.propTypes = {
  width: PropTypes.string,
  margin: PropTypes.string,
  placeholder: PropTypes.string,
  onchange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  prop: PropTypes.shape(Prop),
  highlight: PropTypes.bool,
};

export default Input;
