import {
  FIELD_TYPE_MULTIPLE_CHOICE,
  FIELD_TYPE_PICKER,
  FIELD_TYPE_YES_OR_NO, LANG_EN,
} from '../../../../../res/constants';
import {
  Prop,
} from '../../../../DonorApplication/components/forms/BasicInformation/forms/Utils';
import BaseForm from '../../../../DonorApplication/components/forms/BasicInformation/forms/BaseForm';

const ContentsEn = new Map([
  ['aids', {
    title: 'AIDS',
  }],
  ['hepatitis_c', {
    title: 'Hepatitis C',
  }],
  ['hepatitis_b', {
    title: 'Hepatitis  B',
  }],
  ['hepatitis_a', {
    title: 'Hepatitis  A',
  }],
  ['ovarian_cyst', {
    title: 'Ovarian Cyst',
  }],
  ['hpv', {
    title: 'HPV',
  }],
  ['syphilis', {
    title: 'Syphilis',
  }],
  ['pid', {
    title: 'PID',
  }],
  ['genital_herpes', {
    title: 'Genital Herpes',
  }],
  ['gonorrhea', {
    title: 'Gonorrhea',
  }],
  ['chlamydia', {
    title: 'Chlamydia',
  }],
]);

const ContentsZh = new Map([
  ['aids', {
    title: '爱滋病',
  }],
  ['hepatitis_c', {
    title: '丙肝',
  }],
  ['hepatitis_b', {
    title: '乙肝',
  }],
  ['hepatitis_a', {
    title: '甲肝',
  }],
  ['ovarian_cyst', {
    title: '卵巢囊肿',
  }],
  ['hpv', {
    title: '人类乳突病毒',
  }],
  ['syphilis', {
    title: '梅毒',
  }],
  ['pid', {
    title: '盆腔炎',
  }],
  ['genital_herpes', {
    title: '生殖器疱疹',
  }],
  ['gonorrhea', {
    title: '淋病',
  }],
  ['chlamydia', {
    title: '衣原体病毒',
  }],
]);

class SexualHistoryForm extends BaseForm {
  constructor({
    user = null, component = null, lang = LANG_EN, appType = 'surrogate_app',
  }) {
    super({
      user,
      component,
      ContentsZh,
      ContentsEn,
      lang,
      appType,
    });
    this.form_attributes = {
      sexual_contact_with: new Prop({
        propName: 'sexual_contact_with',
        title: 'Please indicate with whom you have had sexual contact:',
        options: ['Men', 'Women', 'Both'],
        type: FIELD_TYPE_PICKER,
        required: false,
      }),
      num_sexual_partners: new Prop({
        propName: 'num_sexual_partners',
        title: 'How many sexual partners have you had in the past 3 years?',
        inputType: 'number',
        required: false,
      }),

      have_sexual_partner: new Prop({
        propName: 'have_sexual_partner',
        title: 'Are you with a sexual partner now?',
        type: FIELD_TYPE_YES_OR_NO,
        required: false,
      }),
      currently_multiple_partner: new Prop({
        propName: 'currently_multiple_partner',
        title: 'Do you currently have more than one sexual partner?',
        type: FIELD_TYPE_YES_OR_NO,
        required: false,
      }),
      have_sex_with_unknown: new Prop({
        propName: 'have_sex_with_unknown',
        title: 'Have you had sexual contact with a person you do not know well?',
        type: FIELD_TYPE_YES_OR_NO,
        required: false,
      }),
      have_sex_with_aids: new Prop({
        propName: 'have_sex_with_aids',
        title: 'In the past 10 years, have you had sexual contact with anyone in high risks group for A.I.D.S.? These include sexually active persons with multiple partners.',
        type: FIELD_TYPE_YES_OR_NO,
        required: false,
      }),
      risk_for_aids: new Prop({
        propName: 'risk_for_aids',
        title: 'Are you at risk for A.I.D.S.?',
        type: FIELD_TYPE_YES_OR_NO,
        required: false,
      }),
      transmitted_disease: new Prop({
        propName: 'transmitted_disease',
        title: 'Have you ever had a sexually transmitted disease?\n',
        type: FIELD_TYPE_YES_OR_NO,
        required: false,
      }),

      chlamydia: new Prop({
        propName: 'chlamydia',
        title: 'Chlamydia',
        type: FIELD_TYPE_MULTIPLE_CHOICE,
        options: ['You', 'Spouse'],
        required: false,
      }),
      gonorrhea: new Prop({
        propName: 'gonorrhea',
        title: 'Gonorrhea',
        type: FIELD_TYPE_MULTIPLE_CHOICE,
        options: ['You', 'Spouse'],
        required: false,
      }),
      genital_herpes: new Prop({
        propName: 'genital_herpes',
        title: 'Genital Herpes',
        type: FIELD_TYPE_MULTIPLE_CHOICE,
        options: ['You', 'Spouse'],
        required: false,
      }),
      pid: new Prop({
        propName: 'pid',
        title: 'PID',
        type: FIELD_TYPE_MULTIPLE_CHOICE,
        options: ['You', 'Spouse'],
        required: false,
      }),
      syphilis: new Prop({
        propName: 'syphilis',
        title: 'Syphilis',
        type: FIELD_TYPE_MULTIPLE_CHOICE,
        options: ['You', 'Spouse'],
        required: false,
      }),
      hpv: new Prop({
        propName: 'hpv',
        title: 'HPV',
        type: FIELD_TYPE_MULTIPLE_CHOICE,
        options: ['You', 'Spouse'],
        required: false,
      }),
      ovarian_cyst: new Prop({
        propName: 'ovarian_cyst',
        title: 'Ovarian Cyst',
        type: FIELD_TYPE_MULTIPLE_CHOICE,
        options: ['You', 'Spouse'],
        required: false,
      }),
      hepatitis_a: new Prop({
        propName: 'hepatitis_a',
        title: 'Hepatitis A',
        type: FIELD_TYPE_MULTIPLE_CHOICE,
        options: ['You', 'Spouse'],
        required: false,
      }),
      hepatitis_b: new Prop({
        propName: 'hepatitis_b',
        title: 'Hepatitis B',
        type: FIELD_TYPE_MULTIPLE_CHOICE,
        options: ['You', 'Spouse'],
        required: false,
      }),
      hepatitis_c: new Prop({
        propName: 'hepatitis_c',
        title: 'Hepatitis C',
        type: FIELD_TYPE_MULTIPLE_CHOICE,
        options: ['You', 'Spouse'],
        required: false,
      }),
      aids: new Prop({
        propName: 'aids',
        title: 'AIDS',
        type: FIELD_TYPE_MULTIPLE_CHOICE,
        options: ['You', 'Spouse'],
        required: false,
      }),
    };
    this.syncData(user, appType);
  }
}

export default SexualHistoryForm;
