import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { styles } from './Pictures.css';

class Pictures extends React.Component {
  constructor(props) {
    super(props);
    const { photos = [] } = props;
    this.state = { photos };
  }

  render() {
    const { photos } = this.state;
    // note: wicked pdf does not accept grid
    // note that img with src which includes https does not work...
    const images = photos.map((photo, index) => (
      <img
        style={[styles.gridContainer.img, { marginRight: '17px' }]}
        alt=""
        src={photo.url.replace('https', 'http')}
        key={photo.id}
      />
    ));
    return (
      <div style={styles.container}>
        <div style={styles.gridContainer}>
          {images}
        </div>
      </div>
    );
  }
}

Pictures.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
  })).isRequired,
};

export default Radium(Pictures);
