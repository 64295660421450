import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import * as classNames from 'classnames';
import CheckBox from '../../../../../components/CheckBox/CheckBox';
import Styles from '../../../../DonorApplication/components/forms/BasicInformation/BasicInformation.module.scss';
import DropdownMenu from '../../../../DonorApplication/components/forms/BasicInformation/components/DropdownMenu';
import BasicInfoForm from './BasicInfoForm';
import Input from '../../../../DonorApplication/components/forms/BasicInformation/components/Input';
import { shallHighlight } from '../../../../../Utils/Util';

class BasicInformation extends React.Component {
  constructor(props) {
    super(props);
    const { basicInfoForm = new BasicInfoForm() } = props;
    basicInfoForm.setComponent(this);
    this.state = {
      basicInfoForm,
    };
  }

  handleInputChange = (event) => {
    const { target } = event;
    const { name: propName, value } = target;
    const { basicInfoForm } = this.state;
    const { user_attributes: user, form_attributes: formAttributes } = basicInfoForm;
    if (Object.prototype.hasOwnProperty.call(user, propName)) {
      user[propName].value = value;
    } else if (Object.prototype.hasOwnProperty.call(formAttributes, propName)) {
      formAttributes[propName].value = value;
    }
    this.setState({ basicInfoForm });
  };

  handleCheckboxChange = (event) => {
    const { name: propName, text } = event;
    const { basicInfoForm } = this.state;
    const { user_attributes: user } = basicInfoForm;
    if (Object.prototype.hasOwnProperty.call(user, propName)) {
      if (text.includes('Others')) {
        user[propName].value = text;
      } else {
        const { options } = user[propName];
        let { value } = user[propName];
        let selectedOptions = [];
        if (value.includes('Others')) {
          value = '';
        }
        // if value is not empty string
        if (value.trim().length > 0) {
          selectedOptions = value.split(',');
        }
        // if it was selected
        if (selectedOptions.indexOf(text) >= 0) {
          selectedOptions.splice(selectedOptions.indexOf(text), 1);
        } else if (options.indexOf(text) >= 0) {
          // if it was not selected and options contains such option
          selectedOptions.push(text);
        }
        user[propName].value = '';
        if (selectedOptions.length > 0) {
          user[propName].value = selectedOptions.reduce((accum, currentVal) => `${accum},${currentVal}`);
        }
      }
    }
    this.setState({ basicInfoForm });
  };


  render() {
    const { basicInfoForm } = this.state;
    const { user_attributes: user, form_attributes: formAttrs } = basicInfoForm;
    const { highlightRequiredField } = this.props;
    return (
      <div className={Styles.container}>
        <h1>Personal Information</h1>
        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(user.first_name),
              })}
            >
              {`${user.first_name.required ? '*' : ''}${user.first_name.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              prop={user.first_name}
              highlight={highlightRequiredField
              && shallHighlight(user.first_name)}
            />
          </div>
          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(user.last_name),
              })}
            >
              {`${user.last_name.required ? '*' : ''}${user.last_name.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              prop={user.last_name}
              highlight={highlightRequiredField
              && shallHighlight(user.last_name)}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${user.middle_name.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              prop={user.middle_name}
            />
          </div>
          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p className={Styles.fieldTitle}>{`${user.maiden_name.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              prop={user.maiden_name}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(user.date_of_birth),
              })}
            >
              {`${user.date_of_birth.required ? '*' : ''}${user.date_of_birth.title}`}
            </p>
            <Input
              type="date"
              onchange={this.handleInputChange}
              prop={user.date_of_birth}
              highlight={highlightRequiredField
              && shallHighlight(user.date_of_birth)}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(user.blood_type),
              })}
            >
              {`${user.blood_type.required ? '*' : ''}${user.blood_type.title}`}
            </p>
            <DropdownMenu
              prop={user.blood_type}
              value={user.blood_type.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && shallHighlight(user.blood_type)}
            />
          </div>
          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(user.rh_factor),
              })}
            >
              {`${user.rh_factor.required ? '*' : ''}${user.rh_factor.title}`}
            </p>
            <DropdownMenu
              className={Styles.fieldContainer}
              prop={user.rh_factor}
              value={user.rh_factor.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && shallHighlight(user.rh_factor)}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(user.height),
              })}
            >
              {`${user.height.required ? '*' : ''}${user.height.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              prop={user.height}
              highlight={highlightRequiredField
              && shallHighlight(user.height)}
            />
          </div>
          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(user.weight),
              })}
            >
              {`${user.weight.required ? '*' : ''}${user.weight.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              prop={user.weight}
              highlight={highlightRequiredField
              && shallHighlight(user.weight)}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(user.bmi),
              })}
            >
              {`${user.bmi.required ? '*' : ''}${user.bmi.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              prop={user.bmi}
              highlight={highlightRequiredField
                && shallHighlight(user.bmi)}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div className={Styles.cell}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(user.ethnicity),
              })}
            >
              {`*${user.ethnicity.title}`}
            </p>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {user.ethnicity.options.map(item => (
                <CheckBox
                  text={item}
                  containerStyle={{ margin: '0 50px 20px 0' }}
                  name={user.ethnicity.propName}
                  onClick={this.handleCheckboxChange}
                  checked={user.ethnicity.value.includes(item)}
                  key={item}
                />
              ))}
            </div>
          </div>
        </div>

        {
          user.ethnicity.value.includes('Others') ? (
            <div className={Styles.row}>
              <div className={classNames({
                [Styles.cell]: true,
                [Styles.mediumCell]: true,
              })}
              >
                <p className={Styles.fieldTitle}>{`${user.ethnicity_others.required ? '*' : ''}${user.ethnicity_others.title}`}</p>
                <Input
                  onchange={this.handleInputChange}
                  name={user.ethnicity_others.propName}
                  value={user.ethnicity_others.value}
                />
              </div>
            </div>
          ) : null
        }

        <div className={Styles.row}>
          <div className={Styles.cell}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(user.religious),
              })}
            >
              {`*${user.religious.title}`}
            </p>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {user.religious.options.map(item => (
                <CheckBox
                  text={item}
                  containerStyle={{ margin: '0 50px 20px 0' }}
                  name={user.religious.propName}
                  onClick={this.handleCheckboxChange}
                  checked={user.religious.value.includes(item)}
                  key={item}
                />
              ))}
            </div>
          </div>
        </div>

        {
          user.religious.value.includes('Others') ? (
            <div className={Styles.row}>
              <div className={classNames({
                [Styles.cell]: true,
                [Styles.mediumCell]: true,
              })}
              >
                <p className={Styles.fieldTitle}>{`${user.religious_others.required ? '*' : ''}${user.religious_others.title}`}</p>
                <Input
                  onchange={this.handleInputChange}
                  name={user.religious_others.propName}
                  value={user.religious_others.value}
                />
              </div>
            </div>
          ) : null
        }

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(user.nationality),
              })}
            >
              {`${user.nationality.required ? '*' : ''}${user.nationality.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              prop={user.nationality}
            />
          </div>
          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(user.us_citizen),
              })}
            >
              {`${user.us_citizen.required ? '*' : ''}${user.us_citizen.title}`}
            </p>
            <DropdownMenu
              onchange={this.handleInputChange}
              prop={user.us_citizen}
              value={user.us_citizen.value}
              highlight={highlightRequiredField
              && shallHighlight(user.us_citizen)}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div className={classNames({ [Styles.cell]: true, [Styles.longCell]: true })}>
            <p className={Styles.fieldTitle}>{`${formAttrs.source.required ? '*' : ''}${formAttrs.source.title}`}</p>
            <textarea
              className={Styles.textArea}
              onChange={this.handleInputChange}
              name={formAttrs.source.propName}
              value={formAttrs.source.value}
            />
          </div>
        </div>

        <h1>Contact Information</h1>
        <div className={Styles.row}>
          <div className={classNames({
            [Styles.cell]: true,
            [Styles.shortCell]: true,
            [Styles.cellMarginBottom]: true,
          })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(user.phone),
              })}
            >
              {`${user.phone.required ? '*' : ''}${user.phone.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              prop={user.phone}
              highlight={highlightRequiredField
              && shallHighlight(user.phone)}
            />
          </div>
          <div className={classNames({
            [Styles.cell]: true,
            [Styles.shortCell]: true,
            [Styles.cellMarginBottom]: true,
          })}
          >
            <p className={Styles.fieldTitle}>{`${user.wechat.required ? '*' : ''}${user.wechat.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              prop={user.wechat}
            />
          </div>
          <div className={classNames({ [Styles.cell]: true, [Styles.shortCell]: true })}>
            <p className={Styles.fieldTitle}>{`${user.line.required ? '*' : ''}${user.line.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              name={user.line.propName}
              value={user.line.value}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div className={classNames({
            [Styles.cell]: true,
            [Styles.shortCell]: true,
            [Styles.cellMarginBottom]: true,
          })}
          >
            <p className={Styles.fieldTitle}>{`${user.facebook.required ? '*' : ''}${user.facebook.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              prop={user.facebook}
            />
          </div>
          <div className={classNames({
            [Styles.cell]: true,
            [Styles.shortCell]: true,
            [Styles.cellMarginBottom]: true,
          })}
          >
            <p className={Styles.fieldTitle}>{`${user.instagram.required ? '*' : ''}${user.instagram.title}`}</p>
            <Input
              prop={user.instagram}
              onchange={this.handleInputChange}
            />
          </div>
          <div className={classNames({ [Styles.cell]: true, [Styles.shortCell]: true })}>
            <p className={Styles.fieldTitle}>{`${user.skype.required ? '*' : ''}${user.skype.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              prop={user.skype}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div className={classNames({ [Styles.cell]: true, [Styles.mediumCell]: true })}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(user.street_address),
              })}
            >
              {`${user.street_address.required ? '*' : ''}${user.street_address.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.street_address.propName}
              value={user.street_address.value}
              highlight={highlightRequiredField
              && shallHighlight(user.street_address)}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div className={classNames({
            [Styles.cell]: true,
            [Styles.shortCell]: true,
            [Styles.cellMarginBottom]: true,
          })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(user.city),
              })}
            >
              {`${user.city.required ? '*' : ''}${user.city.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.city.propName}
              value={user.city.value}
              highlight={highlightRequiredField
              && shallHighlight(user.city)}
            />
          </div>
          <div className={classNames({
            [Styles.cell]: true,
            [Styles.shortCell]: true,
            [Styles.cellMarginBottom]: true,
          })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(user.state),
              })}
            >
              {`${user.state.required ? '*' : ''}${user.state.title}`}
            </p>
            <DropdownMenu
              options={user.state.options}
              name={user.state.propName}
              value={user.state.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && shallHighlight(user.state)}
            />
          </div>
          <div className={classNames({
            [Styles.cell]: true,
            [Styles.shortCell]: true,
          })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(user.zipcode),
              })}
            >
              {`${user.zipcode.required ? '*' : ''}${user.zipcode.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.zipcode.propName}
              value={user.zipcode.value}
              type={user.zipcode.inputType}
              highlight={highlightRequiredField
              && shallHighlight(user.state)}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div className={classNames({
            [Styles.cell]: true,
            [Styles.mediumCell]: true,
          })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(user.country),
              })}
            >
              {`${user.country.required ? '*' : ''}${user.country.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              name={user.country.propName}
              value={user.country.value}
              highlight={highlightRequiredField
              && shallHighlight(user.country)}
            />
          </div>
          <div className={classNames({
            [Styles.cell]: true,
            [Styles.mediumCell]: true,
          })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.how_long_addr.required ? '*' : ''}${formAttrs.how_long_addr.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.how_long_addr}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div className={classNames({
            [Styles.cell]: true,
            [Styles.longCell]: true,
          })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.prior_addr.required ? '*' : ''}${formAttrs.prior_addr.title}`}</p>
            <textarea
              className={Styles.textArea}
              onChange={this.handleInputChange}
              name={formAttrs.prior_addr.propName}
              value={formAttrs.prior_addr.value}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div className={classNames({
            [Styles.cell]: true,
            [Styles.longCell]: true,
          })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.prior_states.required ? '*' : ''}${formAttrs.prior_states.title}`}</p>
            <textarea
              className={Styles.textArea}
              onChange={this.handleInputChange}
              name={formAttrs.prior_states.propName}
              value={formAttrs.prior_states.value}
            />
          </div>
        </div>

      </div>
    );
  }
}

BasicInformation.defaultProps = {
  highlightRequiredField: false,
};

BasicInformation.propTypes = {
  basicInfoForm: PropTypes.instanceOf(BasicInfoForm).isRequired,
  highlightRequiredField: PropTypes.bool,
};

export default Radium(BasicInformation);
