import React, { Component } from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import Styles from './Carousel.module.scss';
import icControl from '../../../../../assets/ic-carousel-left-control.png';

class Carousel extends Component {
  constructor(props) {
    super(props);
    const { photos = [] } = props;
    this.state = {
      position: 0,
      numPhotoVisible: 4,
      width: 1200,
      photos,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { photos = [] } = nextProps;
      this.setState({ photos });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions, false);
  }

  updateWindowDimensions = () => {
    if (window.innerWidth <= 1300) {
      this.setState({ width: 900, numPhotoVisible: 3 });
    } else {
      this.setState({ width: 1200, numPhotoVisible: 4 });
    }
  };

  onPrevious = () => {
    const { position } = this.state;
    if (position < 0) {
      this.setState({ position: position + 1 });
    }
  };

  onNext = () => {
    const { position, photos, numPhotoVisible } = this.state;
    if (position + photos.length > numPhotoVisible) {
      this.setState({ position: position - 1 });
    }
  };

  render() {
    const { position, photos, width } = this.state;
    const carouselStyle = {
      width: `${width}px`,
      transform: `translateX(${position * 300}px)`,
    };
    const images = photos.map(photo => (<img alt="" src={photo.url} key={photo.id} />));
    return (
      <div className={Styles.container}>
        <img alt="" style={{ marginRight: '47px' }} src={icControl} onClick={this.onPrevious} />
        <div className={Styles.wrapper} style={{ width: `${width}px` }}>
          <div className={Styles.carousel} style={carouselStyle}>
            {images}
          </div>
        </div>
        <img alt="" style={{ marginLeft: '47px', transform: 'rotate(180deg' }} src={icControl} onClick={this.onNext} />
      </div>
    );
  }
}

Carousel.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string,
  })).isRequired,
};

export default Radium(Carousel);
