import React from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import renderHTML from 'react-render-html';
import { tify } from 'chinese-conv';
import {LANG_EN, LANG_ZH_TRADITIONAL, PATH_FERTILITY_CENTER_DETAILS, PATH_POST} from '../../res/constants';
import { getHospitalWithId } from '../../Api/Apis/Hospitals';
import Styles from './Post.module.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { getPostWithId } from '../../Api/Apis/Posts';

const mapStateToProps = state => ({
  langState: state.langState,
});


class Post extends React.Component {
  constructor(props) {
    super(props);
    const { id } = props.match.params;
    const { path } = props.match;
    this.state = { id, path };
  }

  componentDidMount() {
    const { id, path } = this.state;
    if (id != null && path != null) {
      if (path.includes(PATH_FERTILITY_CENTER_DETAILS)) {
        getHospitalWithId(id)
          .then((res) => {
            const { data: hospital } = res.data;
            this.setState({ hospital });
          })
          .catch((err) => {});
      } else if (path.includes(PATH_POST)) {
        getPostWithId(id)
          .then((res) => {
            const { data: post } = res.data;
            this.setState({ post });
          })
          .catch((err) => {});
      }
    }
  }

  render() {
    const { hospital, post } = this.state;
    const { langState } = this.props;
    const { lang } = langState;
    let title = '';
    let date = '';
    let coverPhotoUrl = '';
    let contents = '';
    if (hospital != null) {
      title = hospital.name;
      coverPhotoUrl = hospital.cover_photo_url;
      contents = hospital.content;
    } else if (post != null) {
      title = post.title;
      if (lang !== LANG_EN) {
        moment.locale('zh-cn');
        date = moment(new Date(post.created_at)).format('MMMDo, YYYY');
      } else {
        moment.locale('en');
        date = moment(new Date(post.created_at)).format('MMMM D, YYYY');
      }
      coverPhotoUrl = post.cover_photo_url;
      contents = post.content;
    }
    return (
      <div className={Styles.container}>

        <Header />

        <div className={Styles.postHeader}>
          <div className={Styles.headerContent}>
            <h1>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
          </div>
        </div>

        <div className={Styles.contents} style={{ height: hospital != null || post != null ? 'fit-content' : '100vh' }}>
          <img alt="" src={coverPhotoUrl} />
          <h2 className={Styles.subTitle}>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h2>
          <div className={Styles.name}>
            {' '}
              By
            <span> NewCare </span>
              Posted
            <span>
              {' '}
              {date}
            </span>
          </div>
          <div>
            {renderHTML(lang === LANG_ZH_TRADITIONAL ? tify(contents) : contents)}
          </div>
        </div>

        <Footer />

      </div>
    );
  }
}

Post.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({ id: PropTypes.string }),
  }).isRequired,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(Post));
