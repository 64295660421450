import queryString from 'query-string';
import { sify, tify } from 'chinese-conv';
import {
  LANG_EN, LANG_ZH_SIMPLIFIED, LANG_ZH_TRADITIONAL, USER_STATUS_REQUEST_CONSULTATION,
} from '../res/constants';
import { updateUserInfo } from '../Api/Apis/Users';
import ModalTypes from '../components/Modals/ModalTypes';
import { showModal } from '../redux/Modal/Modalactions';
import { setUser } from '../redux/User/UserActions';

export function formatFileSize(bytes, decimalPoint) {
  if (bytes === 0) return '0 Bytes';
  const k = 1000;


  const dm = decimalPoint || 2;


  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];


  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function mapLangToReadable(lang) {
  switch (lang) {
    case LANG_EN:
      return 'EN';
    case LANG_ZH_SIMPLIFIED:
      return '简';
    case LANG_ZH_TRADITIONAL:
      return '繁';
    default:
      return '';
  }
}

export function getValueWith({
  EnKeyMaps, ZhKeyMaps, key, lang,
}) {
  switch (lang) {
    case LANG_EN:
      return EnKeyMaps.get(key);
    case LANG_ZH_SIMPLIFIED:
      return ZhKeyMaps.get(key);
    case LANG_ZH_TRADITIONAL:
      return tify(ZhKeyMaps.get(key));
    default:
      return '';
  }
}

export function translate({ lang, value }) {
  if (lang === LANG_ZH_TRADITIONAL) {
    return tify(value);
  }
  return sify(value);
}

export function syncLang(props) {
  const { location, langState, history } = props;
  const { lang } = langState;
  const { search } = location;
  let params = queryString.parse(search);
  if (params.lang !== lang) {
    params = { ...params, lang };
    location.search = queryString.stringify(params);
    history.replace(location);
  }
}

export const onGetConsultation = (options) => {
  const { user, dispatch, langState } = options;
  const { lang } = langState;
  const LangPacks = {
    EnKeyMaps: new Map([
      ['modal_title', 'Success'],
      ['modal_msg', 'Your request has been successfully submitted. Our stuff will contact you within 2 business days.'],
    ]),
    ZhKeyMaps: new Map([
      ['modal_title', '请求成功'],
      ['modal_msg', '您的请求已经成功发送，我们会在2个工作日之内尽快与您联系。'],
    ]),
  };
  if (user != null) {
    updateUserInfo({ status: USER_STATUS_REQUEST_CONSULTATION })
      .then((res) => {
        const { data: updatedUser } = res.data;
        const successModal = {
          title: getValueWith({ key: 'modal_title', lang, ...LangPacks }),
          message: getValueWith({ key: 'modal_msg', lang, ...LangPacks }),
        };
        const ModalData = {
          modalProps: {
            isOpen: true,
            props: { ...successModal },
          },
          modalType: ModalTypes.ALERT,
        };
        dispatch(showModal(ModalData));
        dispatch(setUser(updatedUser));
      })
      .catch(() => {});
  } else {
    const data = {
      modalProps: {
        isOpen: true,
        props: {
          options: { status: USER_STATUS_REQUEST_CONSULTATION },
        },
      },
      modalType: ModalTypes.SIGN_UP,
    };
    dispatch(showModal(data));
  }
};

export function parseEduLevel(eduLevel = '') {
  switch (sify(eduLevel.toLowerCase())) {
    case 'High School Degree'.toLowerCase():
    case '高中':
      return '高中/同等文凭 High School Degree/GED';
    case 'Vocational Degree'.toLowerCase():
    case '职业学位':
      return '职业学位 Vocational Degree';
    case 'Associated Degree'.toLowerCase():
    case '大专':
      return '大专 Associated';
    case 'Bachelors Degree'.toLowerCase():
    case '本科/学士':
      return '本科/学士 Bachelors Degree';
    case 'Masters Degree'.toLowerCase():
    case '硕士':
      return '硕士 Masters Degree';
    case 'Doctoral Degree'.toLowerCase():
    case '博士':
      return '博士 Doctoral Degree';
    case 'Post-Doctoral Degree'.toLowerCase():
    case '博士后':
      return '博士后 Post-Doctoral Degree';
    case 'Professional Certification'.toLowerCase():
    case '职业证书':
      return '职业证书 Professional Certification';
    default:
      return eduLevel;
  }
}
export function parseMaritalStatus(status = '') {
  switch (sify(status.toLowerCase())) {
    case 'Single'.toLowerCase():
    case '单身':
      return '单身 Single';
    case 'Married'.toLowerCase():
    case '已婚':
      return '已婚 Married';
    case 'Divorced'.toLowerCase():
    case '离婚':
      return '离婚 Divorced';
    case 'Widowed'.toLowerCase():
    case 'Has Stable Boyfriend'.toLowerCase():
    case '有稳定男友':
      return '有稳定男友 Has Stable Boyfriend';
    case '寡妇':
      return '寡妇 Widowed';
    default:
      return status;
  }
}

export function parseRelation({ lang = LANG_EN, relation = '' }) {
  if (lang === LANG_EN) {
    return relation;
  }
  switch (relation) {
    case 'No relationship':
      return '无关系';
    case 'Can be in contact':
      return '可以联络';
    case 'Always be in contact':
      return '一直保持联络';
    default:
      return relation;
  }
}

export function parseEthnicity({ lang = LANG_EN, ethnicity = '' }) {
  if (lang === LANG_EN) {
    return ethnicity;
  }
  switch (ethnicity) {
    case 'Chinese / Taiwanese':
      return '中国人 / 台湾人';
    case 'Asian':
      return '亚洲人';
    case 'Caucasian':
      return '白人';
    case 'African American':
      return '黑人';
    case 'Native American':
      return '印第安人';
    case 'Hispanic / Latino':
      return '墨西哥人';
    default:
      return ethnicity;
  }
}

export function parseNationality({ lang = LANG_EN, nationality = '' }) {
  if (lang === LANG_EN) {
    return nationality;
  }
  switch (nationality) {
    case 'Taiwan/China/Other Asian country':
      return '台湾/中国/其他亚洲国家';
    case 'United States':
      return '美国';
    default:
      return nationality;
  }
}

export function parseSkinColor({ lang = LANG_EN, skinColor = '' }) {
  if (lang === LANG_EN) {
    switch (sify(skinColor.toString().toLowerCase())) {
      case 'pale':
      case '白皙':
        return 'Pale';
      case 'fair':
      case '健康':
        return 'Fair';
      case 'bronze':
      case '古铜':
        return 'Bronze';
      case 'dark brown':
      case '深咖啡色':
        return 'Dark Brown';
      default:
        return skinColor;
    }
  } else {
    switch (sify(skinColor.toString().toLowerCase())) {
      case 'pale':
      case '白皙':
        return '白皙';
      case 'fair':
      case '健康':
        return '健康';
      case 'bronze':
      case '古铜':
        return '古铜';
      case 'dark brown':
      case '深咖啡色':
        return '深咖啡色';
      default:
        return skinColor;
    }
  }
}

export function parseHairColor({ lang = LANG_EN, hairColor = '' }) {
  if (lang === LANG_EN) {
    switch (sify(hairColor.toString().toLowerCase())) {
      case 'black':
      case '黑色':
        return 'Black';
      case 'dark brown':
      case '深咖啡色':
        return 'Dark Brown';
      case 'brown':
      case '咖啡色':
        return 'Brown';
      case 'light brown':
      case '浅咖啡色':
        return 'Light Brown';
      case 'blond':
      case '金色':
        return 'Blond';
      case 'dirty blond':
      case '深金色':
        return 'Dirty Blond';
      case 'red':
      case '红色':
        return 'Red';
      default:
        return hairColor;
    }
  } else {
    switch (sify(hairColor.toString().toLowerCase())) {
      case 'black':
      case '黑色':
        return '黑色';
      case 'dark brown':
      case '深咖啡色':
        return '深咖啡色';
      case 'brown':
      case '咖啡色':
        return '咖啡色';
      case 'light brown':
      case '浅咖啡色':
        return '浅咖啡色';
      case 'blond':
      case '金色':
        return '金色';
      case 'dirty blond':
      case '深金色':
        return '深金色';
      case 'red':
      case '红色':
        return '红色';
      default:
        return hairColor;
    }
  }
}

export function parseEyeColor({ lang = LANG_EN, eyeColor = '' }) {
  if (lang === LANG_EN) {
    switch (sify(eyeColor.toString().toLowerCase())) {
      case 'black':
      case '黑色':
        return 'Black';
      case 'dark brown':
      case '深咖啡色':
        return 'Dark Brown';
      case 'brown':
      case '咖啡色':
        return 'Brown';
      case 'blue':
      case '蓝色':
        return 'Blue';
      case 'green':
      case '绿色':
        return 'Green';
      default:
        return eyeColor;
    }
  } else {
    switch (sify(eyeColor.toString().toLowerCase())) {
      case 'black':
      case '黑色':
        return '黑色';
      case 'dark brown':
      case '深咖啡色':
        return '深咖啡色';
      case 'brown':
      case '咖啡色':
        return '咖啡色';
      case 'blue':
      case '蓝色':
        return '蓝色';
      case 'green':
      case '绿色':
        return '绿色';
      default:
        return eyeColor;
    }
  }
}

export function parseHealthCondition({ lang = LANG_EN, healthCondition = '' }) {
  if (lang === LANG_EN) {
    switch (sify(healthCondition.toString().toLowerCase()).trim()) {
      case 'good':
      case '良好':
        return 'Good';
      case 'sick':
      case '生病':
        return 'Sick';
      case 'passed away':
      case '过世':
        return 'Passed away';
      case 'passed away accidentally':
      case '意外过世':
        return 'Passed away accidentally';
      default:
        return healthCondition;
    }
  } else {
    switch (sify(healthCondition.toString().toLowerCase())) {
      case 'good':
      case '良好':
        return '良好';
      case 'sick':
      case '生病':
        return '生病';
      case 'passed away':
      case '过世':
        return '过世';
      case 'passed away accidentally':
      case '意外过世':
        return '意外过世';
      default:
        return healthCondition;
    }
  }
}

export function parseSexualOrientation(value = '') {
  switch (sify(value.toLowerCase())) {
    case 'men':
    case '男':
      return '男 Men';
    case 'women':
    case '女':
      return '女 Women';
    case 'both':
    case '二者皆可':
      return '二者皆可 Both';
    default:
      return value;
  }
}

export function parseEyeCondition(eyeCondition = '') {
  const condition = sify(eyeCondition.toLowerCase());
  let result = '';
  if (condition.includes('very good') || condition.includes('很好')
      || condition.includes('no problem')) {
    result += '很好 Very good';
  } if (condition.includes('myopia') || condition.includes('近视')) {
    result += result.length > 0 ? ', ' : '';
    result += '近视 Myopia - Nearsighted';
  } if (condition.includes('hypermetropia') || condition.includes('远视')) {
    result += result.length > 0 ? ', ' : '';
    result += '远视 Hypermetropia - Farsighted';
  } if (condition.includes('amblyopia') || condition.includes('弱视')) {
    result += result.length > 0 ? ', ' : '';
    result += '弱视 Amblyopia - Weaksighted';
  } if (condition.includes('astigmatism') || condition.includes('散光')) {
    result += result.length > 0 ? ', ' : '';
    result += '散光 Astigmatism';
  }
  return result;
}

export function parseSexOrientation(sexOrientation = '') {
  switch (sify(sexOrientation.toLocaleLowerCase())) {
    case 'Heterosexual'.toLocaleLowerCase():
    case '异性恋':
      return '异性恋 Heterosexual';
    case 'Homosexual'.toLocaleLowerCase():
    case '同性恋':
      return '同性恋 Homosexual';
    case 'Bisexual'.toLocaleLowerCase():
    case '双性恋':
      return '双性恋 Bisexual';
    default:
      return sexOrientation;
  }
}

export function parseBloodType({ bloodType = '', lang = LANG_EN }) {
  if (bloodType.toLowerCase().replace('’', "'").includes("don't know") && lang !== LANG_EN) {
    return '不知道';
  }
  return bloodType;
}

export function parseStatus({ status = '', lang = LANG_EN }) {
  if (lang === LANG_EN) { return status; }
  switch (status) {
    case 'Available':
      return '可用 Available';
    case 'Unavailable':
      return '不可用 Unavailable';
    case 'Matched':
      return '已配对 Matched';
    default:
      return status;
  }
}

export function parseAnonymousVideo(value = '', lang = LANG_EN) {
  if (typeof value !== 'number') {
    return value;
  }
  if (lang === LANG_EN) {
    if (value === 1) {
      return 'Yes';
    } if (value === 2) {
      return 'No';
    }
  } else {
    if (value === 1) {
      return '是';
    } if (value === 2) {
      return '否';
    }
  }
  return '';
}

export const shallHighlight = attr => attr.required && (attr.value == null || (typeof attr.value === 'string' && attr.value.trim().length === 0));
