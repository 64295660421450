import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Styles from './DonorsListCard.module.scss';
import {
  DonorsListCardRows, DonorsListCardRowsZh, SurrogatesListCardRows, SurrogatesListCardRowsZh,
} from './constatns';
import { LANG_EN, PATH_DONORS_LIST, PATH_SURROGATE_MOTHERS_LIST } from '../../../../res/constants';
import { translate } from '../../../../Utils/Util';

const mapStateToProps = state => ({
  langState: state.langState,
});

class DonorsListCard extends React.Component {
  constructor(props) {
    super(props);
    const { donor } = props;
    this.state = { donor, donorsListCardRows: [] };
  }

  componentDidMount() {
    const { langState } = this.props;
    this.setContents(langState.lang);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setContents(langState.lang);
    }
  }

  setContents = (lang) => {
    const { location } = this.props;
    const { pathname } = location;
    let donorsListCardRows = lang === LANG_EN ? DonorsListCardRows : DonorsListCardRowsZh;
    if (pathname === PATH_SURROGATE_MOTHERS_LIST) {
      donorsListCardRows = lang === LANG_EN ? SurrogatesListCardRows : SurrogatesListCardRowsZh;
    }

    this.setState({ donorsListCardRows });
  };

  render() {
    const { donor, donorsListCardRows } = this.state;
    const { langState } = this.props;
    const { lang } = langState;
    const { location } = this.props;
    const { pathname } = location;
    const rows = donorsListCardRows.map((row, index) => {
      const cols = row.col.map((col) => {
        let value = donor != null ? (donor[col.propName] || '') : '';
        if (col.propName === 'id') {
          value = pathname === PATH_DONORS_LIST
            ? donor.cus_donor_app_id : donor.cus_surrogate_app_id;
        }
        if (col.propName === 'date_of_birth' && value.trim().length > 0) {
          const birthDate = new Date(value);
          const month = birthDate.getMonth() + 1;
          value = `${month >= 10 ? month : `0${month}`}/${birthDate.getFullYear()}`;
        }
        if (col.unit != null && value != null) {
          if (typeof value === 'number' || (typeof value === 'string' && value.trim().length > 0)) {
            value += col.unit;
          }
        }
        return (
          <div className={Styles.col} key={col.propName}>
            <p style={{ width: '167px' }}>{translate({ lang, value: col.title })}</p>
            <p>{value}</p>
          </div>
        );
      });
      return (
        <div className={Styles.row} style={index % 2 !== 0 ? { backgroundColor: '#fefafc' } : {}} key={index}>
          {cols}
        </div>
      );
    });
    return (
      <div className={Styles.container}>
        <img alt="" src={donor.avatar} />
        <div className={Styles.infoArea}>
          {rows}
        </div>
      </div>
    );
  }
}

DonorsListCard.propTypes = {
  donor: PropTypes.shape({
    avatar: PropTypes.string,
  }).isRequired,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export default connect(mapStateToProps)(Radium(DonorsListCard));
