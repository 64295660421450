import { sify, tify } from 'chinese-conv';
import React, { Fragment } from 'react';
import * as classNames from 'classnames';
import AutoComplete from 'react-autocomplete';
import {
  ERROR_TYPE_MISSED_FIELD,
  FIELD_TYPE_MULTIPLE_CHOICE,
  FIELD_TYPE_PICKER, FIELD_TYPE_TEXTAREA, FIELD_TYPE_YES_OR_NO,
  LANG_EN, LANG_ZH_SIMPLIFIED, LANG_ZH_TRADITIONAL,
} from '../../../../../../res/constants';
import { uploadPhoto } from '../../../../../../Api/Apis/Photos';
import Styles from '../../FamilyHistory/FamilyHistory.module.scss';
import Styles3 from '../BasicInformation.module.scss';
import RadioButton from '../../../../../../components/RadioButton/RadioButton';
import Styles2 from '../../HealthHistory/HealthHistory.module.scss';
import {
  Prop,
  retrieveKeyValuesFrom,
} from './Utils';
import CheckBox from '../../../../../../components/CheckBox/CheckBox';
import { shallHighlight } from '../../../../../../Utils/Util';

class BaseForm {
  constructor({
    user = null, component = null, lang = LANG_EN, ContentsEn, ContentsZh, appType = 'donor_app',
  }) {
    this.appType = appType;
    this.user = user;
    this.component = component;
    this.lang = lang;
    this.ContentsEn = ContentsEn;
    this.ContentsZh = ContentsZh;
    this.user_attributes = {};
    this.form_attributes = {};
    this.photos_attributes = {};
    this.yesTxt = 'Yes';
    this.noTxt = 'No';
  }

  setContents = (lang = LANG_EN) => {
    this.lang = lang;
    if (this.ContentsEn && this.ContentsZh) {
      const contentsMap = lang === LANG_EN ? this.ContentsEn : this.ContentsZh;
      this.setAttributesContents({ contentsMap, lang, attrs: this.user_attributes });
      this.setAttributesContents({ contentsMap, lang, attrs: this.form_attributes });
      this.setAttributesContents({ contentsMap, lang, attrs: this.photos_attributes });
    }
    this.yesTxt = lang === LANG_EN ? 'Yes' : '是';
    this.noTxt = lang === LANG_EN ? 'No' : '否';
    this.reRenderComponent();
  };

  setAttributesContents = ({ contentsMap, attrs, lang }) => {
    const formAttrs = attrs;
    Object.keys(formAttrs).forEach((attr) => {
      const contents = contentsMap.get(attr);
      if (contents) {
        Object.keys(contents).forEach((key) => {
          if (typeof contents[key] === 'string') {
            if (lang === LANG_ZH_SIMPLIFIED) {
              contents[key] = sify(contents[key]);
            } else if (lang === LANG_ZH_TRADITIONAL) {
              contents[key] = tify(contents[key]);
            }
          } else if (contents[key] instanceof Array) {
            contents[key].forEach((item, index) => {
              if (typeof item === 'string') {
                if (lang === LANG_ZH_SIMPLIFIED) {
                  contents[key][index] = sify(item);
                } else if (lang === LANG_ZH_TRADITIONAL && !contents.notUnsimplified) {
                  contents[key][index] = tify(item);
                }
              }
            });
          }
        });
        const { type } = formAttrs[attr];
        let { value } = formAttrs[attr];
        // translate if necessary
        if (typeof value === 'string') {
          if (lang === LANG_ZH_SIMPLIFIED) {
            value = sify(value);
          } else if (lang === LANG_ZH_TRADITIONAL && !contents.notUnsimplified) {
            value = tify(value);
          }
        }
        // if its type is multiple choice, assign value to empty string
        // it's too complicated to convert for now
        if (type === FIELD_TYPE_MULTIPLE_CHOICE) {
          if (!formAttrs[attr].options.some(option => value.includes(option))) {
            value = '';
          }
        }
        formAttrs[attr] = {
          ...attrs[attr],
          ...contents,
          value,
        };
      }
    });
  };

  reRenderComponent = () => {
    if (this.component) {
      this.component.setState({ form: this });
    }
  };

  setComponent = (component) => {
    this.component = component;
  };

  generateNotesAttrs = () => {
    Object.values(this.form_attributes).filter(attr => attr.type === FIELD_TYPE_YES_OR_NO)
      .forEach((attr) => {
        const propName = `${attr.propName}_notes`;
        this.form_attributes[propName] = new Prop({
          propName,
          title: 'Notes',
          required: false,
        });
      });
  };

  getUserInfo = () => retrieveKeyValuesFrom(this.user_attributes);

  getFormAttrs = () => retrieveKeyValuesFrom({
    ...this.form_attributes,
    ...this.photos_attributes,
  });

  save = () => {
    const validationErr = this.validateForm();
    if (validationErr != null) { return Promise.reject(validationErr); }
    if (Object.keys(this.photos_attributes).length > 0) {
      let uploaders = [];
      Object.values(this.photos_attributes).forEach((attr) => {
        uploaders = uploaders.concat(BaseForm.getPhotosUploaders(attr, this.user.id));
      });
      return Promise.all(uploaders);
    }
    return Promise.resolve();
  };

  syncData = (user, app = this.appType) => {
    if (user == null) { return; }
    this.user = user;
    Object.keys(user).forEach((attr) => {
      if (Object.hasOwnProperty.call(this.user_attributes, attr)) {
        const userAttr = this.user_attributes[attr];
        userAttr.value = user[attr] != null ? user[attr] : '';
        if (userAttr.type instanceof Array && userAttr.type.includes(FIELD_TYPE_PICKER)
            && userAttr.type.includes(FIELD_TYPE_YES_OR_NO)) {
          if (typeof userAttr.value === 'number') {
            userAttr.value = userAttr.value === 1 ? this.yesTxt : this.noTxt;
          } else if (typeof userAttr.value === 'boolean') {
            userAttr.value = userAttr.value ? this.yesTxt : this.noTxt;
          }
        }
      }
    });
    if (user[app] == null) { return; }
    const { form_attributes: formAttrs } = user[app].data;
    if (formAttrs != null) {
      Object.keys(formAttrs).forEach((attr) => {
        if (Object.hasOwnProperty.call(this.form_attributes, attr)) {
          const formAttr = this.form_attributes[attr];
          formAttr.value = formAttrs[attr] != null ? formAttrs[attr] : '';
          if (formAttr.type instanceof Array && formAttr.type.includes(FIELD_TYPE_PICKER)
              && formAttr.type.includes(FIELD_TYPE_YES_OR_NO)) {
            if (typeof formAttr.value === 'number') {
              formAttr.value = formAttr.value === 1 ? this.yesTxt : this.noTxt;
            } else if (typeof formAttr.value === 'boolean') {
              formAttr.value = formAttr.value ? this.yesTxt : this.noTxt;
            }
          }
        }
        if (Object.hasOwnProperty.call(this.photos_attributes, attr)) {
          this.photos_attributes[attr].files = formAttrs[attr] || [];
          this.photos_attributes[attr].files = this.photos_attributes[attr].files.map(file => ({
            remoteFile: file, saved: true, name: file.file_name, size: file.size,
          }));
        }
      });
    }
    this.setContents(this.lang);
  };

  static getPhotosUploaders = (attr, userId = this.user.id) => attr.files
    .filter(file => !file.saved).map((file) => {
      if (file.saved) {
        return Promise.resolve();
      }
      // Initial FormData
      const formData = new FormData();
      formData.append('photo', file.localFile);
      formData.append('photo_type', attr.propName);
      formData.append('target_type', 'User');
      formData.append('target_id', userId);
      return uploadPhoto(formData)
        .then((res) => {
          file.saved = true;
          file.remoteFile = { ...res.data, file_name: file.name, size: file.size };
          return res;
        })
        .catch(err => err);
    });

  validateForm = () => {
    const loginErr = this.lang === LANG_EN ? 'In order to update the form, you must log in first'
      : '请先登陆再进行此操作';
    if (this.user == null) { return new Error(loginErr); }
    const allAttrs = [...Object.values(this.user_attributes),
      ...Object.values(this.form_attributes)];
    if (allAttrs.filter(attr => attr.required)
      .some(attr => attr.value == null || (typeof attr.value === 'string' && attr.value.trim().length === 0))) {
      const errMissFields = this.lang === LANG_EN ? 'Please fill all required fields.'
        : '请填写所有必填项';
      return {
        message: errMissFields,
        type: ERROR_TYPE_MISSED_FIELD,
      };
    }
    if (Object.values(this.photos_attributes)
      .some(attr => attr.required && attr.files.length < attr.filesNumLimit[0])) {
      const fileNumErr = this.lang === LANG_EN ? 'Number of selected files does not meet the requirement'
        : '文件数目与规定不符';
      return new Error(fileNumErr);
    }
    return null;
  };

  static renderFormTable = ({
    RowsAttrs, ColsAttrs, formAttrs, inputHandler, lang = LANG_EN, readOnly = false,
  }) => {
    const rows = RowsAttrs.map((row, index) => {
      let backgroundColor = '#fefafc';
      if (index % 2 === 0) {
        backgroundColor = '#ffffff';
      }
      const borderBottom = '1.5px solid rgba(238, 163, 201, 0.5)';
      const cols = ColsAttrs.map(col => (
        <div key={col.key} className={Styles.column} style={{ height: '40px', marginRight: col.key === 'edu_level' ? '68px' : '18px' }}>
          <p style={{ visibility: 'hidden', margin: 0 }}>
            {lang === LANG_ZH_SIMPLIFIED ? col.name : tify(col.name)}
          </p>
          <div style={{
            position: 'absolute', minWidth: '100%', height: '100%', top: 0,
          }}
          >
            {col.options ? (
              <AutoComplete
                getItemValue={item => item.label}
                items={col.options}
                menuStyle={{
                  zIndex: '6000', position: 'relative', left: 0, top: 0,
                }}
                renderItem={(item, isHighlighted) => (
                  <div
                    style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                  >
                    {item.label}
                  </div>
                )}
                value={formAttrs[row.key][col.key].value}
                onChange={(event) => {
                  const { target } = event;
                  target.name = formAttrs[row.key][col.key].propName;
                  inputHandler({
                    ...event, row, col, formAttrs, propName: formAttrs[row.key][col.key].propName,
                  });
                }}
                name={formAttrs[row.key][col.key].propName}
                onSelect={(val) => {
                  const event = {
                    target: {
                      name: formAttrs[row.key][col.key].propName,
                      value: val,
                    },
                  };
                  inputHandler({
                    ...event, row, col, formAttrs, propName: formAttrs[row.key][col.key].propName,
                  });
                }}
              />
            ) : (
              <input
                onChange={event => inputHandler({
                  ...event, row, col, formAttrs,
                })}
                readOnly={readOnly}
                name={formAttrs[row.key][col.key].propName}
                value={formAttrs[row.key][col.key].value}
                type={formAttrs[row.key][col.key].inputType}
                style={{ width: col.key === 'edu_level' ? '160px' : '80px' }}
              />
            )}
          </div>
        </div>
      ));
      return (
        <div className={Styles.row} key={row.key} style={[{ backgroundColor, borderBottom }]}>
          <div className={Styles.attrCell}>
            {lang === LANG_ZH_SIMPLIFIED ? row.name : tify(row.name)}
          </div>
          {cols}
        </div>
      );
    });
    const cols = ColsAttrs.map(col => (
      <div
        key={col.key}
        className={Styles.column}
        style={{ marginRight: col.key === 'edu_level' ? '68px' : '18px' }}
      >
        {lang === LANG_ZH_SIMPLIFIED ? col.name : tify(col.name)}
      </div>
    ));
    const firstRow = (
      <div className={Styles.row}>
        <div className={Styles.attrCell} />
        {cols}
      </div>
    );
    return (
      <div className={Styles.table}>
        {firstRow}
        {rows}
      </div>
    );
  };

  static renderYesOrNoTable = ({
    formAttrs, radioBtnClickHandler, title = '*Please answer following questions:', lang = LANG_EN,
    highlightRequiredField = false,
  }) => {
    const questions = Object.values(formAttrs).filter(attr => attr.type === FIELD_TYPE_YES_OR_NO);
    const rows = questions.map((item, index) => {
      let backgroundColor = '#fefafc';
      if (index % 2 === 0) {
        backgroundColor = '#ffffff';
      }
      let borderBottom = 'none';
      if (index < questions.length - 1) {
        borderBottom = '2px solid rgba(238, 163, 201, 0.5)';
      }
      return (
        <div
          className={Styles3.othersRow}
          key={item.propName}
          style={{ backgroundColor, borderBottom }}
        >
          <div style={{ width: '60%' }}>
            <p
              className={classNames({
                [Styles3.fieldTitle]: true,
                [Styles3.highlightRequired]: highlightRequiredField && shallHighlight(item),
              })}
              style={{ margin: '0' }}
            >
              {item.title}
            </p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className={Styles3.fieldTitle}>
              <RadioButton
                selected={item.value === 1}
                onClick={event => radioBtnClickHandler({
                  ...event,
                  propName: item.propName,
                  choice: 1,
                })}
              />
            </div>
            <div className={Styles3.fieldTitle} style={{ margin: '0 0 0 43px' }}>
              <RadioButton
                selected={item.value === 2}
                onClick={event => radioBtnClickHandler({
                  ...event,
                  propName: item.propName,
                  choice: 2,
                })}
              />
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className={Styles3.othersContainer}>
        <div className={Styles3.othersRow}>
          <div style={{ width: '60%' }}>
            <p className={Styles3.fieldTitle} style={{ margin: '0' }}>
              {title}
            </p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className={Styles3.fieldTitle} style={{ margin: '0' }}>{lang === LANG_EN ? 'Yes' : '是'}</div>
            <div className={Styles3.fieldTitle} style={{ margin: '0 0 0 43px' }}>{lang === LANG_EN ? 'No' : '否'}</div>
          </div>
        </div>
        {rows}
      </div>
    );
  };

  static renderYesOrNoWithNotesTable = ({
    formAttrs, radioBtnClickHandler, notesInputHandler, notesText = 'notes', lang = LANG_EN, windowWidth = null,
    highlightRequiredField = false,
  }) => {
    const attrs = Object.values(formAttrs).filter(attr => attr.type === FIELD_TYPE_YES_OR_NO)
      .sort((a, b) => a.index - b.index);
    const rows = attrs.map((attr, index) => {
      let backgroundColor = '#fefafc';
      if (index % 2 === 0) {
        backgroundColor = '#ffffff';
      }
      const borderBottom = '1.5px solid rgba(238, 163, 201, 0.5)';
      return (
        <div
          className={Styles2.row}
          key={attr.propName}
          style={[{ backgroundColor, borderBottom }]}
        >
          <div className={Styles2.rowLeft}>
            <div className={classNames({
              [Styles2.attrCell]: true,
              [Styles2.highlightRequired]: highlightRequiredField && shallHighlight(attr),
            })}
            >
              {`${attr.title}`}
            </div>
            <div className={Styles2.column}>
              <RadioButton
                selected={attr.value === 1}
                onClick={event => radioBtnClickHandler({
                  ...event,
                  propName: attr.propName,
                  choice: 1,
                })}
              />
            </div>
            <div className={Styles2.column}>
              <RadioButton
                selected={attr.value === 2}
                onClick={event => radioBtnClickHandler({
                  ...event,
                  propName: attr.propName,
                  choice: 2,
                })}
              />
            </div>
          </div>
          <div className={classNames({ [Styles2.column]: true, [Styles2.noteInput]: true })}>
            <textarea
              placeholder={windowWidth && windowWidth <= 768 ? notesText : ''}
              onChange={notesInputHandler}
              name={`${attr.propName}_notes`}
              value={formAttrs[`${attr.propName}_notes`].value}
            />
          </div>
        </div>
      );
    });
    return (
      <Fragment>
        <div className={Styles2.row} style={{ padding: 0, flexDirection: 'row' }}>
          <div className={Styles2.rowLeft}>
            <div className={Styles2.attrCell} />
            <div className={Styles2.column}>
              {lang === LANG_EN ? 'Yes' : '是'}
            </div>
            <div className={Styles2.column}>
              {lang === LANG_EN ? 'No' : '否'}
            </div>
          </div>
          {windowWidth && windowWidth <= 768 ? null : (
            <div className={Styles2.column} style={{ width: '253px' }}>
              <p style={{ width: '253px', padding: 0, margin: 0 }}>{notesText}</p>
            </div>
          )}
        </div>
        {rows}
      </Fragment>
    );
  };

  static renderQuestionsWithTextArea = ({ attrs, inputHandler }) => Object.values(attrs)
    .filter(attr => attr.type === FIELD_TYPE_TEXTAREA)
    .map(attr => (
      <div className={Styles3.row} key={attr.propName}>
        <div className={classNames({
          [Styles3.cell]: true,
          [Styles3.longCell]: true,
        })}
        >
          <p className={Styles3.fieldTitle}>{attr.required ? `*${attr.title}` : attr.title}</p>
          <textarea
            className={Styles3.textArea}
            onChange={inputHandler}
            name={attr.propName}
            value={attr.value}
          />
        </div>
      </div>
    ));

  static renderMultipleChoice = ({ attr, checkboxHandler, highlight = false }) => (
    <div className={Styles3.row} style={{ justifyContent: 'flex-start' }}>
      <div className={Styles3.cell}>
        <p
          className={classNames({
            [Styles3.fieldTitle]: true,
            [Styles3.highlightRequired]: highlight,
          })}
        >
          {`${attr.required ? '*' : ''}${attr.title}`}
        </p>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
          {attr.options.map(item => (
            <CheckBox
              text={item}
              containerStyle={{ margin: '0 50px 20px 0' }}
              name={attr.propName}
              onClick={checkboxHandler}
              checked={attr.value.split(',').indexOf(item) >= 0}
              key={item}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default BaseForm;
