
export const EnKeyMaps = new Map([
  ['title', 'Gestational Surrogacy Process'],
  ['process', [
    'Consultation',
    'Choose Surrogate Mother',
    'Video Chat with Surrogate Mother',
    'Confirm Matching & Signing Agreement',
    'Initial Medical Screening for surrogate mother',
    'Signing legal contract',
    'Transfer Embryo and confirmation of gestation',
    'Labor and Delivery',
    'Baby’s documents (passport, SSN, PBO, ect…)',
    'Home sweet home',
  ]],
]);

export const ZhKeyMaps = new Map([
  ['title', '代理妊娠流程'],
  ['process', [
    '国内谘询',
    '选择代孕妈妈',
    '安排和代孕妈妈视频见面',
    '确认配对，签署合同',
    '安排代母做身体检查',
    '签署法律合同',
    '代母移植',
    '孕育生产',
    '办理证件',
    '回国',
  ]],
]);
