import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { tify } from 'chinese-conv';
import { PersonalInfoColumns, PersonalInfoColumnsZh } from './constants';
import Styles from './PersonalInfo.module.scss';
import { LANG_EN, LANG_ZH_TRADITIONAL } from '../../../../../res/constants';
import {
  parseBloodType, parseEduLevel, parseMaritalStatus, parseSexualOrientation, parseStatus,
} from '../../../../../Utils/Util';

const mapStateToProps = state => ({
  langState: state.langState,
});

class PersonalInfo extends React.Component {
  constructor(props) {
    super(props);
    const { surrogateMother } = props;
    this.state = {
      surrogateMother,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { surrogateMother = null } = nextProps;
      this.setState({ surrogateMother });
    }
  }

  renderColumns = () => {
    const { surrogateMother } = this.state;
    const { langState } = this.props;
    const { lang } = langState;
    const personalInfoColumns = lang === LANG_EN ? PersonalInfoColumns : PersonalInfoColumnsZh;
    return personalInfoColumns.map((column, index) => {
      let backgroundColor = '#fefafc';
      if (index % 2 === 0) {
        backgroundColor = '#ffffff';
      }
      let value = '';
      if (surrogateMother != null) { value = surrogateMother[column.key] != null ? surrogateMother[column.key] : ''; }
      if (column.key === 'date_of_birth' && value.trim().length > 0) {
        const birthDate = new Date(value);
        const month = birthDate.getMonth() + 1;
        value = `${month >= 10 ? month : `0${month}`}/${birthDate.getFullYear()}`;
      }
      if (column.type === 'form_attrs') {
        if (surrogateMother && surrogateMother.surrogate_app) {
          const { form_attributes: formAttrs } = surrogateMother.surrogate_app.data;
          value = formAttrs[column.key] != null ? formAttrs[column.key] : '';
        }
        if (column.key === 'single_gestation_comp') { value = `US$${value}`; }
      }
      if (column.key === 'edu_level') {
        value = parseEduLevel(value);
      }
      if (column.key === 'blood_type') {
        value = parseBloodType({ bloodType: value, lang });
      }
      if (column.key === 'avail_status') {
        value = parseStatus({ status: value, lang });
      }
      if (column.key === 'marriage') {
        value = parseMaritalStatus(value);
      }
      if (column.key === 'sexual_contact_with') {
        value = parseSexualOrientation(value);
      }
      if (value != null && typeof value === 'string') {
        value = lang === LANG_ZH_TRADITIONAL ? tify(value) : value;
      }
      return (
        <div className={Styles.row} key={column.key} style={[{ backgroundColor }]}>
          <div className={Styles.attrCell}>
            {`${lang === LANG_ZH_TRADITIONAL ? tify(column.name) : column.name}:`}
          </div>
          <div className={Styles.valueCell}>
            {`${value} ${column.unit || ''}`}
          </div>
        </div>
      );
    });
  };

  render() {
    const columns = this.renderColumns();
    const { langState } = this.props;
    const { lang } = langState;
    const title = lang === LANG_EN ? 'Personal Information' : '个人信息';
    return (
      <div className={Styles.container}>
        <h1>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
        {columns}
        <div style={{ height: '50px' }} />
      </div>
    );
  }
}

PersonalInfo.defaultProps = {
  surrogateMother: null,
};

PersonalInfo.propTypes = {
  surrogateMother: PropTypes.shape({
    authentication_token: PropTypes.string,
  }),
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(PersonalInfo));
