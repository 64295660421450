import { loadState } from '../../Utils/LocalStorage';
import { LangActionTypes } from './LangActions';
import { LANG_EN } from '../../res/constants';

const persistedState = loadState();
let initialState = { lang: LANG_EN };
if (persistedState != null && persistedState.langState) {
  initialState = persistedState.langState;
}

export function langState(state = initialState, action) {
  switch (action.type) {
    case LangActionTypes.SET_LANGUAGE:
      return Object.assign({}, state, action.language);
    default:
      return state;
  }
}
