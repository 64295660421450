import {
  Prop,
} from '../../../../DonorApplication/components/forms/BasicInformation/forms/Utils';
import {
  FIELD_TYPE_FILE,
  PHOTO_TYPE_FULL_PHOTOS, PHOTO_TYPE_HEAD_SHOT,
  PHOTO_TYPE_LIFE_PHOTOS,
} from '../../../../../res/constants';
import BaseForm from '../../../../DonorApplication/components/forms/BasicInformation/forms/BaseForm';

class ShowYourselfForm extends BaseForm {
  constructor(user = null, component = null) {
    super({ user, component });
    this.photos_attributes = {
      life_photos: new Prop({
        propName: PHOTO_TYPE_LIFE_PHOTOS,
        type: FIELD_TYPE_FILE,
        inputType: 'file',
        title: 'Please upload 2-10 daily life pictures. No heavy makeup, crazy outwear, or using filter.',
        filesNumLimit: [2, 10],
        fileType: 'image/*',
        required: false
      }),
      full_photos: new Prop({
        propName: PHOTO_TYPE_FULL_PHOTOS,
        type: FIELD_TYPE_FILE,
        inputType: 'file',
        title: 'Please upload 2-5 full body pictures.',
        filesNumLimit: [2, 5],
        fileType: 'image/*',
        required: false
      }),
      headshot_photos: new Prop({
        propName: PHOTO_TYPE_HEAD_SHOT,
        type: FIELD_TYPE_FILE,
        inputType: 'file',
        title: 'Please upload at least 1 headshot.',
        filesNumLimit: [1, 1],
        fileType: 'image/*',
        required: false
      }),
    };
  }

  setShowYourselfComponent = (component) => {
    this.showYourselfComponent = component;
  };

  getFormAttrs = () => {
    const result = {};
    Object.values(this.photos_attributes).forEach((attr) => {
      const { files = [] } = attr;
      result[attr.propName] = files.map(file => file.remoteFile);
    });
    return result;
  };
}

export default ShowYourselfForm;
