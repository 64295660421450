export const styles = {
  container: {
    display: 'flex',
    WebkitFlexDirection: 'column',
    WebkitAlignItems: 'center',
  },
  title: {
    margin: '99px 0 55px 0',
    textShadow: '0 0 3px rgba(238, 163, 201, 0.5)',
    fontFamily: 'Avenir-Roman',
    fontSize: '48px',
    textAlign: 'center',
    color: '#000000',
  },
};
