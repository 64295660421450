import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import * as classNames from 'classnames';
import Styles from '../../../../DonorApplication/components/forms/BasicInformation/BasicInformation.module.scss';
import Styles2 from '../../../../DonorApplication/components/forms/ShowYourself/ShowYourself.module.scss';
import InsuranceInfoForm from './InsuranceInfoForm';
import Input from '../../../../DonorApplication/components/forms/BasicInformation/components/Input';
import DropdownMenu from '../../../../DonorApplication/components/forms/BasicInformation/components/DropdownMenu';
import SelectFileBtn from '../../../../DonorApplication/components/forms/ShowYourself/SelectFileBtn';
import { SelectedFiles } from '../../../../DonorApplication/components/forms/ShowYourself/ShowYourself';
import BaseForm from '../../../../DonorApplication/components/forms/BasicInformation/forms/BaseForm';
import { shallHighlight } from '../../../../../Utils/Util';

class InsuranceInfo extends React.Component {
  constructor(props) {
    super(props);
    const { form = new InsuranceInfoForm({}) } = props;
    this.state = {
      form,
    };
  }

  handleInputChange = (event) => {
    const { target } = event;
    const { name: propName, value } = target;
    const { form } = this.state;
    const { user_attributes: userAttrs, form_attributes: formAttrs } = form;
    if (Object.prototype.hasOwnProperty.call(userAttrs, propName)) {
      userAttrs[propName].value = value;
    } else if (Object.prototype.hasOwnProperty.call(formAttrs, propName)) {
      formAttrs[propName].value = value;
    }
    this.setState({ form });
  };

  handleRadioBtnEvent = (event) => {
    const { propName, choice } = event;
    const { form } = this.state;
    const { form_attributes: formAttrs } = form;
    if (Object.prototype.hasOwnProperty.call(formAttrs, propName)) {
      formAttrs[propName].value = choice;
    }
    this.setState({ form });
  };

  onSelectFile = (event) => {
    const { form } = this.state;
    const { insurance_photos: insurancePhoto } = form.photos_attributes;
    const newSelectedFiles = Array.from(event.target.files);
    if (newSelectedFiles.length > 0) {
      const newSelectedFile = newSelectedFiles[0];
      insurancePhoto.files = [{
        localFile: newSelectedFile,
        saved: false,
        name: newSelectedFile.name,
        size: newSelectedFile.size,
      }];
    }
    this.setState({ form });
  };

  onDeleteFile = (event) => {
    const { index } = event;
    const { form } = this.state;
    const { insurance_photos: insurancePhoto } = form.photos_attributes;
    insurancePhoto.files.splice(index, 1);
    this.setState({ form });
  };

  render() {
    const { form } = this.state;
    const { form_attributes: formAttrs, photos_attributes: photoAttrs } = form;
    const { insurance_photos: insurancePhoto } = photoAttrs;
    const { highlightRequiredField } = this.props;
    return (
      <div className={Styles.container}>
        <h1>Insurance Information</h1>
        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.has_health_insurance),
              })}
            >
              {`${formAttrs.has_health_insurance.required ? '*' : ''}${formAttrs.has_health_insurance.title}`}
            </p>
            <DropdownMenu
              prop={formAttrs.has_health_insurance}
              value={formAttrs.has_health_insurance.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && shallHighlight(formAttrs.has_health_insurance)}
            />
          </div>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.maternity_coverage.required ? '*' : ''}${formAttrs.maternity_coverage.title}`}</p>
            <DropdownMenu
              className={Styles.fieldContainer}
              prop={formAttrs.maternity_coverage}
              value={formAttrs.maternity_coverage.value}
              onchange={this.handleInputChange}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.insurance_company.required ? '*' : ''}${formAttrs.insurance_company.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.insurance_company}
            />
          </div>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.insurance_company_phone.required ? '*' : ''}${formAttrs.insurance_company_phone.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.insurance_company_phone}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.through_agency.required ? '*' : ''}${formAttrs.through_agency.title}`}</p>
            <DropdownMenu
              className={Styles.fieldContainer}
              prop={formAttrs.through_agency}
              value={formAttrs.through_agency.value}
              onchange={this.handleInputChange}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start', margin: '10px 0 0 0' }}>
          <div className={Styles.cell}>
            <p className={Styles.fieldTitle}>{`${insurancePhoto.required ? '*' : ''}${insurancePhoto.title}`}</p>
            <div
              className={classNames({
                [Styles2.selectBox]: true,
                [Styles.mediumCell]: true,
              })}
            >
              <SelectFileBtn
                onChange={this.onSelectFile}
                name={insurancePhoto.propName}
                fileType={insurancePhoto.fileType}
              />
            </div>
            <SelectedFiles
              name={insurancePhoto.propName}
              files={insurancePhoto.files}
              onDelete={this.onDeleteFile}
            />
          </div>
        </div>

        <div style={{ height: '20px' }} />
        {BaseForm.renderYesOrNoTable({
          formAttrs,
          radioBtnClickHandler: this.handleRadioBtnEvent,
          highlightRequiredField,
        })}

      </div>
    );
  }
}

InsuranceInfo.defaultProps = {
  highlightRequiredField: false,
};

InsuranceInfo.propTypes = {
  form: PropTypes.instanceOf(InsuranceInfoForm).isRequired,
  highlightRequiredField: PropTypes.bool,
};

export default Radium(InsuranceInfo);
