import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { tify } from 'chinese-conv';
import { FIELD_TYPE_YES_OR_NO, LANG_EN, LANG_ZH_TRADITIONAL } from '../../../../../../res/constants';
import { styles } from './MedicalInfo.css';
import InsuranceInfoForm from '../../../../../SurrogateApplication/components/forms/InsuranceInfo/InsuranceInfoForm';

class MedicalInfo extends React.Component {
  constructor(props) {
    super(props);
    const { surrogateMother = null } = props;
    const { lang } = this.props;
    this.state = {
      surrogateMother,
      form: new InsuranceInfoForm({
        user: surrogateMother, component: this, lang, appType: 'surrogate_app',
      }),
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { surrogateMother = null } = nextProps;
      const { form } = this.state;
      form.syncData(surrogateMother);
      this.setState({ surrogateMother, form }, () => {
        const { lang } = this.props;
        form.setContents(lang);
      });
    }
  }

  renderRows = () => {
    const { form } = this.state;
    const { lang } = this.props;
    const yesTxt = lang === LANG_EN ? 'Yes' : '是';
    const noTxt = lang === LANG_EN ? 'No' : '否';
    const attrs = Object.values(form.form_attributes)
      .filter(attr => attr.type === FIELD_TYPE_YES_OR_NO);
    return attrs.map((attr, index) => {
      let backgroundColor = '#fefafc';
      let marginTop = 0;
      if (lang === LANG_EN && index === 6) {
        marginTop = 14;
      }
      if (lang !== LANG_EN && index === 14) {
        marginTop = 20;
      }
      if (index % 2 === 0) {
        backgroundColor = '#ffffff';
      }
      return (
        <div key={attr.propName} style={[styles.row, { backgroundColor, marginTop }]}>
          <div style={styles.attrCell}>{`${attr.title}`}</div>
          <div style={{ ...styles.column, marginLeft: '135px' }}>
            {attr.value === 1 ? yesTxt : noTxt}
          </div>
        </div>
      );
    });
  };

  render() {
    const rows = this.renderRows();
    const { lang } = this.props;
    const title = lang === LANG_EN ? 'Medical Information' : '身体状况';
    return (
      <div style={styles.container}>
        <h1 style={styles.container.title}>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
        <div style={styles.row}>
          <div style={styles.attrCell} />
          <div style={styles.column} />
          <div style={styles.column} />
          <div style={styles.column} />
        </div>
        {rows}
        <div style={{ height: '50px' }} />
      </div>
    );
  }
}

MedicalInfo.defaultProps = {
  surrogateMother: null,
};

MedicalInfo.propTypes = {
  surrogateMother: PropTypes.shape({
    authentication_token: PropTypes.string,
  }),
  lang: PropTypes.string.isRequired,
};

export default Radium(MedicalInfo);
