import Radium from 'radium';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './Header.css';
import Managers from './managers/Managers';
import LanguageMenu from './components/LanguageMenu/LanguageMenu';
import HeaderMenu from './components/HeaderMenu/HeaderMenu';
import logo from '../../assets/logo.png';
import './Header.scss';
import { PATH_HOME_PAGE } from '../../res/constants';
import HeaderForPhone from './components/HeaderForPhone/HeaderForPhone';

class Header extends React.Component {
  constructor(props) {
    super(props);
    const { allowTransparent } = props;
    this.state = {
      allowTransparent,
      transparent: true,
    };
    this.managers = new Managers(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
    window.addEventListener('resize', this.updateWindowDimensions);
    this.updateWindowDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.updateWindowDimensions, false);
  }

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  handleScroll = (event) => {
    const { allowTransparent } = this.state;
    if (allowTransparent) {
      const { pageYOffset } = window;
      if (pageYOffset >= 80) {
        this.setState({ transparent: false });
      } else {
        this.setState({ transparent: true });
      }
    }
  };

  render() {
    const { transparent, allowTransparent, windowWidth = null } = this.state;
    const { fixed = true, pageName } = this.props;
    const dynamicStyle = { position: fixed ? 'fixed' : 'relative' };
    if (allowTransparent && transparent) {
      dynamicStyle.backgroundImage = 'linear-gradient(to left, rgba(248, 216, 206, 0.86), rgba(238, 163, 201, 0.86))';
    } else {
      dynamicStyle.backgroundColor = '#96d8f3';
    }
    const containerStyle = [styles.container, dynamicStyle];
    const { redirectPathAfterLogout } = this.props;
    return (
      windowWidth != null && windowWidth < 768 ? (
        <HeaderForPhone
          allowTransparent={allowTransparent}
          redirectPathAfterLogout={redirectPathAfterLogout}
        />
      ) : (
        <div
          style={containerStyle}
        >
          <div style={styles.left} className="header-img-wrap">
            <Link to={PATH_HOME_PAGE} style={{ textDecoration: 'none' }}>
              <img style={styles.logo} src={logo} alt="logo" />
            </Link>
            <LanguageMenu />
          </div>

          <div style={styles.right}>
            <HeaderMenu pageName={pageName} redirectPathAfterLogout={redirectPathAfterLogout} />
          </div>
        </div>
      )
    );
  }
}

Header.defaultProps = {
  pageName: null,
  fixed: true,
  allowTransparent: true,
  redirectPathAfterLogout: null,
};

Header.propTypes = {
  pageName: PropTypes.string,
  fixed: PropTypes.bool,
  allowTransparent: PropTypes.bool,
  redirectPathAfterLogout: PropTypes.string,
};

export default Radium(Header);
