import React from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import Styles from './ForgetPasswordCard.module.scss';
import { hideModal } from '../../../redux/Modal/Modalactions';

class ForgetPasswordCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'I forgot my password!',
      content: 'No problem! We\'ll send you an email so you can quickly change your password.',
    };
  }

  render() {
    const { title, content } = this.state;
    const { dispatch } = this.props;
    return (
      <div className={Styles.card}>
        <img alt="" className={Styles.btnClose} onClick={() => dispatch(hideModal())} />
        <h1>{title}</h1>
        <p>{content}</p>
        <div className={Styles.field}>
          <input type="email" placeholder="Email" />
        </div>

        <div className={Styles.resetPswBtn}>
          <p>Reset my password</p>
        </div>
      </div>
    );
  }
}

ForgetPasswordCard.defaultProps = {
};

ForgetPasswordCard.propTypes = {
};


export default connect()(Radium(ForgetPasswordCard));
