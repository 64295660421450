
import { getAxiosInstance } from '../../Utils/WebRequest';

export const UserApiRoute = '/api/users';

export function signUp(user) {
  return getAxiosInstance().post(`${UserApiRoute}/sign_up`, { user });
}

export function logIn(user) {
  return getAxiosInstance().post(`${UserApiRoute}/login`, { user });
}

export function getUserInfo() {
  return getAxiosInstance().get(UserApiRoute);
}

export function updateUserInfo(user) {
  return getAxiosInstance().put(`${UserApiRoute}/update`, { user });
}

export function requestViewMore() {
  return getAxiosInstance().post(`${UserApiRoute}/request_view_more`);
}

export function fetchUserById(id) {
  return getAxiosInstance().get(`${UserApiRoute}/${id}`);
}

export function downloadProfilePdf(id, html) {
  if (id == null || html == null) { return Promise.reject(new Error('id or html is not provided')); }
  return getAxiosInstance().post(`${UserApiRoute}/${id}/download_profile.pdf`, { html }, {
    headers: {
      Accept: 'application/pdf',
    },
    responseType: 'blob',
  });
}

export function fetchSingleUser({ page, filterOptions = {} }) {
  if (page == null) { return Promise.reject(new Error('page is not provided.')); }
  return getAxiosInstance().get(`${UserApiRoute}/query`, {
    params: { ...filterOptions, page: page + 1 },
  });
}
