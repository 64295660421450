import styled from 'styled-components';

export const section = {
  minHeight: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
};

const styles = {
  container: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
};

export const HomePageSection = styled.div`
  width: 100%;
  height: 100vh;
`;


export default styles;
