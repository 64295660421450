import React from 'react';
import Radium from 'radium';
import SignatureCanvas from 'react-signature-canvas';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import * as classNames from 'classnames';
import Styles from './Completion.module.scss';
import Styles2 from '../../../../DonorApplication/components/forms/BasicInformation/BasicInformation.module.scss';
import Input from '../../../../DonorApplication/components/forms/BasicInformation/components/Input';
import CompletionForm from './CompletionForm';
import { CompensationNotes, ConsentNotes, ConsentOfGestationalCarrier } from './constants';
import { shallHighlight } from '../../../../../Utils/Util';

class Completion extends React.Component {
  constructor(props) {
    super(props);
    const { completionForm } = props;
    this.consentSignatureBase64 = null;
    this.gestationalCarrierSignatureBase64 = null;
    this.state = {
      completionForm,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions, false);
  }

  updateWindowDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  bindSignaturePadRef = (ref, _signatureAttr) => {
    // note that, this function is invoked for every render so
    const signatureAttr = _signatureAttr;
    signatureAttr.ref = ref;
    const { base64 } = signatureAttr;
    // if signature pad ref is not null and signature image exists, draw it to canvas
    if (ref != null && base64 != null
        && ((signatureAttr.propName === 'consent_signature' && this.consentSignatureBase64 !== base64)
            || (signatureAttr.propName === 'gestational_carrier_signature' && this.gestationalCarrierSignatureBase64 !== base64))
    ) {
      signatureAttr.saved = false;
      if (signatureAttr.propName === 'consent_signature') {
        this.consentSignatureBase64 = base64;
      } else {
        this.gestationalCarrierSignatureBase64 = base64;
      }
      ref.clear();
      ref.fromDataURL(base64);
    }
  };

  onEndSign = (_signatureAttr) => {
    const signatureAttr = _signatureAttr;
    const { ref } = signatureAttr;
    // update signature in form base64
    if (ref != null) {
      signatureAttr.saved = false;
      const newBase64 = ref.toDataURL();
      if (signatureAttr.propName === 'consent_signature') {
        this.consentSignatureBase64 = newBase64;
      } else {
        this.gestationalCarrierSignatureBase64 = newBase64;
      }
      signatureAttr.base64 = newBase64;
    }
  };

  onClear = (_signatureAttr) => {
    const signatureAttr = _signatureAttr;
    const { ref } = signatureAttr;
    if (ref != null) {
      signatureAttr.saved = false;
      if (signatureAttr.propName === 'consent_signature') {
        this.consentSignatureBase64 = null;
      } else {
        this.gestationalCarrierSignatureBase64 = null;
      }
      signatureAttr.base64 = null;
      ref.clear();
    }
  };

  handleInputChange = (event) => {
    const { target } = event;
    const { name: propName, value } = target;
    const { completionForm } = this.state;
    if (Object.prototype.hasOwnProperty.call(completionForm.attrs, propName)) {
      completionForm.attrs[propName].value = value;
    } else if (Object.prototype.hasOwnProperty.call(completionForm.form_attributes, propName)) {
      completionForm.form_attributes[propName].value = value;
    }
    this.setState({ completionForm });
  };

  render() {
    const { completionForm, windowWidth } = this.state;
    if (windowWidth == null) { return null; }
    const { attrs, form_attributes: formAttrs } = completionForm;
    const { highlightRequiredField } = this.props;
    return (
      <div className={Styles.container}>
        <h1>Compensation</h1>
        {renderHTML(CompensationNotes)}
        <div className={Styles2.row} style={{ marginTop: '20px' }}>
          <div className={classNames({
            [Styles2.cell]: true,
            [Styles2.shortCell]: true,
            [Styles2.cellMarginBottom]: true,
          })}
          >
            <p
              className={classNames({
                [Styles2.fieldTitle]: true,
                [Styles2.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.single_gestation_comp),
              })}
            >
              {formAttrs.single_gestation_comp.title}
            </p>
            <Input
              prop={formAttrs.single_gestation_comp}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && shallHighlight(formAttrs.single_gestation_comp)}
            />
          </div>
          <div className={classNames({
            [Styles2.cell]: true,
            [Styles2.shortCell]: true,
            [Styles2.cellMarginBottom]: true,
          })}
          >
            <p
              className={classNames({
                [Styles2.fieldTitle]: true,
                [Styles2.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.double_gestation_comp),
              })}
            >
              {formAttrs.double_gestation_comp.title}
            </p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.double_gestation_comp}
              highlight={highlightRequiredField
              && shallHighlight(formAttrs.double_gestation_comp)}
            />
          </div>
          <div className={classNames({
            [Styles2.cell]: true,
            [Styles2.shortCell]: true,
          })}
          >
            <p
              className={classNames({
                [Styles2.fieldTitle]: true,
                [Styles2.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.triple_gestation_comp),
              })}
            >
              {formAttrs.triple_gestation_comp.title}
            </p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.triple_gestation_comp}
              highlight={highlightRequiredField
              && shallHighlight(formAttrs.triple_gestation_comp)}
            />
          </div>
        </div>

        <h1>Consent</h1>
        {renderHTML(ConsentNotes)}
        <div className={Styles.signaturePadWrapper}>
          <SignatureCanvas
            canvasProps={{ width: windowWidth <= 768 ? `${windowWidth * 0.9 - 20}px` : '790px', height: '196px' }}
            ref={ref => this.bindSignaturePadRef(ref, attrs.consent_signature)}
            onEnd={() => this.onEndSign(attrs.consent_signature)}
          />
        </div>
        <p className={Styles.clear} onClick={() => this.onClear(attrs.consent_signature)}>Clear</p>
        <div className={Styles2.row}>
          <div className={classNames({
            [Styles2.cell]: true,
            [Styles2.mediumCell]: true,
            [Styles2.cellMarginBottom]: true,
          })}
          >
            <p
              className={classNames({
                [Styles2.fieldTitle]: true,
                [Styles2.highlightRequired]: highlightRequiredField
                  && shallHighlight(attrs.consent_date),
              })}
            >
            *Date
            </p>
            <Input
              onchange={this.handleInputChange}
              prop={attrs.consent_date}
              highlight={highlightRequiredField
              && shallHighlight(attrs.consent_date)}
            />
          </div>
          <div className={classNames({
            [Styles2.cell]: true,
            [Styles2.mediumCell]: true,
          })}
          >
            <p
              className={classNames({
                [Styles2.fieldTitle]: true,
                [Styles2.highlightRequired]: highlightRequiredField
                  && shallHighlight(attrs.consent_name),
              })}
            >
            *My Name
            </p>
            <Input
              onchange={this.handleInputChange}
              prop={attrs.consent_name}
              highlight={highlightRequiredField
              && shallHighlight(attrs.consent_name)}
            />
          </div>
        </div>

        <h1>Consent of Gestational Carrier</h1>
        {renderHTML(ConsentOfGestationalCarrier)}
        <div className={Styles.signaturePadWrapper}>
          <SignatureCanvas
            canvasProps={{ width: windowWidth <= 768 ? `${windowWidth * 0.9 - 20}px` : '790px', height: '196px' }}
            ref={ref => this.bindSignaturePadRef(ref, attrs.gestational_carrier_signature)}
            onEnd={() => this.onEndSign(attrs.gestational_carrier_signature)}
          />
        </div>
        <p
          className={Styles.clear}
          onClick={() => this.onClear(attrs.gestational_carrier_signature)}
        >
          Clear
        </p>
        <div className={Styles2.row}>
          <div className={classNames({
            [Styles2.cell]: true,
            [Styles2.mediumCell]: true,
            [Styles2.cellMarginBottom]: true,
          })}
          >
            <p
              className={classNames({
                [Styles2.fieldTitle]: true,
                [Styles2.highlightRequired]: highlightRequiredField
                  && shallHighlight(attrs.gestational_carrier_date),
              })}
            >
            *Date
            </p>
            <Input
              onchange={this.handleInputChange}
              prop={attrs.gestational_carrier_date}
              highlight={highlightRequiredField
              && shallHighlight(attrs.gestational_carrier_date)}
            />
          </div>
          <div className={classNames({
            [Styles2.cell]: true,
            [Styles2.mediumCell]: true,
          })}
          >
            <p
              className={classNames({
                [Styles2.fieldTitle]: true,
                [Styles2.highlightRequired]: highlightRequiredField
                  && shallHighlight(attrs.gestational_carrier_name),
              })}
            >
            *My Name
            </p>
            <Input
              onchange={this.handleInputChange}
              prop={attrs.gestational_carrier_name}
              highlight={highlightRequiredField
              && shallHighlight(attrs.gestational_carrier_name)}
            />
          </div>
        </div>

      </div>
    );
  }
}

Completion.defaultProps = {
  highlightRequiredField: false,
};

Completion.propTypes = {
  completionForm: PropTypes.instanceOf(CompletionForm).isRequired,
  highlightRequiredField: PropTypes.bool,
};

export default Radium(Completion);
