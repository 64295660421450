import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import * as classNames from 'classnames';
import { tify } from 'chinese-conv';
import Styles from '../../../../DonorApplication/components/forms/BasicInformation/BasicInformation.module.scss';
import MaritalChildStatusForm from './MaritalStatusChildForm';
import DropdownMenu from '../../../../DonorApplication/components/forms/BasicInformation/components/DropdownMenu';
import Input from '../../../../DonorApplication/components/forms/BasicInformation/components/Input';
import BaseForm from '../../../../DonorApplication/components/forms/BasicInformation/forms/BaseForm';
import { ChildrenInfoCols, ChildrenRows } from './constants';
import AutoComplete from 'react-autocomplete';
import TableStyles from './ChildrenStatusTable.module.scss';
import { LANG_EN, LANG_ZH_SIMPLIFIED } from '../../../../../res/constants';
import { shallHighlight } from '../../../../../Utils/Util';

class MaritalChildStatus extends React.Component {
  constructor(props) {
    super(props);
    const { form = new MaritalChildStatusForm({}) } = props;
    this.state = {
      form,
    };
  }

  handleInputChange = (event) => {
    const { target } = event;
    const { name: propName, value } = target;
    const { form } = this.state;
    const { user_attributes: userAttrs, form_attributes: formAttrs } = form;
    if (Object.prototype.hasOwnProperty.call(userAttrs, propName)) {
      userAttrs[propName].value = value;
    } else if (Object.prototype.hasOwnProperty.call(formAttrs, propName)) {
      formAttrs[propName].value = value;
    }
    this.setState({ form });
  };

  handleChildrenInfoTableInputChange = (event) => {
    const { target, row, col } = event;
    const { name: propName, value } = target;
    const { form } = this.state;
    const attr = form.child_info_attributes[row.key][col.key];
    if (attr.propName === propName) {
      attr.value = value;
    }
    this.setState({ form });
  };

  handleRadioBtnEvent = (event) => {
    const { propName, choice } = event;
    const { form } = this.state;
    const { form_attributes: formAttrs } = form;
    if (Object.prototype.hasOwnProperty.call(formAttrs, propName)) {
      formAttrs[propName].value = choice;
    }
    this.setState({ form });
  };

  renderFormTable = ({
    RowsAttrs, ColsAttrs, formAttrs, inputHandler, lang = LANG_EN, readOnly = false,
  }) => {
    const rows = RowsAttrs.map((row, index) => {
      let backgroundColor = '#fefafc';
      if (index % 2 === 0) {
        backgroundColor = '#ffffff';
      }
      const borderBottom = '1.5px solid rgba(238, 163, 201, 0.5)';
      const cols = ColsAttrs.map(col => (
        <div key={col.key} className={TableStyles.column} style={{ height: '40px' }}>
          <p style={{ visibility: 'hidden', margin: 0 }}>
            {lang === LANG_ZH_SIMPLIFIED ? col.name : tify(col.name)}
          </p>
          <div style={{
            position: 'absolute', minWidth: '100%', height: '100%', top: 0,
          }}
          >
            {col.options ? (
              <AutoComplete
                getItemValue={item => item.label}
                items={col.options}
                menuStyle={{
                  zIndex: '6000',
                  position: 'relative',
                  left: 0,
                  top: 0,
                  backgroundColor: 'red',
                }}
                renderItem={(item, isHighlighted) => (
                  <div
                    style={{ background: isHighlighted ? 'lightgray' : 'white' }}
                  >
                    {item.label}
                  </div>
                )}
                value={formAttrs[row.key][col.key].value}
                onChange={(event) => {
                  const { target } = event;
                  target.name = formAttrs[row.key][col.key].propName;
                  inputHandler({
                    ...event, row, col, formAttrs, propName: formAttrs[row.key][col.key].propName,
                  });
                }}
                name={formAttrs[row.key][col.key].propName}
                onSelect={(val) => {
                  const event = {
                    target: {
                      name: formAttrs[row.key][col.key].propName,
                      value: val,
                    },
                  };
                  inputHandler({
                    ...event, row, col, formAttrs, propName: formAttrs[row.key][col.key].propName,
                  });
                }}
              />
            ) : (
              <input
                onChange={event => inputHandler({
                  ...event, row, col, formAttrs,
                })}
                readOnly={readOnly}
                name={formAttrs[row.key][col.key].propName}
                value={formAttrs[row.key][col.key].value}
                type={formAttrs[row.key][col.key].inputType}
              />
            )}
          </div>
        </div>
      ));
      return (
        <div className={TableStyles.row} key={row.key} style={[{ backgroundColor, borderBottom }]}>
          <div className={TableStyles.attrCell}>
            {lang === LANG_ZH_SIMPLIFIED ? row.name : tify(row.name)}
          </div>
          {cols}
        </div>
      );
    });
    const cols = ColsAttrs.map(col => (
      <div
        key={col.key}
        className={TableStyles.column}
      >
        {lang === LANG_ZH_SIMPLIFIED ? col.name : tify(col.name)}
      </div>
    ));
    const firstRow = (
      <div className={Styles.row}>
        <div className={TableStyles.attrCell} />
        {cols}
      </div>
    );
    return (
      <div className={TableStyles.table}>
        {firstRow}
        {rows}
      </div>
    );
  };

  render() {
    const { form } = this.state;
    const {
      user_attributes: user,
      form_attributes: formAttrs, child_info_attributes: childFormAttrs,
    } = form;
    const childrenInfoTable = this.renderFormTable({
      formAttrs: childFormAttrs,
      RowsAttrs: ChildrenRows,
      ColsAttrs: ChildrenInfoCols,
      inputHandler: this.handleChildrenInfoTableInputChange,
    });
    const { highlightRequiredField } = this.props;
    return (
      <div className={Styles.container}>
        <h1>Marital Status</h1>
        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(user.marriage),
              })}
            >
              {`${user.marriage.required ? '*' : ''}${user.marriage.title}`}
            </p>
            <DropdownMenu
              prop={user.marriage}
              value={user.marriage.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && shallHighlight(user.marriage)}
            />
          </div>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.marital_problems.required ? '*' : ''}${formAttrs.marital_problems.title}`}</p>
            <DropdownMenu
              className={Styles.fieldContainer}
              prop={formAttrs.marital_problems}
              value={formAttrs.marital_problems.value}
              onchange={this.handleInputChange}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.longCell]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.marital_problems_notes.required ? '*' : ''}${formAttrs.marital_problems_notes.title}`}</p>
            <textarea
              className={Styles.textArea}
              onChange={this.handleInputChange}
              name={formAttrs.marital_problems_notes.propName}
              value={formAttrs.marital_problems_notes.value}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.partner_name.required ? '*' : ''}${formAttrs.partner_name.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.partner_name}
            />
          </div>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.partner_birth_date.required ? '*' : ''}${formAttrs.partner_birth_date.title}`}</p>
            <Input
              type="date"
              onchange={this.handleInputChange}
              prop={formAttrs.partner_birth_date}
            />
          </div>
        </div>

        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.live_together.required ? '*' : ''}${formAttrs.live_together.title}`}</p>
            <DropdownMenu
              className={Styles.fieldContainer}
              margin="0 60px 0 0"
              prop={formAttrs.live_together}
              value={formAttrs.live_together.value}
              onchange={this.handleInputChange}
            />
          </div>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.live_together_how_long.required ? '*' : ''}${formAttrs.live_together_how_long.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.live_together_how_long}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.divorce_date.required ? '*' : ''}${formAttrs.divorce_date.title}`}</p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.divorce_date}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.longCell]: true,
            })}
          >
            <p className={Styles.fieldTitle}>{`${formAttrs.break_up_explain.required ? '*' : ''}${formAttrs.break_up_explain.title}`}</p>
            <textarea
              className={Styles.textArea}
              onChange={this.handleInputChange}
              name={formAttrs.break_up_explain.propName}
              value={formAttrs.break_up_explain.value}
            />
          </div>
        </div>

        <h1>Child</h1>
        <div className={Styles.row}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
              [Styles.cellMarginBottom]: true,
            })}
          >
            {' '}
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.any_child),
              })}
            >
              {`${formAttrs.any_child.required ? '*' : ''}${formAttrs.any_child.title}`}
            </p>
            <DropdownMenu
              className={Styles.fieldContainer}
              prop={formAttrs.any_child}
              value={formAttrs.any_child.value}
              onchange={this.handleInputChange}
              highlight={highlightRequiredField
              && shallHighlight(formAttrs.any_child)}
            />
          </div>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.num_of_pregnancies),
              })}
            >
              {`${formAttrs.num_of_pregnancies.required ? '*' : ''}${formAttrs.num_of_pregnancies.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.num_of_pregnancies}
              highlight={highlightRequiredField
              && shallHighlight(formAttrs.num_of_pregnancies)}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.num_of_miscarriages),
              })}
            >
              {`${formAttrs.num_of_miscarriages.required ? '*' : ''}${formAttrs.num_of_miscarriages.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.num_of_miscarriages}
              highlight={highlightRequiredField
              && shallHighlight(formAttrs.num_of_miscarriages)}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.longCell]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.miscarriages_dates),
              })}
            >
              {`${formAttrs.miscarriages_dates.required ? '*' : ''}${formAttrs.miscarriages_dates.title}`}
            </p>
            <textarea
              className={classNames({
                [Styles.textArea]: true,
                [Styles.textAreaHighLight]: highlightRequiredField
                && shallHighlight(formAttrs.miscarriages_dates),
              })}
              onChange={this.handleInputChange}
              name={formAttrs.miscarriages_dates.propName}
              value={formAttrs.miscarriages_dates.value}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.num_of_abortions),
              })}
            >
              {`${formAttrs.num_of_abortions.required ? '*' : ''}${formAttrs.num_of_abortions.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.num_of_abortions}
              highlight={highlightRequiredField
              && shallHighlight(formAttrs.num_of_abortions)}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.longCell]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.abortions_dates),
              })}
            >
              {`${formAttrs.abortions_dates.required ? '*' : ''}${formAttrs.abortions_dates.title}`}
            </p>
            <textarea
              className={classNames({
                [Styles.textArea]: true,
                [Styles.textAreaHighLight]: highlightRequiredField
                  && shallHighlight(formAttrs.abortions_dates),
              })}
              onChange={this.handleInputChange}
              name={formAttrs.abortions_dates.propName}
              value={formAttrs.abortions_dates.value}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.mediumCell]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.num_of_stillbirths),
              })}
            >
              {`${formAttrs.num_of_stillbirths.required ? '*' : ''}${formAttrs.num_of_stillbirths.title}`}
            </p>
            <Input
              onchange={this.handleInputChange}
              prop={formAttrs.num_of_stillbirths}
              highlight={highlightRequiredField
              && shallHighlight(formAttrs.num_of_stillbirths)}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.longCell]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.stillbirths_dates),
              })}
            >
              {`${formAttrs.stillbirths_dates.required ? '*' : ''}${formAttrs.stillbirths_dates.title}`}
            </p>
            <textarea
              className={classNames({
                [Styles.textArea]: true,
                [Styles.textAreaHighLight]: highlightRequiredField
                  && shallHighlight(formAttrs.stillbirths_dates),
              })}
              onChange={this.handleInputChange}
              name={formAttrs.stillbirths_dates.propName}
              value={formAttrs.stillbirths_dates.value}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start' }}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.longCell]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.pregnancies_history),
              })}
            >
              {`${formAttrs.pregnancies_history.required ? '*' : ''}${formAttrs.pregnancies_history.title}`}
            </p>
            <textarea
              className={classNames({
                [Styles.textArea]: true,
                [Styles.textAreaHighLight]: highlightRequiredField
                  && shallHighlight(formAttrs.pregnancies_history),
              })}
              onChange={this.handleInputChange}
              name={formAttrs.pregnancies_history.propName}
              value={formAttrs.pregnancies_history.value}
            />
          </div>
        </div>

        <div className={Styles.row} style={{ justifyContent: 'flex-start', margin: '20px 0 0 0' }}>
          <div className={Styles.cell} style={{ width: '100%' }}>
            <p className={Styles.fieldTitle}>If yes, sex, number, and age of Children</p>
            {childrenInfoTable}
          </div>
        </div>

        <div style={{ height: '40px' }} />
        {BaseForm.renderYesOrNoWithNotesTable({
          formAttrs,
          notesText: 'Other',
          radioBtnClickHandler: this.handleRadioBtnEvent,
          notesInputHandler: this.handleInputChange,
          highlightRequiredField,
        })}

      </div>
    );
  }
}

MaritalChildStatus.defaultProps = {
  highlightRequiredField: false,
};

MaritalChildStatus.propTypes = {
  form: PropTypes.instanceOf(MaritalChildStatusForm).isRequired,
  highlightRequiredField: PropTypes.bool,
};

export default Radium(MaritalChildStatus);
