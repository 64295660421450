export const EggFreezingFAQs = [
  {
    Q: 'Why would I consider preserving my eggs?',
    A: 'You may not be ready to start a family right now for various reasons such as relationships, your career, finances or other reasons, but know that you might want kids in the future.',
  },
  {
    Q: 'What does age have to do with fertility?',
    A: 'In general, the younger you are, the better chances you have of producing a higher quantity and quality of eggs. \n'
        + 'Ovarian reserve: When we are born, we are born with all of the eggs we will ever have. As we get older, we lose those eggs every month when they are not used. \n'
        + 'Age also degrades the genetic material of our eggs which can make having a healthy pregnancy tough. Eggs that are lower quality have a higher chance of having chromosomal abnormalities. Chromosomal abnormalities can also lead to genetic disorders like Down syndrome. In our 20s, about 80–90% of our eggs will be genetically normal, compared to about 50% when we\'re in our 30s, and about 10–20% at 40 and older.',
  },
  {
    Q: 'What can I expect during the egg freezing process?',
    A: 'Typically, during a normal period, you produce a single egg. With egg freezing, you\'re on hormone injections for 8-12 days to stimulate your ovaries to produce multiple eggs in one menstrual cycle. \n'
        + 'You\'ll have 5–7 short office visits, including blood tests and transvaginal ultrasound exams. \n'
        + 'There\'s a 15-minute surgical procedure performed under mild anesthesia to retrieve the eggs from your ovaries.',
  },
  {
    Q: 'How are the eggs retrieved?',
    A: 'While you are under anesthesia, the physician inserts a needle through the vaginal canal into each ovary to draw out the eggs and surrounding fluid. This is done with the guidance of an ultrasound. The needle is attached to a catheter that\'s connected to a test tube. The eggs flow through the catheter into these test tubes. They are then handed off to the embryologist, a highly trained expert in the science of egg freezing. The entire procedure takes about 10–15 minutes and there are no extreme measures needed such as stitches. You won\'t even be left with a scar!',
  },
  {
    Q: 'Is there any risk of side effects from the egg freezing medications?',
    A: 'About 1 in 4 women using stimulation medications experience hormonal fluctuations that can cause mood swings, headaches, hot/cold flashes, insomnia, breast tenderness, fluid retention and/or bloating. \n'
        + 'Your injection site could become sore, red, or slightly bruised. Allergic reactions are usually rare.\n'
        + ' “Ovarian hyperstimulation syndrome” or OHSS: is associated with swollen, enlarged ovaries and the collection of fluid in the abdominal cavity. In less than 5% of cases, OHSS can require monitoring or bedrest. In some extreme cases (less than 1% of women taking these medications) OHSS can cause medical complications, like ovarian torsion, that might require surgery.',
  },
  {
    Q: 'How long does it take to recover from the retrieval procedure?',
    A: 'The vaginal soreness and cramping can last for a few days after the procedure. After a few days, everything will go back to normal.',
  },
  {
    Q: 'Am I likely to need more than one egg freezing procedure to preserve enough eggs?',
    A: 'This also usually depends on age. In general, when you are younger, you are likely to produce a larger number of eggs in one egg freezing cycle than when you are older. Younger women also produce a higher percentage of genetically healthy eggs, so they need to freeze fewer to begin with.',
  },
  {
    Q: 'How long can I keep my eggs frozen?',
    A: '5–10 years, with the longest reported successful thaw coming after 14 years. There has not been an evidence that shows that the health or viability of frozen eggs decreases over time.',
  },
];

export const EggFreezingFAQsZh = [
  {
    Q: '我为什么要考虑保存我的卵子?',
    A: '由于各种各样的原因，比如感情生活、职业、经济或其他原因，您现在可能还没有准备好组建家庭，但是您知道您以后可能会想要孩子。',
  },
  {
    Q: '年龄和生育能力有什么关系?',
    A: '一般来说，您越年轻，您就越有可能生产出数量更多和质量更高的卵子。卵巢储备:当我们出生的时候，我们生来就拥有所有的卵子。随着年龄的增长，我们每个月都会失去那些不用的卵子。\n'
        + '年龄也会降低卵子的遗传物质，这使得健康地怀孕变得困难。质量较低的卵子发生染色体异常的几率较高。染色体异常也会导致唐氏综合症等遗传病。在我们20多岁的时候，大约80-90%的卵子在基因上是正常的，而在我们30多岁的时候，这一比例约为50%，40岁以上的时候这一比例约为10% -20%。',
  },
  {
    Q: '冷冻卵子的过程是什么？',
    A: '通常情况下，您会产下一个卵子。冷冻卵子需要注射8-12天的激素，以刺激卵巢在一个月经周期内产生多个卵子。\n'
        + '您将有5-7次短暂的诊所访问，包括血液检查和阴道超声检查。在轻度麻醉下，需要15分钟的外科手术才能从卵巢取出卵子。',
  },
  {
    Q: '卵子是如何取出的?',
    A: '当您处于麻醉状态时，医生将一根针穿过阴道插入每个卵巢，抽出卵子和周围的液体。这是在超声引导下完成的。针被连接到与试管相连的导管上。卵子通过导管进入这些试管。然后把它们交给胚胎学家，他们都是在卵子冷冻科学方面训练有素的专家。整个过程大约需要10-15分钟，而且不需要像缝针这样的极端措施。您甚至不会留下疤痕!',
  },
  {
    Q: '冷冻卵子的过程疼吗?',
    A: '在10到15分钟的取出卵子的过程中，您将处于麻醉状态，以防止感觉到任何疼痛。当你醒来时，您的阴道可能会感到一些疼痛。您可能还会感到腹部绞痛，就像您经期时的感觉一样。',
  },
  {
    Q: '冷冻卵子药物有副作用的风险吗?',
    A: '大约四分之一的服用刺激性药物的女性会经历荷尔蒙波动，导致情绪波动、头痛、潮冷潮热、失眠、乳房压痛、液体潴留和/或腹胀。您的注射部位可能会变得疼痛、发红或轻微瘀伤。过敏反应通常很少见。卵巢过度刺激综合征(OHSS):与卵巢肿胀、增大和腹腔积液有关。在不到5%的情况下，OHSS可能需要监测或监测。在一些极端情况下(服用这些药物的女性不到1%)，OHSS可能会导致医学并发症，如卵巢扭转，这可能需要手术。',
  },
  {
    Q: '恢复需要多长时间?',
    A: '术后阴道疼痛可能会持续几天。几天后，一切都会恢复正常。',
  },
  {
    Q: '我可能需要不止一个卵子冷冻程序来保存足够的卵子吗?',
    A: '这通常也取决于年龄。一般来说，当您年轻的时候，您可能会在一个冷冻卵子的周期内产生比您年老时更多的卵子。年轻女性生产的基因健康的卵子比例也更高，所以她们一开始需要冷冻的卵子更少。',
  },
  {
    Q: '我的卵子可以冷冻多久',
    A: '5-10年，据报道最长的解冻时间是在14年后。没有证据表明冷冻卵子的健康或活力会随着时间的推移而下降。',
  },
];


export const EnKeyMaps = new Map([
  ['title', 'Why Egg Freezing?'],
  ['intro', 'Or Oocyte cryopreservation, can increase the chance of a future pregnancy for women:\n'
  + '1. Who would like to preserve their future ability to have children, either because\n'
  + '    • they do not yet have a partner\n'
  + '    • for other personal reasons\n'
  + '    • for medical reasons (example, who was diagnosed with cancer but has not yet received any treatment yet.)\n'
  + '2. Who have been diagnosed with cancer, but have not yet begun chemotherapy or radiotherapy.\n',
  ],
  ['btn_txt', 'Get a Free Consultation'],
  ['process_title', 'Egg Freezing Process'],
  ['process', [
    'Consultation with the doctors',
    'Cycle Calendar Planned',
    'Self injection medication for 10-12 days 4-6 follow up doctor\'s office visits',
    '15-30 min egg retrieval procedure',
    'Egg Cryopreservation',
  ]],
  ['leader_title', 'Egg Freezing Advantages'],
  ['we_are_leader', '• In the United States, we have the newest and best egg freezing technology, "VITRIFICATION", which means to transform to glass or a glass-like substance\n'
  + '• "VITRIFICATION" has higher levels of cryoprotectants (anti-freeze), which prevent the formation of crystals and damage of the cell\n'
  + '• Success rates of thawing and survival are more than 90 percent.\n'
  + '• Preserving time can be up to 10 years'],
]);

export const ZhKeyMaps = new Map([
  ['title', '冻卵'],
  ['intro', '为什么要冻卵？女性在出生时，卵子的数量是固定的，随着年纪的增长，卵巢的功能会降低，染色体发生变异几率增加，例如30岁的时候原来是10%的变异几率，到了40岁几率可能就达到50%，所以有很多女性才选择在最好的年纪将质量好的卵子冷冻起来以防万一。'],
  ['btn_txt', '免费咨询'],
  ['process_title', '冷冻卵子流程'],
  ['process', [
    '咨询',
    '周期安排',
    '打促排针',
    '取卵',
    '培养',
    '冷冻',
    '复苏',
  ]],
  ['leader_title', '美国冻卵优势'],
  ['we_are_leader', '• 美国冻卵技术使用的是最先进的玻璃化冷冻技术\n'
  + '• 此技术既可以保证卵子内部的细胞组织不被破坏又能让冷冻的卵子複甦率达到90%以上\n'
  + '• 卵子保存的时间可以长达10年以上, 这是世界上其他国家都不能达到的\n'
  + '• 在美国进行冻卵时, 不需提供任何证件, 国内的女士只要获得美签, 即可赴美进行冻卵'],
]);
