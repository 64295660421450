import React from 'react';
import Styles from './LeftMenu.module.scss';
import logo from '../../../../assets/logo.png';
import { ReactComponent as IcFacebook } from './assets/facebook.svg';
import { ReactComponent as IcInstagram } from './assets/instagram.svg';
import { ReactComponent as IcTwitter } from './assets/twitter.svg';
import { ReactComponent as IcPhone } from './assets/phone.svg';
import { ReactComponent as IcFax } from './assets/fax.svg';
import { ReactComponent as IcWechat } from './assets/wechat.svg';
import { ReactComponent as IcMail } from './assets/email.svg';

class LeftMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '11160 Warner Ave, #305, Fountain Valley, CA 92708',
      copyRight: '© 2021 NewCare All Rights Reserved.',
      menuItems: [
        {
          id: 0,
          icon: <IcPhone className={Styles.menuItemIcon} />,
          value: '1 (818) 617-9555',
        }, {
          id: 1,
          icon: <IcFax className={Styles.menuItemIcon} />,
          value: '1 (818) 743-8808',
        }, {
          id: 2,
          icon: <IcWechat className={Styles.menuItemIcon} />,
          value: 'newcareivf',
        }, {
          id: 4,
          icon: <IcMail className={Styles.menuItemIcon} />,
          value: 'info@usnewcare.com',
        },
      ],
      socialMedias: [
        {
          id: 5,
          icon: <IcFacebook className={Styles.socialMediaIcon} />,
          url: 'https://www.facebook.com/usnewcare/',
        }, {
          id: 6,
          icon: <IcInstagram className={Styles.socialMediaIcon} />,
          url: 'https://www.instagram.com/usnewcare/',
        }, {
          id: 7,
          icon: <IcTwitter className={Styles.socialMediaIcon} />,
          url: 'https://twitter.com/usnewcare',
        },
      ],
    };
  }

  render() {
    const {
      address, copyRight, menuItems = [], socialMedias = [],
    } = this.state;
    const menu = menuItems.map(item => (
      <div className={Styles.menuItem} key={item.id}>
        {item.icon}
        <p className={Styles.menuItemValue}>{item.value}</p>
      </div>
    ));
    return (
      <div className={Styles.container}>
        <img alt="logo" className={Styles.logo} src={logo} />
        {menu}
        <div className={Styles.socialMediasList}>
          {socialMedias.map(socialMedia => (
            <a href={socialMedia.url} style={{ textDecoration: 'none' }} key={socialMedia.id}>
              {socialMedia.icon}
            </a>
          ))
          }
        </div>
        <p className={Styles.address}>{address}</p>
        <p className={Styles.copyRight}>{copyRight}</p>
      </div>
    );
  }
}

export default LeftMenu;
