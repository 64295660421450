import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { PROFILE_TYPE_DONOR, PROFILE_TYPE_SURROGATE } from '../../res/constants';
import DonorProfile from './DonorProfile/DonorProfile';
import SurrogateMotherProfile from './SurrogateMotherProfile/SurrogateMotherProfile';

const mapStateToProps = state => ({
  user: state.userState,
  langState: state.langState,
});

const Profile = (props) => {
  const { location } = props;
  const { search } = location;
  const params = queryString.parse(search);
  const { type = PROFILE_TYPE_DONOR } = params;
  if (type === PROFILE_TYPE_SURROGATE) {
    return <SurrogateMotherProfile {...props} />;
  }
  return <DonorProfile {...props} />;
};

Profile.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
};

export default connect(mapStateToProps)(Profile);
