import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { tify } from 'chinese-conv';
import Styles from './EggDonationProcess.module.scss';
import { EggDonationPageSection } from '../../EggDonationPage.css';
import { ReactComponent as ImgGene } from './assets/gene.svg';
import { ReactComponent as ImgGeneZh } from './assets/egg_donor_process_zh.svg';
import { LANG_EN, LANG_ZH_TRADITIONAL } from '../../../../res/constants';

const mapStateToProps = state => ({
  langState: state.langState,
  user: state.userState,
});

class EggDonationProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { langState } = this.props;
    this.setContents(langState.lang);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { langState } = nextProps;
      this.setContents(langState.lang);
    }
  }

  setContents = (lang) => {
    this.setState({
      title: lang === LANG_EN ? 'Egg Donation Process' : '捐卵流程',
    });
  };

  render() {
    const { title = '' } = this.state;
    const { langState } = this.props;
    const { lang } = langState;
    return (
      <EggDonationPageSection className={Styles.section}>
        <div className={Styles.container}>
          <div className={Styles.contents}>
            <h1 className={Styles.title}>{lang === LANG_ZH_TRADITIONAL ? tify(title) : title}</h1>
            {lang === LANG_EN ? (
              <p className={Styles.intro}>
                {'• Egg Donation fee starts at $25,000.00 (depending on the egg donor you choose)\n'
              + '• Entire donation cycle from matching to egg retrieval takes approximately 2 months.\n'}
              </p>
            ) : ''}
            {lang === LANG_EN ? <ImgGene className={Styles.imgGene} />
              : <ImgGeneZh className={Styles.imgGene} />}
          </div>
        </div>
      </EggDonationPageSection>
    );
  }
}

EggDonationProcess.propTypes = {
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(Radium(EggDonationProcess));
