export const EggDonationFAQs = [
  {
    Q: 'What is an egg donor?',
    A: 'An egg donor has her eggs retrieved so that couples and individuals can use these eggs to make a child.',
  },
  {
    Q: 'When is an egg donor needed?',
    A: 'Usually, an egg donor is needed when a woman cannot use her own eggs. Egg donors are also needed for single male parents or same-sex male couples.',
  },
  {
    Q: 'Are egg donors screened?',
    A: 'Extensively. First, the egg donor submits an application. The egg donor is asked many questions about their medical history including their families medical and educational backgrounds. After the initial application, the donors receive a medical exam with bloodwork to make sure there are no infectious diseases. A counseling appointment is then scheduled to provide support to the egg donor and make sure she is mentally prepared for the process.',
  },
  {
    Q: 'What information can I receive about a potential donor?',
    A: 'A wide array of information about your egg donor is gathered including their education, ethnicity and interests. ',
  },
  {
    Q: 'What happens during the matching process?',
    A: 'Once the egg donor has passed all screenings, her photo and information will be put together in a profile for perspective recipients to review. This database is completely confidential and no identifiable information is given. Once a recipient chooses to work with an egg donor, the process to retrieve the eggs begins. A final screening is done and the donor is prepped for her egg donation cycle. She will be given medication to help her make more follicles that will (hopefully) produce a high number of eggs.',
  },
  {
    Q: 'How long does the egg donation process last?',
    A: 'Typically, the egg donation process will take 4 to 6 weeks. Once the egg donor is screened, she is put on medications that will help her create more follicles. The egg retrieval procedure generally takes around 30 minutes.',
  },
];

export const EggDonationFAQsZh = [
  {
    Q: '我可以查看捐卵者的资料然后选择捐卵者吗？',
    A: '可以，加州是美国少数法律允许准父母看捐卵人照片，选择捐卵人的州。',
  },
  {
    Q: '我怎麽样查询NewCare捐卵中心的供卵者资料？',
    A: '出于保护捐卵人信息和受美国赠卵法律的监管，有两种方式查询供卵者信息:\n'
        + '   1. 您可以亲自来NewCare捐卵中心的洛杉矶办公室浏览捐卵人数据。\n'
        + '   2. 您可以远程将需求提供给我们的案件经理，签署保密协议后，案件经理会为您准备候选人资料提供您参考。',
  },
  {
    Q: '我可以和捐卵者见面吗？',
    A: '不可以，法律要求捐卵过程是盲捐，即准父母和捐卵者在互相不认识的情况下完成捐卵过程，否则有可能会给双方带来情感上的负担和伦理上的濳在风险。',
  },
  {
    Q: '从我确定供卵人到卵子取出完成需要多长时间？',
    A: '平均两个月左右，诊所已完成了所有捐卵人的背景调查和身体筛查后，只需要协调供卵人的时间就可以直接进入周期取卵。',
  },
  {
    Q: '我可以用赠卵胚胎与我自己的胚胎一起移植吗？',
    A: '可以对于 长时间取卵仍然未能获得高质量胚胎的客户，可以尝试使用捐卵胚胎与自有胚胎一起移植提高成功率',
  },
  {
    Q: '捐卵的成功率高吗？',
    A: '捐卵的成功率非常高，捐卵者是21到30岁身体健康的年轻女性，当精子和子宫条件符合时，捐卵妊娠成功率可达到 85%以上。',
  },
  {
    Q: '捐赠卵子需要选择与自己同血型的吗?',
    A: '仅从医学角度来说，不需要血型相同，怀孕母体与胚胎血型之间没有配对要求，是否选择同样血型主要是基于患者个人的考虑。',
  },
];


export const EnKeyMaps = new Map([
  ['title', 'Egg Donation'],
  ['intro', 'Oocytes (eggs) from a young woman are fertilized in the laboratory with the sperm of the recipient\'s partner, or selected sperm donor, to create embryos. These embryos are transferred into the patient\'s (recipient/surrogate mother) uterus to develop into a baby. This procedure enables women with severely diminished ovarian function, or genetic abnormalities, to carry and deliver their own child.'],
  ['btn_txt', 'View Egg Donor Profiles'],
  ['requirement_title', 'Who needs Egg Donation?'],
  ['requirements', '• Women with diminished ovarian reserve, and other health considerations\n'
  + '• Genetic abnormalities that prevent or restrict the ability to carry and deliver their own child\n'
  + '• Women who have tried IVF 3 or more times, and fail with good quality of sperms. (highly recommended)\n'
  + '• LGBT / Single men\n'],
  ['advantage_title', 'NewCare Egg Donation Program Advantages'],
  ['advantages', '• Free consultation\n'
  + '• Has Fertility Specialist Lawyers draft contracts. \n'
  + '• All donors will be covered under "Egg Donor Complication Insurance" .\n'
  + '• Anonymous donation. Both intended parents and egg donors\' privacy will be protected.\n'
  + 'The whole donation process will be supervised by NewCare case coordinators, to insure all doctor visits, medications, and injections are done correctly and efficiently. \n'],
]);

export const ZhKeyMaps = new Map([
  ['title', '卵子供给'],
  ['intro', '供卵试管婴儿，又名赠卵试管婴儿，或捐卵试管婴儿，是指在准母亲由于卵巢储备衰竭或其他遗传疾病等原因自有卵子不能使用的情况下，可以借助试管婴儿技术，从第三方卵子捐赠者那里取出卵子，与准父亲的精子结合后，形成受精卵胚胎，再将胚胎移植回准母亲或者代孕母亲子宫中受孕的过程'],
  ['btn_txt', '浏览捐卵天使'],
  ['requirement_title', '需要供卵对象:'],
  ['requirements', '• 免费咨询\n'
  + '• 年龄 < 50岁\n'
  + '• 专业合法的第三方服务\n'
  + '• 卵巢储备衰竭的妇女\n'
  + '• 女方有遗传病或染色体异常\n'
  + '• 女方自行试管3次失败且男方精子质量良好，建议供卵\n'],
  ['advantage_title', '我们的供卵优势'],
  ['advantages', '• 免费咨询\n'
  + '• 专业生殖律师起草法律合同\n'
  + '• 专业合法的第三方服务\n'
  + '• 捐卵者有定制的捐卵保险保障安全"\n'
  + '• 直接拥有且无缝隙管理捐卵人及代母，无中间机构\n'
  + '• 匿名捐卵，全程保护准父母和捐卵者的隐私\n'
  + '• 捐卵全程实时陪同监督，确保检查，用药打针无误\n'],
]);
