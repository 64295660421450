import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styles from './HomePage.css';
import Header from '../../components/Header/Header';
import SuccessStories from './components/SuccessStories/SuccessStories';
import Footer from '../../components/Footer/Footer';
import { onGetConsultation, syncLang, translate } from '../../Utils/Util';
import IntroSection from '../../components/IntroSection/IntroSection';
import { ZhKeyMaps, EnKeyMaps } from './res/constants';
import {
  LANG_EN,
  PATH_DONOR_APPLICATION,
  PATH_SURROGATE_APPLICATION,
  USER_STATUS_REQUEST_CONSULTATION,
} from '../../res/constants';
import ExtraIntro from '../../components/ExtraIntro/ExtraIntro';
import Styles from './HomePage.module.scss';

const mapStateToProps = state => ({
  langState: state.langState,
  user: state.userState,
});
const LangPacks = { ZhKeyMaps, EnKeyMaps };

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    syncLang(props);
    this.state = {
      width: 1600, height: 722,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions, false);
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 675) {
      this.setState({ width: 375, height: 812 });
    } else {
      this.setState({ width: 1600, height: 722 });
    }
  };

  render() {
    const { user, langState } = this.props;
    const { lang } = langState;
    const { width, height } = this.state;
    const advantagesTitle = lang === LANG_EN ? EnKeyMaps.get('advantage_title') : ZhKeyMaps.get('advantage_title');
    const advantages = lang === LANG_EN ? EnKeyMaps.get('advantages') : ZhKeyMaps.get('advantages');
    const ourServicesTitle = lang === LANG_EN ? EnKeyMaps.get('our_services_title') : ZhKeyMaps.get('our_services_title');
    const ourServices = lang === LANG_EN ? EnKeyMaps.get('our_services') : ZhKeyMaps.get('our_services');
    const beSurrogateMotherTitle = lang === LANG_EN ? EnKeyMaps.get('be_surrogate_mother_title') : ZhKeyMaps.get('be_surrogate_mother_title');
    const beSurrogateMother = lang === LANG_EN ? EnKeyMaps.get('be_surrogate_mother') : ZhKeyMaps.get('be_surrogate_mother');
    const beEggDonorTitle = lang === LANG_EN ? EnKeyMaps.get('be_egg_donor_title') : ZhKeyMaps.get('be_egg_donor_title');
    const beEggDonor = lang === LANG_EN ? EnKeyMaps.get('be_egg_donor') : ZhKeyMaps.get('be_egg_donor');
    const moreInfoTitle = lang === LANG_EN ? EnKeyMaps.get('more_info_title') : ZhKeyMaps.get('more_info_title');
    const moreInfo = lang === LANG_EN ? EnKeyMaps.get('more_info') : ZhKeyMaps.get('more_info');
    const surrogateBtnTxt = lang === LANG_EN ? EnKeyMaps.get('surrogate_btn') : ZhKeyMaps.get('surrogate_btn');
    const eggDonorBtnTxt = lang === LANG_EN ? EnKeyMaps.get('egg_donor_btn') : ZhKeyMaps.get('egg_donor_btn');
    return (
      <div style={styles.container}>
        <Header />
        <IntroSection
          LangPacks={LangPacks}
          bg={`https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,q_80,w_${width},h_${height}/v1557173928/NewCare/Assets/homepage_cover.png`}
          onBtnClicked={() => onGetConsultation({ ...this.props })}
          btnVisible={user == null || user.status !== USER_STATUS_REQUEST_CONSULTATION}
        />
        <ExtraIntro title={advantagesTitle} details={advantages} styles={{ marginBottom: '0' }} />
        <ExtraIntro
          //title={ourServicesTitle}
          //details={ourServices}
          styles={{ margin: '0' }}
          images={[
            'https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,e_shadow:40,q_80,w_800/v1557100368/NewCare/Assets/doctor2.jpg',
            'https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,e_shadow:40,q_80,w_800/v1557100367/NewCare/Assets/doctor5.jpg',
            'https://res.cloudinary.com/hyyxofhbh/image/upload/c_thumb,e_shadow:40,q_80,w_800/v1557100363/NewCare/Assets/baby3.jpg',
          ]}
        />
        <ExtraIntro title={beSurrogateMotherTitle} details={beSurrogateMother} richText styles={{ marginBottom: '0' }} />
        <div className={Styles.buttonWrapper} style={{ marginTop: '20px' }}>
          <a href={PATH_SURROGATE_APPLICATION} style={{ textDecoration: 'none' }}>
            <div className={Styles.button}>
              <p>{translate({ lang, value: surrogateBtnTxt })}</p>
            </div>
          </a>
        </div>
        <ExtraIntro title={beEggDonorTitle} details={beEggDonor} richText styles={{ marginBottom: '0' }} />
        <div className={Styles.buttonWrapper} style={{ marginTop: '20px' }}>
          <a href={PATH_DONOR_APPLICATION} style={{ textDecoration: 'none' }}>
            <div className={Styles.button}>
              <p>{translate({ lang, value: eggDonorBtnTxt })}</p>
            </div>
          </a>
        </div>
        <ExtraIntro title={moreInfoTitle} details={moreInfo} richText styles={{ marginBottom: '0' }} />
        <SuccessStories />
        <Footer />
      </div>
    );
  }
}

HomePage.defaultProps = {
  user: null,
};

HomePage.propTypes = {
  user: PropTypes.shape(
    {
      display_name: PropTypes.string,
    },
  ),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  langState: PropTypes.shape({
    lang: PropTypes.string,
  }).isRequired,
};

export default withRouter(connect(mapStateToProps)(Radium(HomePage)));
