import React from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import * as classNames from 'classnames';
import Styles from '../../../../DonorApplication/components/forms/BasicInformation/BasicInformation.module.scss';
import GeneralQuestionsForm from './GeneralQuestionsForm';
import CheckBox from '../../../../../components/CheckBox/CheckBox';
import { FIELD_TYPE_TEXTAREA } from '../../../../../res/constants';
import BaseForm from '../../../../DonorApplication/components/forms/BasicInformation/forms/BaseForm';
import { shallHighlight } from '../../../../../Utils/Util';

class GeneralQuestions extends React.Component {
  constructor(props) {
    super(props);
    const { form = new GeneralQuestionsForm() } = props;
    this.state = {
      form,
    };
  }

  handleInputChange = (event) => {
    const { target } = event;
    const { name: propName, value } = target;
    const { form } = this.state;
    const { user_attributes: userAttrs, form_attributes: formAttrs } = form;
    if (Object.prototype.hasOwnProperty.call(userAttrs, propName)) {
      userAttrs[propName].value = value;
    } else if (Object.prototype.hasOwnProperty.call(formAttrs, propName)) {
      formAttrs[propName].value = value;
    }
    this.setState({ form });
  };

  handleCheckboxChange = (event) => {
    const { name: propName, text } = event;
    const { form } = this.state;
    const { form_attributes: formAttrs } = form;
    if (Object.prototype.hasOwnProperty.call(formAttrs, propName)) {
      const { options, value } = formAttrs[propName];
      let selectedOptions = [];
      // if value is not empty string
      if (value.trim().length > 0) {
        selectedOptions = value.split(',');
      }
      // if it was selected
      if (selectedOptions.indexOf(text) >= 0) {
        selectedOptions.splice(selectedOptions.indexOf(text), 1);
      } else if (options.indexOf(text) >= 0) {
        // if it was not selected and options contains such option
        selectedOptions.push(text);
      }
      formAttrs[propName].value = '';
      if (selectedOptions.length > 0) {
        formAttrs[propName].value = selectedOptions.reduce((accum, currentVal) => `${accum},${currentVal}`);
      }
      this.setState({ form });
    }
  };

  handleRadioBtnEvent = (event) => {
    const { propName, choice } = event;
    const { form } = this.state;
    const { user_attributes: userAttrs, form_attributes: formAttrs } = form;
    const attrs = { ...userAttrs, ...formAttrs };
    if (Object.prototype.hasOwnProperty.call(attrs, propName)) {
      attrs[propName].value = choice;
    }
    this.setState({ form });
  };

  renderQuestionsWithTextArea = () => {
    const { form } = this.state;
    const { user_attributes: userAttrs, form_attributes: formAttrs } = form;
    const { highlightRequiredField } = this.props;
    return Object.values({ ...userAttrs, ...formAttrs })
      .filter(attr => attr.type === FIELD_TYPE_TEXTAREA)
      .map(attr => (
        <div className={Styles.row} key={attr.propName}>
          <div
            className={classNames({
              [Styles.cell]: true,
              [Styles.longCell]: true,
            })}
          >
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(attr),
              })}
            >
              {attr.required ? `*${attr.title}` : attr.title}
            </p>
            <textarea
              className={classNames({
                [Styles.textArea]: true,
                [Styles.textAreaHighLight]: highlightRequiredField
                  && shallHighlight(attr),
              })}
              onChange={this.handleInputChange}
              name={attr.propName}
              value={attr.value}
            />
          </div>
        </div>
      ));
  };

  render() {
    const { form } = this.state;
    const { form_attributes: formAttrs } = form;
    const questionsWithTextArea = this.renderQuestionsWithTextArea();
    const { highlightRequiredField } = this.props;
    return (
      <div className={Styles.container}>
        <h1>General Questions</h1>
        <div className={Styles.row}>
          <div className={Styles.cell}>
            <p
              className={classNames({
                [Styles.fieldTitle]: true,
                [Styles.highlightRequired]: highlightRequiredField
                  && shallHighlight(formAttrs.willing_work_with),
              })}
            >
              {`*${formAttrs.willing_work_with.title}`}
            </p>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {formAttrs.willing_work_with.options.map(item => (
                <CheckBox
                  text={item}
                  containerStyle={{ margin: '0 50px 20px 0' }}
                  name={formAttrs.willing_work_with.propName}
                  onClick={this.handleCheckboxChange}
                  checked={formAttrs.willing_work_with.value.includes(item)}
                  key={item}
                />
              ))}
            </div>
          </div>
        </div>

        <div style={{ height: '40px' }} />
        {BaseForm.renderYesOrNoWithNotesTable({
          formAttrs,
          radioBtnClickHandler: this.handleRadioBtnEvent,
          notesInputHandler: this.handleInputChange,
          highlightRequiredField,
        })}

        <div style={{ height: '40px' }} />
        {questionsWithTextArea}

      </div>
    );
  }
}

GeneralQuestions.defaultProps = {
  highlightRequiredField: false,
};

GeneralQuestions.propTypes = {
  form: PropTypes.instanceOf(GeneralQuestionsForm).isRequired,
  highlightRequiredField: PropTypes.bool,
};

export default Radium(GeneralQuestions);
