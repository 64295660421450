import React, { Component } from 'react';
import './index.scss'
import ReactLoading from 'react-loading';
import ReactDOM from 'react-dom';

class Spin extends Component {

  componentWillReceiveProps(nextProps){
    const body = document.getElementsByTagName('body')
    if (!body) return;
    if (nextProps.loading === true) {
        ReactDOM.findDOMNode(body[0]).style = 'overflow-y:hidden;padding-right:16px;'
    } else if (nextProps.loading === false) {
      ReactDOM.findDOMNode(body[0]).style = ''
    }
  }
  
  render() {
    const { tip, loading } = this.props
    const style = {
      background: '#fff',
      opacity: '0.4'
    }
    return (
      <div className='spin-loading'>
        <div style={loading ? style : {}}>
          {this.props.children}
        </div>
        {loading && <ReactLoading className='loading-icon' type='spin' color="rgb(53, 126, 221)" width='40px' height='40px' />}
      </div>
    );
  }
}

export default Spin
